import {
  View,
  Text,
  SafeAreaView,
  Dimensions,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Feather, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";
import React, { useEffect, useRef, useState } from "react";
import {
  Categories,
  DateTimeSelect,
  FloatingButton,
  Input,
  Modal,
  Row,
  BackButton,
} from "../../components/all";
import hospitals from "../../constants/stub/hospitals.json";
import colors from "../../constants/colors";
import useDebounce from "../../utils/useDebounce";
import { normalize } from "../../utils/normalize";
import { useQuery } from "react-query";
import { getLocation } from "../../services/job";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecentSearch,
  removeRecentSearch,
} from "../../reducers/search/recentSearch";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";
import {
  resetSearchFilter,
  setSearchFilter,
} from "../../reducers/search/searchFilter";
import allStates from "../../constants/states";

export default function (props) {
  const searchInputRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [searchQueries, setSearchQueries] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 1500);
  const dispatch = useDispatch();

  const {
    data: locationQuery,
    refetch,
    isLoading,
    isFetched: locationFetched,
  } = useQuery(["filterLocation", { query: searchQueries }], getLocation, {
    cacheTime: 0,
  });
  const { recentSearch } = useSelector((state) => state.recentSearch);
  const { filters } = useSelector((state) => state.searchFilter);

  let locations = locationQuery?.data?.data;

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef?.current.focus();
    }
  }, [searchInputRef]);

  useEffect(() => {
    if (locationQuery?.data) {
      setSearchLoading(false);
    }
  }, [locationQuery]);

  useEffect(() => {
    if (searchText) {
      setSearchLoading(true);
    }
  }, [searchText]);

  useEffect(
    () => {
      if (debouncedSearchText) {
        setSearchQueries(debouncedSearchText);
      } else {
        setSearchLoading(false);
      }
    },
    [debouncedSearchText] // Only call effect if debounced search term changes
  );

  const setCurrentLocation = () => {
    setSearchText("");
    const { location, ...newFilters } = filters;
    dispatch(
      setSearchFilter({
        ...newFilters,
      })
    );
    props.navigation.goBack();
  };

  return (
    <View>
      <BackButton />
      <View style={{ paddingHorizontal: 24 }}>
        <View style={{ marginBottom: 24, marginTop: -40 }}>
          <Row style={{ justifyContent: "flex-end" }} centered>
            <TouchableOpacity onPress={setCurrentLocation}>
              <Row centered>
                <Text style={{ marginRight: 8 }}>Set Current Location</Text>
                <MaterialCommunityIcons name="target" size={20} color="black" />
              </Row>
            </TouchableOpacity>
          </Row>
        </View>
        <Input
          icon={<Feather name="search" />}
          placeholder="Search by state or city"
          bordered={true}
          ref={searchInputRef}
          value={searchText}
          onChangeText={(text) => setSearchText(text)}
        />

        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{
            height: Dimensions.get("window").height - 250,
            paddingTop: 24,
          }}
        >
          {searchText?.length === 0 ? (
            <>
              {allStates
                .map((o) => {
                  const coords = o.coord.split(",");
                  return {
                    address_line_1: o.label,
                    latitude: parseFloat(coords[0]),
                    longitude: parseFloat(coords[1]),
                  };
                })
                .map((item, index) => {
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        dispatch(
                          addRecentSearch({
                            type: "location",
                            data: item.address_line_1,
                          })
                        );
                        dispatch(
                          setSearchFilter({
                            ...filters,
                            location: item,
                          })
                        );
                        props.navigation.goBack();
                      }}
                    >
                      <Row style={styles.resultRowContainer}>
                        <Row centered>
                          <Feather
                            name="map-pin"
                            size={18}
                            color={colors.primary}
                          />
                          <View>
                            <Text style={styles.resultTitle}>
                              {item.address_line_1}
                            </Text>
                          </View>
                        </Row>
                        <Feather
                          name="chevron-right"
                          size={18}
                          color="rgba(0,0,0,0.2)"
                        />
                      </Row>
                      <View style={styles.resultContainer} />
                    </TouchableOpacity>
                  );
                })}
              {/* {recentSearch?.length === 0 && (
                <View
                  style={{
                    marginVertical: 144,
                    alignItems: "center",
                  }}
                >
                  <Ionicons
                    name="alert-circle-outline"
                    size={24}
                    color={"rgba(0,0,0,0.3)"}
                  />
                  <Text style={{ color: "rgba(0,0,0,0.3)", marginTop: 14 }}>
                    No recent locations
                  </Text>
                </View>
              )} */}
            </>
          ) : (
            <>
              {searchLoading ? (
                <View style={{ paddingTop: 10 }}>
                  {[0, 0, 0].map(() => (
                    <>
                      <ContentLoader
                        speed={2}
                        width={300}
                        height={40}
                        viewBox="0 0 300 40"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        {...props}
                      >
                        <Rect
                          x="45"
                          y="18"
                          rx="3"
                          ry="3"
                          width="215"
                          height="7"
                        />
                        <Rect
                          x="45"
                          y="6"
                          rx="3"
                          ry="3"
                          width="95"
                          height="6"
                        />
                        <Rect
                          x="0"
                          y="56"
                          rx="3"
                          ry="3"
                          width="410"
                          height="6"
                        />
                        <Rect
                          x="0"
                          y="72"
                          rx="3"
                          ry="3"
                          width="380"
                          height="6"
                        />
                        <Rect
                          x="0"
                          y="88"
                          rx="3"
                          ry="3"
                          width="178"
                          height="6"
                        />
                        <Circle cx="17" cy="21" r="13" />
                        <Rect
                          x="46"
                          y="30"
                          rx="3"
                          ry="3"
                          width="215"
                          height="7"
                        />
                      </ContentLoader>
                      <View style={{ margin: 10 }} />
                    </>
                  ))}
                </View>
              ) : (
                <>
                  {locations?.map((item) => (
                    <TouchableOpacity
                      onPress={() => {
                        setSearchText("");
                        dispatch(
                          addRecentSearch({
                            type: "location",
                            data: item.address_line_1,
                          })
                        );
                        dispatch(
                          setSearchFilter({
                            ...filters,
                            location: item,
                          })
                        );
                        props.navigation.goBack();
                      }}
                    >
                      <Row style={styles.resultRowContainer}>
                        <Row centered>
                          <Feather
                            name="map-pin"
                            size={18}
                            color={colors.primary}
                          />
                          <View>
                            <Text style={styles.resultTitle}>
                              {item.address_line_1}
                            </Text>
                            <Text style={styles.resultAddress}>
                              {item.address_line_2}
                            </Text>
                          </View>
                        </Row>
                        <Feather
                          name="chevron-right"
                          size={18}
                          color="rgba(0,0,0,0.2)"
                        />
                      </Row>
                      <View style={styles.resultContainer} />
                    </TouchableOpacity>
                  ))}
                  {locations?.length === 0 && locationFetched && (
                    <View
                      style={{
                        marginVertical: 144,
                        alignItems: "center",
                      }}
                    >
                      <Ionicons
                        name="alert-circle-outline"
                        size={24}
                        color={"rgba(0,0,0,0.3)"}
                      />
                      <Text
                        style={{
                          textAlign: "center",
                          color: "rgba(0,0,0,0.3)",
                          marginTop: 14,
                          lineHeight: 24,
                        }}
                      >
                        {`No location found\nTry a different keyword`}
                      </Text>
                    </View>
                  )}
                </>
              )}
            </>
          )}
        </ScrollView>
      </View>
    </View>
  );
}

const styles = {
  resultTitle: {
    marginLeft: 24,
    fontWeight: "bold",
    fontFamily: "NotoSans-Bold",
  },
  resultAddress: {
    marginLeft: 24,
    marginTop: 4,
    maxWidth: "88%",
    fontSize: normalize(13),
  },
  resultContainer: {
    marginBottom: 18,
    width: "100%",
    alignSelf: "center",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.03)",
  },
  resultRowContainer: {
    marginBottom: 18,
    alignItems: "center",
    justifyContent: "space-between",
  },
  recentContainer: {
    marginTop: 20,
    padding: 8,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "space-between",
  },
};
