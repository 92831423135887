import React from "react";
import { Text, View, Dimensions, Platform } from "react-native";
import { WebView } from "react-native-webview";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentSetup } from "../../reducers/auth/user";
import {
  BackButton,
  MenuButton,
  ScreenScrollView,
  GradientHeader,
} from "../../components/all";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const WebViewScreen = (props) => {
  const dispatch = useDispatch();
  const safeInsets = useSafeAreaInsets();
  const gotoURL = props.route.params?.gotoURL;
  const hideBackButton = props.route.params?.hideBackButton;
  const { isDoctor } = useSelector((state) => state.user);

  const handleWebViewNavigationStateChange = (newNavState) => {
    const { url } = newNavState;
    if (!url) return;
  };
  let source = {};
  let link = "";
  let isPDF = true;
  const links = {
    policy:
      "https://docs.google.com/document/d/e/2PACX-1vSo1kguu2aumjHm1IwT-hT_nDz3G70SHARIWF2PhOfFG5_abMJQqBkKZTh0BctMAg/pub?embedded=true",
    doctorTerms:
      "https://docs.google.com/document/d/e/2PACX-1vR7TPQsU-svthrBmcWp0-TFEkVBdcJIBN7ICZoiAI3onDsMbhLprh9exxvMMn3npg/pub?embedded=true",
  };

  let iframeHTML = (url) =>
    `<iframe width="100%" height="100%" style="border:none;overflow:hidden" src=${url}></iframe>`;

  switch (gotoURL) {
    case "policy":
      source = { html: iframeHTML(links.policy) };
      link = links.policy;
      break;
    case "terms":
      source = {
        html: iframeHTML(links.doctorTerms),
      };
      link = links.doctorTerms;
      break;
    default:
      source = { uri: gotoURL };
      isPDF = false;
      link = gotoURL;
      break;
  }

  return (
    <>
      <GradientHeader />

      <ScreenScrollView
        style={{
          flex: 1,
          backgroundColor: "rgba(0,0,0,0)",
          width:
            Dimensions.get("window").width > 780
              ? 780
              : Dimensions.get("window").width,
          alignSelf: "center",
        }}
        showsVerticalScrollIndicator={false}
      >
        <View style={{ flex: 1, backgroundColor: "white" }}>
          {hideBackButton ? (
            <View
              style={{ flexDirection: "row", paddingTop: safeInsets.top }}
            />
          ) : (
            <BackButton />
          )}

          {Platform.OS === "web" ? (
            <iframe
              src={link}
              height={"100%"}
              width={"100%"}
              style={{
                borderWidth: 0,
                height: Dimensions.get("window").height - safeInsets.top,
              }}
            />
          ) : (
            <WebView
              style={{
                ...styles.container,
                marginLeft: isPDF ? (Platform.OS === "ios" ? 60 : 0) : 0,
              }}
              source={source}
              originWhitelist={["*"]}
              scalesPageToFit={false}
              onNavigationStateChange={handleWebViewNavigationStateChange}
            />
          )}
        </View>
      </ScreenScrollView>
    </>
  );
};
const styles = {
  container: {
    flex: 1,
    width: "100%",
    alignSelf: "center",
  },
};
export default WebViewScreen;
