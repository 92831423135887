import { FontAwesome5 } from "@expo/vector-icons";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Dimensions,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Fade from "react-native-fade";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Categories,
  CategoryTab,
  Centered,
  Input,
  Loading,
  Row,
  StepHeader,
  Steps,
} from "../../../components/all";
import specializations from "../../../constants/stub/specializations.json";
import { addFields } from "../../../reducers/auth/register";
import { setUserToken } from "../../../reducers/auth/user";
import { register, getFacilities } from "../../../services/auth";
import { normalize } from "../../../utils/normalize";
import Styles from "../../../constants/styles";
import professions from "../../../constants/lov/professions";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function Step4({ editProfile, navigation }) {
  const {
    data: facilitiesQuery,
    refetch,
    isLoading,
  } = useQuery("facilities", getFacilities);
  const [facilityData, setFacilityData] = useState(null);
  const [selectedCategoryID, setSelectedCategoryID] = useState(1);


  useEffect(() => {
    getProfession();
    if (facilitiesQuery?.data) {
      // dispatch(setFields(facilitiesQuery.data.data));
      setFacilityData(facilitiesQuery.data.data);
      let cats = [];
      facilitiesQuery.data.data.map((item) => {
        cats.push(item.category);
      });
      setAllCategories([...new Set(cats)]);
    }
  }, [facilitiesQuery]);

  const safeInsets = useSafeAreaInsets();

  const dispatch = useDispatch();
  const { fields, errorFields } = useSelector((state) => state.register);
  const { isDoctor } = useSelector((state) => state.user);

  const [selectedSpecialization, setSelectedSpecialization] = useState(
    fields?.specialization || []
  );
  const [filterInput, setFilterInput] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Doctor");
  const [allCategories, setAllCategories] = useState([]);

  const getProfession = async () => {
    try {
      const value = await AsyncStorage.getItem("profession");

      if (value !== null) {
        let v = JSON.parse(value);
        // value previously stored
        setSelectedCategory(v.slug.split("-")[0]);
        return value;
      }
    } catch (e) {
      // error reading value
    }
  };

  const pickedItem = (index, children) => {
    let newSelected = [...selectedSpecialization];
    if (newSelected.includes(index)) {
      newSelected = newSelected.filter((item) => {
        let childrenIndex = [];
        if (children) {
          children.forEach((child) => {
            childrenIndex.push(child.id);
          });
        }
        return ![index, ...childrenIndex].includes(item);
      });
    } else {
      newSelected.push(index);
      if (children) {
        children.map((item) => {
          newSelected.push(item.id);
        });
      }
    }
    setSelectedSpecialization(newSelected);
    dispatch(addFields({ ...fields, facilities: newSelected }));
  };

  return (
    <>
      <ScrollView
        style={{
          ...Styles.webSmallContainer,

          marginTop: 120,
        }}
      >
        <StepHeader
          label={isDoctor ? `Doctor \nSpecialization` : `Medical \nFacilities`}
          icon={
            <FontAwesome5 name="briefcase-medical" size={24} color="black" />
          }
          subtitle={
            isDoctor
              ? `You will receive alerts on jobs of the selected specialisation, please include fields of interests.`
              : `Select your medical facilities`
          }
        />
        <View style={{ padding: 20 }}>
          <Input
            onChangeText={(text) => setFilterInput(text)}
            value={filterInput}
            placeholder={"Search specializations"}
          />
          {/* <Centered style={{ width: "100%" }}>
            <Fade visible={showError} duration={300}>
              <Text
                style={{ fontSize: normalize(10), color: "red", marginTop: 10 }}
              >
                Please select at least one specialty
              </Text>
            </Fade>
          </Centered> */}
          {isDoctor ? null : (
            <CategoryTab
              selectedCategoryID={selectedCategoryID}
              setSelectedCategoryID={setSelectedCategoryID}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          )}
          {allCategories?.map((category, idx) => {
            let type = category.split("-")[0];
            if (type?.toLowerCase() !== selectedCategory?.toLowerCase()) {
              return null;
            }
            if(!fields.isSpecialist && isDoctor) {
              if(category.split("-")[1].toLowerCase() === "specialist") {
                return null;
              }
            }

            return (
              <Categories
                show={false}
                data={facilityData}
                selected={selectedSpecialization}
                onPress={(index, children) => pickedItem(index, children)}
                title={category.split("-")[1]}
                isCheckbox
                filterText={filterInput}
                category={category}
                isDoctor={isDoctor}
              />
            );
          })}
          {/* {facilityData ? (
            <Categories
              show={false}
              data={facilityData}
              selected={selectedSpecialization}
              onPress={(index, children) => pickedItem(index, children)}
              title={"Doctor - GP"}
              isCheckbox
              filterText={filterInput}
              category={"Doctor-General Practicioner"}
              isDoctor={isDoctor}
            />
          ) : null}

          {fields.isSpecialist || !isDoctor ? (
            <>
              {facilityData ? (
                <Categories
                  show={false}
                  data={facilityData}
                  selected={selectedSpecialization}
                  onPress={(index, children) => pickedItem(index, children)}
                  title={"Doctor - Specialist"}
                  isCheckbox
                  filterText={filterInput}
                  category={"Doctor-Specialist"}
                  isDoctor={isDoctor}
                />
              ) : null}
            </>
          ) : null}

          {fields.isSpecialist || !isDoctor ? (
            <>
              {facilityData ? (
                <Categories
                  show={false}
                  data={facilityData}
                  selected={selectedSpecialization}
                  onPress={(index, children) => pickedItem(index, children)}
                  title={"Nurse - Clinic Assistant"}
                  isCheckbox
                  filterText={filterInput}
                  category={"Nurse-Clinic Assistant"}
                  isDoctor={isDoctor}
                />
              ) : null}
            </>
          ) : null} */}
        </View>
        <View style={{ marginBottom: 140 }} />
      </ScrollView>
      <View
        style={{
          paddingBottom: safeInsets.bottom,
          paddingTop: 10,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
        }}
      >
        <Centered>
          <Row style={{ marginBottom: 24 }}>
            <Button
              label="Back"
              type={"secondary"}
              onPress={() => {
                navigation.goBack();
              }}
            />
            <Button
              label="Next"
              type={"primary"}
              onPress={() => {
                if (selectedSpecialization.length > 0) {
                  navigation.navigate("UploadDocsScreen");
                } else {
                  setShowError(true);
                }
              }}
            />
          </Row>
          <Steps totalPages={isDoctor ? 5 : 4} currentPage={isDoctor ? 5 : 4} />
        </Centered>
      </View>
      {Platform.OS === "web" ? null : (
        <Loading visible={verifying || isLoading} />
      )}
    </>
  );
}
