import {
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { Text, View, Dimensions, Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useDispatch, useSelector } from "react-redux";
import { Row, BackButton, GradientHeader } from "../../components/all";
import colors from "../../constants/colors";
import { useQuery, useMutation } from "react-query";
import { getTransaction } from "../../services/profile";
import { getJobFacilities } from "../../services/job";
import { normalize } from "../../utils/normalize";
import moment from "moment";
const Transaction = (props) => {
  const { isDoctor } = useSelector((state) => state.user);

  const {
    data: transactionQuery,
    refetch: transactionRefetch,
    isLoading: transactionLoading,
  } = useQuery("transaction", () =>
    getTransaction(props.route.params.data.uuid)
  );
  let isCash = props.route.params.data?.is_cash;
  let transactionData = isCash
    ? props.route.params.data
    : transactionQuery?.data?.data;
  const { data: jobFacilitiesQuery, refetch: refetchFacilities } = useQuery(
    "jobFacilities",
    () => getJobFacilities(transactionData?.job?.uuid),
    {
      enabled: !!transactionData,
    }
  );
  const jobFacilityData = jobFacilitiesQuery?.data?.data;
  const sectionTitle = (text, icon) => (
    <Row centered style={{ marginTop: 40, marginBottom: icon ? 8 : 0 }}>
      {icon && <FontAwesome name={icon} color={colors.tertiary} />}
      <Text style={{ ...styles.detailTitle, marginLeft: icon ? 8 : 0 }}>
        {text}
      </Text>
    </Row>
  );
  const title = (text, content) => (
    <Row centered style={{ marginTop: 12 }}>
      <Text
        style={{ fontSize: normalize(14), fontWeight: "bold", marginRight: 8 }}
      >
        {text}:
      </Text>
      <Text>{content}</Text>
    </Row>
  );
  const buttonWrapper = (child) => {
    if (Platform.OS === "web") {
      return (
        <View
          style={{
            flex: 1,
            width:
              Dimensions.get("window").width > 780
                ? 780
                : Dimensions.get("window").width,
            alignSelf: "center",
            marginBottom: 40,
          }}
        >
          {child}
        </View>
      );
    } else {
      return <>{child}</>;
    }
  };
  return (
    <View>
      <GradientHeader />

      {buttonWrapper(<BackButton color={"white"} />)}
      <ScrollView
        style={{
          backgroundColor: "white",
          width:
            Dimensions.get("window").width > 780
              ? 780
              : Dimensions.get("window").width,
          alignSelf: "center",
          borderRadius: 32,
        }}
        contentContainerStyle={styles.transactionContainer}
      >
        <Row style={{ alignItems: "center", paddingTop: 24 }}>
          <Ionicons name="receipt-outline" size={20} />

          <Text style={styles.headerName}>{`Transaction`}</Text>
        </Row>
        {sectionTitle("JOB INFORMATION", "suitcase")}
        <Text>{transactionData?.job_title}</Text>
        {isDoctor && !isCash ? (
          <>
            {sectionTitle("CLINIC NAME", "building-o")}
            <Text>{transactionData?.clinic_name}</Text>
          </>
        ) : null}

        {sectionTitle("JOB DATE & TIME", "clock-o")}
        <Row centered>
          <View>
            <Text
              style={{
                fontSize: normalize(14),
                fontWeight: "bold",
                marginBottom: 4,
              }}
            >
              {moment(transactionData?.job_start_timestamp).format("MMMM Do")}
            </Text>
            <Text style={{ fontSize: normalize(14) }}>
              {moment(transactionData?.job_start_timestamp).format("h:mm A")}
            </Text>
          </View>
          <View>
            <Text style={{ fontSize: normalize(18), marginHorizontal: 12 }}>
              →
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: normalize(14),
                fontWeight: "bold",
                marginBottom: 4,
              }}
            >
              {moment(transactionData?.job_end_timestamp).format("MMMM Do")}
            </Text>
            <Text style={{ fontSize: normalize(14) }}>
              {moment(transactionData?.job_end_timestamp).format("h:mm A")}
            </Text>
          </View>
        </Row>
        {sectionTitle("AMOUNT", "money")}
        <Text>
          {transactionData?.job_price + `${isCash ? " (Cash Payment)" : ""}`}
        </Text>

        {isDoctor || isCash ? (
          <></>
        ) : (
          <>
            {sectionTitle("BANK NAME", "bank")}
            <Text>{transactionData?.transaction_bank_name}</Text>
            {sectionTitle("PAYMENT DATE & TIME", "clock-o")}
            <Text>{transactionData?.transaction_timestamp}</Text>
          </>
        )}
        {isCash ? null : (
          <>
            {sectionTitle("TRANSACTION ID", "sticky-note")}
            <Text>{transactionData?.transaction_id}</Text>
          </>
        )}

        {isDoctor ? (
          <>
            <View
              style={{
                height: 1,
                width: "90%",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                marginTop: 32,
              }}
            ></View>
            <Text
              style={{
                marginTop: 24,
                fontSize: normalize(12),
                fontWeight: "bold",
              }}
            >
              Disclaimer
            </Text>
            <Text
              style={{
                fontSize: normalize(12),
                marginTop: 8,
                marginRight: 40,
              }}
            >
              {transactionData?.is_cash
                ? `Payments are to be made directly from the clinic to the doctor Supermedic does not handle payments for this booking. Both parties are required to liaise directly with each other.`
                : `Payments to your listed bank account for this completed job is currently being processed, please do periodically check your bank account in the next 24 to 48 hours.\n\n3% processing and platform fee will be charged by the supermedic platform.`}
            </Text>
          </>
        ) : null}

        {/* {sectionTitle("REQUIREMENTS", "bookmark")}

        {jobFacilityData
          ?.filter((val) => {
            return val.category === "GP";
          })
          .map((speciality, index) => (
            <>
              {index === 0 && (
                <Text
                  style={{
                    fontWeight: "bold",
                    marginBottom: 8,
                    fontSize: normalize(11),
                    marginTop: 12,
                    marginLeft: 8,
                  }}
                >
                  GENERAL PRACTITIONER
                </Text>
              )}
              <Text style={{ margin: 6 }}>- {speciality.doctor_title}</Text>
            </>
          ))}
        {jobFacilityData
          ?.filter((val) => {
            return val.category !== "GP";
          })
          .map((speciality, index) => (
            <>
              {index === 0 && (
                <Text
                  style={{
                    fontWeight: "bold",
                    marginBottom: 8,
                    fontSize: normalize(11),
                    marginTop: 12,
                    marginLeft: 8,
                  }}
                >
                  SPECIALIST
                </Text>
              )}
              <Text style={{ margin: 6 }}>- {speciality.doctor_title}</Text>
            </>
          ))} */}
        <View style={{ margin: 20 }} />
      </ScrollView>
    </View>
  );
};

const styles = {
  transactionContainer: {
    marginTop: 12,
    paddingLeft: 32,
    minHeight: "100%",
  },
  headerName: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 12,
  },
  detailTitle: {
    color: colors.tertiary,
    fontWeight: "bold",
    fontSize: normalize(12),
    alignSelf: "flex-start",
    paddingRight: 8,
    marginLeft: 8,
  },
};

export default Transaction;
