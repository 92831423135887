// only for doctor
import { FontAwesome } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import { StepHeader } from "../../../components/all";
import colors from "../../../constants/colors";
import Styles from "../../../constants/styles";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function Step5({ navigation, setField }) {
  const input = {
    emailCode: "emailCode",
    isGP: "isGP",
    isSpecialist: "isSpecialist",
    expertise: "expertise",
  };

  const [selectedExpertise, setSelectedExpertise] = React.useState(null);
  const [profession, setProfession] = useState("");

  useEffect(() => {
    getProfession();
  }, [])

  const getProfession = async () => {
    try {

      const value = await AsyncStorage.getItem("profession");

      if (value !== null) {
        let v = JSON.parse(value);
        // value previously stored
        setProfession(v);
        return value;
      }
    } catch (e) {
      // error reading value
    }
  };

  return (
    <View
      style={{
        ...Styles.webSmallContainer,
        marginTop: 20,
      }}
    >
      <StepHeader
        label={`Medical Expertise`}
        icon={<FontAwesome name="envelope-o" size={24} color="black" />}
        subtitle={`Select your professional specialities`}
      />
      <View style={{ alignItems: "center", margin: 18, marginTop: 32 }}>
        {profession.types?.map((item, index) => (
          <TouchableOpacity
            onPress={() => {
              setField(input.isSpecialist, item?.isSpecialist ? true : false);
              setField(input.expertise, item.id);
              setSelectedExpertise(index + 1);
            }}
          >
            <View
              style={{
                ...styles.category,
                borderColor:
                  selectedExpertise === index + 1
                    ? colors.primary
                    : "transparent",
                backgroundColor:
                  selectedExpertise === index + 1
                    ? "white"
                    : "rgba(0,0,0,0.04)",
              }}
            >
              <Text>{item.name}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
}

const styles = {
  category: {
    padding: 12,
    margin: 6,
    marginTop: 10,
    paddingHorizontal: 24,
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 10,
    minWidth: "80%",
  },
};
