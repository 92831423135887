let statusFilter = (type, val, isDoctor) => {
  let code = val.job_application_status_code;
  switch (type) {
    case "pending":
      let filled = true;
      if (!isDoctor && val.job_applications?.length < 1) filled = false;
      return [null, 0, 1, -3].includes(code) && filled;
    case "cancelled":
      let id = isDoctor ? -2 : 2;
      return code === id;
    case "approved":
      return isDoctor ? [2, 3].includes(code) : code === 3;
    case "completed":
      return code === 4;
    default:
      return true;
  }
};

export default statusFilter;
