import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import PropTypes from "prop-types";
import * as React from "react";
import { Pressable, View, Text } from "react-native";
import { useSelector } from "react-redux";

const Row = (props) => {
  const navigation = useNavigation();
  const { isDoctor, paymentSetup } = useSelector((state) => state.user);

  return (
    <Pressable
      onPress={() => {
        navigation.navigate(isDoctor ? "CheckinScreen" : "PostJobScreen");
      }}
    >
      <View
        style={{
          top: -18,
          borderRadius: 100,
          overflow: "hidden",
        }}
      >
        <LinearGradient
          start={{ x: -0.2, y: 0.2 }}
          // Button Linear Gradient
          colors={[
            " rgb(248, 100, 108) ",
            "rgb(242, 49, 123)",
            "rgb(234,0,137)",
          ]}
        >
          <View
            style={{
              height: 70,
              width: 70,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Feather
              name={isDoctor ? "check-circle" : "plus-circle"}
              size={30}
              color="white"
            />
          </View>
        </LinearGradient>
      </View>
    </Pressable>
  );
};

Row.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default Row;
