import { FontAwesome5 } from "@expo/vector-icons";
import DateTimePicker from "@react-native-community/datetimepicker";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Dimensions,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Modal from "react-native-modal";
import { normalize } from "../utils/normalize";
import { Button, Centered, Row } from "./all";
import { DatePickerModal, TimePickerModal } from "react-native-paper-dates";

const DateTimeSelect = (props) => {
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [prevDate, setPrevDate] = useState(new Date());
  const [dateData, setDateData] = useState({
    startDate: props.initialData?.startDate || "",
    endDate: props.initialData?.endDate || "",
    startTime: props.initialData?.startTime || "",
    endTime: props.initialData?.endTime || "",
  });
  const [isStart, setIsStart] = useState(true);
  const [isTime, setIsTime] = useState(false);
  const [minStartTime, setMinStartTime] = useState(
    props.initialData?.startTime || ""
  );
  const [minEndTime, setMinEndTime] = useState(
    props.initialData?.startTime || ""
  );
  const [minStartDate, setMinStartDate] = useState();
  const [minEndDate, setMinEndDate] = useState();
  const [maxEndDate, setMaxEndDate] = useState();

  useEffect(() => {
    if (props.initialData) {
      setDateData({
        startDate: props.initialData.startDate || "",
        endDate: props.initialData.endDate || "",
        startTime: props.initialData.startTime || "",
        endTime: props.initialData.endTime || "",
      });
      const startTime = moment();
      const remainder = 30 - (startTime.minute() % 30);
      setMinStartTime(moment().add(remainder, "minutes"));
      setMinEndTime(
        moment(props.initialData.startTime || new Date())
          .add(1, "hour")
          .toDate() || ""
      );
      if (props.initialData.startDate) {
        setMinEndDate(props.initialData.startDate);
      }
    }
  }, [props.initialData]);

  const checkMinTime = () => {
    let currentDay = moment().format("DD");
    let startDay = moment(dateData?.startDate || new Date()).format("DD");
    let endDay = moment(dateData?.endDate || new Date()).format("DD");

    if (currentDay === startDay) {
      return moment(isStart ? minStartTime : minEndTime)
        .utc()
        .toDate();
    } else {
      return;
    }
  };

  const checkMinDate = () => {
    return moment(isStart ? new Date() : minEndDate)
      .utc()
      .toDate();
  };

  const checkDateState = () => {
    let dateState = "";
    if (isStart) {
      isTime ? (dateState = "startTime") : (dateState = "startDate");
    } else {
      isTime ? (dateState = "endTime") : (dateState = "endDate");
    }
    return dateState;
  };

  const datePickerValue = () => {
    let dateState = checkDateState();
    return typeof dateData[dateState]?.getTime === "function"
      ? dateData[dateState]
      : new Date();
  };

  const onChangeDate = (event, selectedDate) => {
    const currentDate = selectedDate;
    console.log("check current date", selectedDate);
    setShowDatepicker(Platform.OS === "ios");
    let dateState = checkDateState();
    let param = {
      ...dateData,
      [dateState]: currentDate,
    };
    if (isStart && isTime) {
      param = {
        ...param,
        endTime: moment(currentDate).add(1, "hour").toDate(),
      };
    }

    if (!isTime && isStart) {
      const startTime = moment();
      const remainder = 30 - (startTime.minute() % 30);
      setMaxEndDate(moment(currentDate).add(1, "day").toDate());
      param = {
        ...param,
        startTime: moment().add(remainder, "minutes").add(2, "hour").toDate(),
        endTime: moment(moment().add(remainder, "minutes"))
          .add(3, "hour")
          .toDate(),
        endDate: moment(currentDate).toDate(),
      };
      if (dateData.endDate) {
        param = {
          ...param,
          endDate: moment(currentDate).toDate(),
        };
      }
    }
    setDateData(param);
    props?.onChange && props.onChange(param);
  };
  return (
    <>
      <View {...props} style={{ ...props.style }}>
        <Row
          style={{
            justifyContent: props.hideToDate ? "flex-start" : "space-between",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setIsTime(false);
              setIsStart(true);
              setShowDatepicker(true);
              setPrevDate(dateData.startDate);
            }}
          >
            <View
              style={{
                ...styles.calendarContainer,
                width: props.hideToDate
                  ? Dimensions.get("window").width - 48
                  : Dimensions.get("window").width > 780
                  ? 360
                  : Dimensions.get("window").width / 2.3,
                borderColor: props.errors?.includes("startDate")
                  ? "red"
                  : "#E5E5E5",
              }}
            >
              <Row centered>
                <FontAwesome5 name="calendar-alt" size={14} color="grey" />
                <Text
                  style={{
                    ...styles.dateText,
                    color: dateData.startDate ? "black" : "rgba(0,0,0,0.3)",
                  }}
                >
                  {dateData.startDate
                    ? moment(dateData.startDate).format("DD MMM YYYY")
                    : props.hideToDate
                    ? "Job Date"
                    : "Start Date"}
                </Text>
              </Row>
            </View>
          </TouchableOpacity>
          {props.hideToDate ? null : (
            <TouchableOpacity
              onPress={() => {
                setIsTime(false);
                setIsStart(false);
                setShowDatepicker(true);
                setPrevDate(dateData.endDate);
              }}
            >
              <View
                style={{
                  ...styles.calendarContainer,
                  width:
                    Dimensions.get("window").width > 780
                      ? 360
                      : Dimensions.get("window").width / 2.3,
                }}
              >
                <Row centered>
                  <FontAwesome5 name="calendar-alt" size={14} color="grey" />
                  <Text
                    style={{
                      ...styles.dateText,
                      color: dateData.endDate ? "black" : "rgba(0,0,0,0.3)",
                    }}
                  >
                    {dateData.endDate
                      ? moment(dateData.endDate).format("DD MMM YYYY")
                      : "End Date"}
                  </Text>
                </Row>
              </View>
            </TouchableOpacity>
          )}
        </Row>

        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setIsTime(true);
              setIsStart(true);
              setShowDatepicker(true);
              setPrevDate(dateData.startTime);
            }}
          >
            <View
              style={{
                ...styles.calendarContainer,
                width:
                  Dimensions.get("window").width > 780
                    ? 360
                    : Dimensions.get("window").width / 2.3,
                borderColor: props.errors?.includes("startTime")
                  ? "red"
                  : "#E5E5E5",
              }}
            >
              <Row centered>
                <FontAwesome5 name="clock" size={14} color="grey" />
                <Text
                  style={{
                    ...styles.dateText,
                    color: dateData.startTime ? "black" : "rgba(0,0,0,0.3)",
                  }}
                >
                  {dateData.startTime
                    ? moment(dateData.startTime).format("h:mm A")
                    : "Start Time"}
                </Text>
              </Row>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setIsTime(true);
              setIsStart(false);
              setShowDatepicker(true);
              setPrevDate(dateData.endTime);
            }}
          >
            <View
              style={{
                ...styles.calendarContainer,
                width:
                  Dimensions.get("window").width > 780
                    ? 360
                    : Dimensions.get("window").width / 2.3,
                borderColor: props.errors?.includes("endTime")
                  ? "red"
                  : "#E5E5E5",
              }}
            >
              <Row centered>
                <FontAwesome5 name="clock" size={14} color="grey" />
                <Text
                  style={{
                    ...styles.dateText,
                    color: dateData.endTime ? "black" : "rgba(0,0,0,0.3)",
                  }}
                >
                  {dateData.endTime
                    ? moment(dateData.endTime).format("h:mm A")
                    : "End Time"}
                </Text>
              </Row>
            </View>
          </TouchableOpacity>
        </Row>
      </View>
      {Platform.OS === "ios" ? (
        <Modal
          isVisible={showDatepicker}
          animationIn={"fadeIn"}
          animationOut={"fadeOut"}
          backdropTransitionOutTiming={0}
          hideModalContentWhileAnimating
          backdropColor={"rgba(0,0,0,0.5)"}
          onBackdropPress={() => {
            onChangeDate("", prevDate);
            setShowDatepicker(false);
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              padding: 20,
              borderRadius: 20,
            }}
          >
            <DateTimePicker
              value={datePickerValue()}
              mode={isTime ? "time" : "date"}
              onChange={onChangeDate}
              display={isTime ? "spinner" : "inline"}
              textColor="black"
              minuteInterval={10}
              themeVariant="light"
              minimumDate={isTime ? checkMinTime() : checkMinDate()}
              maximumDate={props.limitDate && !isStart ? maxEndDate : null}
            />
            <Centered>
              <Row>
                <Button
                  type="secondary"
                  style={{ marginTop: 32 }}
                  label="Cancel"
                  onPress={() => {
                    if (prevDate)
                      onChangeDate("", moment(prevDate).utc().toDate());
                    setShowDatepicker(false);
                  }}
                />
                <Button
                  type="primary"
                  style={{ marginTop: 32 }}
                  label="Submit"
                  onPress={() => {
                    let dateState = checkDateState();
                    if (isTime && isStart)
                      setMinEndTime(
                        moment(dateData.startTime).add(1, "hour").toDate()
                      );
                    if (dateData[dateState] === "") {
                      onChangeDate("", new Date());
                    }
                    setShowDatepicker(false);
                  }}
                />
              </Row>
            </Centered>
          </View>
        </Modal>
      ) : (
        <>
          {showDatepicker && (
            <DateTimePicker
              value={datePickerValue()}
              mode={isTime ? "time" : "date"}
              display={"default"}
              onChange={onChangeDate}
              is24Hour={false}
              textColor="black"
              minuteInterval={10}
              themeVariant="light"
              minimumDate={isTime ? null : new Date()}
              maximumDate={props.limitDate && !isStart ? maxEndDate : null}
            />
          )}
        </>
      )}
      <DatePickerModal
        locale="en"
        mode="single"
        visible={Platform.OS === "web" && !isTime && showDatepicker}
        onDismiss={() => {
          setShowDatepicker(false);
        }}
        onConfirm={(params) => {
          onChangeDate(null, params.date || new Date());
        }}
        validRange={{
          startDate: checkMinDate(),
          endDate: props.limitDate && !isStart ? maxEndDate : null,
        }}

        // validRange={{
        //   startDate: new Date(2021, 1, 2),  // optional
        //   endDate: new Date(), // optional
        //   disabledDates: [new Date()] // optional
        // }}
        // onChange={} // same props as onConfirm but triggered without confirmed by user
        // saveLabel="Save" // optional
        // uppercase={false} // optional, default is true
        // label="Select date" // optional
        // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
      />

      <TimePickerModal
        visible={Platform.OS === "web" && isTime && showDatepicker}
        onDismiss={() => {
          setShowDatepicker(false);
        }}
        onConfirm={({ hours, minutes }) => {
          let time = new Date(new Date().setHours(hours, minutes, 0, 0));
          onChangeDate(null, time);
        }}
        // onConfirm={onConfirm}
        label="Select time" // optional, default 'Select time'
        uppercase={false} // optional, default is true
        cancelLabel="Cancel" // optional, default: 'Cancel'
        confirmLabel="Ok" // optional, default: 'Ok'
        animationType="fade" // optional, default is 'none'
        locale="en" // optional, default is automically detected by your system
      />
    </>
  );
};
const styles = {
  calendarContainer: {
    padding: 10,
    paddingLeft: 14,
    paddingRight: 20,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#E5E5E5",
    marginTop: 20,
  },
  dateText: {
    marginLeft: 14,
    fontSize: normalize(14),
    marginVertical: 4,
  },
};
DateTimeSelect.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default DateTimeSelect;
