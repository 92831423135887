import { Linking, Platform } from "react-native";

import * as IntentLauncher from "expo-intent-launcher";
import Constants from "expo-constants";
import * as Application from "expo-application";

const pkg = __DEV__ ? "host.exp.exponent" : Application.applicationId;

const openPermissionSettings = () => {
  if (Platform.OS === "ios") {
    Linking.openURL("app-settings:");
  } else {
    IntentLauncher.startActivityAsync(
      IntentLauncher.ACTION_APPLICATION_DETAILS_SETTINGS,
      { data: "package:" + pkg }
    );
  }
};

export default openPermissionSettings;
