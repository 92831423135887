import PropTypes from "prop-types";
import React from "react";
import { Dimensions, View } from "react-native";
import Modal from "react-native-modal";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Styles from "../../constants/styles";

const BottomModal = (props) => {
  const safeInsets = useSafeAreaInsets();

  return (
    <Modal
      backdropTransitionOutTiming={0}
      style={{
        justifyContent: "flex-end",
        margin: 0,
      }}
      {...props}
    >
      <View
        style={{
          backgroundColor: "white",
          padding: 20,
          paddingTop: 30,
          paddingBottom: safeInsets.bottom + 20,
          borderTopRightRadius: 20,
          borderTopLeftRadius: 20,
        }}
      >
        {props.children}
      </View>
    </Modal>
  );
};

const styles = {
  modalStyle: {
    margin: 0,
    justifyContent: "flex-end",
  },
  modalContainer: {
    backgroundColor: "white",
    height: "60%",
    borderTopLeftRadius: 48,
    borderTopRightRadius: 48,

    marginHorizontal: 0,
    margin: 0,
    ...Styles.shadows,
  },
  calendarContainer: {
    margin: 48,
    marginTop: 12,
  },
  saveButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  resetButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.05)",
  },
};

BottomModal.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default BottomModal;
