import React, { useEffect, useState } from "react";
import { Alert, Text, TouchableOpacity, View } from "react-native";
import { normalize } from "../utils/normalize";
import professions from "../constants/lov/professions";
import colors from "../constants/colors";
import { useDispatch, useSelector } from "react-redux";

const CategoryTab = (props) => {
  const {  isDev } = useSelector((state) => state.user);

  return (
    <View style={styles.tabContainer}>
      {professions.map((prof, idx) => (
        <TouchableOpacity
          style={{
            ...styles.tab,
            borderColor:
              props.selectedCategoryID === prof.id
                ? colors.primary
                : "lightgrey",
          }}
          onPress={() => {
            if (isDev) {
            props.setSelectedCategory(prof.slug.split("-")[0]);
            props.setSelectedCategoryID(prof.id)
            } else {
              if(idx !== 0) Alert.alert("Coming Soon!");
            }
          }}
        >
          <Text style={styles.tabText}>{prof.name}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = {
  tabContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 20,
    flex: 1,
  },
  tab: {
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "center",
    height: 50,
    flexGrow: 1,
    marginHorizontal: 5,
    flexBasis: "15%",
  },
  tabText: {
    fontSize: normalize(12),
    fontWeight: "bold",
    textAlign: "center",
  },
};

export default CategoryTab;
