import { Feather, MaterialIcons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import Collapsible from "react-native-collapsible";
import colors from "../constants/colors";
import Row from "./Row";
import { normalize } from "../utils/normalize";
import { useSelector } from "react-redux";
// TODO: add isSpecialist
const Catergories = (props) => {
  const { isDoctor } = useSelector((state) => state.user);

  let allData = props.data || Array.from(Array(4).keys());
  let allCategories =
    isDoctor || props.isDoctor
      ? allData
          .slice()
          .sort((a, b) => a.doctor_title?.localeCompare(b.doctor_title))
      : allData;
  let selected = props.selected || [];
  const [showSection, setShowSection] = useState(props.show ?? true);
  const [showChildren, setShowChildren] = useState([]);
  const [selectedChildren, setSelectedChildren] = useState([]);
  useEffect(() => {
    if (props.filterText?.length > 0) {
      setShowSection([0, 1]);
    } else {
      if (props.startCollapse) {
        if (!props.filterText) setShowSection([]);
      }
    }
  }, [props.filterText]);

  const onSelectChildren = (category) => {
    if (selectedChildren.includes(category.slug)) {
      setSelectedChildren(
        selectedChildren.filter((val) => val !== category.slug)
      );
    } else {
      setSelectedChildren([...selectedChildren, category.slug]);
    }
  };
  return (
    <>
      {props.hideLabel ? null : (
        <TouchableOpacity
          onPress={() => {
            setShowSection(!showSection);
          }}
        >
          <View
            style={{
              marginTop: 14,
              marginLeft: props.isShowAll ? 0 : props.isCheckbox ? 10 : 20,
              alignItems: props.isShowAll ? "center" : "flex-start",
            }}
          >
            {props.isShowAll ? <View style={styles.line} /> : null}

            <Row centered>
              {props.isShowAll ? null : (
                <Feather
                  name={`chevron-${showSection ? "down" : "up"}`}
                  size={20}
                />
              )}
              <Text
                style={{
                  margin: 4,
                  paddingHorizontal: 10,
                  backgroundColor: props.isShowAll ? "white" : "transparent",
                  marginTop: props.isShowAll ? -9 : 4,
                  fontWeight: "bold",
                  textAlign:'center',
                  color: "rgba(0,0,0,0.8)",
                }}
              >
                {props.title}
              </Text>
            </Row>
          </View>
        </TouchableOpacity>
      )}
      <Collapsible collapsed={props.isShowAll ? false : !showSection}>
        <View
          {...props}
          style={{
            ...styles.categoryContainer,
            ...props.style,
            flexDirection: props.isCheckbox ? "column" : "row",
            flexWrap: props.isCheckbox ? "nowrap" : "wrap",
            marginVertical: props.isCheckbox ? 8 : 24,
          }}
        >
          {allCategories
            ?.filter((val) => {
              if (props.filterText && props.filterText.length > 0) {
                return Object.keys(val).some((key) =>
                  val.name
                    .toLowerCase()
                    .includes(props.filterText.toLowerCase())
                );
              } else {
                return true;
              }
            })
            .map((category, index) => {
              if (props.category) {
                if (props.category !== category.category) return;
              }
              let checkboxStyles = props.isCheckbox
                ? {
                    borderColor: "transparent",
                    backgroundColor: selected.includes(category.slug)
                      ? "rgb(233,237, 250)"
                      : "transparent",
                    marginVertical: 2,
                  }
                : {};
              return (
                <>
                  <TouchableOpacity
                    key={index}
                    onPress={() => {
                      if (category?.children?.length > 0) {
                        if (showChildren.includes(category.slug)) {
                          props.onPress(
                            category.slug,
                            category?.children,
                            category?.doctor_title
                          );
                          onSelectChildren(category);
                        } else {
                          setShowChildren([...showChildren, category.slug]);
                        }
                      } else {
                        props.onPress(
                          category.slug,
                          null,
                          category?.doctor_title
                        );
                        setShowChildren(selectedChildren);
                      }
                    }}
                  >
                    <View
                      style={{
                        ...styles.category,
                        backgroundColor: selected.includes(category.slug)
                          ? "#f5f5f5"
                          : "#eee",
                        borderColor: selected.includes(category.slug)
                          ? colors.primary
                          : "transparent",
                        width: props.isCheckbox
                          ? Dimensions.get("window").width > 780
                            ? 700
                            : Dimensions.get("window").width - 80
                          : "auto",
                        borderRadius: props.isCheckbox ? 10 : 100,
                        ...checkboxStyles,
                      }}
                    >
                      <Row centered>
                        {props.isCheckbox ? (
                          <View style={{ marginRight: 10, marginLeft: -10 }}>
                            <MaterialIcons
                              name={
                                selected.includes(category.slug)
                                  ? "check-box"
                                  : "check-box-outline-blank"
                              }
                              size={20}
                              color={
                                selected.includes(category.slug)
                                  ? "rgb(72, 92, 242)"
                                  : "rgba(0,0,0,0.2)"
                              }
                            />
                          </View>
                        ) : null}
                        <Text
                          style={{
                            color: "rgba(0,0,0,0.8)",
                            fontWeight: props.isCheckbox ? "regular" : "bold",
                            fontSize: props.isCheckbox
                              ? normalize(14)
                              : normalize(12),
                            margin: props?.isCheckbox ? 4 : 0,
                          }}
                        >
                          {props?.isDoctor
                            ? category?.doctor_title
                            : category?.name}
                        </Text>
                      </Row>
                    </View>
                  </TouchableOpacity>
                  <Collapsible
                    collapsed={
                      props.isCheckbox
                        ? !showChildren.includes(category.slug)
                        : false
                    }
                  >
                    <View
                      style={{
                        flexDirection: props.isCheckbox ? "column" : "row",
                        flexWrap: props.isCheckbox ? "nowrap" : "wrap",
                      }}
                    >
                      {category.children && category.children.length > 0
                        ? category.children.map((subcategory, subIndex) => {
                            let subCheckboxStyle = props.isCheckbox
                              ? {
                                  borderColor: "transparent",
                                  backgroundColor: selected.includes(
                                    subcategory.slug
                                  )
                                    ? "rgb(233,237, 250)"
                                    : "transparent",
                                  marginVertical: 2,
                                }
                              : {};
                            return (
                              <TouchableOpacity
                                key={subIndex}
                                onPress={() => {
                                  props.onPress(
                                    subcategory.slug,
                                    null,
                                    category?.doctor_title
                                  );
                                  onSelectChildren(category);
                                }}
                              >
                                <View
                                  style={{
                                    marginLeft: props.isCheckbox ? 32 : 0,
                                    ...styles.category,
                                    backgroundColor: selected.includes(
                                      subcategory.slug
                                    )
                                      ? "#f5f5f5"
                                      : "#eee",
                                    borderColor: selected.includes(
                                      subcategory.slug
                                    )
                                      ? colors.primary
                                      : "transparent",
                                    width: props.isCheckbox
                                      ? Dimensions.get("window").width > 780
                                        ? 674
                                        : Dimensions.get("window").width - 106
                                      : "auto",
                                    borderRadius: props.isCheckbox ? 10 : 100,
                                    ...subCheckboxStyle,
                                  }}
                                >
                                  <Row centered>
                                    {props.isCheckbox ? (
                                      <View
                                        style={{
                                          marginRight: 10,
                                          marginLeft: -10,
                                        }}
                                      >
                                        <MaterialIcons
                                          name={
                                            selected.includes(subcategory.slug)
                                              ? "check-box"
                                              : "check-box-outline-blank"
                                          }
                                          size={20}
                                          color={
                                            selected.includes(subcategory.slug)
                                              ? "rgb(72, 92, 242)"
                                              : "rgba(0,0,0,0.2)"
                                          }
                                        />
                                      </View>
                                    ) : null}
                                    <Text
                                      style={{
                                        color: "rgba(0,0,0,0.8)",
                                        fontWeight: props.isCheckbox
                                          ? "regular"
                                          : "bold",
                                        fontSize: props.isCheckbox
                                          ? normalize(14)
                                          : normalize(12),
                                        margin: props?.isCheckbox ? 4 : 0,
                                      }}
                                    >
                                      {props?.isDoctor
                                        ? subcategory?.doctor_title
                                        : subcategory?.name}
                                    </Text>
                                  </Row>
                                </View>
                              </TouchableOpacity>
                            );
                          })
                        : null}
                    </View>
                  </Collapsible>
                </>
              );
            })}
        </View>
      </Collapsible>
    </>
  );
};

Catergories.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

const styles = {
  categoryContainer: {
    marginHorizontal: 10,
    justifyContent: "center",
  },
  category: {
    padding: 8,
    margin: 6,
    paddingHorizontal: 24,
    borderWidth: 1,
  },
  line: {
    zIndex: -1,
    width: "90%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
};

export default Catergories;
