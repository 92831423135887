import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Platform,
  PlatformColor,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import Fonts from "../constants/fonts";
import { normalize } from "../utils/normalize";
import { Modal, ModalButtons } from "./all";
import Styles from "../constants/styles";

const Input = (props) => {
  const [showPickerModal, setShowPickerModal] = useState(false);

  let focusStyles = {};
  let errorStyles = {};
  if (props.error) {
    errorStyles = {
      shadowOpacity: 0,
      elevation: 0,
    };
  }

  let multilineStyle = {};

  if (props.multiline && Platform.OS === "ios") {
    multilineStyle = {
      height: 70,
      paddingTop: 14,
    };
  }
  let label = props.label || false;
  if (props.isPicker) {
    label =
      props.pickerItems?.find((val) => val.value === props.value)?.label ||
      false;
  }

  return (
    <>
      <View
        style={{
          borderColor: props.error ? "red" : "rgba(0,0,0,0.08)",
          shadowOpacity: 0.05,
          shadowRadius: 1,
          ...styles.inputStyles,
          alignItems: props.multiline ? "flex-start" : "center",

          height: props.multiline ? 90 : 50,
          ...focusStyles,
          ...errorStyles,
          backgroundColor: props.bordered ? "white" : "#f5f5f5",
          ...props.containerStyle,

          borderWidth: 1,
        }}
      >
        {props.icon && (
          <View
            style={{
              width: 40,
              alignItems: "center",
              paddingTop: props.multiline ? 14 : null,
            }}
          >
            {props.icon}
          </View>
        )}

        {props.isPicker ? (
          <View
            style={{
              marginLeft: props.icon ? 0 : Platform.OS === "android" ? 8 : 20,
            }}
          >
            {Platform.OS === "ios" ? (
              <TouchableOpacity onPress={() => setShowPickerModal(true)}>
                <View
                  style={{
                    height: 50,
                    justifyContent: "center",
                    width: 500,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: label ? "black" : "rgba(0,0,0,0.25)",
                    }}
                  >
                    {label ? label : props.placeholder}
                  </Text>
                </View>
              </TouchableOpacity>
            ) : (
              <Picker
                selectedValue={props.value}
                style={{ width: 500 }}
                onValueChange={(itemValue, itemIndex) =>
                  props.onChangeText(itemValue)
                }
              >
                {props.placeholder && (
                  <Picker.Item
                    label={props.placeholder}
                    value={props.placeholder}
                  />
                )}
                {props.pickerItems.map((pickerData) => (
                  <Picker.Item
                    label={pickerData.label}
                    value={pickerData.value}
                  />
                ))}
              </Picker>
            )}
          </View>
        ) : (
          <TextInput
            {...props}
            value={props.value}
            placeholder={props.placeholder}
            editable={!props.disabled}
            placeholderTextColor={"rgba(0,0,0,0.25)"}
            numberOfLines={
              props.multiline
                ? props.multilineNumber
                  ? props.multilineNumber
                  : 3
                : 1
            }
            multiline={props.multiline}
            style={{
              flex: 1,
              paddingVertical:
                Platform.OS === "ios"
                  ? 10
                  : Platform.OS === "android" || props.multiline
                  ? 14
                  : 8,
              marginRight: 2,
              textAlignVertical: "top",
              paddingLeft: props.icon ? 0 : 14,
              paddingRight: 14,
              backgroundColor: props.bordered ? "white" : "#f5f5f5",
              borderRadius: 10,
              ...multilineStyle,
              ...props.inputStyle,
            }}
          />
        )}
        {props.backIcon && (
          <TouchableOpacity onPress={() => props.onPressBackIcon()}>
            <View
              style={{
                width: 40,
                alignItems: "center",
                paddingTop: props.multiline ? 14 : null,
                marginRight: 8,
              }}
            >
              {props.backIcon}
            </View>
          </TouchableOpacity>
        )}
        {props.backComponent && (
          <View style={{ marginRight: 12 }}>{props.backComponent()}</View>
        )}
      </View>
      {props.multiline ? (
        <View
          style={{
            alignSelf: "flex-end",
            marginRight: props.isAbout ? 28 : 10,
            marginTop: -18,
          }}
        >
          <Text style={{ opacity: 0.3, fontSize: normalize(12) }}>
            {props.value?.length || 0}/{props.maxLength || 200}
          </Text>
        </View>
      ) : null}

      {props.error && typeof props.error === "string" && (
        <Text
          style={{ color: "red", fontSize: normalize(Fonts.small), margin: 4 }}
        >
          {props.error}
        </Text>
      )}
      <Modal
        visible={showPickerModal}
        onBackdropPress={() => setShowPickerModal(false)}
        styles={{ width: "auto", padding: 0, ...Styles.shadows }}
      >
        <View
          style={{
            borderWidth: 1,
            borderRadius: 20,
            borderColor: "rgba(0,0,0,0.1)",
          }}
        >
          <Picker
            selectedValue={props.value}
            onValueChange={(itemValue, itemIndex) =>
              props.onChangeText(itemValue)
            }
          >
            {props.pickerItems?.map((pickerData) => (
              <Picker.Item label={pickerData.label} value={pickerData.value} />
            ))}
          </Picker>
          <ModalButtons
            onCancel={() => setShowPickerModal(false)}
            onConfirm={() => {
              setShowPickerModal(false);
            }}
          />
        </View>
      </Modal>
    </>
  );
};

const styles = {
  inputStyles: {
    height: 45,
    borderRadius: 10,
    flexDirection: "row",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 14,
    },

    shadowRadius: 20.84,
    zIndex: -8,
  },
};

Input.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default Input;
