import { Feather, Ionicons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import * as React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { normalize } from "../utils/normalize";
import Row from "./Row";
import Title from "./Title";

const StepHeader = (props) => {
  return (
    <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
      <View style={{ marginHorizontal: 28 }}>
        <View style={{ marginLeft: 2 }}>{props.icon}</View>
        <Title
          label={props.label}
          style={{
            marginVertical: 8,
            fontFamily: "NotoSans-Bold",
          }}
        />
        <Text style={{ color: "black" }}>
          {props.presubtitle}
          <Text style={{ marginLeft: 0, color: "#FF4B4B" }}>
            {props.subtitle}
          </Text>
        </Text>
      </View>
      {props.showImage ? (
        <View style={{ marginRight: 24, alignItems: "center" }}>
          <TouchableOpacity onPress={props.onPressImage}>
            {props.imageUrl ? (
              <Image
                source={{ uri: "data:image/jpg;base64," + props.imageUrl }}
                style={{ width: 75, height: 75, borderRadius: 50 }}
              />
            ) : (
              <View
                style={{
                  backgroundColor: "rgba(0,0,0,0.1)",
                  borderWidth: 1,
                  padding: 24,
                  borderRadius: 40,
                  borderColor: props.imageError ? "red" : "rgba(0,0,0,0)",
                }}
              >
                <Ionicons name="ios-image-outline" size={24} />
              </View>
            )}
            <View
              style={{ alignItems: "flex-end", marginTop: -20, marginRight: 2 }}
            >
              <View
                style={{
                  padding: 4,
                  backgroundColor: "grey",
                  borderRadius: 20,
                }}
              >
                <Feather name="plus" size={14} color="white" />
              </View>
            </View>
          </TouchableOpacity>
          <Text style={{ marginTop: 8, fontWeight: "bold" }}>
            {props.isProfile ? "Profile Photo" : "Clinic Logo"}
          </Text>
          {props.imageError && (
            <Text
              style={{ color: "red", fontSize: normalize(12), marginTop: 2 }}
            >
              Required
            </Text>
          )}
        </View>
      ) : null}
    </Row>
  );
};

StepHeader.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default StepHeader;
