import { Ionicons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import React from "react";
import {
  Dimensions,
  Text,
  KeyboardAvoidingView,
  View,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import Modal from "./Modal";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Styles from "../../constants/styles";
import { Row, Input, ModalButtons } from "../all";
import { normalize } from "../../utils/normalize";

const CancelRequestModal = (props) => {
  const safeInsets = useSafeAreaInsets();
  const { isDoctor } = useSelector((state) => state.user);
  const dismissKeyboard = () => {
    Keyboard.dismiss();
  };
  let requestee = isDoctor ? "Clinic" : "Doctor";
  requestee = props?.isRaiseToAdmin ? "Admin" : requestee;
  return (
    <TouchableWithoutFeedback onPress={dismissKeyboard}>
      <Modal isVisible={props.isVisible} onBackdropPress={dismissKeyboard}>
        <KeyboardAvoidingView keyboardVerticalOffset={150} behavior="padding">
          <View
            style={{
              marginHorizontal: 24,
            }}
          >
            <View style={{ alignItems: "flex-start", marginVertical: 12 }}>
              <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
            </View>
            <Text style={{ fontWeight: "bold", fontSize: normalize(24) }}>
              {props?.isRaiseToAdmin ? "Raise to Admin" : "Cancel Request"}
            </Text>
            <Text style={{ marginTop: 10, marginBottom: 24 }}>
              {requestee} will be informed of cancellation request and would
              need time to accept.
            </Text>
            <Input
              onChangeText={props.onChangeText}
              placeholder="Reason for Cancellation"
              multiline={true}
              error={
                props.error ? "Please enter a reason for cancellation" : false
              }
            />
          </View>
          <View style={{ margin: 10 }} />
          <ModalButtons
            onCancel={props.onCancel}
            rightLabel={"Submit"}
            onConfirm={props.onConfirm}
          />
        </KeyboardAvoidingView>
      </Modal>
    </TouchableWithoutFeedback>
  );
};

const styles = {
  modalStyle: {
    margin: 0,
    justifyContent: "flex-end",
  },
  modalContainer: {
    backgroundColor: "white",
    height: "60%",
    borderTopLeftRadius: 48,
    borderTopRightRadius: 48,

    marginHorizontal: 0,
    margin: 0,
    ...Styles.shadows,
  },
  calendarContainer: {
    margin: 48,
    marginTop: 12,
  },
  saveButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  resetButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.05)",
  },
};

CancelRequestModal.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default CancelRequestModal;
