import { Ionicons, FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState, useEffect } from "react";
import {
  Image,
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  Platform,
  Dimensions,
  Alert,
  Linking,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation } from "react-query";

import {
  BackButton,
  BottomModal,
  Categories,
  Header,
  Row,
  Loading,
  GradientHeader,
  StatusCard,
  Modal,
  Card,
  ModalButtons,
  VerificationModal,
} from "../../../components/all";
import homeStub from "../../../constants/stub/home.json";
import specializations from "../../../constants/stub/specializations.json";
import { setLoggedOut } from "../../../reducers/auth/user";
import professions from "../../../constants/lov/professions";
import {
  getUser,
  getProfile,
  getClinics,
  deleteUser,
  getOverview,
} from "../../../services/profile";
import { setFields, addFields } from "../../../reducers/profile/profile";
import queryClient from "../../../utils/queryClient";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";
import { normalize } from "../../../utils/normalize";
import { getApplications } from "../../../services/job";
export default function App(props) {
  const fromDoctorCard = props.route.params?.fromDoctorCard;
  const doctorCardData = props.route.params?.data;

  const profileEditSuccess = props.route.params?.profileEditSuccess;
  const clinicEditSuccess = props.route.params?.clinicEditSuccess;

  const { fields } = useSelector((state) => state.profile);
  const { isDoctor: checkDoctor, slug } = useSelector((state) => state.user);
  let isDoctor = fromDoctorCard ? true : checkDoctor;
  const dispatch = useDispatch();
  const safeInsets = useSafeAreaInsets();
  const [modalVisible, setModalVisible] = useState(false);
  const [verifiedModalVisible, setVerifiedModalVisible] = useState(false);
  const [clinicData, setClinicData] = useState([]);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [profession, setProfession] = useState("");

  const {
    data: overviewQuery,
    refetch,
    isLoading: overviewLoading,
  } = useQuery("overview", getOverview);
  let overviewInfo = overviewQuery?.data?.data;
  const {
    data: jobsQuery,
    refetch: jobRefetch,
    isLoading: jobLoading,
    isFetched,
  } = useQuery("applications", getApplications);
  const {
    data: profileQuery,
    refetch: profileRefetch,
    isLoading: profileLoading,
  } = useQuery(
    "profile",
    () => (!fromDoctorCard ? getProfile() : getUser(doctorCardData?.slug)),
    {}
  );

  let profileData = profileQuery?.data?.data;
  const {
    data: userQuery,
    refetch: userRefetch,
    isLoading: userLoading,
  } = useQuery("doctorProfile", () => getUser(doctorCardData?.slug), {
    enabled: !!fromDoctorCard,
  });

  const {
    data: clinicsQuery,
    refetch: clinicsRefetch,
    isLoading: clinicLoading,
  } = useQuery("clinics", getClinics, {
    enabled: !isDoctor,
  });

  const onDeleteUser = useMutation(deleteUser, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      props.navigation.replace("AuthScreen");
    },
  });

  useEffect(() => {
    if (profileQuery?.data) {
      dispatch(addFields(profileQuery.data.data));
      getProfession(profileQuery.data.data);
    }
  }, [profileQuery]);

  useEffect(() => {
    if (userQuery?.data) {
      dispatch(addFields(userQuery.data.data));
    }
  }, [userQuery]);

  useEffect(() => {
    if (clinicsQuery?.data) {
      let clinicFields = clinicsQuery.data.data;
      setClinicData(clinicFields[0]);
      let { profile_image, name, phone_number, ...fieldsWithoutImage } =
        clinicFields[0];
      dispatch(
        addFields({
          ...fieldsWithoutImage,
          clinic_profile_image: profile_image,
          clinic_phone_number: phone_number,
          clinic_name: name,
        })
      );
    }
  }, [clinicsQuery]);

  useEffect(() => {
    clinicEditSuccess && clinicsRefetch();
  }, [clinicEditSuccess]);

  useEffect(() => {
    if (profileEditSuccess) {
      profileRefetch();
      !isDoctor && clinicsRefetch();
    }
  }, [profileEditSuccess]);
  const convertPhone = (phone) => {
    if (phone) {
      let phoneArray = phone.split("");
      phoneArray.splice(3, 0, "-");
      phoneArray.splice(7, 0, "");
      return phoneArray.join("");
    }
    return "";
  };
  const getProfession = (data) => {
    let id = data?.expertise
    let prof = professions;
    if(id > 5) prof = prof.find((item) => item.id === 5).types;
    let p = prof.find((item) => item.id === id);
    setProfession(p?.name);
  }
  useEffect(
    () =>
      props.navigation.addListener("beforeRemove", (e) => {
        // Prevent default behavior of leaving the screen (if needed)
        queryClient.removeQueries("doctorProfile");
        queryClient.removeQueries("profile");
        dispatch(setFields({}));
      }),
    [props.navigation]
  );
  const licenseList = (title, value) => {
    return (
      <>
        <Text
          style={{
            fontSize: normalize(12),
            fontWeight: "bold",
            marginRight: 12,
            marginBottom: 4,
            marginTop: 20,
          }}
        >
          {title} No.
        </Text>
        <Text>{value}</Text>
      </>
    );
  };
  const editButton = (title, navigateTo, params) => {
    return (
      <TouchableOpacity
        onPress={() => {
          props.navigation.navigate(navigateTo, params);
        }}
      >
        <View style={styles.editButton}>
          <Row style={{ justifyContent: "center" }}>
            <Text
              style={{
                fontSize: normalize(14),
                color: "rgba(0,0,0,0.4)",
              }}
            >
              {title}
            </Text>
          </Row>
        </View>
      </TouchableOpacity>
    );
  };
  const renderTitle = (title, icon) => {
    return (
      <View style={{ marginTop: 20 }}>
        <Row centered>
          <FontAwesome5 name={icon} color="black" size={16} />
          <Text style={styles.sectionTitle}>{title}</Text>
        </Row>
      </View>
    );
  };
  const checkPendingJobs = () => {
    console.log("check jobs", jobsQuery.data.data);
    let noPendingJobs = true;

    if (jobsQuery.data.data) {
      jobsQuery.data.data.map((job) => {
        if ([0, 1, 2, 3].includes(job.job_application_status_code)) {
          noPendingJobs = false;
        }
      });
    }
    return noPendingJobs;
  };
  const onPressDelete = () => {
    const isDeletable = checkPendingJobs();
    if (isDeletable) {
      Alert.alert(
        "Are you sure?",
        `Your account will be deleted and you will not be able to undo this action.`,
        [
          {
            text: "Cancel",
            style: "cancel",
          },
          {
            text: "Confirm",
            style: "destructive",
            onPress: () => {
              onDeleteUser.mutate(slug);
            },
          },
        ]
      );
    } else {
      Alert.alert(
        "Oops!",
        `You have pending jobs. Please complete or cancel them before deleting your account.`,
        [
          {
            text: "Cancel",
            style: "cancel",
          },
          {
            text: "Confirm",
          },
        ]
      );
    }
  };

  return (
    <>
      <GradientHeader />
      <ScrollView
        contentContainerStyle={{
          backgroundColor: "rgba(0,0,0,0)",
          width:
            Dimensions.get("window").width > 780
              ? 780
              : Dimensions.get("window").width,
          alignSelf: "center",
        }}
        showsVerticalScrollIndicator={false}
      >
        {fromDoctorCard ? (
          <View style={{ marginTop: Platform.OS === "ios" ? -40 : 0 }}>
            <BackButton color="white" isClose />
          </View>
        ) : (
          <View
            style={{
              width: "100%",
              alignItems: "flex-end",
              paddingTop: safeInsets.top + 20,
              paddingRight: 20,
            }}
          >
            <View style={{ position: "absolute", left: 0 }}>
              <BackButton color="white" />
            </View>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("EditProfileScreen");
              }}
            >
              <View
                style={{
                  borderRadius: 8,
                  borderWidth: 1,
                  padding: 10,
                  paddingHorizontal: 18,
                  borderColor: "rgba(255,255,255,0.5)",
                }}
              >
                <Row style={{ alignItems: "center" }}>
                  <Text
                    style={{
                      color: "rgba(255,255,255,0.5)",
                      marginRight: 8,
                    }}
                  >
                    Edit Profile
                  </Text>
                  <FontAwesome
                    name="edit"
                    size={14}
                    color="rgba(255,255,255,0.5)"
                  />
                </Row>
              </View>
            </TouchableOpacity>
          </View>
        )}
        <Header
          profileLoading={profileLoading || userLoading}
          from={"profile"}
          containerStyle={{ marginLeft: 24 }}
          first_name={profileData?.first_name}
          last_name={profileData?.last_name}
          profile_image={profileData?.profile_image}
          data={{ ...profileData, ...overviewInfo }}
          fromDoctorCard={fromDoctorCard}
          showVerifiedModal={(bool) => {
            setVerifiedModalVisible(bool);
          }}
          onPressVerify={() => {
            setVerifyModalVisible(true);
          }}
        />
        {/* Reports */}

        <View
          style={{
            marginTop: Platform.OS === "web" ? 120 : 0,

            paddingTop: Platform.OS === "web" ? 110 : 14,

            backgroundColor: "white",
            padding: 14,
            paddingHorizontal: 24,
            minHeight: "100%",

            borderTopLeftRadius: Platform.OS === "web" ? 32 : 0,
            borderTopRightRadius: Platform.OS === "web" ? 32 : 0,
          }}
        >
          {fromDoctorCard && typeof profileData?.completed_jobs === "number" ? (
            <>
              <View style={{ flexDirection: "row", marginLeft: -30 }}>
                <Card
                  onPress={() => setVerifiedModalVisible(true)}
                  style={{
                    width:
                      Dimensions.get("window").width > 780
                        ? 500
                        : Dimensions.get("window").width / 1.6,
                    alignSelf: "center",
                    marginRight: 4,
                  }}
                >
                  <View style={{ padding: 20 }}>
                    <Row centered>
                      <Image
                        source={require("../../../assets/images/check.png")}
                        style={{ height: 14, width: 14 }}
                      />
                      <Text
                        style={{
                          paddingLeft: 8,
                          fontSize: normalize(16),
                          fontWeight: "bold",
                        }}
                      >
                        Verified
                      </Text>
                    </Row>
                    <Text style={{ fontSize: normalize(14), marginTop: 10 }}>
                      {`${profileData.first_name} ${profileData.last_name[0]}.`}{" "}
                      account has been verified by the Supermedic team.
                    </Text>
                  </View>
                </Card>
                <Card
                  style={{
                    width:
                      Dimensions.get("window").width > 780
                        ? 220
                        : Dimensions.get("window").width / 3.5,
                    marginHorizontal: 8,
                    alignItems: "center",
                  }}
                  onPress={() => {
                    Linking.openURL(`tel:${profileData?.phone_number}`);
                  }}
                >
                  <View style={{ marginTop: 12 }}>
                    <StatusCard
                      noHr
                      icon={<FontAwesome5 name="phone" />}
                      smallValue
                      data={{
                        title: `Contact\nInformation`,
                        value: convertPhone(profileData?.phone_number) + "\n",
                      }}
                    />
                  </View>
                </Card>
              </View>
              <Row style={{ marginTop: 0, justifyContent: "center" }}>
                <Card
                  style={{
                    width:
                      Dimensions.get("window").width > 780
                        ? 220
                        : Dimensions.get("window").width / 3.5,
                    marginHorizontal: 8,
                    alignItems: "center",
                  }}
                >
                  <View style={{ marginTop: 12 }}>
                    <StatusCard
                      icon={<FontAwesome5 name="clipboard-check" />}
                      data={{
                        title: `Completed\nJobs`,
                        value: profileData?.completed_jobs || 0,
                      }}
                    />
                  </View>
                </Card>
                <Card
                  style={{
                    width:
                      Dimensions.get("window").width > 780
                        ? 220
                        : Dimensions.get("window").width / 3.5,
                    marginHorizontal: 8,
                    alignItems: "center",
                  }}
                >
                  <View style={{ marginTop: 12 }}>
                    <StatusCard
                      noHr
                      icon={<FontAwesome5 name="clock" />}
                      data={{
                        title: `Hours\nWorked`,
                        value: profileData?.hours_worked?.toFixed() || 0,
                      }}
                    />
                  </View>
                </Card>

                <Card
                  style={{
                    width:
                      Dimensions.get("window").width > 780
                        ? 220
                        : Dimensions.get("window").width / 3.5,
                    marginHorizontal: 8,
                    alignItems: "center",
                  }}
                  onPress={() => {
                    props.navigation.navigate("RatingScreen", {
                      profileImg: profileData?.profile_image,
                    });
                  }}
                >
                  <View style={{ marginTop: 12 }}>
                    <StatusCard
                      noHr
                      icon={<FontAwesome5 name="trophy" />}
                      data={{
                        title: `Current\nRating`,
                        value: profileData?.average_rating
                          ? profileData.average_rating
                          : "n/a",
                      }}
                    />
                  </View>
                </Card>
              </Row>
            </>
          ) : null}
          {profileData?.about ? (
            <View style={{ marginTop: 20 }}>
              <Text>{profileData?.about}</Text>
            </View>
          ) : null}
          {isDoctor ? (
            <>
              <View style={{ margin: 10 }} />
              {isDoctor && !fromDoctorCard ? (
                <>
                  {renderTitle("Medical License", "address-card")}
                  {profileLoading ? (
                    <View style={{ marginTop: 20 }}>
                      <ContentLoader
                        speed={2}
                        width={400}
                        height={150}
                        viewBox="0 0 400 150"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        {...props}
                      >
                        <Circle cx="10" cy="20" r="8" />
                        <Rect
                          x="25"
                          y="15"
                          rx="5"
                          ry="5"
                          width="220"
                          height="10"
                        />
                        <Circle cx="10" cy="50" r="8" />
                        <Rect
                          x="25"
                          y="45"
                          rx="5"
                          ry="5"
                          width="220"
                          height="10"
                        />
                      </ContentLoader>
                    </View>
                  ) : (
                    <>
                      <View style={{ margin: 8 }}>
                        {licenseList("APC", profileData?.apc)}
                        {licenseList("MMC", profileData?.mmc)}
                        {profileData?.nsr &&
                          licenseList("NSR", profileData?.nsr)}
                      </View>
                      {editButton(
                        "Edit Medical Licenses",
                        "EditProfileScreen",
                        { isEditLicense: true }
                      )}
                      <View style={{ margin: 10 }} />
                    </>
                  )}
                </>
              ) : null}

              {renderTitle("Education", "user-graduate")}
              <View style={{ margin: 10 }} />
              {profileLoading ? (
                <>
                  <ContentLoader
                    speed={2}
                    width={400}
                    height={150}
                    viewBox="0 0 400 150"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <Circle cx="10" cy="20" r="8" />
                    <Rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
                    <Circle cx="10" cy="50" r="8" />
                    <Rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
                    <Circle cx="10" cy="80" r="8" />
                    <Rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
                  </ContentLoader>
                </>
              ) : (
                <View>
                  {profileData?.education?.map((education) => {
                    return (
                      <View style={{ margin: 10 }}>
                        <Text
                          style={{
                            fontWeight: "bold",
                            color: "rgba(0,0,0,0.9)",
                            marginBottom: 4,
                          }}
                        >
                          {education.name}
                        </Text>
                        <Text
                          style={{
                            color: "rgba(0,0,0,0.6)",
                          }}
                        >
                          {education.year} - {education.school}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              )}
              {fromDoctorCard ? (
                <></>
              ) : (
                editButton("Edit Education", "EditEducationScreen")
              )}
            </>
          ) : (
            <>
              <View
                style={{
                  margin: 10,
                }}
              />
              {renderTitle("Clinic Information", "clinic-medical")}
              <View style={{ margin: 10 }} />
              {profileLoading || clinicLoading ? (
                <>
                  <ContentLoader
                    speed={2}
                    width={476}
                    height={124}
                    viewBox="0 0 476 124"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                    {...props}
                  >
                    <Rect x="111" y="9" rx="3" ry="3" width="144" height="12" />
                    <Rect x="110" y="73" rx="3" ry="3" width="133" height="6" />
                    <Rect x="111" y="55" rx="3" ry="3" width="62" height="6" />
                    <Circle cx="47" cy="42" r="38" />
                    <Rect x="111" y="39" rx="0" ry="0" width="65" height="7" />
                  </ContentLoader>
                </>
              ) : (
                <Row
                  style={{
                    marginHorizontal: 10,
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={{
                      uri: "data:image/jpg;base64," + clinicData.profile_image,
                    }}
                    style={{
                      width: 75,
                      height: 75,
                      borderRadius: 50,
                      marginRight: 28,
                    }}
                  />
                  <View>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: normalize(18),
                        marginBottom: 8,
                      }}
                    >
                      {clinicData.name}
                    </Text>
                    <Text style={{ width: "40%" }}>{clinicData.address}</Text>
                    <Text>{clinicData.city}</Text>
                    {clinicData.postcode ? (
                      <Text>
                        {clinicData.postcode}, {clinicData.state}
                      </Text>
                    ) : null}

                    <View style={{ margin: 4 }} />
                    <Text>{clinicData.phone_number}</Text>
                  </View>
                </Row>
              )}
              {fromDoctorCard ? (
                <></>
              ) : (
                editButton("Edit Clinic Details", "EditClinicScreen")
              )}
            </>
          )}
          <View style={{ margin: 10 }} />

          {isDoctor ? renderTitle("Clinical Expertise", "notes-medical") : null}
          <View style={{ margin: 10 }} />
          {profileLoading || clinicLoading ? (
            <>
              <ContentLoader
                speed={2}
                width={340}
                height={84}
                viewBox="0 0 340 84"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <Rect x="53" y="0" rx="3" ry="3" width="67" height="11" />
                <Rect x="137" y="0" rx="3" ry="3" width="140" height="11" />
                <Rect x="145" y="46" rx="3" ry="3" width="53" height="11" />
                <Rect x="217" y="45" rx="3" ry="3" width="72" height="11" />
                <Rect x="30" y="46" rx="3" ry="3" width="100" height="11" />
                <Rect x="13" y="23" rx="3" ry="3" width="140" height="11" />
                <Rect x="161" y="23" rx="3" ry="3" width="173" height="11" />
              </ContentLoader>
            </>
          ) : (
            <>
              {profileData?.facilities || clinicData.facilities ? (
                <View style={{ marginHorizontal: -30 }}>
                  <Categories
                    data={
                      isDoctor || fromDoctorCard
                        ? profileData?.facilities
                        : clinicData.facilities
                    }
                    isShowAll={true}
                    title={isDoctor ? profession : "Clinical Expertise"}
                    category={""}
                    isDoctor={isDoctor}
                  />
                  <View style={{ marginHorizontal: 32 }}>
                    {fromDoctorCard ? (
                      <></>
                    ) : (
                      editButton(`Edit Clinical Expertise`, "EditFaciltyScreen")
                    )}
                  </View>
                </View>
              ) : null}
            </>
          )}
          <View style={{ margin: 100 }} />
          {fromDoctorCard ? (
            <></>
          ) : (
            <View
              style={{
                alignItems: "center",
              }}
            >
              <TouchableOpacity onPress={onPressDelete}>
                <Text
                  style={{
                    color: "red",
                    fontSize: normalize(12),
                    opacity: 0.5,
                  }}
                >
                  Delete Account
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
        <View style={{ margin: 100 }} />
      </ScrollView>
      {/* <Loading visible={profileLoading || userLoading} /> */}

      <BottomModal visible={modalVisible} hide={() => setModalVisible(false)} />
      <Modal
        isVisible={verifiedModalVisible}
        onBackdropPress={() => setVerifiedModalVisible(false)}
        animationIn={"fadeIn"}
        animationOut={"fadeOut"}
      >
        <View
          style={{
            margin: 32,
            marginVertical: 12,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Image
            source={require("../../../assets/images/check.png")}
            style={{ height: 14, width: 14 }}
          />
          <Text
            style={{
              fontWeight: "bold",
              fontSize: normalize(20),
              marginHorizontal: 8,
            }}
          >
            Verified
          </Text>
        </View>

        <Text
          style={{
            marginTop: 4,
            marginBottom: 8,
            marginHorizontal: 32,
          }}
        >
          {`${profileData?.first_name} ${profileData?.last_name[0]}. account has been verified by the Supermedic team\n\n`}
          <Text style={{ fontWeight: "bold" }}>{`Verified documents:\n`}</Text>
          <Text style={{ lineHeight: 24 }}>
            {`- Medical Licenses (APC, MMC, NSR)\n- Clinical Expertise\n- Personal Information`}
          </Text>
        </Text>
        <ModalButtons
          onCancel={() => setVerifiedModalVisible(false)}
          onConfirm={() => setVerifiedModalVisible(false)}
          rightLabel={"OK"}
        />
      </Modal>
      <VerificationModal
        isVisible={verifyModalVisible}
        onHide={() => setVerifyModalVisible(false)}
        onConfirm={() => {
          setVerifyModalVisible(false);
        }}
      />
    </>
  );
}

const styles = {
  title: {
    margin: 18,
  },
  jobsAvailableTitle: {
    fontSize: 24,
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 18,
    marginBottom: 10,
  },
  filterText: {
    fontWeight: "bold",
  },
  divider: {
    alignSelf: "center",
    width: "90%",
    height: 0.5,
    backgroundColor: "#eee",
    marginBottom: 24,
  },
  sortContainer: {
    marginTop: -18,
    alignItems: "flex-end",
    marginRight: 18,
  },
  sortByText: {
    fontSize: 12,
    marginBottom: 2,
  },
  sortText: {
    fontWeight: "bold",
  },
  line: {
    marginTop: 20,
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  lineTitle: {
    padding: 10,
    backgroundColor: "white",
    marginTop: -18,
  },
  editButton: {
    marginTop: 24,
    borderRadius: 8,
    borderWidth: 1,
    padding: 10,
    paddingHorizontal: 18,
    borderColor: "rgba(0,0,0,0.3)",
  },
  sectionTitle: {
    fontFamily: "NotoSerif",
    fontSize: normalize(18),
    letterSpacing: -0.5,
    marginLeft: 14,
  },
};
