import { Feather } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState, useEffect } from "react";
import { View, Alert, Text, Keyboard, Platform } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import {
  BackButton,
  FloatingButton,
  Loading,
  ErrorMessage,
} from "../../../components/all";
import { updateProfile } from "../../../services/profile";
import * as Random from "expo-random";
import {
  addFields,
  checkEmptyFields,
  resetEmptyFields,
  setErrorFields,
} from "../../../reducers/profile/profile";
import { useMutation } from "react-query";

import Step3 from "../../Auth/Register/Step3";
import Step4 from "../../Auth/Register/Step4";
import Step5 from "../../Auth/Register/Step5";
import { normalize } from "../../../utils/normalize";
import Styles from "../../../constants/styles";

Object.map = function (o, f, ctx) {
  ctx = ctx || this;
  var result = {};
  Object.keys(o).forEach(function (k) {
    result[k] = f.call(ctx, o[k], k, o);
  });
  return result;
};

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const EditProfile = (props) => {
  const safeInsets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const isEditLicense = props.route.params?.isEditLicense;

  const { fields, errorFields } = useSelector((state) => state.profile);
  const { isDoctor } = useSelector((state) => state.user);

  const [focusedInput, setFocusedInput] = useState(null);
  const [allFields, setAllFields] = useState({ education: [""] });
  const [verifying, setVerifying] = useState(false);
  const [isSubmitData, setSubmitData] = useState(false);
  const [editedProfileImage, setEditProfileImage] = useState(false);
  const [isTakenError, setIsTakenError] = useState(false);
  const [updatedFields, setUpdatedFields] = useState([]);

  useEffect(() => {
    setAllFields({
      ...allFields,
      ...fields,
    });
  }, []);

  const resetErrorFields = () => {
    dispatch(resetEmptyFields());
  };
  const onUpdateProfile = useMutation(updateProfile, {
    onError: (error) => {
      setVerifying(false);
      let formattedError = "";
      let allErrors = [];
      Object.map(error.response.data.errors, (v, k) => {
        let errorMessage = v.join("\n");
        formattedError += `${capitalizeFirstLetter(k)}`;
        allErrors += k;
      });
      dispatch(setErrorFields(allErrors));
      if (allErrors.length > 0) {
        setIsTakenError(true);
      }
      Alert.alert(
        "Profile Update Error",
        `Please check the highlighted fields and try again.`
      );
    },
    onSuccess: (res) => {
      setVerifying(false);
      props.navigation.navigate({
        name: "ProfileScreen",
        params: {
          profileEditSuccess: Random.getRandomBytes(1),
        },
        merge: true,
      });
    },
  });

  const setField = (field, value) => {
    console.log("check updated field", updatedFields);
    setUpdatedFields([...new Set([...updatedFields, field])]);
    setAllFields({ ...allFields, [field]: value });
  };

  const onPressUpdate = () => {
    Keyboard.dismiss();
    setIsTakenError(false);
    setVerifying(true);
    dispatch(addFields(allFields));
    dispatch(
      checkEmptyFields({
        isDoctor: isDoctor,
      })
    );
    setSubmitData(true);
  };

  useEffect(() => {
    if (errorFields.length > 0) {
      setVerifying(false);
    } else if (isSubmitData) {
      let params = Object.fromEntries(
        Object.entries(fields).filter(([key, value]) =>
          updatedFields.includes(key)
        )
      );
      console.log("check params", params);
      onUpdateProfile.mutate(params);
    }
  }, [fields]);
  return (
    <>
      <View style={{ backgroundColor: "white", ...Styles.webSmallContainer }}>
        <BackButton
          onPressBack={() => {
            resetErrorFields();
          }}
        />
        <KeyboardAwareScrollView extraHeight={180}>
          <View style={{ margin: 10 }} />
          {isEditLicense && isDoctor ? (
            <>
              <Step5
                editProfile
                isEditLicense={true}
                setFocusedInput={setFocusedInput}
                focusedInput={focusedInput}
                setField={setField}
                setAllFields={setAllFields}
                allFields={allFields}
              />
              <View>
                <Text
                  style={{
                    marginHorizontal: 24,
                    marginBottom: 20,
                    fontSize: normalize(12),
                    color: "#979797",
                  }}
                >
                  Note: Updating your APC/MMC{fields?.nsr ? `/nsr` : ""} number
                  will require us to re-verify your identity.
                </Text>
              </View>
              {isTakenError && errorFields.includes("mmc") ? (
                <ErrorMessage
                  style={{ marginHorizontal: 24 }}
                  visible={true}
                  label={`The MMC number has already been taken.`}
                />
              ) : null}

              {isTakenError && errorFields.includes("apc") ? (
                <ErrorMessage
                  style={{ marginHorizontal: 24 }}
                  visible={true}
                  label={`The APC number has already been taken.`}
                />
              ) : null}

              {isTakenError && errorFields.includes("nsr") ? (
                <ErrorMessage
                  style={{ marginHorizontal: 24 }}
                  visible={true}
                  label={`The NSR number has already been taken.`}
                />
              ) : null}
            </>
          ) : (
            <>
              <Step3
                editProfile
                setFocusedInput={setFocusedInput}
                focusedInput={focusedInput}
                setField={setField}
                allFields={allFields}
                setEditProfileImage={setEditProfileImage}
              />
              {isDoctor ? (
                <Step4
                  editProfile
                  setFocusedInput={setFocusedInput}
                  focusedInput={focusedInput}
                  setField={setField}
                  allFields={allFields}
                />
              ) : null}
              {isTakenError && errorFields.includes("nric") ? (
                <ErrorMessage
                  style={{ marginHorizontal: 24, marginBottom: 14 }}
                  visible={true}
                  label={`The NRIC/Passport number has already been taken.`}
                />
              ) : null}
            </>
          )}
          <View style={{ margin: 140 }} />
        </KeyboardAwareScrollView>
      </View>
      <FloatingButton status={"rejected"} onPress={() => onPressUpdate()}>
        <View
          style={{
            borderRadius: 100,
            overflow: "hidden",
          }}
        >
          <LinearGradient
            start={{ x: -0.2, y: 0.2 }}
            // Button Linear Gradient
            colors={[
              "rgb(248, 100, 108)",
              "rgb(242, 49, 123)",
              "rgb(234,0,137)",
            ]}
          >
            <View
              style={{
                height: 70,
                width: 70,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Feather name={"check-circle"} size={30} color="white" />
            </View>
          </LinearGradient>
        </View>
      </FloatingButton>
      {Platform.OS === "web" ? null : <Loading visible={verifying} />}
    </>
  );
};

export default EditProfile;
