import {
  FontAwesome,
  FontAwesome5,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import React from "react";
import {
  Dimensions,
  Text,
  View,
  Alert,
  TouchableOpacity,
  Platform,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
  Centered,
  ErrorMessage,
  Input,
  Row,
  StepHeader,
} from "../../../components/all";
import colors from "../../../constants/colors";
import { resetEmptyFields } from "../../../reducers/auth/register";
import { resetEmptyFields as resetProfileEmptyFields } from "../../../reducers/profile/profile";

export default function Step5({
  setFocusedInput,
  focusedInput,
  setAllFields,
  setField,
  allFields,
  editProfile,
  editEducation,
  isEditLicense,
  isWeb,
  currentStep,
}) {
  const dispatch = useDispatch();

  const { errorFields } = useSelector(
    (state) => state[editProfile ? "profile" : "register"]
  );
  let { education } = allFields;
  if (education === undefined) {
    education = [];
  }
  const years = Array.from({ length: 100 }, (v, k) => {
    let date = new Date().getFullYear() - k;
    return {
      label: date.toString(),
      value: date.toString(),
    };
  });

  const setErrorFields = () => {
    dispatch(editProfile ? resetProfileEmptyFields() : resetEmptyFields());
  };

  const setArrayField = (field, index, key, value) => {
    let temp = [...allFields[field]];
    temp[index] = {
      ...temp[index],
      [key]: value,
    };
    setAllFields({ ...allFields, [field]: temp });
  };

  const onAddEducation = () => {
    setErrorFields();
    setAllFields({
      ...allFields,
      education: [...allFields.education, ""],
    });
  };

  const onRemoveEducation = (index) => {
    Alert.alert(
      "Delete Education",
      "Are you sure you want to delete this education?",
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "OK",
          onPress: () => {
            setErrorFields();
            let temp = [...allFields.education];
            temp.splice(index, 1);
            setAllFields({ ...allFields, education: temp });
          },
        },
      ]
    );
  };
  if (isWeb && currentStep !== 4) return <></>;

  const input = {
    education: "education",
    educationError: "educationError",
    mmc: "mmc",
    apc: "apc",
    nsr: "nsr",
    mmcError: "mmcError",
    apcError: "apcError",
    nsrError: "nsrError",
  };
  return (
    <View
      style={{
        width: Dimensions.get("window").width,
        marginTop: isEditLicense ? 0 : Platform.OS !== "android" ? -50 : 0,
      }}
    >
      {editEducation ? (
        <View
          style={{
            marginTop: 80,
          }}
        ></View>
      ) : null}
      {editProfile && !isEditLicense ? (
        <></>
      ) : (
        <StepHeader
          label={
            editEducation
              ? `Update Education`
              : isEditLicense
              ? `Update\nMedical License`
              : `Medical License \n& Education`
          }
          icon={<FontAwesome name="user-md" size={24} color="black" />}
          subtitle={
            isEditLicense
              ? `Change your medical license numbers below`
              : `Include relevant certification, licenses and education`
          }
        />
      )}
      <Centered style={{ marginTop: 20 }}>
        {editEducation ? null : (
          <>
            <Input
              onChangeText={(val) => {
                setField(input.mmc, val);
              }}
              value={allFields[input.mmc]}
              icon={<FontAwesome5 name="address-card" size={16} color="grey" />}
              placeholder="MMC no. - xxxxxx"
              containerStyle={{ width: "90%", marginTop: 24 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.mmc);
              }}
              error={
                errorFields.includes(input.mmc) ||
                errorFields.includes(input.mmcError)
              }
              focused={focusedInput === input.mmc}
              maxLength={6}
              keyboardType="numeric"
            />
            <Input
              onChangeText={(val) => {
                setField(input.apc, val);
              }}
              value={allFields[input.apc]}
              icon={<Ionicons name="medical" size={16} color="grey" />}
              placeholder="APC no. - xxxxx/xxxx"
              containerStyle={{ width: "90%", marginTop: 10 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.apc);
              }}
              error={
                errorFields.includes(input.apc) ||
                errorFields.includes(input.apcError)
              }
              focused={focusedInput === input.apc}
              maxLength={11}
            />
            {allFields.isSpecialist && (
              <Input
                onChangeText={(val) => {
                  setField(input.nsr, val);
                }}
                value={allFields[input.nsr]}
                icon={
                  <FontAwesome5 name="book-medical" size={16} color="grey" />
                }
                placeholder="NSR no."
                containerStyle={{ width: "90%", marginTop: 10 }}
                onBlur={() => setFocusedInput(null)}
                onFocus={() => {
                  setErrorFields();
                  setFocusedInput(input.nsr);
                }}
                error={
                  errorFields.includes(input.nsr) ||
                  errorFields.includes(input.nsrError)
                }
                focused={focusedInput === input.nsr}
                maxLength={6}
                keyboardType="numeric"
              />
            )}
          </>
        )}
        {!editProfile &&
          education?.map((item, index) => (
            <>
              <Row>
                <Input
                  onChangeText={(val) => {
                    setArrayField(input.education, index, "name", val);
                  }}
                  value={education[index]?.name || ""}
                  placeholder={
                    index === 0 ? "Primary Degree" : "Secondary Degree"
                  }
                  icon={<FontAwesome name="book" size={14} color="grey" />}
                  containerStyle={{
                    width: index === 0 ? "90%" : "80%",
                    marginTop: 10,
                  }}
                  onBlur={() => setFocusedInput(null)}
                  onFocus={() => {
                    setErrorFields();
                    setFocusedInput(input.education[index]);
                  }}
                  error={errorFields.includes(input.educationError)}
                  focused={focusedInput === input.education[index]}
                />
                {index === 0 ? null : (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "10%",
                      marginTop: 14,
                    }}
                  >
                    <TouchableOpacity onPress={() => onRemoveEducation(index)}>
                      <MaterialIcons
                        name="highlight-remove"
                        size={18}
                        color={"rgba(0,0,0,0.5)"}
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </Row>
              <Row>
                <Input
                  onChangeText={(val) => {
                    setArrayField(input.education, index, "school", val);
                  }}
                  value={education[index]?.school || ""}
                  placeholder={"University/College"}
                  icon={
                    <FontAwesome name="university" size={14} color="grey" />
                  }
                  containerStyle={{ width: "63%", marginTop: 10 }}
                  onBlur={() => setFocusedInput(null)}
                  onFocus={() => {
                    setErrorFields();
                    setFocusedInput(input.education[index]);
                  }}
                  error={errorFields.includes(input.educationError)}
                  focused={focusedInput === input.education[index]}
                />
                <Input
                  isPicker
                  onChangeText={(val) => {
                    setArrayField(
                      input.education,
                      index,
                      "year",
                      val?.toString()
                    );
                  }}
                  value={education[index]?.year || ""}
                  placeholder="Year"
                  containerStyle={{
                    width: "25%",
                    marginTop: 10,
                    marginLeft: 10,
                  }}
                  onBlur={() => setFocusedInput(null)}
                  onFocus={() => {
                    setErrorFields();
                    setFocusedInput(input.state);
                  }}
                  error={errorFields.includes(input.educationError)}
                  focused={focusedInput === input.state}
                  pickerItems={years}
                />
              </Row>
            </>
          ))}
      </Centered>
      {!editProfile && (
        <View style={{ width: "100%", alignItems: "flex-end" }}>
          <TouchableOpacity onPress={onAddEducation}>
            <View>
              <Text
                style={{
                  color: colors.primary,
                  fontWeight: "bold",
                  padding: 10,
                  paddingRight: 24,
                }}
              >
                + add education
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      )}
      <View style={{ margin: 10, marginLeft: 24 }}>
        <ErrorMessage
          visible={
            errorFields.includes(input.mmc) ||
            errorFields.includes(input.apc) ||
            errorFields.includes(input.educationError)
          }
          label={"Please fill in the required fields."}
        />
        <ErrorMessage
          visible={errorFields.includes(input.mmcError)}
          label={"Please enter a valid MMC number."}
        />
        <ErrorMessage
          visible={errorFields.includes(input.apcError)}
          label={"Please enter a valid APC number."}
        />
        <ErrorMessage
          visible={errorFields.includes(input.nsrError)}
          label={"Please enter a valid NSR number."}
        />
      </View>
    </View>
  );
}
