import React from "react";
import { Text, View } from "react-native";
import { WebView } from "react-native-webview";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentSetup } from "../../../reducers/auth/user";

const PaymentSetup = () => {
  const dispatch = useDispatch();

  const handleWebViewNavigationStateChange = (newNavState) => {
    const { url } = newNavState;
    if (!url) return;
  };

  React.useEffect(() => {
    // after done payment
    dispatch(setPaymentSetup(false));
  }, []);
  return (
    <WebView
      style={styles.container}
      source={{ uri: "https://curlec.com/" }}
      onNavigationStateChange={handleWebViewNavigationStateChange}
    />
  );
};
const styles = {
  container: {
    flex: 1,
  },
};
export default PaymentSetup;
