import PropTypes from "prop-types";
import * as React from "react";
import { View } from "react-native";

const Row = (props) => {
  let extraStyles = {};
  if (props.centered) extraStyles = { alignItems: "center" };
  return (
    <View
      {...props}
      style={{ flexDirection: "row", ...extraStyles, ...props.style }}
    >
      {props.children}
    </View>
  );
};

Row.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default Row;
