let settings = [
  {
    title: `My Profile`,
    icon: "account-circle",
    navigate: "ProfileScreen",
  },
  {
    title: `Change Password`,
    icon: "lock-open",
    navigate: "ChangePasswordScreen",
  },
  {
    title: `App Permissions`,
    icon: "notifications-none",
    navigate: "Permission",
    url: "https://curlec.com/",
  },
  // {
  //   title: `Bank Account Details`,
  //   icon: "local-atm",
  //   navigate: "BankAccountScreen",
  //   url: "home",
  // },
  {
    title: `Privacy Policy`,
    icon: "shield",
    navigate: "WebViewScreen",
    url: "policy",
  },
  {
    title: `Terms & Conditions`,
    icon: "list",
    navigate: "WebViewScreen",
    url: "terms",
  },
  {
    title: `Help & Support - FAQ`,
    icon: "help-outline",
    navigate: "HelpSupportScreen",
  },
  {
    title: `Give us feedback`,
    icon: "feedback",
    navigate: "FeedbackScreen",
  },
  {
    title: `Learn how to use Supermedic`,
    icon: "ondemand-video",
    navigate: "WebViewScreen",
    url: "https://google.com/",
  },
];

export default settings;
