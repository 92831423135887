import { Feather, FontAwesome } from "@expo/vector-icons";
import { useIsFocused } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Image,
  Platform,
  Dimensions,
  AppState,
  Linking
} from "react-native";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Centered,
  ErrorMessage,
  Input,
  Loading,
  Title,
} from "../../../components/all";
import Colors from "../../../constants/colors";
import {
  setFirstLogin,
  setIsDoctor,
  setLoggedIn,
  setLoggedOut,
  setIsDev,
} from "../../../reducers/auth/user";

import { getVersion, login, postNotificationToken } from "../../../services/auth";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import api from "../../../services/api";
import queryClient from "../../../utils/queryClient";
import { normalize } from "../../../utils/normalize";
import { buildVersion } from "../../../../App";
import * as Application from "expo-application";
import { setDocsUploaded } from "../../../reducers/auth/user";

const ver = "v" + Application.nativeApplicationVersion;

export default function Login(props) {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { firstLogin, isDoctor, isDev } = useSelector((state) => state.user);

  const [focusedInput, setFocusedInput] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const appState = useRef(AppState.currentState);

  const { data: versionQuery, refetch: refetchVersionQuery } = useQuery(
    "version",
    getVersion
  );
  let currentVersion = versionQuery?.data?.data?.version;

  const _handleAppStateChange = (nextAppState) => {
    if (nextAppState === "active") {
      queryClient.removeQueries("version");
      refetchVersionQuery();
    }

    appState.current = nextAppState;
  };

  useEffect(() => {
    if (currentVersion && !__DEV__ && Platform.OS !== "web") checkUpdate();
    const subscription = AppState.addEventListener(
      "change",
      _handleAppStateChange
    );
    return () => {
      subscription?.remove();
    };
  }, [versionQuery]);

  const compareVersion = (old, curr) => {
    const oldArr = old.split(".");
    const currArr = curr.split(".");
    for (let i = 0; i < oldArr.length; i++) {
      if (parseInt(oldArr[i]) < parseInt(currArr[i])) {
        return true;
      }
    }
    return false;
  };

  const checkUpdate = () => {
    const appVersion = Application.nativeApplicationVersion;
    if (compareVersion(appVersion, currentVersion)) {
      Alert.alert(
        "Version Update",
        "There is a newer version released, please update the app to continue",
        [
          {
            text: "Update Now",
            onPress: () => {
              const isAndroid = Platform.OS === "android";
              const doctorURL = isAndroid
                ? "https://play.google.com/store/apps/details?id=com.supermedic.supermedic"
                : "https://apps.apple.com/my/app/supermedic/id6443664230";
              const facilitiesURL = isAndroid
                ? "https://play.google.com/store/apps/details?id=com.supermedic.facilities"
                : "https://apps.apple.com/my/app/supermedic-facilities/id6443664368";
              Linking.openURL(isDoctor ? doctorURL : facilitiesURL);
            },
          },
        ],
        {
          cancelable: false,
        }
      );
    }
  }

  useEffect(() => {
    if (!isFocused) {
      setVerifyError(false);
    } else {
      queryClient.clear();
    }
  }, [isFocused]);

  const loginUser = useMutation(login, {
    onError: (error) => {
      console.log("check arrive here error", error.response);
      setVerifying(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      let token = res.data.data.token;
      let slug = res.data.data.slug;

      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch(setLoggedIn({ token, slug }));
      if (Platform.OS === "web") {
        console.log(" is enter here?");
        props.navigation.navigate("HomeScreen");
      } else if (firstLogin === false && Platform.OS !== "web") {
        setVerifying(false);
        console.log(" is enter here? 22");

        props.navigation.navigate("OnboardScreen");
        dispatch(setFirstLogin());
      } else {
        handleLogin(slug);
      }
    },
  });

  const sendToken = useMutation(postNotificationToken, {
    onError: (error) => {
      setVerifying(false);
      // alert("Oops! " + error.response.data.message);
      props.navigation.navigate("HomeScreen");
    },
    onSuccess: (res) => {
      setVerifying(false);
      props.navigation.navigate("HomeScreen");
    },
  });

  const handleLogin = async (slug) => {
    let token = (await Notifications.getExpoPushTokenAsync({
      'projectId': 'fb1217de-321c-4a1d-befc-c8ebb96b032c',
    }))?.data || false;

    if (!token) {
      props.navigation.navigate("HomeScreen");
    } else {
      sendToken.mutate({
        slug,
        token,
      });
    }
  };

  const verifyLoginCredentials = async () => {
    dispatch(setLoggedOut());

    setVerifyError(false);
    setVerifying(true);

    if (!email || !password) {
      setVerifying(false);
      setVerifyError(true);
    } else {
      loginUser.mutate({
        email,
        password,
        scope: isDoctor ? "doctor" : "clinic_owner",
      });
    }
  };

  return (
    <>
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: "white",
        }}
      >
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Centered></Centered>
          <Centered style={{ width: "100%" }}>
            {isDoctor ? (
              <Image
                source={require("../../../assets/images/supermedic-logo.png")}
                style={{ height: 250, width: 300 }}
              />
            ) : (
              <Image
                source={require("../../../assets/images/supermedic-logo-facilities.png")}
                style={{ height: 250, width: 300 }}
              />
            )}

            <Input
              icon={<FontAwesome name="envelope" size={14} color="grey" />}
              placeholder="Email"
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setVerifyError(false);
                setFocusedInput("email");
              }}
              focused={focusedInput === "email"}
              error={verifyError}
              disabled={verifying}
              containerStyle={{
                width: Dimensions.get("window").width > 480 ? 480 : "90%",
              }}
              autoCapitalize="none"
              value={email}
              onChangeText={(val) => setEmail(val)}
            />
            <Input
              icon={<FontAwesome name="lock" size={18} color="grey" />}
              placeholder="Password"
              secureTextEntry={!showPassword}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setVerifyError(false);
                setFocusedInput("password");
              }}
              focused={focusedInput === "password"}
              error={verifyError}
              autoCapitalize="none"
              disabled={verifying}
              containerStyle={{
                width: Dimensions.get("window").width > 480 ? 480 : "90%",
                marginTop: 10,
              }}
              value={password}
              onChangeText={(val) => setPassword(val)}
              onSubmitEditing={verifyLoginCredentials}
              backIcon={
                <Feather
                  name={showPassword ? "eye-off" : "eye"}
                  size={18}
                  color="grey"
                />
              }
              onPressBackIcon={() => {
                setShowPassword(!showPassword);
              }}
            />
            <View
              style={{
                width: Dimensions.get("window").width > 480 ? 480 : "88%",
                marginTop: 8,
              }}
            >
              <ErrorMessage
                visible={verifyError}
                label="Incorrect email address or password."
              />
            </View>
            <View
              style={{
                width: Dimensions.get("window").width > 480 ? 480 : "100%",
              }}
            >
              <Text
                style={{
                  alignSelf: "flex-end",
                  marginRight: 24,
                  fontSize: normalize(12),
                  color: Colors.primary,
                }}
                onPress={() =>
                  props.navigation.navigate("ForgotPasswordScreen")
                }
              >
                Forgot Password
              </Text>
            </View>

            <Button
              type="primary"
              style={{ marginTop: 48 }}
              label="Sign in"
              disabled={verifying}
              onPress={() => {
                verifyLoginCredentials();
              }}
            />
            <>
              <Text
                style={{
                  marginTop: 48,
                  marginBottom: -8,
                  fontSize: normalize(16),
                }}
              >
                Don't have an account?
              </Text>
              <Button
                label="Create an account"
                disabled={verifying}
                onPress={() => {
                  props.navigation.navigate(
                    isDoctor ? "StepProfession" : "RegisterScreen"
                  );
                }}
                textStyle={{
                  fontSize: normalize(18),
                  fontWeight: "bold",
                  color: Colors.primary,
                }}
                style={{ shadowOpacity: 0 }}
              />
            </>
          </Centered>
          <Centered>
            <TouchableOpacity
              onLongPress={() => {
                dispatch(setIsDev(!isDev));
                // if (!isDev) {
                //   Alert.prompt("Change API URL", "Please enter the url below", [
                //     {
                //       text: "Cancel",
                //       onPress: () => console.log("Cancel Pressed"),
                //       style: "cancel",
                //     },
                //     {
                //       text: "OK",
                //       onPress: (url) => {
                //         api.defaults.baseURL = url;
                //       },
                //     },
                //   ]);
                // }
              }}
            >
              <Text
                style={{
                  color: "rgba(0,0,0,0.4)",
                  fontSize: normalize(10),
                  marginBottom: 12,
                }}
              >
                {Platform.OS === "web" ? (
                  `v${buildVersion}`
                ) : (
                  <>
                    {ver} ({buildVersion}) {isDev ? `- dev` : ``}
                  </>
                )}
              </Text>
            </TouchableOpacity>
          </Centered>
        </ScrollView>
      </SafeAreaView>

      <Loading visible={verifying && Platform.OS !== "web"} />
    </>
  );
}
