import { Entypo, FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState, useEffect } from "react";
import { View, Alert, Text, TouchableOpacity } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import {
  Centered,
  Input,
  Row,
  StepHeader,
  BackButton,
  Button,
  Loading,
} from "../../../components/all";
import {
  deleteEducation,
  getEducation,
  postEducation,
  updateEducation,
} from "../../../services/profile";
import { useMutation, useQuery } from "react-query";

import Styles from "../../../constants/styles";
import { normalize } from "../../../utils/normalize";
import SelectDropdown from "react-native-select-dropdown";

const EditEducation = (props) => {
  const safeInsets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const { fields } = useSelector((state) => state.profile);
  const { isDoctor } = useSelector((state) => state.user);

  const [focusedInput, setFocusedInput] = useState(null);
  const [allFields, setAllFields] = useState({ education: [""] });
  const [verifying, setVerifying] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [newEducation, setNewEducation] = useState({
    school: "",
    name: "",
    year: "",
  });
  const [errorFields, setErrorFields] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const {
    data: educationQuery,
    refetch,
    isLoading,
  } = useQuery("educations", getEducation);

  useEffect(() => {
    setAllFields({
      ...allFields,
      ...fields,
    });
  }, []);

  useEffect(() => {
    if (educationQuery?.data) {
      // dispatch(setFields(facilitiesQuery.data.data));
      setEducationData(educationQuery.data.data);
    }
  }, [educationQuery]);

  const onChangeEducation = (field, value) => {
    setNewEducation({ ...newEducation, [field]: value });
  };
  const years = Array.from({ length: 35 }, (v, k) => {
    let date = new Date().getFullYear() - k;
    return {
      label: date.toString(),
      value: date.toString(),
    };
  });
  const input = {
    education: "education",
    educationError: "educationError",
  };

  const onPressDelete = (data) => {
    Alert.alert("Are you sure?", "Do you want to delete this education?", [
      {
        text: "Cancel",
        style: "cancel",
      },
      {
        text: "OK",
        onPress: () => {
          onDeleteEducation.mutate(data);
        },
      },
    ]);
  };

  const onAddEducation = useMutation(postEducation, {
    onError: (error) => {
      setVerifying(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      setVerifying(false);
      refetch();
      setNewEducation();
    },
  });

  const onUpdateEducation = useMutation(updateEducation, {
    onError: (error) => {
      setVerifying(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      setVerifying(false);
      refetch();
      setEditIndex(null);
      setNewEducation();
    },
  });

  const onDeleteEducation = useMutation(deleteEducation, {
    onError: (error) => {
      setVerifying(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      setVerifying(false);
      refetch();
      setNewEducation();
    },
  });

  const onSaveEducation = (isAddNew) => {
    let errors = [];
    !newEducation?.school && errors.push("school");
    !newEducation?.name && errors.push("name");
    !newEducation?.year && errors.push("year");
    if (errors.length > 0) {
      setErrorFields(errors);
    } else {
      if (isAddNew) {
        onAddEducation.mutate(newEducation);
      } else {
        onUpdateEducation.mutate(newEducation);
      }
    }
  };

  const backAndRefresh = () => {
    props.navigation.navigate({
      name: "ProfileScreen",
      params: {
        profileEditSuccess: Math.random(),
      },
      merge: true,
    });
  };

  const renderEducationFields = () => (
    <>
      <Row>
        <Input
          onChangeText={(val) => {
            onChangeEducation("name", val);
          }}
          value={newEducation?.name || ""}
          placeholder={"Degree"}
          icon={<FontAwesome name="book" size={14} color="grey" />}
          containerStyle={{
            width: "90%",
            marginTop: 10,
          }}
          onBlur={() => setFocusedInput(null)}
          onFocus={() => {
            setErrorFields([]);
            setFocusedInput("name");
          }}
          error={errorFields.includes("name")}
          focused={focusedInput === "name"}
        />
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Input
          onChangeText={(val) => {
            onChangeEducation("school", val);
          }}
          value={newEducation?.school || ""}
          placeholder={"University/College"}
          icon={<FontAwesome name="university" size={14} color="grey" />}
          containerStyle={{ width: "63%", marginTop: 10 }}
          onBlur={() => setFocusedInput(null)}
          onFocus={() => {
            setErrorFields([]);
            setFocusedInput("school");
          }}
          error={errorFields.includes("school")}
          focused={focusedInput === "school"}
        />
        <Input
          isPicker
          onChangeText={(val) => {
            onChangeEducation("year", val?.toString());
            setErrorFields([]);
          }}
          value={newEducation?.year || ""}
          placeholder="Year"
          containerStyle={{
            width: "25%",
            marginTop: 10,
            marginLeft: 10,
          }}
          onBlur={() => setFocusedInput(null)}
          onFocus={() => {
            setErrorFields([]);
            setFocusedInput("year");
          }}
          error={errorFields.includes("year")}
          focused={focusedInput === "year"}
          pickerItems={years}
        />
      </Row>
    </>
  );
  return (
    <>
      <View style={{ backgroundColor: "white" }}>
        <BackButton
          onPressBack={() => {
            backAndRefresh();
          }}
          ignoreBack
        />
        <KeyboardAwareScrollView extraHeight={180}>
          <StepHeader
            label={`Edit Education`}
            icon={<FontAwesome5 name="user-graduate" size={24} color="black" />}
            subtitle={`Updating your education will require us to verify your identity again.`}
          />
          <View style={{ margin: 10 }} />
          <View>
            {educationData?.map((education, eduIndex) => {
              return (
                <>
                  {editIndex === eduIndex ? (
                    <Centered style={{ marginBottom: 24 }}>
                      {renderEducationFields()}
                      <Row>
                        <Button
                          label="Cancel"
                          type={"secondary"}
                          onPress={() => {
                            setEditIndex(null);
                            setErrorFields([]);
                            setNewEducation();
                          }}
                        />

                        <Button
                          label="Save Education"
                          type={"primary"}
                          onPress={() => {
                            onSaveEducation(false);
                          }}
                        />
                      </Row>
                    </Centered>
                  ) : (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginHorizontal: 24,
                        marginBottom: 20,
                      }}
                    >
                      <View
                        style={{
                          margin: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: "bold",
                            color: "rgba(0,0,0,0.9)",
                          }}
                        >
                          {education.name}
                        </Text>
                        <Text
                          style={{
                            color: "rgba(0,0,0,0.6)",
                          }}
                        >
                          {education.year} - {education.school}
                        </Text>
                      </View>
                      <View
                        style={{ marginTop: 0, marginRight: 10, width: 20 }}
                      >
                        <SelectDropdown
                          data={eduIndex === 0 ? ["Edit"] : ["Edit", "Delete"]}
                          dropdownStyle={{
                            marginLeft: -80,
                            marginTop: -40,
                            width: 100,
                          }}
                          rowTextStyle={{
                            fontSize: normalize(14),
                            textAlign: "right",
                            width: "auto",
                            marginRight: 16,
                          }}
                          renderCustomizedButtonChild={() => {
                            return (
                              <Entypo size={16} name="dots-three-vertical" />
                            );
                          }}
                          buttonStyle={{
                            backgroundColor: "transparent",
                          }}
                          onSelect={(selectedItem, index) => {
                            if (selectedItem === "Delete") {
                              setTimeout(() => {
                                onPressDelete(education);
                              }, 500);
                            } else {
                              setEditIndex(eduIndex);
                              setErrorFields([]);

                              setNewEducation({
                                uuid: education.uuid,
                                name: education.name,
                                school: education.school,
                                year: education.year,
                              });
                            }
                          }}
                        />
                      </View>
                    </View>
                  )}
                </>
              );
            })}
          </View>

          {editIndex === null && (
            <Centered>
              {renderEducationFields()}
              <Row>
                <Button
                  label="Add Education"
                  type={"primary"}
                  onPress={() => {
                    setEditIndex(null);
                    onSaveEducation(true);
                  }}
                />
              </Row>
            </Centered>
          )}
          <View style={{ margin: 140 }} />
        </KeyboardAwareScrollView>
      </View>

      <Loading visible={verifying || isLoading} />
    </>
  );
};

export default EditEducation;
