import api from "./api";

export const submitHelp = (data) => {
  return api.post("/questions", data);
};

export const submitFeedback = (data) => {
  return api.post("/questions", data);
};

export const submitChangePassword = (data) => {
  return api.post("/auth/update_password", data);
};

export const submitResetPassword = (data) => {
  return api.post("/auth/reset_password", data);
};

export const getCoord = (data) => {
  return api.get("/clinics/geocode?address=" + data);
};

export const submitBankDetails = ({ data, slug }) => {
  return api.post("/users/" + slug + "/bank", data);
};

export const updateBankDetails = ({ data, slug }) => {
  return api.put("/users/" + slug + "/bank", data);
};

export const getAllBanks = () => {
  return api.get("/banks");
};

export const getBankDetails = (slug) => {
  return api.get("/users/" + slug + "/bank");
};
