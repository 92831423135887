import PropTypes from "prop-types";
import * as React from "react";
import { Text } from "react-native";
import Fonts from "../constants/fonts";

const Title = (props) => {
  return (
    <Text {...props} style={[Fonts.title, props.style]}>
      {props.label}
    </Text>
  );
};

Title.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default Title;
