import { Feather, MaterialIcons, FontAwesome } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import * as React from "react";
import {
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  Image,
  TouchableWithoutFeedback,
  Keyboard,
  Alert,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import {
  BottomModal,
  Header,
  Row,
  Input,
  Centered,
  Button,
  BackButton,
  GradientHeader,
} from "../../../components/all";
import homeStub from "../../../constants/stub/home.json";
import { normalize } from "../../../utils/normalize";
import { useMutation } from "react-query";
import { submitFeedback } from "../../../services/shared";
import Styles from "../../../constants/styles";
export default function Settings(props) {
  const safeInsets = useSafeAreaInsets();
  const { isDoctor } = useSelector((state) => state.user);

  const [modalVisible, setModalVisible] = React.useState(false);
  const [feedbackMessage, setFeedbackMessage] = React.useState("");

  const onSubmit = useMutation(submitFeedback, {
    onError: (error) => alert("Oops! " + error.response.data.message),
    onSuccess: (data) => {
      setFeedbackMessage("");
      alert("Feedback Submitted", "Thanks for submitting your feedback!");
    },
  });

  return (
    <>
      <View
        style={{
          position: "absolute",
          zIndex: 10,
          ...Styles.webContainer,
        }}
      >
        <BackButton color="white" />
      </View>
      <GradientHeader />
      <ScrollView
        style={{
          backgroundColor: "rgba(0,0,0,0)",
          paddingTop: safeInsets.top + 70,
          ...Styles.webContainer,
        }}
      >
        <>
          <View style={{ ...styles.headerContainer }}>
            <MaterialIcons name="feedback" size={24} />

            <View style={styles.header}>
              <Text style={styles.headerName}>{`Feedback`}</Text>
              <Text style={styles.subtitle}>Let us know what you think!</Text>
            </View>
          </View>
          <Image
            source={require("../../../assets/images/wave.png")}
            style={{ width: "100%", zIndex: -10, marginTop: -100 }}
          />
        </>
        <View style={{ backgroundColor: "white", paddingHorizontal: 12 }}>
          <Centered style={{ width: "100%" }}>
            <Input
              onChangeText={setFeedbackMessage}
              value={feedbackMessage}
              placeholder="Enter your feedback"
              containerStyle={{ width: "92%", marginTop: 24 }}
              multiline={true}
            />
          </Centered>
          <Centered style={{ width: "100%" }}>
            <Row>
              <Button
                label={`Back`}
                onPress={() => {
                  props.navigation.goBack();
                }}
                type={"secondary"}
                style={{
                  marginTop: 80,
                  alignItems: "center",
                }}
                textStyle={{
                  textAlign: "center",
                }}
              />
              <Button
                label={`Submit `}
                onPress={() => {
                  if (feedbackMessage) {
                    onSubmit.mutate({
                      type: "feedback",
                      content: feedbackMessage,
                    });
                  } else {
                    alert("Oops! " + "Please enter a valid feedback");
                  }
                }}
                type={"primary"}
                style={{
                  marginTop: 80,
                  alignItems: "center",
                }}
                textStyle={{
                  textAlign: "center",
                }}
              />
            </Row>
          </Centered>
          <View style={{ padding: 80 }} />
        </View>
      </ScrollView>
      <BottomModal visible={modalVisible} hide={() => setModalVisible(false)} />
    </>
  );
}

const styles = {
  title: {
    margin: 18,
  },
  jobsAvailableTitle: {
    fontSize: 24,
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 18,
    marginBottom: 10,
  },
  filterText: {
    fontWeight: "bold",
  },
  divider: {
    alignSelf: "center",
    width: "90%",
    height: 0.5,
    backgroundColor: "#eee",
    marginBottom: 24,
  },
  sortContainer: {
    marginTop: -18,
    alignItems: "flex-end",
    marginRight: 18,
  },
  sortByText: {
    fontSize: 12,
    marginBottom: 2,
  },
  sortText: {
    fontWeight: "bold",
  },
  line: {
    marginTop: 20,
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  lineTitle: {
    padding: 10,
    backgroundColor: "white",
    marginTop: -18,
  },
  headerContainer: {
    margin: 30,
    marginTop: 40,
    marginBottom: 4,
  },
  header: {},
  headerName: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
  },
  subtitle: {
    marginTop: 4,
    color: "rgba(0,0,0,0.5)",
  },
};
