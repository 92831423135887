import {
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import { Image, TouchableOpacity } from "react-native";

function CustomDrawerContent(props) {
  return (
    <DrawerContentScrollView {...props}>
      <TouchableOpacity
        onPress={() => {
          props.navigation.navigate("Home");
        }}
      >
        <Image
          source={require("../assets/images/supermedic-logo-facilities.png")}
          style={{ height: 200, width: 250, alignSelf: "center" }}
        />
      </TouchableOpacity>
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  );
}

export default CustomDrawerContent;
