import { Dimensions, Platform, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Feather, MaterialIcons } from "@expo/vector-icons";

const MenuButton = (props) => {
  return (
    <>
      {Platform.OS === "web" && props?.navigation ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            width:
              Dimensions.get("window").width > 780
                ? 780
                : Dimensions.get("window").width,
            alignSelf: "center",
            padding: 8,
            backgroundColor: props.isTop ? "transparent" : "white",
          }}
        >
          <TouchableOpacity onPress={() => props?.navigation?.openDrawer()}>
            <Feather
              name="menu"
              color={props.isTop ? "white" : "black"}
              size={24}
            />
          </TouchableOpacity>
        </View>
      ) : null}
    </>
  );
};
export default MenuButton;
