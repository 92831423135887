import { Feather } from "@expo/vector-icons";
import React from "react";
import { View, Text } from "react-native";
import { useSelector } from "react-redux";
import { normalize } from "../utils/normalize";
import GradientText from "./GradientText";

const Status = (props) => {
  let title = "";
  let colors = [];
  let icon = "check";
  let subtitle = "";
  let subtitleColor = [];

  const gradient = {
    yellow: ["#F7971E", "#FFD200"],
    blue: ["#005EEC", "#67B4FC"],
    red: ["#EC008C", "#FC6767"],
    green: ["#11998E", "#38EF7D"],
  };

  const { isDoctor: currentIsDoctor } = useSelector((state) => state.user);
  let isDoctor = props.forceIsDoctor ? true : currentIsDoctor;

  // 0 pending
  // 1 pending-doctor
  // 2 pending payment
  // 3 approved
  // 4 completed
  // -1 rejected
  // -2 cancelled
  switch (props.status) {
    case 0:
      colors = isDoctor ? gradient.yellow : gradient.blue;
      title = isDoctor
        ? "Pending\nClinic"
        : props.doctorCard
        ? "Approve"
        : `Doctor${props?.doctorCount > 1 ? "s" : ""}\nApplied`;
      icon = isDoctor ? "clock" : props.doctorCard ? "user-check" : "clock";
      break;
    case 1:
      colors = isDoctor ? gradient.blue : gradient.yellow;
      title = isDoctor ? `Accept\nJob` : `Pending\nDoctor`;
      icon = isDoctor ? "check-circle" : "clock";
      break;
    case 2:
      colors = isDoctor ? gradient.green : gradient.red;
      title = isDoctor ? "Approved" : `Make\nPayment`;
      icon = isDoctor ? "check-circle" : "credit-card";
      if (isDoctor) {
        subtitle = props.isRow ? "Pending Payment" : "Pending\nPayment";
        subtitleColor = gradient.red;
      }
      break;
    case 3:
      colors = gradient.green;
      title = "Approved";
      icon = "check-circle";
      subtitle = props.isRow ? "No Check-in" : "No Check-in";
      subtitleColor = gradient.red;
      break;
    case 4:
      colors = gradient.yellow;
      title = "Job\nCompleted";
      icon = "star";
      break;
    case -1:
      colors = gradient.red;
      title = "Application\nDeclined";
      icon = "x-circle";
      break;
    case -2:
      colors = gradient.red;
      title = "Cancelled";
      icon = "x-circle";
      break;
    case -3:
      colors = gradient.yellow;
      title = "Pending";
      icon = "clock";
      subtitle = "Cancellation";
      subtitleColor = gradient.red;

      break;
    case "disabled":
      colors = ["grey", "grey"];
      title = "Pending";
      icon = "x-circle";
      break;
    case 5:
      colors = gradient.blue;
      title = `Approve`;
      icon = "user-check";
      break;
    case 6:
      colors = gradient.green;
      title = `Checked in`;
      icon = "check-square";
      break;
    default:
      colors = gradient.blue;
      title = isDoctor
        ? props.isRow
          ? "Apply Now"
          : "Apply"
        : `Doctor${props?.doctorCount > 1 ? "s" : ""}\nApplied`;
      icon = "check-circle";
      break;
  }
  if (props.isEdit) {
    title = "Edit Description";
    icon = "edit";
  }
  return (
    <>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: props.isRow ? "row" : "column",
          borderWidth: props.showBorder ? 1 : 0,
          borderColor: colors[0],
          padding: props.showBorder ? 8 : 0,
          paddingHorizontal: props.showBorder ? 18 : 0,
          borderRadius: 20,
          backgroundColor: "white",
        }}
      >
        {[null, 0, undefined].includes(props.status) &&
        !props.isEdit &&
        !isDoctor &&
        !props.doctorCard ? (
          <GradientText
            colors={colors}
            style={{
              fontWeight: "bold",
              fontSize: props.isRow ? normalize(13) : normalize(20),
              textAlign: "center",
              marginBottom: props.isRow ? 0 : -4,
            }}
          >
            {props?.doctorCount || 0}
          </GradientText>
        ) : (
          <Feather name={icon} size={20} color={colors[0]} />
        )}
        <GradientText
          colors={colors}
          style={{
            fontWeight: "bold",
            marginTop: props.isRow ? 0 : 8,
            marginLeft: props.isRow ? 8 : 0,
            fontSize: normalize(11),
            textAlign: "center",
          }}
        >
          {props.isRow ? title.replace(/\n/g, " ") : title}
        </GradientText>
        {subtitle && !props.isRow ? (
          <GradientText
            colors={subtitleColor}
            style={{
              marginTop: props.isRow ? 0 : 4,
              marginLeft: props.isRow ? 8 : 0,
              fontSize: normalize(9),
              textAlign: "center",
            }}
          >
            {subtitle}
          </GradientText>
        ) : null}
      </View>
      {subtitle && props.isRow ? (
        <GradientText
          colors={subtitleColor}
          style={{
            fontSize: normalize(9),
            marginTop: props.showBorder ? 4 : 0,
            textAlign: "center",
            marginLeft: props.showBorder ? 0 : 30,
          }}
        >
          {subtitle}
        </GradientText>
      ) : null}
    </>
  );
};

export default Status;
