import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstLogin: false,
  loggedIn: false,
  isDoctor: false,
  paymentSetup: true,
  token: "",
  slug: "",
  isDev: false,
  notifCount: 0,
  docsUploaded: false,
};

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setFirstLogin: (state) => {
      state.firstLogin = true;
    },
    setLoggedIn: (state, action) => {
      state.token = action.payload.token;
      state.slug = action.payload.slug;
      state.loggedIn = true;
    },
    setLoggedOut: (state) => {
      console.log("setlogged out");
      state.loggedIn = false;
    },
    setIsDoctor: (state, action) => {
      state.isDoctor = action.payload;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    setPaymentSetup: (state, action) => {
      state.paymentSetup = action.payload;
    },
    setIsDev: (state, action) => {
      state.isDev = action.payload;
    },
    setNotifCount: (state, action) => {
      state.notifCount = action.payload;
    },
    setDocsUploaded: (state, action) => {
      state.docsUploaded = action.payload;
    },
  },
});

export const {
  setFirstLogin,
  setLoggedIn,
  setLoggedOut,
  setIsDoctor,
  setUserToken,
  setPaymentSetup,
  setIsDev,
  setNotifCount,
  setDocsUploaded,
} = user.actions;

export default user.reducer;
