import { Feather } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState, useEffect } from "react";
import { View, Alert, Text, Keyboard, Platform } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import {
  BackButton,
  FloatingButton,
  Loading,
  ErrorMessage,
} from "../../../components/all";
import { updateClinic } from "../../../services/profile";

import {
  addFields,
  checkEmptyFields,
  resetEmptyFields,
  setErrorFields,
} from "../../../reducers/profile/profile";
import { useMutation } from "react-query";

import Step3 from "../../Auth/Register/Step3";
import Step4 from "../../Auth/Register/Step4";
import Step5 from "../../Auth/Register/Step5";

Object.map = function (o, f, ctx) {
  ctx = ctx || this;
  var result = {};
  Object.keys(o).forEach(function (k) {
    result[k] = f.call(ctx, o[k], k, o);
  });
  return result;
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const EditProfile = (props) => {
  const safeInsets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const { fields, errorFields } = useSelector((state) => state.profile);
  const { isDoctor } = useSelector((state) => state.user);

  const [focusedInput, setFocusedInput] = useState(null);
  const [allFields, setAllFields] = useState({ education: [""] });
  const [verifying, setVerifying] = useState(false);
  const [isSubmitData, setSubmitData] = useState(false);
  const [editedProfileImage, setEditProfileImage] = useState(false);
  const [isTakenError, setIsTakenError] = useState(false);

  useEffect(() => {
    setAllFields({
      ...allFields,
      ...fields,
    });
  }, []);

  const resetErrorFields = () => {
    dispatch(resetEmptyFields());
  };
  const onUpdateClinic = useMutation(updateClinic, {
    onError: (error) => {
      setVerifying(false);
      let formattedError = "";
      let allErrors = [];
      Object.map(error.response.data.errors, (v, k) => {
        let errorMessage = v.join("\n");
        formattedError += `${capitalizeFirstLetter(k)}`;
        allErrors += k;
      });
      dispatch(setErrorFields(allErrors));
      if (allErrors.length > 0) {
        setIsTakenError(true);
      }
      Alert.alert("Clinic Update Error", formattedError);
    },
    onSuccess: (res) => {
      console.log("success?");
      props.navigation.navigate({
        name: "ProfileScreen",
        params: {
          clinicEditSuccess: Math.random(),
        },
        merge: true,
      });
    },
  });

  const setField = (field, value) =>
    setAllFields({ ...allFields, [field]: value });

  const onPressUpdate = () => {
    Keyboard.dismiss();
    setIsTakenError(false);
    setVerifying(true);
    dispatch(addFields(allFields));
    dispatch(
      checkEmptyFields({
        isDoctor: isDoctor,
        isClinic: true,
      })
    );
    setSubmitData(true);
  };

  useEffect(() => {
    if (errorFields.length > 0) {
      setVerifying(false);
    } else if (isSubmitData) {
      let { clinic_profile_image, clinic_name, ...fieldsWithoutImage } = fields;
      onUpdateClinic.mutate(
        editedProfileImage
          ? { ...fields, name: clinic_name }
          : { ...fieldsWithoutImage, name: clinic_name }
      );
    }
  }, [fields]);
  return (
    <>
      <View style={{ backgroundColor: "white" }}>
        <BackButton
          onPressBack={() => {
            resetErrorFields();
          }}
        />
        <KeyboardAwareScrollView extraHeight={180}>
          <View style={{ margin: 10 }} />

          <Step4
            editProfile
            setFocusedInput={setFocusedInput}
            focusedInput={focusedInput}
            setField={setField}
            allFields={allFields}
            setEditProfileImage={setEditProfileImage}
          />

          {isTakenError && errorFields.includes("nric") ? (
            <ErrorMessage
              style={{ marginHorizontal: 24, marginBottom: 14 }}
              visible={true}
              label={`The NRIC/Passport number has already been taken.`}
            />
          ) : null}

          <View style={{ margin: 140 }} />
        </KeyboardAwareScrollView>
      </View>
      <FloatingButton status={"rejected"} onPress={() => onPressUpdate()}>
        <View
          style={{
            borderRadius: 100,
            overflow: "hidden",
          }}
        >
          <LinearGradient
            start={{ x: -0.2, y: 0.2 }}
            // Button Linear Gradient
            colors={[
              "rgb(248, 100, 108)",
              "rgb(242, 49, 123)",
              "rgb(234,0,137)",
            ]}
          >
            <View
              style={{
                height: 70,
                width: 70,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Feather name={"check-circle"} size={30} color="white" />
            </View>
          </LinearGradient>
        </View>
      </FloatingButton>
      {Platform.OS === "web" ? null : <Loading visible={verifying} />}
    </>
  );
};

export default EditProfile;
