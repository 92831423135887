import { Ionicons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Dimensions,
  Text,
  TouchableOpacity,
  View,
  Alert,
  ActivityIndicator,
  Platform,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Styles from "../../constants/styles";
import { normalize } from "../../utils/normalize";
import { Row, Status, ModalButtons, Modal } from "../all";
import { useMutation, useQuery } from "react-query";
import { resendVerificationEmail } from "../../services/profile";
import { WebView } from "react-native-webview";
import { useDispatch, useSelector } from "react-redux";
import { setDocsUploaded } from "../../reducers/auth/user";
const VerificationModal = (props) => {
  const airtableURL =
    Platform.OS === "web"
      ? "https://airtable.com/embed/"
      : "https://airtable.com/";
  const safeInsets = useSafeAreaInsets();
  const { isDoctor, docsUploaded } = useSelector((state) => state.user);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const INJECTED_JAVASCRIPT = `document.addEventListener("click", function(){
    setTimeout(() => {
      let bodyHTML = document.body.innerHTML;
      window.ReactNativeWebView.postMessage(JSON.stringify(bodyHTML));
    }, 2000)
  });`;
  const name =
    props.profileInfo?.first_name + "+" + props.profileInfo?.last_name;
  const links = {
    doctorTerms: props.profileInfo?.is_specialist
      ? airtableURL + `shrW39tnb9YghKdTy?prefill_Name=${name}`
      : airtableURL + `shrVz3Kn8iZVhT8nF?prefill_Name=${name}`,
    clinicTerms:
      airtableURL +
      `shrrnHBt7prAoSYj3?prefill_Clinic+Name=${props.profileInfo?.clinic_name}`,
  };

  return (
    <Modal
      backdropTransitionOutTiming={0}
      animationIn={"fadeIn"}
      animationOut={"fadeOut"}
      onBackdropPress={() => props.onHide()}
      {...props}
    >
      <View
        style={[
          {
            width:
              Dimensions.get("window").width > 480
                ? 480
                : Dimensions.get("window").width - 40,
            alignSelf: "center",
            backgroundColor: "white",
            paddingTop: 0,
            borderRadius: 20,
            borderTopLeftRadius: 20,
          },
        ]}
      >
        {docsUploaded ? (
          <>
            <View style={{ alignItems: "center", marginBottom: 12 }}>
              <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
            </View>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: normalize(16),
                textAlign: "center",
              }}
            >
              Pending Verification
            </Text>
            <Text
              style={{
                textAlign: "center",
                marginTop: 10,
                marginBottom: 24,
                marginHorizontal: 24,
              }}
            >
              {isDoctor
                ? `We are currently verifying your credentials with the documents uploaded by you. You can start applying for shifts soon.`
                : `We are currently verifying your business registration. You may start posting shifts.`}
            </Text>
          </>
        ) : (
          <>
            <View
              style={[
                {
                  height: Dimensions.get("window").height - 200,
                },
                Platform.OS === "web"
                  ? {
                      height: Dimensions.get("window").height - 100,
                    }
                  : {},
              ]}
            >
              {isLoading && (
                <View style={{ marginTop: 100 }}>
                  <ActivityIndicator size="large" />
                </View>
              )}
              {Platform.OS === "web" ? (
                <iframe
                  src={isDoctor ? links.doctorTerms : links.clinicTerms}
                  height={"100%"}
                  width={"100%"}
                  style={{
                    borderWidth: 0,
                    height: Dimensions.get("window").height - safeInsets.top,
                  }}
                />
              ) : (
                <WebView
                  source={{
                    uri: isDoctor ? links.doctorTerms : links.clinicTerms,
                  }}
                  injectedJavaScript={INJECTED_JAVASCRIPT}
                  javaScriptEnabled={true}
                  originWhitelist={["*"]}
                  scalesPageToFit={true}
                  onLoadStart={() => {
                    setIsLoading(true);
                  }}
                  onLoadEnd={() => {
                    setIsLoading(false);
                  }}
                  style={{ height: "100%" }}
                  onMessage={(event) => {
                    let innerHTML = event.nativeEvent.data;
                    if (innerHTML.includes("Now create your own form")) {
                      console.log("finally success");
                      setIsFileUploaded(true);
                    }
                  }}
                />
              )}
            </View>
          </>
        )}
        <ModalButtons
          rightLabel={"Done"}
          leftLabel={docsUploaded ? "Cancel" : "Skip for now"}
          onCancel={props.onHide}
          onConfirm={() => {
            if (Platform.OS === "web") {
              props.onConfirm && props.onConfirm();
            } else if (isFileUploaded || docsUploaded) {
              dispatch(setDocsUploaded(true));
              props.onConfirm && props.onConfirm();
            } else {
              Alert.alert(
                "Upload Required",
                "Please upload the required documents and press the submit button."
              );
            }
          }}
        />
      </View>
    </Modal>
  );
};

const styles = {
  modalStyle: {
    margin: 0,
    justifyContent: "flex-end",
  },
  modalContainer: {
    backgroundColor: "white",
    height: "60%",
    borderTopLeftRadius: 48,
    borderTopRightRadius: 48,

    marginHorizontal: 0,
    margin: 0,
    ...Styles.shadows,
  },
  calendarContainer: {
    margin: 48,
    marginTop: 12,
  },
  saveButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  resetButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.05)",
  },
};

VerificationModal.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default VerificationModal;
