import LottieView from "lottie-react-native";
import React from "react";
import { StyleSheet, View } from "react-native";

export default class App extends React.Component {
  componentDidUpdate() {
    this.animation?.play();
    // Or set a specific startFrame and endFrame with:
    // this.animation.play(30, 120);
  }

  render() {
    if (!this.props.visible) return null;
    return (
      <View style={styles.animationContainer}>
        <View
          style={{
            height: 170,
            justifyContent: "center",
            borderRadius: 25,
            zIndex: 100,
          }}
        >
          <LottieView
            ref={(animation) => {
              this.animation = animation;
            }}
            style={{
              width: 100,
              height: 100,
            }}
            source={require("../assets/lottie/loading.json")}
            // OR find more Lottie files @ https://lottiefiles.com/featured
            // Just click the one you like, place that file in the 'assets' folder to the left, and replace the above 'require' statement
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  animationContainer: {
    backgroundColor: "rgba(0,0,0,0.3)",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    height: "100%",
    width: "100%",
  },
});
