import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fields: {},
  errorFields: [],
};

export const register = createSlice({
  name: "register",
  initialState,
  reducers: {
    addFields: (state, action) => {
      const { payload } = action;
      state.fields = { ...initialState.fields, ...payload };
    },
    resetFields: (state) => {
      state.fields = initialState.fields;
      state.errorFields = initialState.errorFields;
    },
    resetEmptyFields: (state) => {
      state.errorFields = initialState.errorFields;
    },
    checkEmptyFields: (state, action) => {
      const { currentStep, isDoctor, checkPassword } = action.payload;
      let { fields } = state;
      let toValidate = [];
      switch (currentStep) {
        case 1:
          toValidate = ["email"];
          if (checkPassword) {
            toValidate = [...toValidate, "password", "confirmPassword"];
            fields.password !== fields.confirmPassword &&
              toValidate.push("passwordMatchError");
            fields.password?.length < 6 &&
              toValidate.push("passwordLengthError");
          }
          fields.email &&
            !fields.email?.match(
              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            ) &&
            toValidate.push("emailError");
          break;

        case 2:
          toValidate = ["first_name", "last_name"];
          toValidate = isDoctor
            ? [...toValidate, "nric_passport_number"]
            : [
                ...toValidate,
                "phone_number",
                "emergency_number",
                "emergency_name",
              ];
          if (isDoctor) {
            fields.nric_passport_number?.length < 6 &&
              toValidate.push("nric_passport_numberError");
          }
          if (!isDoctor) {
            if (
              fields.phone_number?.length < 7 ||
              fields.emergency_number?.length < 7
            )
              toValidate.push("phoneError");
          }
          break;
        case 3:
          toValidate = ["address", "city", "postcode", "state"];
          toValidate = isDoctor
            ? [
                ...toValidate,
                "phone_number",
                "emergency_number",
                "emergency_name",
              ]
            : [
                ...toValidate,
                "business_registration_number",
                "clinic_phone_number",
                "clinic_name",
              ];
          if (isDoctor) {
            if (
              fields.phone_number?.length < 7 ||
              fields.emergency_number?.length < 7
            )
              toValidate.push("phoneError");
          } else {
            fields.business_registration_number?.length < 4 &&
              toValidate.push("business_registration_numberError");
          }
          fields.postcode?.length < 5 && toValidate.push("postcodeError");
          break;
        case 4:
          break;
        case 5:
          toValidate = ["mmc", "apc"];
          const fieldsToCheck = ["name", "school", "year"];

          if (fields.education?.length < 1) {
            toValidate.push("educationError");
          } else {
            for (let i in fields.education) {
              fieldsToCheck.map((field) => {
                if (!fields.education[i].hasOwnProperty(field)) {
                  toValidate.push("educationError");
                }
              });
            }
          }

          fields.mmc?.length < 4 && toValidate.push("mmcError");
          fields.apc?.length < 9 && toValidate.push("apcError");
          fields.nsr?.length < 4 && toValidate.push("nsrError");

          break;
        case 6:
          if (fields.specialization?.length < 1 || !fields.specialization)
            toValidate = ["specializationError"];
          break;
        case 7:
          toValidate = ["emailCode"];
          break;
        case 8:
          toValidate = [""];
          break;
        default:
          break;
      }
      const result = toValidate.filter((val) => {
        return state.fields[val] === undefined || state.fields[val] === "";
      });
      state.errorFields = result;
    },
  },
});

export const { checkEmptyFields, addFields, resetFields, resetEmptyFields } =
  register.actions;

export default register.reducer;
