import * as React from "react";
import { View } from "react-native";

const Centered = (props) => {
  return (
    <View {...props} style={{ alignItems: "center", ...props.style }}>
      {props.children}
    </View>
  );
};

export default Centered;
