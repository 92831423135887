import { Ionicons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import React from "react";
import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import Modal from "./Modal";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Styles from "../../constants/styles";
import { Row, ModalButtons } from "../all";
import { normalize } from "../../utils/normalize";

const ApplyJobModal = (props) => {
  const safeInsets = useSafeAreaInsets();
  const { isDoctor } = useSelector((state) => state.user);

  return (
    <Modal isVisible={props.isVisible} onBackdropPress={props.onBackdropPress}>
      <View style={{ alignItems: "center", marginBottom: 12 }}>
        <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
      </View>
      <Text
        style={{
          fontWeight: "bold",
          fontSize: normalize(20),
          textAlign: "center",
        }}
      >
        {props?.isAcceptJob ? "Accept" : "Apply"} Job
      </Text>
      <Text
        style={{
          textAlign: "center",
          marginTop: 10,
          marginBottom: 24,
          marginHorizontal: 24,
        }}
      >
        Please make sure that you are able to reach the clinic 15 minutes before
        the start time.
      </Text>
      <ModalButtons onCancel={props.onCancel} onConfirm={props.onConfirm} />
    </Modal>
  );
};

const styles = {
  modalStyle: {
    margin: 0,
    justifyContent: "flex-end",
  },
  modalContainer: {
    backgroundColor: "white",
    height: "60%",
    borderTopLeftRadius: 48,
    borderTopRightRadius: 48,

    marginHorizontal: 0,
    margin: 0,
    ...Styles.shadows,
  },
  calendarContainer: {
    margin: 48,
    marginTop: 12,
  },
  saveButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  resetButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.05)",
  },
};

ApplyJobModal.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default ApplyJobModal;
