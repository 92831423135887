import {
  View,
  Text,
  StyleSheet,
  Platform,
  Linking,
  Alert,
  AppState,
} from "react-native";
import React, { useState, useEffect } from "react";
import BackButton from "../../../components/BackButton";
import * as Notifications from "expo-notifications";
import * as Location from "expo-location";
import * as IntentLauncher from "expo-intent-launcher";
import { normalize } from "../../../utils/normalize";
import * as Application from "expo-application";
import * as Device from "expo-device";

const pkg = __DEV__ ? "host.exp.exponent" : Application.applicationId;

async function getNotificationToken() {
  let token;
  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      // const { status } = await Notifications.requestPermissionsAsync();
      // finalStatus = status;
    }
    if (finalStatus !== "granted") {
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync({
      'projectId': 'fb1217de-321c-4a1d-befc-c8ebb96b032c',
    })).data;
  } else {
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return token;
}

async function getLocation() {
  let location = await Location.getCurrentPositionAsync({
    accuracy: Location.Accuracy.Lowest,
  });
  return location;
}

const Notification = () => {
  useEffect(() => {
    let sub = AppState.addEventListener("change", _handleAppStateChange);
    return () => {
     sub.remove();
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    if (nextAppState === "active") {
      // checkUpdate();
      checkAllPermissions();
    }
  };

  const [notificationToken, setNotificationToken] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");

  useEffect(() => {
    checkAllPermissions();
  }, []);

  const checkAllPermissions = () => {
    getNotificationToken().then((token) => setNotificationToken(token));
    getLocation().then((location) => {
      setCurrentLocation(location);
    });
  };

  let requestNotification = async () => {
    const { status } = await Notifications.requestPermissionsAsync();
    if (status === "denied") {
      permissionDeniedAlert("Notification");
    }
  };

  let requestLocation = async () => {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status === "denied") {
      permissionDeniedAlert("Location");
    }
  };

  let permissionDeniedAlert = (item) => {
    Alert.alert(
      `${item} Permission`,
      `The permission is denied. Please enable ${item} Permission in your settings.`,
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
        { text: "Settings", onPress: () => openAppSettings() },
      ]
    );
  };

  const openAppSettings = () => {
    if (Platform.OS === "ios") {
      Linking.openURL("app-settings:");
    } else {
      IntentLauncher.startActivityAsync(
        IntentLauncher.ACTION_APPLICATION_DETAILS_SETTINGS,
        { data: "package:" + pkg }
      );
    }
  };

  return (
    <View>
      <BackButton />
      <Text>Permissions</Text>
      <Text style={styles.label} onPress={() => requestNotification()}>
        Notification: {JSON.stringify(notificationToken)}
      </Text>
      <Text style={styles.label} onPress={() => requestLocation()}>
        Location: {JSON.stringify(currentLocation)}{" "}
      </Text>
      <Text style={styles.label}>Camera: </Text>
    </View>
  );
};

export default Notification;

const styles = StyleSheet.create({
  label: { fontSize: normalize(20), padding: 20 },
});
