import { Feather, Ionicons } from "@expo/vector-icons";
import Slider from "@react-native-community/slider";
import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Dimensions,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Categories,
  Centered,
  DateTimeSelect,
  Row,
  Input,
  BackButton,
} from "../../components/all";
import specializations from "../../constants/stub/specializations.json";
import {
  resetSearchFilter,
  setSearchFilter,
} from "../../reducers/search/searchFilter";
import { getProfile } from "../../services/profile";
import { useQuery } from "react-query";
import { getFacilities } from "../../services/auth";
import { normalize } from "../../utils/normalize";
import professions from "../../constants/lov/professions";

export default function SearchFilter(props) {
  const safeInsets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.searchFilter);
  const { isDoctor } = useSelector((state) => state.user);

  const [selectedCategory, setSelectedCategory] = useState("Doctor");
  const [selectedCategoryID, setSelectedCategoryID] = useState(1);
  const [allCategories, setAllCategories] = useState([]);

  const {
    data: facilitiesQuery,
    refetch,
    isLoading,
  } = useQuery("facilities", getFacilities);

  const {
    data: profileQuery,
    refetch: profileRefetch,
    isLoading: profileLoading,
  } = useQuery("profile", getProfile, {});

  useEffect(() => {
    let doctorFacilities = profileQuery?.data?.data?.facilities;
    if ( profileQuery && selectedSpecialization?.length === 0) {
      let preSelectFacilities = doctorFacilities?.map((val) => val.slug);
      setSelectedSpecialization(preSelectFacilities);
    }
    if (isDoctor) {
    
      let id = profileQuery?.data?.data?.expertise;
      let prof = professions;
      setSelectedCategoryID(id);
      if (id > 5) id = 5;
      let p = prof.find((item) => item.id === id);
      setSelectedCategory(p.slug.split("-")[0]);
    }
  }, [profileQuery]);

  const [facilityData, setFacilityData] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [minPrice, setMinPrice] = useState(filters.minPrice);
  const [maxPrice, setMaxPrice] = useState(filters.maxPrice);
  const [allDateTime, setAllDateTime] = useState({});
  const [selectedSpecialization, setSelectedSpecialization] = useState(
    filters.specialization || []
  );
  const [mapDistance, setMapDistance] = useState(filters.mapDistance || 40);
  const [mapDistanceText, setMapDistanceText] = useState(
    filters.mapDistance || 50
  );

  let fixedLength = (text) => {
    if (text?.length > 20) {
      return text.substring(0, 20) + "...";
    } else {
      return text;
    }
  };
  const pickedItem = (index, children) => {
    let newSelected = [...selectedSpecialization];
    if (newSelected.includes(index)) {
      newSelected = newSelected.filter((item) => {
        let childrenIndex = [];
        if (children) {
          children.forEach((child) => {
            childrenIndex.push(child.id);
          });
        }
        return ![index, ...childrenIndex].includes(item);
      });
    } else {
      newSelected.push(index);
      if (children) {
        children.map((item) => {
          newSelected.push(item.id);
        });
      }
    }
    setSelectedSpecialization(newSelected);
  };

  useEffect(() => {
    if (facilitiesQuery?.data) {
      setFacilityData(facilitiesQuery.data.data);
      let cats = [];
      facilitiesQuery.data.data.map((item) => {
        cats.push(item.category);
      });
      setAllCategories([...new Set(cats)]);
    }
  }, [facilitiesQuery]);

  useEffect(() => {
    if (filters) {
      setAllDateTime({
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        startTime: filters?.startTime,
        endTime: filters?.endTime,
      });
    }
  }, [filters]);

  let convertSearchFilters = (data) => {
    let params = {};
    data.location && (params["location"] = data.location);
    data.maxPrice && (params["maxPrice"] = data.maxPrice);
    data.minPrice && (params["minPrice"] = data.minPrice);
    data.mapDistance > 5 && (params["mapDistance"] = data.mapDistance);
    data.specialization?.length &&
      (params["specialization"] = data.specialization);
    data.startDate && (params["startDate"] = data.startDate);
    data.endDate && (params["endDate"] = data.endDate);
    data.startTime && (params["startTime"] = data.startTime);
    data.endTime && (params["endTime"] = data.endTime);

    return params;
  };

  return (
    <>
      <BackButton />

      <View style={{ backgroundColor: "white", padding: 24, paddingTop: 0 }}>
        <Row centered style={{ marginBottom: 0 }}>
          <Feather name={"filter"} size={20} color="black" />
          <Text style={styles.jobsAvailableTitle}>{`Search Filters`}</Text>
        </Row>
      </View>

      <KeyboardAwareScrollView extraHeight={200}>
        <ScrollView
          style={{
            padding: 24,
            paddingTop: 20,
            backgroundColor: "white",
          }}
        >
          <Text style={{ fontSize: normalize(10), fontWeight: "bold" }}>
            DATE & TIME RANGE
          </Text>
          <DateTimeSelect
            initialData={allDateTime}
            onChange={(dates) => {
              setAllDateTime(dates);
            }}
          />
          <View style={styles.divider} />
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Text style={styles.sectionTitle}>MAP DISTANCE (RADIUS)</Text>
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("SearchLocationScreen", {
                  focusLocation: true,
                });
              }}
            >
              <Row
                style={{
                  marginHorizontal: 18,
                  alignSelf: "flex-end",
                }}
              >
                <Text style={{ marginRight: 8, fontSize: normalize(13) }}>
                  {filters.location
                    ? fixedLength(filters.location.address_line_1)
                    : "Current Location"}
                </Text>
                <Feather name="map-pin" size={13} color="black" />
              </Row>
            </TouchableOpacity>
          </Row>

          <Row centered style={{ justifyContent: "space-around" }}>
            <Slider
              style={{
                width: Dimensions.get("window").width - 180,
                height: 40,
              }}
              value={mapDistance}
              minimumValue={5}
              maximumValue={50}
              minimumTrackTintColor="rgba(0,0,0,0.2)"
              maximumTrackTintColor="rgba(0,0,0,0.2)"
              step={1}
              onValueChange={(val) => setMapDistanceText(val)}
              onSlidingComplete={(val) => setMapDistance(val)}
            />
            <Text style={styles.distanceInput}>
              {`${mapDistanceText}`}
              <Text style={{ fontSize: normalize(12) }}> KM</Text>
            </Text>
          </Row>

          <View style={styles.divider} />

          <Text style={styles.sectionTitle}>PRICE RANGE (HOURLY RATE)</Text>
          <Row
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Input
              placeholder="Min Price"
              bordered={true}
              icon={<Text style={styles.priceIcon}>RM</Text>}
              containerStyle={{ width: "42%" }}
              inputStyle={{ width: "42%" }}
              keyboardType="numeric"
              maxLength={4}
              onChangeText={(val) => {
                const cleanNumber = val.replace(/[^0-9]/g, "");
                setMinPrice(cleanNumber);
              }}
              value={minPrice}
            />
            <Text
              style={{
                fontWeight: "bold",
                fontSize: normalize(28),
                color: "grey",
              }}
            >
              ~
            </Text>
            <Input
              placeholder="Max Price"
              icon={<Text style={styles.priceIcon}>RM</Text>}
              bordered={true}
              containerStyle={{ width: "42%" }}
              inputStyle={{ width: "42%" }}
              value={maxPrice}
              keyboardType="numeric"
              maxLength={4}
              onChangeText={(val) => {
                const cleanNumber = val.replace(/[^0-9]/g, "");
                setMaxPrice(cleanNumber);
              }}
            />
          </Row>
          <View style={styles.divider} />

          <Text style={styles.sectionTitle}>CLINICAL EXPERTISE</Text>

          <Input
            icon={<Feather name={"search"} />}
            placeholder={"Search Clinical Expertise"}
            onChangeText={(text) => setFilterText(text)}
            value={filterText}
            bordered={true}
          />

          {allCategories.map((category, idx) => {
            let type = category.split("-")[0];
            if (
              profileQuery?.data?.data?.nsr == null &&
              category.split("-")[1] === "Specialist"
            ) {
              return null;
            }

            if (type.toLowerCase() !== selectedCategory.toLowerCase()) {
              return null;
            }
            return (
              <Categories
                show={false}
                data={facilityData}
                selected={selectedSpecialization}
                onPress={(index, children) => pickedItem(index, children)}
                title={category.split("-")[1]}
                isCheckbox
                filterText={filterText}
                category={category}
                isDoctor={isDoctor}
              />
            );
          })}
          <View style={{ margin: 150 }} />
        </ScrollView>
      </KeyboardAwareScrollView>

      <View
        intensity={100}
        tint="light"
        style={{
          paddingBottom: safeInsets.bottom + 10,
          ...styles.bottomBarContainer,
        }}
      >
        <Centered style={{ width: "100%" }}>
          <Row>
            <Button
              type="secondary"
              style={{
                marginTop: 12,
                borderColor: "rgba(0,0,0,0.2)",
              }}
              label="Clear"
              onPress={() => {
                dispatch(resetSearchFilter());
                props.navigation.goBack();
              }}
            />
            <Button
              type="primary"
              style={{ marginTop: 12 }}
              label="Save"
              onPress={() => {
                setFilterText("");
                if (parseInt(maxPrice) < parseInt(minPrice)) {
                  Alert.alert(
                    "Invalid Price Range",
                    "Please enter a valid price range"
                  );
                } else {
                  let allFilters = convertSearchFilters({
                    ...allDateTime,
                    mapDistance,
                    minPrice,
                    maxPrice,
                    location: filters.location,
                    specialization: selectedSpecialization,
                  });

                  dispatch(setSearchFilter(allFilters));
                  props.navigation.goBack();
                }
              }}
            />
          </Row>
        </Centered>
      </View>
    </>
  );
}

const styles = {
  jobsAvailableTitle: {
    fontSize: 24,
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 10,
  },
  divider: {
    borderBottomWidth: 1,
    borderColor: "rgba(0,0,0,0.1)",
    marginTop: 20,
    marginBottom: 0,
  },
  backButtonContainer: {
    backgroundColor: "rgba(0,0,0,0)",
    justifyContent: "center",
    paddingRight: 0,
    borderRadius: 14,
    paddingVertical: 10,
  },
  bottomBarContainer: {
    position: "absolute",
    backgroundColor: "white",
    bottom: 0,
    left: 0,
    right: 0,
  },
  sectionTitle: {
    fontSize: normalize(10),
    fontWeight: "bold",
    marginTop: 30,
    marginBottom: 30,
  },
  priceIcon: {
    fontWeight: "bold",
    fontSize: normalize(12),
    color: "grey",
  },
  distanceInput: {
    borderWidth: 1,
    paddingHorizontal: 24,
    fontSize: normalize(14),
    paddingVertical: 8,
    borderRadius: 10,
    borderColor: "rgba(0,0,0,0.1)",
    fontWeight: "bold",
  },
};
