import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { Dimensions, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Centered, Input, StepHeader } from "../../../components/all";
import { resetEmptyFields } from "../../../reducers/auth/register";
import Styles from "../../../constants/styles";
export default function Step2({
  setFocusedInput,
  focusedInput,
  setField,
  allFields,
  navigation,
  isWeb,
  currentStep,
}) {
  const dispatch = useDispatch();
  const { errorFields, fields } = useSelector((state) => state.register);
  const setErrorFields = () => {
    dispatch(resetEmptyFields());
  };

  const input = {
    emailCode: "emailCode",
  };
  if (isWeb && currentStep !== 5) return <></>;

  return (
    <View
      style={{
        ...Styles.webSmallContainer,
      }}
    >
      <StepHeader
        label={`Email \nVerification`}
        icon={<FontAwesome name="envelope-o" size={24} color="black" />}
        presubtitle={`We have sent an email to `}
        subtitle={fields.email}
      />
      <Centered style={{ width: "100%" }}>
        <Input
          keyboardType="numeric"
          onChangeText={(val) => {
            setErrorFields();
            setField(input.emailCode, val);
            if (val?.length === 6) {
              navigation.navigate("CompleteRegistrationScreen");
            }
          }}
          value={allFields[input.emailCode]}
          icon={<FontAwesome name="envelope" size={14} color="grey" />}
          placeholder="Enter your 6-digit code"
          containerStyle={{ width: "90%", marginTop: 24 }}
          maxLength={6}
          onBlur={() => setFocusedInput(null)}
          onFocus={() => {
            setErrorFields();
            setFocusedInput(input.emailCode);
          }}
          error={errorFields.includes(input.emailCode)}
          focused={focusedInput === input.emailCode}
        />
      </Centered>
    </View>
  );
}
