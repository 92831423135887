import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {},
};

export const searchFilter = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    setSearchFilter: (state, action) => {
      state.filters = action.payload;
    },
    resetSearchFilter: (state, action) => {
      state.filters = initialState.filters;
    },
  },
});

export const { setSearchFilter, resetSearchFilter } = searchFilter.actions;

export default searchFilter.reducer;
