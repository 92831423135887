import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Animated,
  Dimensions,
  Keyboard,
  Platform,
  SafeAreaView,
  ScrollView,
  View,
  Text,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Centered,
  Loading,
  Row,
  Steps,
  Modal,
  ModalButtons,
} from "../../../components/all";
import {
  addFields,
  checkEmptyFields,
  resetEmptyFields,
  resetFields,
} from "../../../reducers/auth/register";
import { validateFields, requestOTP } from "../../../services/auth";
import { getCoord } from "../../../services/shared";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import StepExpertise from "./StepExpertise";
import { Ionicons, FontAwesome5, Feather } from "@expo/vector-icons";
import { normalize } from "../../../utils/normalize";
import Styles from "../../../constants/styles";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function Register(props) {
  const screenWidth = Dimensions.get("window").width;
  const isWeb = Platform.OS === "web";

  const dispatch = useDispatch();
  const { isDoctor } = useSelector((state) => state.user);
  const { fields, errorFields } = useSelector((state) => state.register);

  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [forceDisable, setForceDisable] = React.useState(false);
  const [isNextPage, setIsNextPage] = useState(false);
  const [step, setStep] = useState(1);
  const [focusedInput, setFocusedInput] = useState(null);
  const [isCheckFields, setIsCheckFields] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [initialRegion, setInitialRegion] = useState(false);
  const [returnModalVisible, setReturnModalVisible] = useState(false);
  const [profession, setProfession] = useState("");

  const [allFields, setAllFields] = useState({
    education: [""],
  });

  const scrollViewRef = useRef(null);
  let scrollX = 0;

  const setField = (field, value) =>
    setAllFields({ ...allFields, [field]: value });

  const { data: locationQuery, refetch: locationRefetch } = useQuery(
    "clinicLocation",
    () =>
      getCoord(
        `${fields.address}, ${fields.city}, ${fields.postcode} ${fields.state}`
      ),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (locationQuery?.data) {
      setVerifying(false);

      props.navigation.navigate("ClinicLocatorScreen", {
        coords: locationQuery?.data.data,
        address: `${fields.address}, ${fields.city}, ${fields.postcode} ${fields.state}`,
      });
    }
  }, [locationQuery]);

  const sendOTP = useMutation(requestOTP, {
    onError: (error) => {
      setVerifying(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      setVerifying(false);
      dispatch(
        addFields({
          ...fields,
          nonce: res.data.data.nonce,
          email: fields.email,
        })
      );
      props.navigation.navigate("ValidateOTPScreen");
    },
  });

  const registerCheck = useMutation(validateFields, {
    onError: (error) => {
      setVerifying(false);
      if (Platform.OS === "web") {
        const response = confirm("Oops! " + error.response.data.message);
        if (response) {
          if (error.response.status === 302) {
            sendOTP.mutate({
              email: fields.email,
            });
          }
        }
      } else {
        Alert.alert(
          "Oops!",
          checkSkip(step)
            ? "The APC or IC Number is already being used by another account."
            : error.response.data.message,
          [
            {
              text: "Cancel",
              style: "cancel",
            },
            {
              text: "OK",
              onPress: () => {
                if (error.response.status === 302) {
                  sendOTP.mutate({
                    email: fields.email,
                  });
                }
              },
            },
          ]
        );
      }
    },
    onSuccess: (data) => {
      if (step === 1 && !showPasswordFields) {
        setShowPasswordFields(true);
        setVerifying(false);
      } else if (checkSkip(step)) {
        setVerifying(false);
        props.navigation.navigate("CategoryScreen");
      } else {
        goNextPage(isNextPage);
      }
    },
  });

  const goNextPage = (isNextPage) => {
    isNextPage ? setStep(step + 1) : setStep(step - 1);
    let currentWidth = screenWidth * (step - 1);
    let direction = isNextPage
      ? screenWidth * step
      : currentWidth - screenWidth;
    if (!scrollX) scrollX = currentWidth;
    const animatedValue = new Animated.Value(scrollX);
    if (scrollViewRef?.current !== null) {
      setTimeout(() => {
        setVerifying(false);
        const id = animatedValue.addListener(({ value }) => {
          scrollViewRef?.current?.scrollTo({
            x: value,
            animated: false,
          });
        });
        Animated.spring(animatedValue, {
          toValue: direction,
          useNativeDriver: false,
          duration: 500,
        }).start(() => {
          animatedValue.removeListener(id); /* finished callback */
        });
      }, 400);
    }
  };

  useEffect(() => {
    if (isCheckFields) {
      setIsCheckFields(false);
      if (errorFields.length === 0) {
        if (step === 1) {
          registerCheck.mutate({
            email: fields.email,
            password: fields.password,
            scope: isDoctor ? "doctor" : "clinic_owner",
          });
          setVerifying(true);
        } else if (!isDoctor && step === 3) {
          setVerifying(true);
          locationRefetch();
        } else if (step === 2) {
          getProfession();
          registerCheck.mutate(
            isDoctor
              ? {
                  scope: "doctor",
                  nric_passport_number: fields.nric_passport_number,
                }
              : {
                  scope: "clinic_owner",
                  business_registration_number:
                    fields.business_registration_number,
                }
          );
          setVerifying(true);
        } else if (checkSkip(step)) {
          registerCheck.mutate({
            apc: fields.apc,
            mmc: fields.mmc,
            nsr: fields.nsr,
            scope: "doctor",
          });
          setVerifying(true);
        }  else {
          goNextPage(isNextPage);
        }
      }
    }
  }, [fields]);

  const checkSkip = (step) => {
    if(profession.types) {
      if(step === 5) return true;
    } else if (step === 4) return true;
  }

  const setPage = (bool) => {
    dispatch(resetEmptyFields());
    Keyboard.dismiss();
    setFocusedInput(null);
    dispatch(addFields(allFields));
    setIsNextPage(bool);
    if (bool) {
      dispatch(
        checkEmptyFields({
          checkPassword: showPasswordFields,
          currentStep: step,
          isDoctor: isDoctor,
        })
      );
      setIsCheckFields(true);
    } else {
      goNextPage(bool);
    }
  };
  const getProfession = async () => {
    try {

      const value = await AsyncStorage.getItem("profession");

      if (value !== null) {
        let v = JSON.parse(value);
        setProfession(v);
        setAllFields({
          ...allFields,
          expertise: v.id
        })
        return value;
      }
    } catch (e) {
    }
  };
  let scrollViewWrapper = (child) => {
    if (isWeb) {
      return (
        <View
          style={{
            ...Styles.webContainer,
            marginTop: 200,
          }}
        >
          {child}
        </View>
      );
    } else {
      return (
        <KeyboardAwareScrollView>
          <ScrollView
            ref={scrollViewRef}
            horizontal
            contentContainerStyle={{ marginTop: "40%", flexGrow: 1 }}
            scrollEnabled={false}
            showsHorizontalScrollIndicator={false}
            onScroll={(event) => {
              scrollX = event.nativeEvent.contentOffset.x;
            }}
            enableOnAndroid
            scrollEventThrottle={400}
          >
            {child}
          </ScrollView>
        </KeyboardAwareScrollView>
      );
    }
  };
  return (
    <>
      <SafeAreaView style={styles.registerContainer}>
        {scrollViewWrapper(
          <>
            {/* {true ? (
              <StepExpertise
                setFocusedInput={setFocusedInput}
                setField={setField}
              />
            ) : null} */}
            <Step1
              setFocusedInput={setFocusedInput}
              focusedInput={focusedInput}
              setField={setField}
              allFields={allFields}
              showPasswordFields={showPasswordFields}
              isWeb={isWeb}
              currentStep={step}
            />
            <Step3
              setFocusedInput={setFocusedInput}
              focusedInput={focusedInput}
              setField={setField}
              allFields={allFields}
              isDoctor={isDoctor}
              isWeb={isWeb}
              currentStep={step}
            />

            <Step4
              setFocusedInput={setFocusedInput}
              focusedInput={focusedInput}
              setField={setField}
              allFields={allFields}
              isDoctor={isDoctor}
              isWeb={isWeb}
              currentStep={step}
            />
            {isDoctor && profession?.types ? (
              <StepExpertise
                setFocusedInput={setFocusedInput}
                setField={setField}
              />
            ) : null}

            <Step5
              setFocusedInput={setFocusedInput}
              focusedInput={focusedInput}
              setAllFields={setAllFields}
              allFields={allFields}
              setField={setField}
              isWeb={isWeb}
              currentStep={step}
            />
            <Step2
              setFocusedInput={setFocusedInput}
              focusedInput={focusedInput}
              setField={setField}
              allFields={allFields}
              navigation={props.navigation}
              isWeb={isWeb}
              currentStep={step}
            />
          </>
        )}
        {step === 7 ? null : (
          <Centered>
            <Row style={{ marginBottom: 24 }}>
              <Button
                label="Back"
                type={"secondary"}
                disabled={forceDisable}
                onPress={() => {
                  setForceDisable(true);
                  setTimeout(() => {
                    setForceDisable(false);
                  }, 1000);
                  if (step > 2) {
                    setPage(false);
                  } else {
                    setReturnModalVisible(true);
                  }
                }}
              />
              <Button
                label="Next"
                type={"primary"}
                disabled={forceDisable}
                onPress={() => {
                  setForceDisable(true);
                  setTimeout(() => {
                    setForceDisable(false);
                  }, 1000);

                  setPage(true);
                }}
              />
            </Row>
            {step < 2 ? (
              <View style={{ margin: 18 }} />
            ) : (
              <Steps totalPages={isDoctor ? 5 : 4} currentPage={step - 1} />
            )}
          </Centered>
        )}
      </SafeAreaView>
      <Modal
        isVisible={returnModalVisible}
        onBackdropPress={() => setReturnModalVisible(false)}
      >
        <View style={{ alignItems: "center", marginBottom: 12 }}>
          <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
        </View>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: normalize(16),
            textAlign: "center",
          }}
        >
          Cancel Registration
        </Text>
        <Text
          style={{
            textAlign: "center",
            marginTop: 10,
            marginBottom: 24,
            marginHorizontal: 24,
          }}
        >
          This action will reset all the fields and bring you back to the login
          page.
        </Text>
        <ModalButtons
          onCancel={() => setReturnModalVisible(false)}
          onConfirm={() => {
            setReturnModalVisible(false);
            setTimeout(() => {
              dispatch(resetFields());
              props.navigation.goBack();
            }, 200);
          }}
        />
      </Modal>
      <Loading visible={verifying && !isWeb} />
    </>
  );
}

const styles = {
  registerContainer: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
  },
};
