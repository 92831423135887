import * as Haptics from "expo-haptics";
import PropTypes from "prop-types";
import * as React from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import Fonts from "../constants/fonts";
import Card from "./Card";
import Row from "./Row";
import Status from "./Status";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";
import moment from "moment";
import { normalize } from "../utils/normalize";
import { useDispatch, useSelector } from "react-redux";
import { addNavParam } from "../reducers/nav/params";
import { getClinics } from "../services/profile";
import { useQuery } from "react-query";

const JobCard = (props) => {
  const dispatch = useDispatch();
  const { isDoctor } = useSelector((state) => state.user);
  const {
    data: clinicsQuery,
    refetch: clinicsRefetch,
    isLoading: clinicLoading,
  } = useQuery("clinics", getClinics, {
    enabled: !isDoctor,
  });
  const [isTruncatedText, setIsTruncatedText] = React.useState(false);

  const data = props.data;
  console.log(data);

  let cardTitle = "";
  if (props.doctorCard) {
    cardTitle = data?.nsr ? "Specialist" : "General Practitioner";
  } else {
    cardTitle = isDoctor
      ? data?.clinic?.city
      : clinicsQuery?.data?.data[0]?.city;
  }

  const desc = props.doctorCard
    ? data?.about
    : isDoctor
    ? data?.clinic?.name
    : clinicsQuery?.data?.data[0]?.name;
  const isScanned = data?.is_scanned || props?.currentJobData?.is_scanned;
  const checkStatus = (props, data) => {
    let status = 0;
    if (props?.isJobCancelled && data?.status === -1) {
      status = 0;
    } else if (data?.status === 3 && isScanned === true) {
      status = 6;
    } else {
      status = data?.status;
    }
    return status;
  };
  const diffHours = Math.abs(
    moment(data?.start_timestamp).diff(moment(data?.end_timestamp), "hours") ||
      1
  );

  const navigatePage = () => {
    const routeName = props.isDoctor
      ? "DetailsScreen"
      : props.doctorCard
      ? "DoctorProfileScreen"
      : "JobDescriptionScreen";
    const routeData = {
      data: data,
      fromJobTab: props?.fromJobTab,
      fromDoctorCard: props?.doctorCard,
      isScanned,
    };
    dispatch(
      addNavParam({
        routeName,
        data: routeData,
      })
    );
    props.navigation?.navigate(routeName, routeData);
  };

  if (props.empty)
    return (
      <Card>
        <View style={{ marginVertical: 12 }}>
          <ContentLoader
            speed={2}
            width={476}
            height={55}
            viewBox="0 0 476 55"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <Rect x="90" y="8" rx="3" ry="3" width="227" height="6" />
            <Rect x="91" y="23" rx="3" ry="3" width="134" height="6" />
            <Circle cx="38" cy="27" r="28" />
            <Rect x="91" y="37" rx="3" ry="3" width="134" height="6" />
          </ContentLoader>
        </View>
      </Card>
    );

  return (
    <Card
      jobCard={!props.doctorCard}
      doctorCard={props.doctorCard}
      data={data}
      image={props.data?.profile_image}
      onPress={() => {
        if (props?.from === "transaction") return;

        props?.onPressCard && props.onPressCard();
        navigatePage();
      }}
    >
      <View style={styles.jobContainer}>
        <View style={{ flex: 1 }}>
          <Text style={styles.title}>{cardTitle}</Text>
          <View style={styles.clinicContainer}>
            <Row centered>
              <Text
                numberOfLines={3}
                onTextLayout={(event) => {
                  const { lines } = event.nativeEvent;
                  setIsTruncatedText(lines?.length > 2);
                }}
                style={styles.clinic_name}
              >
                {data?.title}
              </Text>
            </Row>
            <Text
              numberOfLines={2}
              style={[
                Fonts.subText,
                {
                  fontFamily: "NotoSans-Regular",
                },
              ]}
            >
              {desc}
            </Text>
          </View>
          {props.doctorCard ? (
            <View style={{ margin: 4 }} />
          ) : (
            <Row centered>
              <Text style={[Fonts.bold, styles.salary]}>RM{data?.price}</Text>
              <View
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 4,
                  marginTop: 6,
                  marginLeft: 4,
                  borderWidth: 1,
                  borderColor: !data?.is_cash ? "#FC6767" : "grey",
                  borderRadius: 10,
                  alignSelf: "flex-start",
                }}
              >
                <Text
                  style={{
                    fontSize: normalize(11),
                    color: !data?.is_cash ? "#FC6767" : "grey",
                    fontWeight: "bold",
                  }}
                >
                  {data?.is_cash ? "CASH" : "S-PAY"}
                </Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 4,
                  marginTop: 6,
                  marginLeft: 4,
                  borderWidth: 1,
                  borderColor: !data?.is_cash ? "#FC6767" : "grey",
                  borderRadius: 10,
                  alignSelf: "flex-start",
                }}
              >
                <Text
                  style={{
                    fontSize: normalize(11),
                    color: !data?.is_cash ? "#FC6767" : "grey",
                    fontWeight: "bold",
                  }}
                >
                  {Math.abs(
                    moment(data?.start_timestamp).diff(
                      moment(data?.end_timestamp),
                      "hours"
                    ) || 1
                  )}
                  hr
                </Text>
              </View>
            </Row>
          )}
        </View>
        {props?.from === "transaction" || props?.clinicJobList ? null : (
          <View style={styles.rightContainer}>
            <TouchableOpacity
              onPress={() => {
                if (Platform.OS === "ios") {
                  Haptics.selectionAsync();
                }
                if (props.fromHome || props?.fromSearch) {
                  navigatePage();
                } else {
                  typeof data?.status === "number"
                    ? props.doctorCard
                      ? props.doctorChangeStatus(data.id, data.status)
                      : props.changeStatus(data.id, data.status)
                    : props?.approveJob && props.approveJob(data.id);
                }
              }}
            >
              <View
                style={{
                  minWidth: 85,
                  paddingHorizontal: 14,
                }}
              >
                <Status
                  doctorCount={data?.job_applications?.length}
                  status={checkStatus(props, data)}
                  doctorCard={props.doctorCard}
                  forceIsDoctor={props.forceIsDoctor}
                />
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </Card>
  );
};

const styles = {
  jobContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    paddingTop: 6,
  },
  title: {
    margin: 10,
    marginTop: 4,
    marginBottom: 0,
    ...Fonts.bold,
    color: "rgba(0,0,0,0.4)",
    fontSize: normalize(10),
    fontFamily: "NotoSans-Bold",
  },
  clinicContainer: {
    margin: 10,
    marginVertical: 0,
  },
  rightContainer: {
    justifyContent: "center",
    alignItems: "center",
    borderLeftWidth: 1,
    borderColor: "rgba(0,0,0,0.1)",
  },
  ringgit: {
    fontSize: normalize(9),
  },
  salary: {
    marginLeft: 10,
    marginTop: 6,
    borderWidth: 1,
    paddingHorizontal: 8,
    paddingVertical: 3,
    borderRadius: 10,
    borderColor: "#FC6767",
    alignSelf: "flex-start",
    color: "#FC6767",
    fontWeight: "bold",
    fontSize: normalize(12),
  },
  clinic_name: {
    fontFamily: "NotoSans-Bold",
    fontSize: normalize(14),
    color: "rgba(0,0,0,0.8)",
    marginBottom: 2,
    marginRight: 4,
    lineHeight: 16,
    marginVertical: 4,
  },
  clinicID: {
    marginBottom: 2,
    fontSize: normalize(12),
    fontWeight: "bold",
    color: "rgba(0,0,0,0.15)",
  },
};

JobCard.PropTypes = {
  data: PropTypes.object,
};

export default JobCard;
