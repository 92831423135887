import AsyncStorage from "@react-native-async-storage/async-storage";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import register from "./reducers/auth/register";
import user from "./reducers/auth/user";
import favoriteJobs from "./reducers/jobs/favoriteJobs";
import recentSearch from "./reducers/search/recentSearch";
import searchFilter from "./reducers/search/searchFilter";
import profile from "./reducers/profile/profile";
import params from "./reducers/nav/params";

const rootReducer = combineReducers({
  user: user,
  register: register,
  profile: profile,
  recentSearch: recentSearch,
  searchFilter: searchFilter,
  favoriteJobs: favoriteJobs,
  params: params,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage: AsyncStorage,
  whitelist: ["user", "favoriteJobs", "recentSearch", "params"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    // {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // },
  }),
});

export const persistor = persistStore(store);
export default store;
