import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  routeName: "",
  data: {},
};

export const params = createSlice({
  name: "params",
  initialState,
  reducers: {
    addNavParam: (state, action) => {
      state.routeName = action.payload.routeName;
      state.data = action.payload.data;
    },
    removeNavParam: (state, action) => {
      state.routeName = "";
      state.data = {};
    },
  },
});

export const { addNavParam, removeNavParam } = params.actions;

export default params.reducer;
