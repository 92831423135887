import { Feather, FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState, useEffect } from "react";
import {
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Keyboard,
  Image,
  Alert,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import {
  BottomModal,
  Header,
  Row,
  Input,
  Centered,
  Button,
  BackButton,
  GradientHeader,
} from "../../../components/all";
import homeStub from "../../../constants/stub/home.json";
import { normalize } from "../../../utils/normalize";
import { useMutation } from "react-query";
import { submitChangePassword } from "../../../services/shared";
import Styles from "../../../constants/styles";

export default function Settings(props) {
  const safeInsets = useSafeAreaInsets();
  const { isDoctor } = useSelector((state) => state.user);

  const [modalVisible, setModalVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = useMutation(submitChangePassword, {
    onError: (error) => alert("Oops! " + error.response.data.message),
    onSuccess: (data) => {
      setCurrentPassword("");
      setNewPassword("");
      setRepeatNewPassword("");
      Alert.alert(
        "Password Changed",
        "You have successfully changed your password."
      );
      props.navigation.goBack();
    },
  });
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <>
        <View
          style={{
            position: "absolute",
            zIndex: 10,
            ...Styles.webContainer,
          }}
        >
          <BackButton color="white" />
        </View>
        <GradientHeader />
        <ScrollView
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            paddingTop: safeInsets.top + 70,
            ...Styles.webContainer,
          }}
        >
          <>
            <View style={{ ...styles.headerContainer }}>
              <MaterialIcons name="lock-open" size={24} />

              <View style={styles.header}>
                <Text style={styles.headerName}>{`Change Password`}</Text>
              </View>
            </View>
            <Image
              source={require("../../../assets/images/wave.png")}
              style={{ width: "100%", zIndex: -10, marginTop: -98 }}
            />
          </>
          <View style={{ padding: 20, backgroundColor: "white" }} />
          <View
            style={{
              backgroundColor: "white",
              paddingHorizontal: 12,
              height: 900,
            }}
          >
            <Centered style={{ width: "100%" }}>
              <Input
                onChangeText={(val) => setCurrentPassword(val)}
                value={currentPassword}
                icon={<FontAwesome name="lock" size={14} color="grey" />}
                placeholder="Current Password"
                containerStyle={{ width: "90%", marginTop: 24 }}
                secureTextEntry={!showPassword}
                backIcon={
                  <Feather
                    name={showPassword ? "eye-off" : "eye"}
                    size={18}
                    color="grey"
                  />
                }
                onPressBackIcon={() => {
                  setShowPassword(!showPassword);
                }}
              />
              <Input
                onChangeText={(val) => setNewPassword(val)}
                value={newPassword}
                icon={<FontAwesome name="lock" size={14} color="grey" />}
                placeholder="New Password"
                containerStyle={{ width: "90%", marginTop: 24 }}
                secureTextEntry={!showPassword}
                backIcon={
                  <Feather
                    name={showPassword ? "eye-off" : "eye"}
                    size={18}
                    color="grey"
                  />
                }
                onPressBackIcon={() => {
                  setShowPassword(!showPassword);
                }}
              />
              <Input
                onChangeText={(val) => setRepeatNewPassword(val)}
                value={repeatNewPassword}
                icon={<FontAwesome name="lock" size={14} color="grey" />}
                placeholder="Repeat New Password"
                containerStyle={{ width: "90%", marginTop: 24 }}
                secureTextEntry={!showPassword}
                backIcon={
                  <Feather
                    name={showPassword ? "eye-off" : "eye"}
                    size={18}
                    color="grey"
                  />
                }
                onPressBackIcon={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </Centered>
            <Centered style={{ width: "100%" }}>
              <Row>
                <Button
                  label={`Update`}
                  onPress={() => {
                    if (currentPassword && newPassword && repeatNewPassword) {
                      if (newPassword !== repeatNewPassword) {
                        Alert.alert(
                          "Oops!",
                          "Password do not match! Please try again."
                        );
                      } else {
                        onSubmit.mutate({
                          old_password: currentPassword,
                          new_password: newPassword,
                        });
                      }
                    } else {
                      alert("Oops! " + "Please fill in all the fields.");
                    }
                  }}
                  type={"primary"}
                  style={{
                    marginTop: 80,
                    alignItems: "center",
                  }}
                  textStyle={{
                    textAlign: "center",
                  }}
                />
              </Row>
            </Centered>
            <View style={{ padding: 80 }} />
          </View>
        </ScrollView>
        <BottomModal
          visible={modalVisible}
          hide={() => setModalVisible(false)}
        />
      </>
    </TouchableWithoutFeedback>
  );
}

const styles = {
  title: {
    margin: 18,
  },
  jobsAvailableTitle: {
    fontSize: 24,
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 18,
    marginBottom: 10,
  },
  filterText: {
    fontWeight: "bold",
  },
  divider: {
    alignSelf: "center",
    width: "90%",
    height: 0.5,
    backgroundColor: "#eee",
    marginBottom: 24,
  },
  sortContainer: {
    marginTop: -18,
    alignItems: "flex-end",
    marginRight: 18,
  },
  sortByText: {
    fontSize: 12,
    marginBottom: 2,
  },
  sortText: {
    fontWeight: "bold",
  },
  line: {
    marginTop: 20,
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  lineTitle: {
    padding: 10,
    backgroundColor: "white",
    marginTop: -18,
  },
  headerContainer: {
    margin: 30,
    marginTop: 40,
    marginBottom: 4,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerName: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginTop: 10,
  },
};
