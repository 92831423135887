import React, { useCallback, useEffect, useRef, useState } from "react";
import { Text, View, Dimensions, Platform } from "react-native";
import { WebView } from "react-native-webview";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentSetup } from "../../reducers/auth/user";
import { BackButton } from "../../components/all";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import JobsList from "./JobList";
import { useQuery } from "react-query";
import { getAllJobs } from "../../services/job";
import { normalize } from "../../utils/normalize";

const WebViewScreen = (props) => {
  const dispatch = useDispatch();
  const safeInsets = useSafeAreaInsets();
  const gotoURL = props.route.params?.gotoURL;
  const hideBackButton = props.route.params?.hideBackButton;
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [refreshing, setRefreshing] = React.useState(false);

  const { isDoctor } = useSelector((state) => state.user);
  const {
    data: jobsQuery,
    refetch,
    isLoading,
    isFetched: jobsFetched,
  } = useQuery(["jobs", { query: "" }], getAllJobs, {});
  let jobs = jobsQuery?.data?.data;
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    refetch();
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);
  return (
    <View style={{ flex: 1, marginTop: 100 }}>
      <Text style={styles.jobsAvailableTitle}>{`Job \nApplications`}</Text>
      <JobsList
        fromJobTab
        isDoctor={isDoctor}
        navigation={props.navigation}
        jobs={jobs}
        jobsFetched={jobsFetched}
        selectedFilter={selectedFilter}
        onRefresh={onRefresh}
        refreshing={refreshing}
      />
    </View>
  );
};
const styles = {
  container: {
    flex: 1,
    width: "100%",
    alignSelf: "center",
  },
  jobsAvailableTitle: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 18,
    marginBottom: 10,
  },
};
export default WebViewScreen;
