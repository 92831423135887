import { FontAwesome, Feather } from "@expo/vector-icons";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Dimensions,
  Text,
  View,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
  TouchableOpacity,
} from "react-native";
import Fade from "react-native-fade";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Centered,
  Input,
  StepHeader,
  Button,
  Loading,
  BackButton,
} from "../../components/all";
import { resetEmptyFields, addFields } from "../../reducers/auth/register";
import { submitResetPassword } from "../../services/shared";
import { normalize } from "../../utils/normalize";
import Styles from "../../constants/styles";
import { requestOTP, validateOTP } from "../../services/auth";

export default function Step5({ navigation }) {
  const dispatch = useDispatch();
  const { fields, errorFields } = useSelector((state) => state.register);
  const setErrorFields = () => {
    dispatch(resetEmptyFields());
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [focusedInput, setFocusedInput] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [verifying, setVerifying] = useState(false);
  const [otpInput, setOTPInput] = useState("");
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [nonce, setNonce] = useState("");
  const input = {
    email: "email",
    otp: "otp",
    password: "password",
    confirmPassword: "confirmPassword",
  };
  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);
  const changePassword = useMutation(submitResetPassword, {
    onError: (error) => {
      setVerifying(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (data) => {
      setVerifying(false);
      // setEmail("");
      Alert.alert(
        "Password Changed",
        "You have successfully changed your password."
      );
      navigation.goBack();
    },
  });

  const sendEmail = useMutation(requestOTP, {
    onError: (error) => {
      setVerifying(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      setVerifying(false);
      setNonce(res.data?.data?.nonce);
      setTimeLeft(60);
      Alert.alert(
        "Email Sent",
        "Please check your email to proceed with your password reset."
      );
      // props.navigation.goBack();
    },
  });

  const onSubmit = () => {
    if (nonce) {
      changePassword.mutate({
        otp: otpInput,
        nonce,
        email,
        password,
      });
    } else if (isChangePassword) {
      sendEmail.mutate({
        email,
      });
    } else if (email) {
      setIsChangePassword(true);
    } else {
      alert("Oops! " + "Please enter a valid email address.");
    }
  };
  const resendOTP = () => {
    setVerifying(true);
    sendEmail.mutate({
      email,
    });
  };
  return (
    <>
      <View
        style={{
          position: "absolute",
          zIndex: 10,
          ...Styles.webSmallContainer,
        }}
      >
        <BackButton />
      </View>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View
          style={{
            ...Styles.webSmallContainer,
            marginTop: 200,
            flex: 1,
          }}
        >
          <StepHeader
            label={`Forgot \nPassword`}
            icon={<FontAwesome name="envelope-o" size={24} color="black" />}
            presubtitle={`Please enter your email address to`}
            subtitle={" reset your password."}
          />
          <Centered style={{ width: "100%" }}>
            <Input
              onChangeText={(val) => {
                setErrorFields();
                setEmail(val);
              }}
              value={email}
              autoCapitalize="none"
              icon={<FontAwesome name="envelope" size={14} color="grey" />}
              placeholder="Email Address"
              containerStyle={{ width: "90%", marginTop: 24 }}
              disabled={isChangePassword}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields();
                console.log("focused");
                setFocusedInput(input.email);
              }}
              error={errorFields.includes(input.email)}
              focused={focusedInput === input.email}
            />

            {nonce ? (
              <Input
                onChangeText={(val) => {
                  setErrorFields();
                  setOTPInput(val);
                }}
                value={otpInput}
                icon={<FontAwesome name="envelope" size={14} color="grey" />}
                placeholder="OTP"
                containerStyle={{ width: "90%", marginTop: 24 }}
                maxLength={6}
                onBlur={() => setFocusedInput(null)}
                onFocus={() => {
                  setErrorFields();
                  console.log("focused");
                  setFocusedInput(input.otp);
                }}
                error={errorFields.includes(input.otp)}
                focused={focusedInput === input.otp}
              />
            ) : (
              <>
                {isChangePassword ? (
                  <>
                    <Input
                      onChangeText={(val) => {
                        setErrorFields();
                        setPassword(val);
                      }}
                      value={password}
                      secureTextEntry={!showPassword}
                      icon={<FontAwesome name="lock" size={14} color="grey" />}
                      placeholder="New Password"
                      containerStyle={{ width: "90%", marginTop: 24 }}
                      disabled={nonce}
                      onBlur={() => setFocusedInput(null)}
                      onFocus={() => {
                        setErrorFields();
                        console.log("focused");
                        setFocusedInput(input.password);
                      }}
                      error={errorFields.includes(input.password)}
                      focused={focusedInput === input.password}
                      backIcon={
                        <Feather
                          name={showPassword ? "eye-off" : "eye"}
                          size={18}
                          color="grey"
                        />
                      }
                      onPressBackIcon={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                    <Input
                      onChangeText={(val) => {
                        setErrorFields();
                        setConfirmPassword(val);
                      }}
                      value={confirmPassword}
                      secureTextEntry={!showPassword}
                      icon={
                        <FontAwesome name="unlock-alt" size={14} color="grey" />
                      }
                      placeholder="Confirm New Password"
                      containerStyle={{ width: "90%", marginTop: 24 }}
                      disabled={nonce}
                      onBlur={() => setFocusedInput(null)}
                      onFocus={() => {
                        setErrorFields();
                        console.log("focused");
                        setFocusedInput(input.confirmPassword);
                      }}
                      error={errorFields.includes(input.confirmPassword)}
                      focused={focusedInput === input.confirmPassword}
                      backIcon={
                        <Feather
                          name={showPassword ? "eye-off" : "eye"}
                          size={18}
                          color="grey"
                        />
                      }
                      onPressBackIcon={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  </>
                ) : null}
              </>
            )}
          </Centered>
          {nonce ? (
            <Centered style={{ width: "100%", marginTop: 24 }}>
              <TouchableOpacity onPress={resendOTP} disabled={timeLeft > 0}>
                <Text style={{ color: "grey", fontSize: normalize(12) }}>
                  {timeLeft === 0
                    ? "Resend OTP"
                    : "Resend OTP in " + timeLeft + " seconds"}
                </Text>
              </TouchableOpacity>
            </Centered>
          ) : null}
          <Centered style={{ width: "100%" }}>
            <Button
              label={`Submit`}
              onPress={onSubmit}
              type={"primary"}
              style={{
                marginTop: 80,
                alignItems: "center",
              }}
              textStyle={{
                textAlign: "center",
              }}
            />
          </Centered>
          <View style={{ margin: 10, marginLeft: 24 }}>
            <Fade visible={errorFields.includes(input.email)} duration={300}>
              <Text style={{ fontSize: normalize(10), color: "red" }}>
                Please fill in the required field
              </Text>
            </Fade>
          </View>
        </View>
      </TouchableWithoutFeedback>

      {Platform.OS === "web" ? null : <Loading visible={verifying} />}
    </>
  );
}
