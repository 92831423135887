import api from "./api";

export const getAllJobs = ({ queryKey }) => {
  const [_key, { query }] = queryKey;

  return api.get(`/jobs${query ? `?${query}` : ""}`);
};

export const postJob = (data) => {
  return api.post("/jobs", data);
};

export const getJob = (data) => {
  return api.get("/jobs/" + data);
};

export const getJobFacilities = (data) => {
  return api.get("/jobs/" + data + "/facilities");
};

export const updateJob = (data) => {
  return api.put("/jobs/" + data.uuid, data);
};

export const deleteJob = (data) => {
  return api.delete("/jobs/" + data);
};

export const applyJob = (data) => {
  return api.post("/jobs/" + data.uuid + "/apply");
};

export const getJobApplications = (data) => {
  return api.get("/jobs/" + data + "/applications");
};

export const getJobQR = (data) => {
  return api.get("/jobs/" + data + "/qr");
};

export const putJobQR = (data) => {
  return api.put("/jobs/qr/" + data.id + "/scan");
};

export const approveJob = (data) => {
  return api.post("/applications/" + data.uuid + "/approve");
};

export const reapproveJob = (data) => {
  return api.post("/applications/" + data.uuid + "/reapprove");
};

export const rejectJob = (data) => {
  return api.post("/applications/" + data.uuid + "/reject");
};

export const getApplications = () => {
  return api.get("/applications");
};

export const getApplication = ({ queryKey }) => {
  const [_key, { query }] = queryKey;
  if (query) {
    return api.get(`/applications/${query}`);
  }
};

export const getBanks = () => {
  return api.get("/payment/banks");
};

export const postBank = (data) => {
  return api.post("/payment/fpx", data);
};
export const getCancellationList = (data) => {
  return api.get("/cancel_requests");
};

export const acceptCancellation = (data) => {
  return api.post("/cancel_requests/" + data.uuid + "/accept");
};

export const rejectCancellation = (data) => {
  return api.post("/cancel_requests/" + data.uuid + "/reject", {
    ...data.data,
  });
};
export const submitCancellation = (data) => {
  return api.post("/jobs/" + data.uuid + "/cancel", {
    ...data.data,
  });
};

export const getLocation = ({ queryKey }) => {
  const [_key, { query }] = queryKey;
  if (query) {
    return api.get(`/clinics/auto_complete?query=${query},Malaysia`);
  }
};

export const submitDoctorRating = (data) => {
  return api.post("/jobs/" + data.jobID + "/rate", {
    ...data.data,
  });
};

export const postCompleteJob = (data) => {
  return api.post("/jobs/" + data.jobID + "/force_complete");
};

export const postNotifyDoctors = (data) => {
  return api.post("/jobs/" + data.jobID + "/notify_doctors");
};

export const exportTransactions = (data) => {
  return api.post("/transactions/export", {
    ...data.data,
  });
};
