import { View, ScrollView, Platform, Dimensions } from "react-native";

function ScreenScrollView({
  useWindowScrolling = true, // defaults to true
  ...props
}) {
  const Component = Platform.select({
    web:
      useWindowScrolling && Dimensions.get("window").width < 600
        ? View
        : ScrollView,
    default: ScrollView,
    android: View,
    ios: View,
  });
  return (
    <Component
      onScroll={(event) => {
        if (event.nativeEvent.contentOffset.y <= 0) {
          console.log("top!");
        }
      }}
      {...props}
    />
  );
}
export default ScreenScrollView;
