import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favorites: [],
};

export const favoriteJobs = createSlice({
  name: "favoriteJobs",
  initialState,
  reducers: {
    addFavoriteJob: (state, action) => {
      if (state.favorites.includes(action.payload)) {
        state.favorites.splice(state.favorites.indexOf(action.payload), 1);
      }
      const newList = [action.payload, ...state.favorites];
      state.favorites = newList;
    },
    removeFavoriteJob: (state, action) => {
      if (state.favorites.includes(action.payload)) {
        state.favorites.splice(state.favorites.indexOf(action.payload), 1);
      }
    },
  },
});

export const { addFavoriteJob, removeFavoriteJob } = favoriteJobs.actions;

export default favoriteJobs.reducer;
