import { Ionicons } from "@expo/vector-icons";
import * as Haptics from "expo-haptics";
import { LinearGradient } from "expo-linear-gradient";
import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import {
  Dimensions,
  Image,
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Styles from "../constants/styles";
import Row from "./Row";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { normalize } from "../utils/normalize";

const Card = (props) => {
  const safeInsets = useSafeAreaInsets();

  return (
    <Pressable
      onPressIn={() => {
        setButtonAnimate({
          marginHorizontal: 24,
        });
      }}
      onPress={() => {
        Platform.OS === "ios" && Haptics.selectionAsync();
        props.onPress ? props.onPress() : null;
      }}
      onPressOut={() => {
        setButtonAnimate({ marginHorizontal: 18 });
      }}
    >
      <View
        {...props}
        style={[
          styles.container,
          props.isDescription ? {} : Styles.shadows,
          props.style,

          {
            marginHorizontal: 18,

            flexDirection: props.jobCard || props.doctorCard ? "row" : "column",
            padding: props.jobCard ? 0 : 0,
            paddingHorizontal: props.jobCard || props.doctorCard ? 0 : 8,
            borderRadius: 20,
          },
        ]}
      >
        {props.jobCard ? (
          <View style={styles.sideContent}>
            <LinearGradient
              start={{ x: -0.2, y: 0.2 }}
              // Button Linear Gradient
              colors={
                props.data?.is_emergency
                  ? [
                      "rgb(248, 100, 108)",
                      "rgb(242, 49, 123)",
                      "rgb(234,0,137)",
                    ]
                  : [
                      "rgb(63, 135, 196)",
                      "rgb(89, 124, 192)",
                      "rgb(118,113,188)",
                    ]
              }
              style={{ flex: 1, justifyContent: "center" }}
            >
              <View
                style={{
                  alignItems: "center",
                  paddingHorizontal:
                    moment(props.data?.start_timestamp).format("DD") !==
                    moment(props.data?.end_timestamp).format("DD")
                      ? 14
                      : 18,
                }}
              >
                <Row centered style={{ alignItems: "center" }}>
                  <View style={styles.dateContainer}>
                    <Text style={styles.dateMonth}>
                      {moment(props.data?.start_timestamp).format("MMM")}
                    </Text>
                    <Text style={styles.dateNumber}>
                      {moment(props.data?.start_timestamp).format("D")}
                    </Text>
                  </View>
                  {moment(props.data?.start_timestamp).format("DD") !==
                  moment(props.data?.end_timestamp).format("DD") ? (
                    <View style={styles.dateContainer}>
                      <Text
                        style={{
                          color: "white",
                          fontSize: normalize(12),
                          marginTop: 10,
                          marginHorizontal: 4,
                        }}
                      >
                        —
                      </Text>
                    </View>
                  ) : null}
                  {moment(props.data?.start_timestamp).format("DD") !==
                  moment(props.data?.end_timestamp).format("DD") ? (
                    <View style={styles.dateContainer}>
                      <Text style={styles.dateMonth}>
                        {moment(props.data?.end_timestamp).format("MMM")}
                      </Text>
                      <Text style={styles.dateNumber}>
                        {moment(props.data?.end_timestamp).format("D")}
                      </Text>
                    </View>
                  ) : null}
                </Row>
                <View style={styles.timeContainer}>
                  <View style={Styles.row}>
                    <Text style={styles.time}>
                      {moment(props.data?.start_timestamp).format("h:mm")}
                    </Text>
                    <Text style={styles.ampm}>
                      {moment(props.data?.start_timestamp).format("A")}
                    </Text>
                  </View>
                  <View style={Styles.row}>
                    <Text style={styles.time}>
                      {moment(props.data?.end_timestamp).format("h:mm")}
                    </Text>
                    <Text style={styles.ampm}>
                      {moment(props.data?.end_timestamp).format("A")}
                    </Text>
                  </View>
                </View>
              </View>
            </LinearGradient>
          </View>
        ) : null}
        {props.doctorCard ? (
          <View
            style={{
              ...styles.sideContent,
              alignItems: "center",
              justifyContent: "center",
              marginHorizontal: 0,
              paddingLeft: 12,
              paddingRight: 8,
              width: 60,
            }}
          >
            <Image
              source={{
                uri:
                  "data:image/jpg;base64," + decodeURIComponent(props?.image),
              }}
              style={{
                height: 55,
                width: 55,
                marginVertical: 10,
                borderRadius: 40,
              }}
            />
          </View>
        ) : null}
        {props.header ? (
          <View
            style={{
              ...styles.header,
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              marginRight: props.isDescription ? -27 : -8,
            }}
          >
            <LinearGradient
              // Button Linear Gradient
              start={{ x: -0.1, y: 0.1 }}
              colors={
                props.data?.is_emergency
                  ? [
                      "rgb(248, 100, 108)",
                      "rgb(242, 49, 123)",
                      "rgb(234,0,137)",
                    ]
                  : [
                      "rgb(63, 135, 196)",
                      "rgb(89, 124, 192)",
                      "rgb(118,113,188)",
                    ]
              }
            >
              <Row
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: props.isDescription ? safeInsets.top : 0,
                }}
              >
                {props.isDescription ? (
                  <TouchableOpacity onPress={() => props.onClosePressed()}>
                    <View
                      style={{
                        marginLeft: 20,
                      }}
                    >
                      <Ionicons
                        name="arrow-back-outline"
                        size={24}
                        color="white"
                      />
                    </View>
                  </TouchableOpacity>
                ) : null}
                <View
                  style={{
                    marginVertical: props.isDescription ? 10 : 0,
                    alignSelf: "flex-start",
                  }}
                >
                  <Text style={styles.title}>{props.title}</Text>
                </View>
                <View
                  style={{ marginHorizontal: props.isDescription ? 24 : 0 }}
                />
              </Row>
            </LinearGradient>
          </View>
        ) : null}
        {props.children}
      </View>
    </Pressable>
  );
};

const styles = {
  container: {
    backgroundColor: "white",
    borderRadius: 20,
    marginBottom: 12,
  },
  header: {
    marginHorizontal: -8,
    marginTop: -8,
    overflow: "hidden",
  },
  sideContent: {
    marginHorizontal: -8,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    minWidth: normalize(90),
    overflow: "hidden",
  },
  title: {
    margin: 14,
    fontWeight: "bold",
    fontSize: normalize(16),
    color: "rgba(255,255,255,0.95)",
    marginLeft: 18,
  },
  jobCount: {
    color: "white",
    margin: 14,
  },
  dateMonth: {
    fontWeight: "bold",
    fontSize: normalize(12),
    color: "rgba(255,255,255,0.90)",
  },
  dateContainer: {
    alignItems: "center",
    marginTop: 14,
    marginBottom: 2,
  },
  dateNumber: {
    fontWeight: "bold",
    fontSize: normalize(18),
    color: "rgba(255,255,255,0.95)",
  },
  time: {
    fontWeight: "bold",
    fontSize: normalize(11),
    color: "rgba(255,255,255,0.8)",
    marginLeft: 8,
    textAlign: "right",
  },
  ampm: {
    fontSize: normalize(6),
    color: "rgba(255,255,255,0.8)",
    fontWeight: "bold",
    margin: 2,
  },
  timeContainer: {
    marginTop: 2,
    marginBottom: 16,
    alignItems: "flex-end",
  },
};

Card.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default Card;
