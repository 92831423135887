import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { BarCodeScanner } from "expo-barcode-scanner";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Alert,
  Platform,
} from "react-native";
import Row from "../../components/Row";
import openPermissionSettings from "../../utils/openPermissionSettings";
import { useMutation } from "react-query";
import { putJobQR } from "../../services/job";
import { Loading } from "../../components/all";
import { normalize } from "../../utils/normalize";

function Checkin(props) {
  const [scanned, setScanned] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const submitJobQR = useMutation(putJobQR, {
    onError: (error) => {
      setVerifying(false);
      Alert.alert(
        "Oops!",
        error.response.data.message,
        [
          {
            text: "Confirm",
            onPress: () => {
              props.navigation.navigate("Home");
            },
          },
        ],
        {
          cancelable: false,
        }
      );
    },
    onSuccess: (data) => {
      setVerifying(false);
      Alert.alert(
        "Check In Successful",
        "You have successfully checked in for the job, press confirm to continue",
        [
          {
            text: "Confirm",
            onPress: () => {
              props.navigation.navigate("Home");
            },
          },
        ],
        {
          cancelable: false,
        }
      );
    },
  });

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      if (status === "granted") {
      } else {
        Alert.alert(
          "Camera Permission",
          "Permission to access camera was denied, please enable it in your permission settings to find clinics near your area.",
          [
            {
              text: "Cancel",
              style: "cancel",
            },
            {
              text: "Go to Settings",
              onPress: () => {
                props.navigation.navigate("Home");
                openPermissionSettings();
              },
            },
          ]
        );
      }
    })();
  }, []);

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    submitJobQR.mutate({
      id: data,
    });
  };
  return (
    <View style={styles.container}>
      <View
        style={{
          ...styles.header,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <LinearGradient
          // Button Linear Gradient
          colors={["rgb(234,0,137)", "rgb(248, 100, 108)"]}
        >
          <Row
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: Platform.OS === "ios" ? 20 : 40,
            }}
          >
            <View
              style={{
                margin: 4,
                alignSelf: "flex-start",
              }}
            >
              <Text style={styles.title}>Scan QR code</Text>
            </View>

            <TouchableOpacity onPress={() => props.navigation.goBack()}>
              <View
                style={{
                  marginRight: 20,
                }}
              >
                <Ionicons name="close" size={24} color="white" />
              </View>
            </TouchableOpacity>
          </Row>
        </LinearGradient>
      </View>
      <View
        style={{
          zIndex: 100,
          position: "absolute",
          top: Dimensions.get("window").height / 2 - 200,
          left: 0,
          right: 0,
          alignItems: "center",
        }}
      >
        <Image
          source={require("../../assets/images/qr-code.png")}
          style={{ height: 300, width: 300, marginVertical: 20 }}
        />
      </View>
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={styles.camera}
      />
      {/* {scanned && (
        <Button title={"Tap to Scan Again"} onPress={() => setScanned(false)} />
      )} */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 0,
  },
  camera: {
    flex: 1,
    width: Platform.OS === "ios" ? "100%" : "110%",
    marginLeft: Platform.OS === "ios" ? 0 : -10,
  },
  buttonContainer: {
    flex: 1,
    backgroundColor: "transparent",
    flexDirection: "row",
    margin: 20,
  },
  button: {
    flex: 0.1,
    alignSelf: "flex-end",
    alignItems: "center",
  },
  text: {
    fontSize: normalize(18),
    color: "white",
  },
  header: {
    marginHorizontal: -8,
    marginTop: -8,
    overflow: "hidden",
  },
  title: {
    margin: 14,
    fontWeight: "bold",
    fontSize: normalize(16),
    color: "rgba(255,255,255,0.95)",
    marginLeft: 18,
  },
});

export default Checkin;
