const noGlow = `
textarea, select, input, button {
	-webkit-appearance: none;
	outline: none!important;
}
textarea:focus, select:focus, input:focus, button:focus {
	-webkit-appearance: none;
	outline: none!important;
}
.container {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
`;

const injectWebCss = (f) => {
  // Only on web

  // Inject style
  const style = document.createElement("style");
  style.textContent = `textarea, select, input, button { outline: none!important; } html, body {
    position:relative;
    overflow-x: hidden; /* Hide horizontal scrollbar */
  } ::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
select {
  border: 1px solid transparent;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
}
`;
  return document.head.append(style);
};

export default injectWebCss;
