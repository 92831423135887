import { Ionicons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import Modal from "./Modal";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Styles from "../../constants/styles";
import { Row, Input } from "../all";
import { normalize } from "../../utils/normalize";
import withPreventDoubleClick from "../../utils/preventDoubleClick";

const TouchableOpacityEx = withPreventDoubleClick(TouchableOpacity);

const ModalButtons = (props) => {
  const safeInsets = useSafeAreaInsets();
  const { isDoctor } = useSelector((state) => state.user);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const onPressButton = (isCancel) => {
    setButtonDisabled(true);
    isCancel ? props.onCancel() : props.onConfirm();

    setTimeout(() => {
      setButtonDisabled(false);
    }, 500);
  };
  return (
    <>
      <Row
        style={{
          justifyContent: "space-around",
          borderTopWidth: 1,
          marginTop: 14,
          borderTopColor: "#E5E5E5",
          ...props.containerStyles,
        }}
      >
        <TouchableOpacityEx onPress={props.onCancel}>
          <View
            style={{
              alignItems: "center",
              paddingVertical: 20,
              width:
                Dimensions.get("window").width > 480
                  ? 480 / 2 - 30
                  : Dimensions.get("window").width / 2 - 30,
              borderRightWidth: 1,
              borderColor: "#E5E5E5",
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: normalize(16) }}>
              {props.leftLabel || "Cancel"}
            </Text>
          </View>
        </TouchableOpacityEx>

        <TouchableOpacityEx onPress={props.onConfirm}>
          <View
            style={{
              alignItems: "center",
              paddingVertical: 20,
              paddingHorizontal: 20,
              width:
                Dimensions.get("window").width > 480
                  ? 480 / 2 - 30
                  : Dimensions.get("window").width / 2 - 30,
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: normalize(16),
                color: "#FF4B4B",
              }}
            >
              {props.rightLabel || "Confirm"}
            </Text>
          </View>
        </TouchableOpacityEx>
      </Row>
    </>
  );
};

const styles = {
  modalStyle: {
    margin: 0,
    justifyContent: "flex-end",
  },
  modalContainer: {
    backgroundColor: "white",
    height: "60%",
    borderTopLeftRadius: 48,
    borderTopRightRadius: 48,

    marginHorizontal: 0,
    margin: 0,
    ...Styles.shadows,
  },
  calendarContainer: {
    margin: 48,
    marginTop: 12,
  },
  saveButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  resetButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.05)",
  },
};

ModalButtons.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default ModalButtons;
