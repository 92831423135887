import { Feather, FontAwesome } from "@expo/vector-icons";
import Styles from "../../../constants/styles";
import React, { useState } from "react";
import { Dimensions, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
  Centered,
  ErrorMessage,
  Input,
  StepHeader,
} from "../../../components/all";
import { resetEmptyFields } from "../../../reducers/auth/register";

export default function Step1({
  setFocusedInput,
  focusedInput,
  setField,
  allFields,
  showPasswordFields,
  isWeb,
  currentStep,
}) {
  const dispatch = useDispatch();
  const { errorFields } = useSelector((state) => state.register);
  const [showPassword, setShowPassword] = useState(false);
  const setErrorFields = () => {
    dispatch(resetEmptyFields());
  };

  const input = {
    email: "email",
    password: "password",
    confirmPassword: "confirmPassword",
  };
  if (isWeb && currentStep !== 1) return <></>;

  return (
    <View
      style={{
        ...Styles.webSmallContainer,
      }}
    >
      <StepHeader
        label={`Account \nCredentials`}
        icon={<Feather name="credit-card" size={24} color="black" />}
        subtitle={`How can we reach you?`}
      />
      <Centered
        style={{ width: Dimensions.get("window").width > 480 ? 480 : "100%" }}
      >
        <Input
          onChangeText={(val) => {
            setField(input.email, val);
          }}
          autoCapitalize="none"
          value={allFields[input.email]}
          icon={<FontAwesome name="envelope" size={14} color="grey" />}
          placeholder="Email Address"
          containerStyle={{
            width: "90%",
            marginTop: 24,
          }}
          onBlur={() => setFocusedInput(null)}
          onFocus={() => {
            setErrorFields();
            setFocusedInput(input.email);
          }}
          focused={focusedInput === input.email}
          error={errorFields.includes(input.email)}
          disabled={showPasswordFields}
        />
        {showPasswordFields ? (
          <>
            <Input
              onChangeText={(val) => {
                setField(input.password, val);
              }}
              value={allFields[input.password]}
              secureTextEntry={!showPassword}
              icon={<FontAwesome name="lock" size={14} color="grey" />}
              placeholder="Password"
              containerStyle={{ width: "90%", marginTop: 10 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.password);
              }}
              autoCapitalize="none"
              error={errorFields.includes(input.password)}
              focused={focusedInput === input.password}
              backIcon={
                <Feather
                  name={showPassword ? "eye-off" : "eye"}
                  size={18}
                  color="grey"
                />
              }
              onPressBackIcon={() => {
                setShowPassword(!showPassword);
              }}
            />
            <Input
              onChangeText={(val) => {
                setField(input.confirmPassword, val);
              }}
              value={allFields[input.confirmPassword]}
              secureTextEntry={!showPassword}
              icon={<FontAwesome name="unlock-alt" size={14} color="grey" />}
              placeholder="Confirm Password"
              containerStyle={{ width: "90%", marginTop: 10 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.confirmPassword);
              }}
              autoCapitalize="none"
              error={errorFields.includes(input.confirmPassword)}
              focused={focusedInput === input.confirmPassword}
              backIcon={
                <Feather
                  name={showPassword ? "eye-off" : "eye"}
                  size={18}
                  color="grey"
                />
              }
              onPressBackIcon={() => {
                setShowPassword(!showPassword);
              }}
            />
          </>
        ) : null}
      </Centered>
      <View style={{ margin: 10, marginLeft: 24 }}>
        <ErrorMessage
          visible={
            errorFields.includes(input.phone_number) ||
            errorFields.includes(input.email) ||
            errorFields.includes(input.password) ||
            errorFields.includes(input.confirmPassword)
          }
          label="Please fill in the required fields"
        />
        <ErrorMessage
          visible={errorFields.includes("emailError")}
          label="Please enter a valid email."
        />
        <ErrorMessage
          visible={errorFields.includes("passwordMatchError")}
          label="Passwords do not match."
        />
        <ErrorMessage
          visible={errorFields.includes("passwordLengthError")}
          label="Passwords length must be greater than 6."
        />
      </View>
    </View>
  );
}
