import api from "./api";

export const getUser = (id) => {
  return api.get(`/users/${id}`);
};

export const getOverview = () => {
  return api.get("/overview");
};

export const getProfile = () => {
  return api.get("/profile");
};

export const updateProfile = (data) => {
  return api.put("/profile", data);
};

export const resendVerificationEmail = (data) => {
  return api.post("/profile/resend_email");
};

export const getProfileFacilities = (data) => {
  return api.get("/profile/facilities", data);
};

export const postFacilities = (data) => {
  return api.post("/profile/facilities", data.facilities);
};

export const getEducation = () => {
  return api.get("/profile/education");
};

export const postEducation = (data) => {
  return api.post("/profile/education", data);
};

export const updateEducation = (data) => {
  return api.put("/education/" + data.uuid, data);
};

export const deleteEducation = (data) => {
  return api.delete("/education/" + data.uuid);
};

export const getClinics = () => {
  return api.get("/clinics");
};

export const updateClinic = (data) => {
  return api.put("/clinics/" + data.uuid, data);
};

export const postClinicFacilities = (data) => {
  return api.post("/clinics/" + data.uuid + "/facilities", data.facilities);
};

export const getTransactions = (data) => {
  return api.get(`/transactions?page=${data?.page ? data?.page : 1}`);
};

export const getTransaction = (data) => {
  return api.get("/transactions/" + data);
};

export const getNotifications = (data) => {
  return api.get(
    "/users/" + data.uuid + `/notifications?page=${data?.page ? data?.page : 1}`
  );
};

export const readNotifications = (data) => {
  return api.post("/users/" + data.uuid + "/notifications", data.data);
};

export const deleteUser = (data) => {
  return api.delete("/users/" + data);
};
