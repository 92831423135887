import { Feather, FontAwesome } from "@expo/vector-icons";
import * as ImagePicker from "expo-image-picker";
import React, { useState, useEffect } from "react";
import { Dimensions, View, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
  Centered,
  ErrorMessage,
  Input,
  Row,
  StepHeader,
} from "../../../components/all";
import { resetEmptyFields } from "../../../reducers/auth/register";
import { resetEmptyFields as resetProfileEmptyFields } from "../../../reducers/profile/profile";
import Styles from "../../../constants/styles";
export default function Step3({
  setFocusedInput,
  focusedInput,
  setField,
  allFields,
  editProfile,
  setEditProfileImage,
  isWeb,
  currentStep,
}) {
  const dispatch = useDispatch();
  const { isDoctor } = useSelector((state) => state.user);
  const { errorFields } = useSelector(
    (state) => state[editProfile ? "profile" : "register"]
  );
  const setErrorFields = () => {
    dispatch(editProfile ? resetProfileEmptyFields() : resetEmptyFields());
  };

  const input = {
    first_name: "first_name",
    last_name: "last_name",
    nric_passport_number: "nric_passport_number",
    about: "about",
    phone_number: "phone_number",
    profile_image: "profile_image",
    emergency_number: "emergency_number",
    emergency_name: "emergency_name",
  };

  const [imageUrl, setImageUrl] = useState(allFields?.profile_image || null);
  const onPressImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert(
        "Sorry, we need camera roll permissions to make this work. Please go to Settings > Permissions and allow it."
      );
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 0.5,
        base64: true,
        height: 100,
        width: 100,
      });
      if (result) {
        setImageUrl(result.base64);
        setField(input.profile_image, result.base64);
      }
    }
  };

  useEffect(() => {
    if (allFields?.profile_image) {
      setImageUrl(allFields?.profile_image);
    }
  }, [allFields?.profile_image]);

  if (isWeb && currentStep !== 2) return <></>;

  return (
    <View
      style={{
        ...Styles.webSmallContainer,
      }}
    >
      <StepHeader
        label={
          editProfile
            ? "Update Profile"
            : isDoctor
              ? `Personal \nDetails`
              : `Clinic Owner's \nInformation`
        }
        icon={<Feather name="user" size={24} color="black" />}
        subtitle={
          editProfile
            ? isDoctor
              ? "Tell us more about yourself"
              : "How can we reach you?"
            : `How can we address you?`
        }
        showImage
        imageUrl={imageUrl}
        imageError={errorFields.includes(input.profile_image)}
        onPressImage={() => {
          setEditProfileImage && setEditProfileImage(true);
          onPressImage();
        }}
        isProfile
      />
      <Centered
        style={{ width: Dimensions.get("window").width > 480 ? 480 : "100%" }}
      >
        <Row style={{ justifyContent: "center" }}>
          <Input
            onChangeText={(val) => {
              setField(input.first_name, val);
            }}
            value={allFields[input.first_name]}
            icon={<FontAwesome name="user" size={14} color="grey" />}
            placeholder="First Name"
            containerStyle={{
              width:
                Dimensions.get("window").width > 480
                  ? 210
                  : Dimensions.get("window").width / 2.28,
              marginTop: 24,
              marginRight: 10,
            }}
            onBlur={() => setFocusedInput(null)}
            onFocus={() => {
              setErrorFields();
              setFocusedInput(input.first_name);
            }}
            error={errorFields.includes(input.first_name)}
            focused={focusedInput === input.first_name}
            inputStyle={{ width: "50%" }}
            autoCapitalize="words"
          />
          <Input
            onChangeText={(val) => {
              setField(input.last_name, val);
            }}
            value={allFields[input.last_name]}
            placeholder="Last Name"
            containerStyle={{
              width:
                Dimensions.get("window").width > 480
                  ? 210
                  : Dimensions.get("window").width / 2.28,
              marginTop: 24,
            }}
            onBlur={() => setFocusedInput(null)}
            onFocus={() => {
              setErrorFields();
              setFocusedInput(input.last_name);
            }}
            error={errorFields.includes(input.last_name)}
            focused={focusedInput === input.last_name}
            autoCapitalize="words"
          />
        </Row>
        {isDoctor ? (
          <Input
            onChangeText={(val) => {
              setField(input.nric_passport_number, val);
            }}
            value={allFields[input.nric_passport_number]}
            placeholder="IC/Passport Number"
            icon={<FontAwesome name="id-card-o" size={14} color="grey" />}
            containerStyle={{ width: "90%", marginTop: 10 }}
            onBlur={() => setFocusedInput(null)}
            onFocus={() => {
              setErrorFields();
              setFocusedInput(input.nric_passport_number);
            }}
            error={
              errorFields.includes(input.nric_passport_number) ||
              errorFields.includes("nric_passport_numberError")
            }
            focused={focusedInput === input.nric_passport_number}
          />
        ) : (
          <>
            <Input
              onChangeText={(val) => {
                setField(input.phone_number, val);
              }}
              value={allFields[input.phone_number]}
              placeholder="Mobile Number"
              keyboardType="numeric"
              icon={<FontAwesome name="phone" size={14} color="grey" />}
              containerStyle={{ width: "90%", marginTop: 10 }}
              onBlur={() => setFocusedInput(null)}
              maxLength={15}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.phone_number);
              }}
              error={errorFields.includes(input.phone_number)}
              focused={focusedInput === input.phone_number}
            />
            <Input
              onChangeText={(val) => {
                setField(input.emergency_number, val);
              }}
              value={allFields[input.emergency_number]}
              placeholder="Emergency Number"
              keyboardType="numeric"
              icon={<FontAwesome name="mobile-phone" size={14} color="grey" />}
              containerStyle={{ width: "90%", marginTop: 10 }}
              onBlur={() => setFocusedInput(null)}
              maxLength={15}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.emergency_number);
              }}
              error={errorFields.includes(input.emergency_number)}
              focused={focusedInput === input.emergency_number}
            />
            <Input
              onChangeText={(val) => {
                setField(input.emergency_name, val);
              }}
              value={allFields[input.emergency_name]}
              placeholder="Emergency Name"
              icon={<FontAwesome name="user" size={14} color="grey" />}
              containerStyle={{ width: "90%", marginTop: 10 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.emergency_name);
              }}
              error={errorFields.includes(input.emergency_name)}
              focused={focusedInput === input.emergency_name}
              autoCapitalize="words"
            />

          </>
        )}
        {!isDoctor ? null : (
          <Input
            onChangeText={(val) => {
              setField(input.about, val);
            }}
            value={allFields[input.about]}
            icon={<Feather name="info" size={14} color="grey" />}
            placeholder="Tell us about yourself"
            containerStyle={{ width: "90%", marginTop: 10 }}
            onBlur={() => setFocusedInput(null)}
            onFocus={() => {
              setErrorFields([]);
              setFocusedInput(input.about);
            }}
            error={errorFields.includes(input.about)}
            focused={focusedInput === input.about}
            maxLength={600}
            multiline={true}
            isAbout
          />
        )}
      </Centered>
      <View style={{ margin: 10, marginLeft: 24, marginBottom: 20 }}>
        <ErrorMessage
          visible={
            errorFields.includes(input.first_name) ||
            errorFields.includes(input.last_name)
          }
          label={"Please fill in the required fields."}
        />
        <ErrorMessage
          visible={errorFields.includes("nric_passport_numberError")}
          label={"Please enter a valid IC/Passport number."}
        />
        <ErrorMessage
          visible={errorFields.includes("phoneError")}
          label={"Please enter a valid Mobile Number."}
        />
      </View>
    </View>
  );
}
