import PropTypes from "prop-types";
import * as React from "react";
import { Text, View } from "react-native";
import Fonts from "../constants/fonts";

const ListTitle = (props) => {
  return (
    <View style={styles.titleContainer}>
      <Text
        {...props}
        style={[
          Fonts.bold,
          { fontFamily: "NotoSans-Bold", color: "rgba(0,0,0,0.9)" },
          props.style,
        ]}
      >
        {props.label}
      </Text>
    </View>
  );
};

ListTitle.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};
const styles = {
  titleContainer: {
    alignItems: "center",

    padding: 10,
    marginBottom: 10,
  },
};
export default ListTitle;
