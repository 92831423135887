import { Ionicons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import CustomDrawer from "./drawer";

import * as React from "react";
import { Platform, View, Text } from "react-native";
import { useSelector } from "react-redux";
import { CheckinButton } from "../components/all";
import aboveX from "../constants/aboveX";

// Auth
import LoginScreen from "../screens/Auth/Login/Login";
import ClinicLocatorScreen from "../screens/Auth/Register/ClinicLocator";
import PaymentSetupScreen from "../screens/Auth/Register/PaymentSetup";
import RegisterScreen from "../screens/Auth/Register/Register";
import CompleteRegistrationScreen from "../screens/Auth/Register/StepComplete";
import CategoryScreen from "../screens/Auth/Register/StepCategory";
import ExpertiseScreen from "../screens/Auth/Register/StepExpertise";
import StepProfession from "../screens/Auth/Register/StepProfession";

import TermsConditionScreen from "../screens/Auth/Register/TermsCondition";
import ValidateOTPScreen from "../screens/Auth/Register/ValidateOTP";
import ForgotPasswordScreen from "../screens/Auth/ForgotPassword";
import CheckinScreen from "../screens/Home/Checkin";
import DetailsScreen from "../screens/Home/Details";
import BankAccountScreen from "../screens/Shared/BankAccount";
import UploadDocsScreen from "../screens/Auth/Register/UploadDocs";

// Home
import HomeScreen from "../screens/Home/Home";
import InboxScreen from "../screens/Home/Inbox";
import JobDescriptionScreen from "../screens/Home/JobDescription";
import OnboardScreen from "../screens/Home/Onboarding";
import TransactionScreen from "../screens/Home/Transaction";

// Settings
import SettingsScreen from "../screens/Home/Settings/Settings";
import PermissionsScreen from "../screens/Home/Settings/Permissions";
import ChangePasswordScreen from "../screens/Home/Settings/ChangePassword";
import HelpSupportScreen from "../screens/Home/Settings/HelpSupport";
import FeedbackScreen from "../screens/Home/Settings/Feedback";

// Profile
import ProfileScreen from "../screens/Home/Settings/Profile";
import EditProfileScreen from "../screens/Home/Settings/EditProfile";
import EditEducationScreen from "../screens/Home/Settings/EditEducation";
import EditFaciltyScreen from "../screens/Home/Settings/EditFacility";
import EditClinicScreen from "../screens/Home/Settings/EditClinic";

// Jobs
import JobsScreen from "../screens/Jobs/Jobs";
import PostJobScreen from "../screens/Jobs/PostJob";
import SearchFilterScreen from "../screens/Jobs/SearchFilter";
import SearchLocationScreen from "../screens/Jobs/SearchLocation";
import MakePaymentScreen from "../screens/Jobs/MakePayment";
import SelectBankScreen from "../screens/Jobs/SelectBank";
import ClinicJobsScreen from "../screens/Jobs/ClinicJobs";

// Others
import WebViewScreen from "../screens/Shared/WebView";
import RatingScreen from "../screens/Shared/Rating";

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

const sharedOptions = {
  headerShown: false,
};

function Navigation() {
  const { loggedIn } = useSelector((state) => state.user);

  let MainStack = (
    <>
      <Stack.Screen
        name="AuthScreen"
        component={AuthStack}
        options={{ gestureEnabled: false }}
      />
      <Stack.Screen
        name="HomeScreen"
        component={HomeTabs}
        options={{ gestureEnabled: false }}
      />
    </>
  );

  if (loggedIn) {
    MainStack = (
      <>
        <Stack.Screen
          name="HomeScreen"
          component={HomeTabs}
          options={{ gestureEnabled: false }}
        />
        <Stack.Screen
          name="AuthScreen"
          component={AuthStack}
          options={{ gestureEnabled: false }}
        />
      </>
    );
  }

  return (
    <Stack.Navigator
      screenOptions={{
        ...sharedOptions,
      }}
    >
      {MainStack}
      {SettingsStack()}
      {ProfileStack()}
      {JobFlowStack()}
      {SharedStack()}
    </Stack.Navigator>
  );
}

function AuthStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        ...sharedOptions,
      }}
    >
      <Stack.Screen name="LoginScreen" component={LoginScreen} />
      <Stack.Screen
        name="RegisterScreen"
        component={RegisterScreen}
        options={{ gestureEnabled: false }}
      />
      <Stack.Screen
        name="OnboardScreen"
        component={OnboardScreen}
        options={{ gestureEnabled: false }}
      />

      <Stack.Screen
        name="TermsConditionScreen"
        component={TermsConditionScreen}
      />
      <Stack.Screen name="UploadDocsScreen" component={UploadDocsScreen} />
      <Stack.Screen name="CategoryScreen" component={CategoryScreen} />

      <Stack.Screen
        name="ValidateOTPScreen"
        component={ValidateOTPScreen}
        options={{ gestureEnabled: false }}
      />
      <Stack.Screen
        name="ForgotPasswordScreen"
        component={ForgotPasswordScreen}
      />
      <Stack.Screen
        name="ClinicLocatorScreen"
        component={ClinicLocatorScreen}
      />
      <Stack.Screen name="ExpertiseScreen" component={ExpertiseScreen} />
      <Stack.Screen name="StepProfession" component={StepProfession} />
    </Stack.Navigator>
  );
}

function HomeTabs() {
  const { isDoctor, paymentSetup, notifCount } = useSelector(
    (state) => state.user
  );
  if (Platform.OS === "web") {
    return (
      <Drawer.Navigator
        initialRouteName="Home"
        drawerType={"slide"}
        swipeEnabled={false}
        gestureEnabled={false}
        defaultStatus={"closed"}
        // useLegacyImplementation={true}
        drawerContent={CustomDrawer}
        screenOptions={{
          headerShown: false,
          drawerPosition: "right",
        }}
      >
        <Drawer.Screen
          name="Home"
          component={HomeStack}
          options={drawerOptions("home-outline")}
        />
        <Drawer.Screen
          name="Inbox"
          component={InboxScreen}
          options={drawerOptions("mail-outline")}
        />
        <Drawer.Screen
          name="Account"
          component={SettingsScreen}
          options={drawerOptions("person-circle-outline")}
        />
      </Drawer.Navigator>
    );
  } else {
    return (
      <Tab.Navigator
        screenOptions={{
          ...sharedOptions,
          tabBarActiveTintColor: "#7176C0",
          tabBarInactiveTintColor: "rgba(0,0,0,0.3)",
          tabBarStyle: {
            height: aboveX() ? 100 : 65,
            marginBottom: Platform.OS === "web" ? -65 : 0,
            opacity: Platform.OS === "web" ? 0 : 1,
            paddingBottom: aboveX() ? 40 : 10,
          },
        }}
      >
        <Tab.Screen
          name="Home"
          component={HomeStack}
          options={tabOptions("home-outline")}
        />
        {Platform.OS === "web" ? null : (
          <>
            <Tab.Screen
              name="Jobs"
              component={JobsStack}
              options={tabOptions("briefcase-outline")}
            />
            <Tab.Screen
              name={isDoctor ? "Checkin" : "New Job"}
              component={() => <></>}
              listeners={({ navigation }) => ({
                tabPress: (e) => {
                  e.preventDefault();
                  navigation.navigate(
                    isDoctor
                      ? "CheckinScreen"
                      : paymentSetup
                      ? "PaymentSetupScreen"
                      : "PostJobScreen"
                  );
                },
              })}
              options={tabOptions("checkmark-circle-outline", true)}
            />
          </>
        )}

        <Tab.Screen
          name="Inbox"
          component={InboxScreen}
          options={tabOptions("mail-outline", false, true, notifCount)}
        />
        <Tab.Screen
          name="Account"
          component={SettingsScreen}
          options={tabOptions("person-circle-outline")}
        />
      </Tab.Navigator>
    );
  }
}

function HomeStack() {
  return (
    <Stack.Navigator
      screenOptions={{
        ...sharedOptions,
      }}
    >
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
    </Stack.Navigator>
  );
}

function JobsStack() {
  const { isDoctor } = useSelector((state) => state.user);

  return (
    <Stack.Navigator
      screenOptions={{
        ...sharedOptions,
      }}
    >
      <Stack.Screen name="JobsScreen" component={JobsScreen} />
    </Stack.Navigator>
  );
}

function SettingsStack() {
  return (
    <>
      <Stack.Screen name="HelpSupportScreen" component={HelpSupportScreen} />
      <Stack.Screen name="FeedbackScreen" component={FeedbackScreen} />
      <Stack.Screen name="WebViewScreen" component={WebViewScreen} />
      <Stack.Screen name="PermissionsScreen" component={PermissionsScreen} />
      <Stack.Screen
        name="ChangePasswordScreen"
        component={ChangePasswordScreen}
      />
    </>
  );
}

function ProfileStack() {
  return (
    <>
      <Stack.Screen name="ProfileScreen" component={ProfileScreen} />
      <Stack.Screen name="EditProfileScreen" component={EditProfileScreen} />
      <Stack.Screen
        name="EditEducationScreen"
        component={EditEducationScreen}
        options={{ gestureEnabled: false }}
      />
      <Stack.Screen name="EditFaciltyScreen" component={EditFaciltyScreen} />
      <Stack.Screen name="EditClinicScreen" component={EditClinicScreen} />
      <Stack.Screen name="SelectBankScreen" component={SelectBankScreen} />
    </>
  );
}

function JobFlowStack() {
  return (
    <>
      <Stack.Screen name="DetailsScreen" component={DetailsScreen} />
      <Stack.Screen
        name="JobDescriptionScreen"
        component={JobDescriptionScreen}
      />
      <Stack.Screen
        name="SearchLocationScreen"
        component={SearchLocationScreen}
      />
      <Stack.Screen name="SearchFilterScreen" component={SearchFilterScreen} />
      <Stack.Screen
        options={{ presentation: "modal" }}
        name="PaymentSetupScreen"
        component={PaymentSetupScreen}
      />
      <Stack.Screen
        options={{ presentation: "modal" }}
        name="PostJobScreen"
        component={PostJobScreen}
      />
      <Stack.Screen
        options={{ presentation: "modal" }}
        name="CheckinScreen"
        component={CheckinScreen}
      />
      <Stack.Screen
        options={{ presentation: "modal" }}
        name="DoctorProfileScreen"
        component={ProfileScreen}
      />
      <Stack.Screen
        name="MakePaymentScreen"
        component={MakePaymentScreen}
        options={{ gestureEnabled: false }}
      />
      <Stack.Screen name="TransactionScreen" component={TransactionScreen} />
    </>
  );
}

function SharedStack() {
  return (
    <>
      <Stack.Screen
        name="BankAccountScreen"
        component={BankAccountScreen}
        options={{ gestureEnabled: false }}
      />
      <Stack.Screen
        name="CompleteRegistrationScreen"
        component={CompleteRegistrationScreen}
        options={{ gestureEnabled: false }}
      />
      <Stack.Screen
        name="RatingScreen"
        component={RatingScreen}
        options={{ presentation: "modal" }}
      />
    </>
  );
}

const tabOptions = (
  iconName,
  isCheckin = false,
  isInbox = false,
  notifCount
) => {
  if (isCheckin) {
    return {
      tabBarIcon: ({ color, size }) => (
        <CheckinButton iconName={iconName} color={color} size={size} />
      ),
    };
  } else {
    return {
      tabBarIcon: ({ color, size }) => (
        <>
          {isInbox && notifCount > 0 ? (
            <View
              style={{
                top: 8,
                right: 16,
                position: "absolute",
                backgroundColor: "rgba(255, 84, 110,1)",
                zIndex: 10,
                borderRadius: 20,
                width: 20,
                height: 20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ color: "white", fontSize: 11 }}>{notifCount}</Text>
            </View>
          ) : null}
          <View style={{ marginBottom: -10 }}>
            <Ionicons name={iconName} color={color} size={size} />
          </View>
        </>
      ),
    };
  }
};

const drawerOptions = (iconName) => {
  return {
    drawerIcon: ({ color, size }) => (
      <View style={{ marginLeft: 12 }}>
        <Ionicons name={iconName} color={color} size={18} />
      </View>
    ),
    drawerLabelStyle: {
      marginLeft: -12,
    },
  };
};

export default Navigation;
