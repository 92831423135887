let banks = [
  {
    code: "PHBMMYKL",
    name: "Affin Bank",
  },
  {
    code: "AIBBMYKL",
    name: "Affin Islamic Bank",
  },
  {
    code: "AGOBMYKL",
    name: "Agrobank",
  },
  {
    code: "MFBBMYKL",
    name: "Alliance Bank",
  },
  {
    code: "ALSRMYKL",
    name: "Alliance Islamic Bank",
  },
  {
    code: "RJHIMYKL",
    name: "Al-Rajhi Banking & Inv.Corp. (M) Bhd",
  },
  {
    code: "ARBKMYKL",
    name: "AmBank Berhad",
  },
  {
    code: "AISLMYKL",
    name: "AmIslamic Bank",
  },
  {
    code: "BKKBMYKL",
    name: "Bangkok Bank Berhad",
  },
  {
    code: "BIMBMYKL",
    name: "Bank Islam Malaysia Berhad",
  },
  {
    code: "BMMBMYKL",
    name: "Bank Muamalat Malaysia Berhad",
  },
  {
    code: "BOFAMY2X",
    name: "Bank of America",
  },
  {
    code: "BKCHMYKL",
    name: "Bank of China",
  },
  {
    code: "BKRMMYKL",
    name: "Bank Rakyat",
  },
  {
    code: "BSNAMYK1",
    name: "Bank Simpanan Nasional",
  },
  {
    code: "BNPAMYKL",
    name: "BNP Paribas",
  },
  {
    code: "PCBCMYKL",
    name: "China Construction Bank",
  },
  {
    code: "CIBBMYKL",
    name: "CIMB Bank",
  },
  {
    code: "CTBBMYKL",
    name: "CIMB Islamic Bank",
  },
  {
    code: "CITIMYKL",
    name: "Citibank",
  },
  {
    code: "DEUTMYKL",
    name: "Deutsche Bank",
  },
  {
    code: "HLBBMYKL",
    name: "Hong Leong Bank",
  },
  {
    code: "HLIBMYKL",
    name: "Hong Leong Islamic Bank",
  },
  {
    code: "HMABMYKL",
    name: "HSBC Amanah",
  },
  {
    code: "HBMBMYKL",
    name: "HSBC Bank",
  },
  {
    code: "ICBKMYKL",
    name: "Industrial & Commercial Bank of China",
  },
  {
    code: "CHASMYKX",
    name: "JP Morgan Chase Bank",
  },
  {
    code: "KFHOMYKL",
    name: "Kuwait Finance House",
  },
  {
    code: "MBBEMYKL",
    name: "Malayan Banking Berhad",
  },
  {
    code: "MBISMYKL",
    name: "Maybank Islamic Bank",
  },
  {
    code: "AFBQMYKL",
    name: "MBSB Bank Malaysia Berhad",
  },
  {
    code: "MHCBMYKA",
    name: "Mizuho Bank (M) Berhad",
  },
  {
    code: "BOTKMYKX",
    name: "MUFG Bank (Malaysia) BHD",
  },
  {
    code: "OABBMYKL",
    name: "OCBC Al-Amin Bank Berhad",
  },
  {
    code: "OCBCMYKL",
    name: "OCBC Bank",
  },
  {
    code: "PBBEMYKL",
    name: "Public Bank",
  },
  {
    code: "PIBEMYK1",
    name: "Public Islamic Bank Berhad",
  },
  {
    code: "RHBBMYKL",
    name: "RHB Bank",
  },
  {
    code: "RHBAMYKL",
    name: "RHB Islamic Bank",
  },
  {
    code: "SCBLMYKX",
    name: "Standard Chartered Bank Berhad",
  },
  {
    code: "SCSRMYKK",
    name: "Standard Chartered Saadiq Bank Berhad",
  },
  {
    code: "SMBCMYKL",
    name: "Sumitomo Mitsui Bank",
  },
  {
    code: "UOVBMYKL",
    name: "United Overseas Bank",
  },
];

export default banks;
