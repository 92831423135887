import React from "react";
import { Text } from "react-native";

const GradientText = (props) => {
  return (
    <Text
      {...props}
      style={[props.style, { opacity: 1, color: props?.colors[0] }]}
    />
  );
};

export default GradientText;
