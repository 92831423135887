import { Feather, FontAwesome5, Ionicons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import * as React from "react";
import { Image, Text, TouchableOpacity, View, Alert } from "react-native";
import Styles from "../constants/styles";
import { decode, encode } from "base-64";
import colors from "../constants/colors";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";
import { normalize } from "../utils/normalize";

const Header = (props) => {
  return (
    <>
      {props.profileLoading ? (
        <View style={{ ...styles.container, marginTop: -20 }}>
          <ContentLoader
            speed={2}
            width={400}
            height={160}
            viewBox="0 0 400 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <Rect x="8" y="131" rx="3" ry="3" width="140" height="14" />
            <Rect x="9" y="111" rx="3" ry="3" width="52" height="6" />
            <Circle cx="30" cy="69" r="29" />
          </ContentLoader>
        </View>
      ) : (
        <>
          <View style={{ ...styles.container, ...props?.containerStyle }}>
            <TouchableOpacity onLongPress={props.onPress}>
              {props.profile_image ? (
                <Image
                  source={{
                    uri:
                      "data:image/jpg;base64," +
                      decodeURIComponent(props.profile_image),
                  }}
                  style={{
                    height: 60,
                    width: 60,
                    marginVertical: 10,
                    borderRadius: 100,
                  }}
                />
              ) : null}
            </TouchableOpacity>
            {props.fromHome ? (
              <Text style={styles.subtitle}>{"Welcome Back"}</Text>
            ) : null}

            <View style={styles.nameContainer}>
              <Text style={styles.name}>
                {props?.data?.clinic_name && !props?.fromDoctorCard ? (
                  props.data.clinic_name
                ) : (
                  <>
                    {props?.first_name
                      ? `${props.first_name} ${props.last_name[0]}.`
                      : ""}{" "}
                  </>
                )}
                {props.from === "home" && "John D."}
              </Text>
              {props.fromDoctorCard ? null : (
                <TouchableOpacity
                  onPress={() => {
                    if (props.fromDoctorCard) {
                      props?.showVerifiedModal(true);
                    } else {
                      if (props?.data?.is_approved) return;
                      props.onPressVerify();
                      // Alert.alert(
                      //   "Pending Verification",
                      //   "We have sent you an email on the steps to verify your profile, please check your inbox to verify your account.",
                      //   [
                      //     {
                      //       text: "Cancel",
                      //       style: "cancel",
                      //     },
                      //     {
                      //       text: "Resend Email",
                      //       onPress: () => {
                      //         console.log("resend email");
                      //       },
                      //     },
                      //   ]
                      // );
                    }
                  }}
                >
                  <View style={{ marginLeft: 8 }}>
                    {props?.data?.is_approved || props.fromDoctorCard ? (
                      <Image
                        source={require("../assets/images/check.png")}
                        style={{ height: 14, width: 14 }}
                      />
                    ) : (
                      <FontAwesome5
                        name="exclamation-circle"
                        size={14}
                        color={colors.primary}
                      />
                    )}
                  </View>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </>
      )}
      <Image
        source={require("../assets/images/wave.png")}
        style={{ width: "100%", zIndex: -10, marginTop: -100 }}
      />
    </>
  );
};

const styles = {
  container: {
    margin: 12,
    marginBottom: 4,
    zIndex: 100,
  },
  nameContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    maxWidth: "50%",
  },
  subtitle: {
    fontSize: normalize(12),
    color: "rgba(0,0,0,0.25)",
    fontWeight: "bold",
  },
  cardStyle: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 8,
    marginBottom: 10,
    marginHorizontal: 10,

    ...Styles.shadows,
  },
};

Header.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default Header;
