import { normalize } from "../utils/normalize";

const fonts = {
  text: {
    fontSize: normalize(12),
  },
  subText: {
    fontSize: normalize(10),
    color: "grey",
  },
  bold: {
    fontSize: normalize(12),
    fontWeight: "bold",
  },
  title: {
    fontSize: normalize(20),
    fontWeight: "bold",
    color: "rgba(0,0,0,0.87)",
  },
};

export default fonts;
