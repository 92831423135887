import api from "./api";

export const getVersion = () => {
  return api.get("/version?query=app");
};

export const validateFields = async (data) => {
  return api.post("/auth/register/check?scope=" + data.scope, data);
};

export const validateOTP = (data) => {
  return api.post("/auth/register/activate", data);
};

export const requestOTP = (data) => {
  return api.post("/auth/otp", data);
};

export const register = (data) => {
  return api.post("/auth/register?scope=" + data.scope, data);
};

export const getFacilities = () => {
  return api.get("/facilities");
};

export const login = (data) => {
  return api.post("/auth/login?scope=" + data.scope, data);
};

export const logout = (data) => {
  return api.post("/auth/logout", data);
};

export const refreshToken = (data) => {
  return api.post("/auth/refresh", data);
};

export const postNotificationToken = (data) => {
  return api.post(`/users/${data.slug}/notification_tokens`, {
    token: data.token,
  });
};

export const checkVersion = () => {
  return api.get("/version?query=app");
};
