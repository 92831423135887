import React, { useState } from "react";
import {
  Text,
  View,
  Dimensions,
  Platform,
  Image,
  ScrollView,
} from "react-native";
import { WebView } from "react-native-webview";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentSetup } from "../../reducers/auth/user";
import ModalButtons from "./ModalButtons";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Rating, AirbnbRating } from "react-native-ratings";
import { normalize } from "../../utils/normalize";
import { Feather, MaterialIcons, FontAwesome } from "@expo/vector-icons";
import Modal from "react-native-modal";
import JobCard from "../JobCard";

const RatingModal = (props) => {
  const dispatch = useDispatch();
  const safeInsets = useSafeAreaInsets();
  const { isDoctor } = useSelector((state) => state.user);
  const isRating =
    props.ratingModalData[props.currentRatingIndex]
      ?.job_application_status_code === 4 || props.fromInbox;
  const isConvert = false;
  const [rating, setRating] = useState(3);
  const ratingText = [
    "Needs improvement",
    "Satisfactory",
    "Average",
    "Good",
    "Exceptional",
  ];
  const ratingCompleted = (val) => {
    setRating(val);
  };
  const userData = props.userQuery?.data?.data;
  return (
    <Modal
      isVisible={props.isVisible}
      backdropTransitionOutTiming={0}
      animationIn={"fadeIn"}
      animationOut={"fadeOut"}
    >
      <View
        style={{
          backgroundColor: "white",
          borderRadius: 24,

          width:
            Dimensions.get("window").width > 480
              ? 480
              : Dimensions.get("window").width - 40,
          alignSelf: "center",
        }}
      >
        <ScrollView
          style={{
            height: isRating ? Dimensions.get("window").height / 1.5 : null,
          }}
        >
          <View style={{ ...styles.headerContainer }}>
            <Feather name={isRating ? "star" : "alert-circle"} size={24} />

            <View style={styles.header}>
              <Text style={styles.headerName}>
                {isRating
                  ? `Job Rating`
                  : isConvert
                  ? "Convert to Urgent Job"
                  : `Check-in for doctor`}
              </Text>
            </View>
            {isRating ? (
              <>
                <Text style={{ marginTop: 12, marginBottom: 14 }}>
                  Rate the service provided by the doctor to help them improve
                  and let others know what you think.
                </Text>
              </>
            ) : (
              <Text style={{ marginVertical: 10 }}>
                {isConvert
                  ? `The following job starts today and has no applicants. Do you want to convert to an Urgent job? `
                  : `Looks like there’s no check-in from the doctor! \n\nThey might be on the way or at your facility, please call or text them. \n\nClick ‘YES’ to check-in on behalf of the doctor or ‘NO’ to cancel the shift.`}
              </Text>
            )}
            {userData ? (
              <View
                style={{
                  alignItems: "center",
                  marginVertical: 0,
                }}
              >
                <Image
                  source={{
                    uri:
                      "data:image/jpg;base64," +
                      decodeURIComponent(userData.profile_image),
                  }}
                  style={{
                    height: 60,
                    width: 60,
                    marginVertical: 10,
                    borderRadius: 100,
                  }}
                />
                <View style={{ alignItems: "center" }}>
                  <Text style={{ fontSize: normalize(12), color: "grey" }}>
                    {userData.is_specialist_job
                      ? "Specialist"
                      : "General Practitioner"}
                  </Text>
                  <Text
                    style={{ fontWeight: "bold", fontSize: normalize(15) }}
                  >{`${userData.first_name} ${userData.last_name[0]}.`}</Text>
                </View>
              </View>
            ) : null}
          </View>
          {isRating ? (
            <>
              <Rating
                jumpValue={1}
                startingValue={3}
                minValue={1}
                fractions={0}
                onFinishRating={ratingCompleted}
                style={{ paddingVertical: 10 }}
              />
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "orange",
                  marginBottom: 24,
                }}
              >
                {ratingText[rating - 1]}
              </Text>
            </>
          ) : (
            <></>
          )}
          <View style={{ marginBottom: 8 }}>
            <JobCard
              clinicJobList
              isDoctor={false}
              data={props.ratingModalData[props.currentRatingIndex]}
              fromHome
            />
          </View>
          {isRating ? (
            <>
              {[
                {
                  title: "Professionalism",
                  content: "A good communicator, knowledgeable and is punctual",
                },
                {
                  title: "Personality",
                  content:
                    "Warm personality, detailed oriented, responsible and motivated",
                },
                {
                  title: "Quality of service",
                  content:
                    "Able to carry out work required according to job scope",
                },
              ].map((val) => (
                <View style={{ marginBottom: 15, marginHorizontal: 20 }}>
                  <Text style={{ fontWeight: "bold" }}>{val.title}</Text>
                  <Text>{val.content}</Text>
                </View>
              ))}
            </>
          ) : null}
          <View style={{ marginBottom: 8 }} />
        </ScrollView>

        <ModalButtons
          leftLabel={isRating ? "Later" : "No"}
          rightLabel={isRating ? "Rate" : "Yes"}
          containerStyles={{ marginTop: 0 }}
          onCancel={isRating || isConvert ? props.onHide : props.cancelJob}
          onConfirm={() => {
            isRating
              ? props.rateDoctor(rating)
              : isConvert
              ? props.convertJob()
              : props.completeJob();
          }}
        />
      </View>
    </Modal>
  );
};
const styles = {
  container: {
    flex: 1,
    width: "100%",
    alignSelf: "center",
  },
  headerContainer: {
    margin: 30,
    marginTop: 40,
    marginBottom: 4,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerName: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginTop: 10,
  },
};
export default RatingModal;
