import React from "react";
import { Text, View, Dimensions, Alert } from "react-native";
import { WebView } from "react-native-webview";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentSetup } from "../../reducers/auth/user";
import { BackButton } from "../../components/all";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const MakePaymentScreen = (props) => {
  const dispatch = useDispatch();
  const safeInsets = useSafeAreaInsets();
  const gotoURL = props.route.params?.gotoURL;
  const hideBackButton = props.route.params?.hideBackButton;

  let source = {};
  let isPDF = true;

  switch (gotoURL) {
    default:
      source = { uri: gotoURL };
      isPDF = false;
      break;
  }

  return (
    <View style={{ flex: 1 }}>
      <BackButton
        ignoreBack
        compact
        onPressBack={() => {
          Alert.alert(
            "Are you sure?",
            "This action will bring you back to the previous page, you will need to restart the payment process.",
            [
              {
                text: "Cancel",
                style: "cancel",
              },
              {
                text: "OK",
                onPress: () => {
                  props.navigation.goBack();
                },
              },
            ]
          );
        }}
      />

      <WebView
        style={{
          ...styles.container,
          marginLeft: isPDF ? 60 : 0,
        }}
        onMessage={(event) => {
          let message = event.nativeEvent.data;

          if (message) {
            let response = JSON.parse(message);
            if (response.fpx_debitAuthCode === "00") {
              Alert.alert(
                "Payment Success",
                "You have successfully made the payment."
              );

              props.navigation.reset({
                index: 0,
                routes: [{ name: "HomeScreen" }],
              });
            } else {
              alert("Oops! " + "Payment failed, please try again.");
              props.navigation.reset({
                index: 0,
                routes: [{ name: "HomeScreen" }],
              });
            }
          }
        }}
        source={source}
        originWhitelist={["*"]}
        scalesPageToFit={false}
      />
    </View>
  );
};
const styles = {
  container: {
    flex: 1,
    width: "100%",
    alignSelf: "center",
  },
};
export default MakePaymentScreen;
