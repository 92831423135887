import { Feather, MaterialIcons, Ionicons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState, useRef } from "react";
import {
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  Image,
  Alert,
  AppState,
  Linking,
  Platform,
  Dimensions,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import {
  BottomModal,
  Centered,
  Row,
  GradientHeader,
  ModalButtons,
  Modal,
  MenuButton,
  ScreenScrollView,
} from "../../../components/all";
import { setLoggedOut, setIsDev } from "../../../reducers/auth/user";
import { resetFields } from "../../../reducers/profile/profile";
import { logout, postNotificationToken } from "../../../services/auth";
import { useMutation, useQuery } from "react-query";
import { startActivityAsync, ActivityAction } from "expo-intent-launcher";
import * as Notifications from "expo-notifications";
import * as Location from "expo-location";
import settings from "../../../constants/lov/settings";
import { normalize } from "../../../utils/normalize";
import { buildVersion } from "../../../../App";
import * as Application from "expo-application";
import * as Device from "expo-device";

const currentVersion = "v" + Application.nativeApplicationVersion;

export default function Settings(props) {
  const dispatch = useDispatch();

  const safeInsets = useSafeAreaInsets();
  const { slug, isDev, isDoctor } = useSelector((state) => state.user);
  const [isTop, setIsTop] = useState(true);

  const [modalVisible, setModalVisible] = useState(false);
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  let toCheckPermission = useRef(false);

  useEffect(() => {
    checkNotificationDisabled();
    let sub = AppState.addEventListener("change", _handleAppStateChange);
    return () => {
      sub.remove();
    };
  }, []);
  const _handleAppStateChange = (nextAppState) => {
    if (nextAppState === "active") {
      toCheckPermission.current && checkAllPermissions();
    }
  };
  const sendToken = useMutation(postNotificationToken, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {},
  });
  const checkAllPermissions = () => {
    toCheckPermission.current = false;
    getNotificationToken().then((token) => {
      if (token)
        sendToken.mutate({
          slug,
          token,
        });
    });
    getLocation().then((location) => {
      // setCurrentLocation(location);
    });
  };

  async function checkNotificationDisabled(isDev) {
    let token;
    if (true) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      if (existingStatus !== "granted") {
        Alert.alert(
          "Enable Notifications",
          "Please enable notifications in Settings to receive updates.",
          [
            {
              text: "Cancel",
              onPress: () => {
                // console.log("Cancel Pressed");
              },
              style: "cancel",
            },
            {
              text: "Settings",
              onPress: () => {
                toCheckPermission.current = true;
                openPermissionSettings();
              },
            },
          ]
        );
      }
    }

    return token;
  }

  async function getNotificationToken() {
    let token;
    if (Device.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      token =
        existingStatus === "denied"
          ? ""
          : (await Notifications.getExpoPushTokenAsync({
            'projectId': 'fb1217de-321c-4a1d-befc-c8ebb96b032c',
          })).data;
      console.log("notif check in here", existingStatus);
      if (existingStatus !== "granted") {
        checkNotificationDisabled();
        return false;
      }
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    return token;
  }

  async function getLocation() {
    Location.requestForegroundPermissionsAsync();

    let location = await Location.getCurrentPositionAsync({
      accuracy: Location.Accuracy.Lowest,
    });
    return location;
  }

  const logoutUser = useMutation(logout, {
    onError: (error) => {
      dispatch(resetFields());
      dispatch(setLoggedOut());
      props.navigation.replace("AuthScreen");
    },
    onSuccess: (data) => {
      dispatch(resetFields());
      dispatch(setLoggedOut());
      // queryClient.clear();
      props.navigation.replace("AuthScreen");
    },
  });

  const openPermissionSettings = () => {
    if (Platform.OS === "ios") {
      Linking.openURL("app-settings:");
    } else {
      Linking.openSettings();
      // startActivityAsync(ActivityAction.APPLICATION_DETAILS_SETTINGS, {
      //   data: "package:" + pkg,
      // });
      // startActivityAsync(ActivityAction.APPLICATION_DETAILS_SETTINGS);
    }
  };

  return (
    <>
      <GradientHeader />
      <MenuButton isTop={isTop} navigation={props?.navigation} />

      <ScrollView
        scrollEventThrottle={24}
        onScroll={(event) => {
          if (event.nativeEvent.contentOffset.y > 40) {
            setIsTop(false);
          } else {
            setIsTop(true);
          }
        }}
        style={{
          backgroundColor: "rgba(0,0,0,0)",
          width:
            Dimensions.get("window").width > 780
              ? 780
              : Dimensions.get("window").width,
          alignSelf: "center",
        }}
        showsVerticalScrollIndicator={false}
      >
        <>
          <View
            style={{
              ...styles.headerContainer,
              zIndex: 100,
              paddingTop: safeInsets.top + 40,
            }}
          >
            <Feather name="user" size={24} />

            <View style={styles.header}>
              <Text style={styles.headerName}>{`Account Settings`}</Text>
            </View>
          </View>
          <Image
            source={require("../../../assets/images/wave.png")}
            style={{ width: "100%", zIndex: -10, marginTop: -100 }}
          />
        </>
        <View
          style={{
            padding: 20,
            backgroundColor: "white",
            paddingTop: Platform.OS === "web" ? 100 : 20,
            borderTopLeftRadius: Platform.OS === "web" ? 32 : 0,
            borderTopRightRadius: Platform.OS === "web" ? 32 : 0,
          }}
        />
        <View
          style={{
            backgroundColor: "white",
            paddingHorizontal: 12,

            minHeight: "100%",
          }}
        >
          {settings.length > 0
            ? settings.map((item, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      if (item?.navigate) {
                        if (item?.navigate === "Permission") {
                          toCheckPermission.current = true;
                          openPermissionSettings();
                        } else {
                          props.navigation.navigate(item.navigate, {
                            gotoURL: item?.url,
                            slug,
                          });
                        }
                      }
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "white",
                        paddingVertical: 18,
                        paddingLeft: 10,
                        marginHorizontal: 20,
                        borderTopWidth: index === 0 ? 0 : 1,
                        borderColor: "rgba(0,0,0,0.05)",
                      }}
                    >
                      <Row style={{ justifyContent: "space-between" }}>
                        <Row centered>
                          <MaterialIcons
                            name={item.icon}
                            size={18}
                            color={"rgba(0,0,0,0.8)"}
                          />
                          <View style={{ marginLeft: 20 }}>
                            <Text
                              style={{
                                fontSize: normalize(14),
                                fontWeight: "bold",
                                color: "rgba(0,0,0,0.8)",
                              }}
                            >
                              {item.title}
                            </Text>
                          </View>
                        </Row>
                        <Text
                          style={{
                            color: "rgba(0,0,0,0.4)",
                            fontSize: normalize(12),
                          }}
                        >
                          <Feather name="chevron-right" size={24} />
                        </Text>
                      </Row>
                    </View>
                  </TouchableOpacity>
                );
              })
            : null}

          <View style={{ margin: 50 }} />
          <View style={{ alignItems: "center" }}>
            <TouchableOpacity
              onPress={() => {
                setLogoutModalVisible(true);
              }}
            >
              <Text style={{ fontWeight: "bold", color: "#FF4B4B" }}>
                Log out from this account
              </Text>
            </TouchableOpacity>
          </View>
          <Centered>
            <TouchableOpacity
              onLongPress={() => {
                dispatch(setIsDev(!isDev));
              }}
            >
              <Text
                style={{
                  color: "rgba(0,0,0,0.4)",
                  fontSize: normalize(10),
                  marginTop: 30,
                }}
              >
                {Platform.OS === "web" ? (
                  `v${buildVersion}`
                ) : (
                  <>
                    {currentVersion} ({buildVersion}) {isDev ? `- dev` : ``}
                  </>
                )}
              </Text>
            </TouchableOpacity>
          </Centered>
          <View style={{ padding: 80 }} />
        </View>
      </ScrollView>
      <Modal
        isVisible={logoutModalVisible}
        onBackdropPress={() => setLogoutModalVisible(false)}
      >
        <View style={{ alignItems: "center", marginBottom: 12 }}>
          <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
        </View>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: normalize(16),
            textAlign: "center",
          }}
        >
          Logout Account
        </Text>
        <Text
          style={{
            textAlign: "center",
            marginTop: 10,
            marginBottom: 24,
            marginHorizontal: 24,
          }}
        >
          You will be logged out from this account and redirected to the login
          screen.
        </Text>
        <ModalButtons
          onCancel={() => setLogoutModalVisible(false)}
          onConfirm={() => {
            setLogoutModalVisible(false);
            setTimeout(() => {
              logoutUser.mutate();
            }, 1000);
          }}
        />
      </Modal>
      <BottomModal visible={modalVisible} hide={() => setModalVisible(false)} />
    </>
  );
}

const styles = {
  title: {
    margin: 18,
  },
  jobsAvailableTitle: {
    fontSize: 24,
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 18,
    marginBottom: 10,
  },
  filterText: {
    fontWeight: "bold",
  },
  divider: {
    alignSelf: "center",
    width: "90%",
    height: 0.5,
    backgroundColor: "#eee",
    marginBottom: 24,
  },
  sortContainer: {
    marginTop: -18,
    alignItems: "flex-end",
    marginRight: 18,
  },
  sortByText: {
    fontSize: 12,
    marginBottom: 2,
  },
  sortText: {
    fontWeight: "bold",
  },
  line: {
    marginTop: 20,
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  lineTitle: {
    padding: 10,
    backgroundColor: "white",
    marginTop: -18,
  },
  headerContainer: {
    margin: 30,
    marginTop: 40,
    marginBottom: 4,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerName: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginTop: 10,
  },
};
