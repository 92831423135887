import {
  Feather,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import BottomSheet, { BottomSheetScrollView } from "@gorhom/bottom-sheet";
import { LinearGradient } from "expo-linear-gradient";
import moment from "moment";
import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  Dimensions,
  Image,
  Linking,
  Text,
  TouchableOpacity,
  View,
  Alert,
  Pressable,
  Platform,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import MapView, { Marker, PROVIDER_GOOGLE } from "react-native-maps";

import {
  ApplyJobModal,
  CancelRequestModal,
  ChangeStatusModal,
  Centered,
  Row,
  Status,
  Loading,
  CancelJobModal,
  Card,
  GradientHeader,
  BackButton,
  VerificationModal,
} from "../../components/all";
import colors from "../../constants/colors";
import hospitals from "../../constants/stub/hospitals.json";
import {
  addFavoriteJob,
  removeFavoriteJob,
} from "../../reducers/jobs/favoriteJobs";
import { useQuery, useMutation } from "react-query";
import {
  getJob,
  getJobFacilities,
  applyJob,
  approveJob,
  rejectJob,
  getApplication,
  submitCancellation,
  rejectCancellation,
  acceptCancellation,
  getCancellationList,
} from "../../services/job";
import { useIsFocused, useFocusEffect } from "@react-navigation/native";
import queryClient from "../../utils/queryClient";
import * as Haptics from "expo-haptics";
import { gestureHandlerRootHOC } from "react-native-gesture-handler";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";
import { normalize } from "../../utils/normalize";
import { getOverview } from "../../services/profile";

function Jobs(props) {
  const safeInsets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const currentJob = props.route.params?.data;
  const fromJobTab = props.route.params?.fromJobTab;
  const clinicView = props.route.params?.clinicView;
  const jobCount = props.route.params?.jobCount;
  const status = props.route.params?.status;
  const isScanned = props.route.params?.isScanned;
  const allowEdit = jobCount < 1;

  const { favorites } = useSelector((state) => state.favoriteJobs);
  const { isDoctor } = useSelector((state) => state.user);

  const bottomSheetRef = useRef(null);
  const scrollViewRef = useRef();
  const SNAPPOINTS = ["50%", "85%"];
  const { data: jobFacilitiesQuery, refetch: refetchFacilities } = useQuery(
    "jobFacilities",
    () => getJobFacilities(currentJob.uuid)
  );
  const {
    data: profileQuery,
    refetch,
    isLoading: profileLoading,
  } = useQuery("overview", getOverview);
  let profileInfo = profileQuery?.data?.data;

  const {
    data: jobQuery,
    refetch: refetchJob,
    isLoading,
  } = useQuery("job", () => getJob(currentJob.uuid), {
    enabled: !!fromJobTab || clinicView,
  });

  const {
    data: cancelQuery,
    refetch: cancelRefetch,
    isLoading: cancelLoading,
  } = useQuery("cancellationList", getCancellationList, {
    enabled: currentJob?.status === -3,
  });

  const onApplyJob = useMutation(
    currentJob?.status === 1 ? approveJob : applyJob,
    {
      onError: (error) => {
        // setVerifying(false);
        setShowConfirmModal(false);
        setTimeout(() => {
          alert("Oops! " + error.response.data.message);
        }, 400);
      },
      onSuccess: (data) => {
        setShowConfirmModal(false);
        props.navigation.navigate("Home");
      },
    }
  );

  const onRejectJob = useMutation(rejectJob, {
    onError: (error) => {
      // setVerifying(false);
      setShowConfirmModal(false);
      setTimeout(() => {
        alert("Oops! " + error.response.data.message);
      }, 400);
    },
    onSuccess: (data) => {
      setShowConfirmModal(false);
      props.navigation.navigate("Home");
    },
  });

  const [facilityData, setFacilityData] = useState(null);
  const [jobFacilityData, setJobFacilityData] = useState(null);

  const [snapPoint, setSnapPoint] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCancelJobModal, setShowCancelJobModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelError, setCancelError] = useState();
  const [cancelData, setCancelData] = useState();
  const [isRaiseToAdmin, setIsRaiseToAdmin] = useState(false);
  const handleSheetAnimate = useCallback((fromIndex, toIndex) => {
    // eslint-disable-next-line no-console
    setSnapPoint(toIndex);
  }, []);

  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isRecentlyApproved, setRecentlyApproved] = useState(false);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);

  const [disablePendingCancellation, setDisablePendingCancellation] =
    useState(true);
  useEffect(() => {
    if (jobFacilitiesQuery?.data) {
      setJobFacilityData(jobFacilitiesQuery.data.data);
    }
  }, [jobFacilitiesQuery]);

  let jobData = jobQuery?.data?.data;

  useEffect(() => {
    let receivedCancellations = cancelQuery?.data?.data?.received;
    if (receivedCancellations?.length > 0) {
      receivedCancellations.map((cancellation) => {
        if (cancellation.job_uuid === currentJob.uuid) {
          setCancelData(cancellation);
          setTimeout(() => {
            setShowCancelJobModal(true);
            setDisablePendingCancellation(false);
          }, 1000);
        }
      });
    }
  }, [cancelQuery]);

  useEffect(() => {
    if (isFocused) {
      // refetch();
      if (jobQuery?.data) {
        fromJobTab && refetchJob();
        refetchFacilities();
      }
    }
  }, [isFocused]);

  useEffect(() => {
    console.log("check current job", currentJob);
    if (currentJob?.status === 3 && isDoctor) {
      setRecentlyApproved(true);
    } else {
      setRecentlyApproved(false);
    }
  }, [currentJob]);

  useEffect(() => {
    // scroll to bottom of the page
    if (isRecentlyApproved) {
      setTimeout(() => {
        if (scrollViewRef.current) {
          scrollViewRef.current.scrollToEnd({ animated: true });
          bottomSheetRef.current?.snapToIndex(1);
        }
      }, 1000);
    }
  }, [isRecentlyApproved]);

  const diffHours = Math.abs(
    moment(jobData?.start_timestamp).diff(
      moment(jobData?.end_timestamp),
      "hours"
    ) || 1
  );

  const onSubmitCancellation = useMutation(submitCancellation, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      Alert.alert(
        "Cancellation Submitted",
        "You have submitted a cancellation for this job"
      );
    },
  });
  const onAcceptCancellation = useMutation(acceptCancellation, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      Alert.alert(
        "Cancellation Accepted",
        "You have accepted the cancellation for this job"
      );
    },
  });

  const onRejectCancellation = useMutation(rejectCancellation, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      Alert.alert(
        "Cancellation Rejected",
        "You have rejected the cancellation for this job"
      );
    },
  });

  const onPressApplyJob = () => {
    queryClient.removeQueries("jobs");

    onApplyJob.mutate({
      uuid:
        currentJob?.status === 1
          ? currentJob?.job_application.uuid
          : jobData?.uuid,
    });
  };

  const onPressRejectJob = () => {
    Alert.alert("Are you sure?", "Do you want to reject this job?", [
      {
        text: "Cancel",
        style: "cancel",
      },
      {
        text: "OK",
        onPress: () => {
          onRejectJob.mutate({
            uuid: currentJob?.job_application.uuid,
          });
        },
      },
    ]);
  };

  const onPressSubmitCancellation = () => {
    setCancelError(false);

    if (cancelReason) {
      if (isRaiseToAdmin) {
        onRejectCancellation.mutate({
          uuid: cancelData.id,
          data: {
            reject_reason: cancelReason,
          },
        });
      } else {
        onSubmitCancellation.mutate({
          uuid: currentJob.uuid,
          data: {
            request_reason: cancelReason,
          },
        });
      }

      setShowCancelModal(false);
      props.navigation.goBack();
    } else {
      setCancelError(true);
    }
  };

  const onPressRejectCancellation = () => {
    setIsRaiseToAdmin(true);
    setShowCancelJobModal(false);
  };

  const onPressAcceptCancellation = () => {
    onAcceptCancellation.mutate({
      uuid: cancelData.id,
    });
    setShowCancelJobModal(false);
    props.navigation.goBack();
  };

  const sectionTitle = (text, icon) => (
    <Row centered style={{ marginTop: 48, marginBottom: 8 }}>
      <Feather name={icon} color={colors.tertiary} />
      <Text style={styles.detailTitle}>{text}</Text>
    </Row>
  );

  const checkStatus = (stat) => {
    let status = 0;
    if (stat === 3 && isScanned === true) {
      status = 6;
    } else {
      status = stat;
    }
    return status;
  };
  const buttonWrapper = (child) => {
    if (Platform.OS === "web") {
      return (
        <View
          style={{
            flex: 1,
            width:
              Dimensions.get("window").width > 780
                ? 780
                : Dimensions.get("window").width,
            alignSelf: "center",
          }}
        >
          {child}
        </View>
      );
    } else {
      return <>{child}</>;
    }
  };
  return (
    <>
      <GradientHeader />

      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* {buttonWrapper(
          <View
            style={{
              position: "absolute",
              top: safeInsets.top + 20,
              left: 20,
              zIndex: 1000,
            }}
          >
            <Pressable
              onPressIn={() => {
                if (Platform.OS === "ios") {
                  Haptics.selectionAsync();
                }
              }}
              onPress={() => {
                queryClient.removeQueries("job");
                queryClient.removeQueries("cancellationList");
                props.navigation.goBack();
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  justifyContent: "center",
                  paddingHorizontal: 14,
                  paddingRight: 18,
                  borderRadius: 14,
                  paddingVertical: 10,
                }}
              >
                <Row>
                  <Ionicons name="arrow-back" size={14} color="black" />
                  <Text style={{ fontWeight: "bold", marginLeft: 4 }}>
                    Back
                  </Text>
                </Row>
              </View>
            </Pressable>
          </View>
        )} */}
        {jobData ? (
          <MapView
            provider={PROVIDER_GOOGLE}
            mapPadding={{ top: 0, left: 0, right: 0, bottom: 400 }}
            initialRegion={{
              latitude:
                Platform.OS === "web"
                  ? parseFloat(jobData?.clinic?.latitude) - 0.15
                  : parseFloat(jobData?.clinic?.latitude),
              latitudeDelta: isRecentlyApproved ? 0.005 : 0.15873233892007388,
              longitude: parseFloat(jobData?.clinic?.longitude),
              longitudeDelta: isRecentlyApproved ? 0.005 : 0.08051385826593105,
            }}
            zoomEnabled={isRecentlyApproved}
            style={{
              zIndex: -2,
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          >
            {isRecentlyApproved ? (
              <MapView.Marker
                coordinate={{
                  latitude: parseFloat(jobData?.clinic?.latitude),
                  longitude: parseFloat(jobData?.clinic?.longitude),
                }}
              >
                <Image
                  source={require("../../assets/images/clinic-icon.png")}
                  style={{ width: 60, height: 90 }}
                />
              </MapView.Marker>
            ) : (
              <MapView.Marker
                coordinate={{
                  latitude: parseFloat(jobData?.clinic?.latitude),
                  longitude: parseFloat(jobData?.clinic?.longitude),
                }}
                icon={() => (
                  <View style={{ borderRadius: 100, overflow: "hidden" }}>
                    <LinearGradient
                      // Button Linear Gradient
                      style={{
                        padding: 15,
                        alignItems: "center",
                        borderRadius: 0,
                      }}
                      colors={[
                        "rgba(234,0,137,0.5)",
                        "rgba(242, 49, 123, 0.5)",
                        "rgba(248, 100, 108, 0.5)",
                      ]}
                    >
                      <View
                        style={{
                          width: 120,
                          height: 120,
                          backgroundColor: "transparent",
                        }}
                      ></View>
                    </LinearGradient>
                  </View>
                )}
              >
                <View style={{ borderRadius: 100, overflow: "hidden" }}>
                  <LinearGradient
                    // Button Linear Gradient
                    style={{
                      padding: 15,
                      alignItems: "center",
                      borderRadius: 0,
                    }}
                    colors={[
                      "rgba(234,0,137,0.5)",
                      "rgba(242, 49, 123, 0.5)",
                      "rgba(248, 100, 108, 0.5)",
                    ]}
                  >
                    <View
                      style={{
                        width: 120,
                        height: 120,
                        backgroundColor: "transparent",
                      }}
                    ></View>
                  </LinearGradient>
                </View>
              </MapView.Marker>
            )}
          </MapView>
        ) : null}
        <ScrollView
          focusHook={useFocusEffect}
          ref={scrollViewRef}
          showsVerticalScrollIndicator={false}
          style={{
            width: Dimensions.get("window").width,
            marginTop: 0,
            width:
              Dimensions.get("window").width > 780
                ? 780
                : Dimensions.get("window").width,
            alignSelf: "center",
          }}
        >
          <View
            style={{
              marginHorizontal: 0,
              paddingTop: 20,
              marginTop: Dimensions.get("window").height / 2.4,
              backgroundColor: "white",
              borderTopLeftRadius: 32,
              borderTopRightRadius: 32,
              minHeight: "85%",
            }}
          >
            <BackButton
              onPress={() => {
                queryClient.removeQueries("job");
                queryClient.removeQueries("cancellationList");
                props.navigation.goBack();
              }}
            />
            <View style={{ marginBottom: 4, marginHorizontal: 30 }}>
              <Row
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    marginRight: 8,
                  }}
                >
                  {jobData?.is_emergency ? (
                    <Text
                      style={{
                        ...styles.detailTitle,
                        marginBottom: 0,
                        color: "#EC008C",
                        marginTop: 0,
                        marginLeft: 0,
                      }}
                    >
                      URGENT JOB
                    </Text>
                  ) : null}
                  {jobData?.title ? (
                    <Text
                      style={{
                        fontSize: normalize(24),
                        marginTop: 4,
                        fontWeight: "bold",
                      }}
                    >
                      {jobData?.title}
                    </Text>
                  ) : (
                    <ContentLoader
                      speed={2}
                      width={340}
                      height={84}
                      viewBox="0 0 340 10"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                      {...props}
                    >
                      <Rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
                      <Rect
                        x="76"
                        y="0"
                        rx="3"
                        ry="3"
                        width="140"
                        height="11"
                      />
                    </ContentLoader>
                  )}
                  <Text
                    style={{
                      fontSize: normalize(12),
                      marginTop: 8,
                      color: "rgba(0,0,0,0.4)",
                    }}
                  >
                    Posted {moment(jobData?.created_at).fromNow()}
                  </Text>
                </View>
                <View style={{ alignItems: "center" }}>
                  {fromJobTab && (
                    <Pressable
                      onPressIn={() => {
                        if (Platform.OS === "ios") {
                          Haptics.selectionAsync();
                        }
                      }}
                      onPress={() => {
                        if (favorites.includes(jobData?.uuid)) {
                          dispatch(removeFavoriteJob(jobData?.uuid));
                        } else {
                          dispatch(addFavoriteJob(jobData?.uuid));
                        }
                      }}
                    >
                      <Row
                        style={{
                          marginTop: -14,
                          alignItems: "center",
                          borderColor: "rgba(0,0,0,0.2)",
                          padding: 4,
                          borderRadius: 20,
                          paddingHorizontal: 10,
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesome
                          name={
                            favorites.includes(jobData?.uuid)
                              ? "star"
                              : "star-o"
                          }
                          size={18}
                          color={
                            favorites.includes(jobData?.uuid)
                              ? "#F7971E"
                              : "rgba(0,0,0,0.8)"
                          }
                        />
                      </Row>
                    </Pressable>
                  )}
                </View>
              </Row>
            </View>

            <View style={{ marginHorizontal: 30 }}>
              {sectionTitle("DESCRIPTION", "alert-circle")}
              <View style={styles.divider} />

              <Text style={{}}>{jobData?.description}</Text>

              {sectionTitle("REQUIREMENTS", "user")}
              <View style={styles.divider} />

              {jobFacilityData
                ?.filter((val) => {
                  return val.category === "GP";
                })
                .map((speciality, index) => (
                  <>
                    {index === 0 && (
                      <Text
                        style={{
                          fontWeight: "bold",
                          marginBottom: 8,
                          fontSize: normalize(11),
                          marginTop: 12,
                        }}
                      >
                        GENERAL PRACTITIONER
                      </Text>
                    )}
                    <Text style={{ margin: 6 }}>
                      - {speciality.doctor_title}
                    </Text>
                  </>
                ))}

              {jobFacilityData
                ?.filter((val) => {
                  return val.category !== "GP";
                })
                .map((speciality, index) => (
                  <>
                    {index === 0 && (
                      <Text
                        style={{
                          fontWeight: "bold",
                          marginBottom: 8,
                          fontSize: normalize(11),
                          marginTop: 12,
                        }}
                      >
                        SPECIALIST
                      </Text>
                    )}
                    <Text style={{ margin: 6 }}>
                      - {speciality.doctor_title}
                    </Text>
                  </>
                ))}

              {sectionTitle("EARNINGS", "credit-card")}
              <View style={styles.divider} />

              <Text style={{ fontWeight: "bold", marginBottom: 10 }}>
                RM{(jobData?.price / diffHours).toFixed(2)} per hour
              </Text>
              <Text>
                Total: RM{jobData?.price}{" "}
                <Text style={{ color: "grey" }}>({diffHours} hours)</Text>
              </Text>

              {sectionTitle("DATE & TIME", "clock")}

              <View style={styles.divider} />
              {moment(jobData?.start_timestamp).format("DD") !==
              moment(jobData?.end_timestamp).format("DD") ? (
                <Row centered>
                  <View>
                    <Text
                      style={{
                        fontSize: normalize(14),
                        fontWeight: "bold",
                        marginBottom: 4,
                      }}
                    >
                      {moment(jobData?.start_timestamp).format("MMMM Do")}
                    </Text>
                    <Text style={{ fontSize: normalize(14) }}>
                      {moment(jobData?.start_timestamp).format("h:mm A")}
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{ fontSize: normalize(18), marginHorizontal: 12 }}
                    >
                      ~
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={{
                        fontSize: normalize(14),
                        fontWeight: "bold",
                        marginBottom: 4,
                      }}
                    >
                      {moment(jobData?.end_timestamp).format("MMMM Do")}
                    </Text>
                    <Text style={{ fontSize: normalize(14) }}>
                      {moment(jobData?.end_timestamp).format("h:mm A")}
                    </Text>
                  </View>
                </Row>
              ) : (
                <>
                  <Text
                    style={{
                      fontSize: normalize(14),
                      fontWeight: "bold",
                      marginBottom: 8,
                    }}
                  >
                    {moment(jobData?.start_timestamp).format(
                      "dddd, MMMM Do YYYY"
                    )}
                  </Text>
                  <Text style={{ fontSize: normalize(14) }}>
                    {moment(jobData?.start_timestamp).format("h:mm A")} ~{" "}
                    {moment(jobData?.end_timestamp).format("h:mm A")}
                  </Text>
                </>
              )}
            </View>

            <View
              style={{
                backgroundColor: isRecentlyApproved
                  ? "rgba(166, 255, 233, 0.12)"
                  : "white",
                marginTop: 20,
              }}
            >
              <View
                style={{
                  marginHorizontal: 30,
                  marginTop: -20,
                }}
              >
                {sectionTitle("LOCATION", "map-pin")}

                <View style={styles.divider} />

                {!isRecentlyApproved && isDoctor ? (
                  <Text>{jobData?.clinic?.city}</Text>
                ) : (
                  <>
                    <Row centered>
                      <Image
                        source={{
                          uri:
                            "data:image/jpg;base64," +
                            jobData?.clinic?.profile_image,
                        }}
                        style={styles.clinicLogo}
                      />
                      <View>
                        <Text
                          style={{
                            fontWeight: "bold",
                            marginBottom: 4,
                            fontSize: normalize(16),
                          }}
                        >
                          {jobData?.clinic?.name}
                        </Text>
                        <Text>{jobData?.clinic?.address}</Text>
                        <Text>{jobData?.clinic?.city}</Text>
                        <Text>
                          {jobData?.clinic?.postcode} {jobData?.clinic?.state}
                        </Text>
                        <TouchableOpacity
                          onPress={() =>
                            Linking.openURL(
                              `tel:${jobData?.clinic?.phone_number}`
                            )
                          }
                        >
                          <Row centered style={{ marginTop: 8 }}>
                            <FontAwesome
                              name="phone"
                              size={normalize(8)}
                              style={{ marginRight: 6 }}
                            />
                            <Text style={{ fontSize: normalize(12) }}>
                              {jobData?.clinic?.phone_number}
                            </Text>
                          </Row>
                        </TouchableOpacity>
                      </View>
                    </Row>

                    <Row>
                      <TouchableOpacity
                        onPress={() =>
                          Linking.openURL(
                            `https://www.google.com/maps/search/?api=1&query=${jobData?.clinic?.latitude},${jobData?.clinic?.longitude}`
                          )
                        }
                      >
                        <View style={styles.openInMapButton}>
                          <Feather name="map-pin" />
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: normalize(12),
                              marginLeft: 8,
                            }}
                          >
                            Open in Google Maps
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() =>
                          Linking.openURL(
                            `https://waze.com/ul?ll=${jobData?.clinic?.latitude},${jobData?.clinic?.longitude}&navigate=yes`
                          )
                        }
                      >
                        <View
                          style={{ ...styles.openInMapButton, marginLeft: 10 }}
                        >
                          <Feather name="map-pin" />
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: normalize(12),
                              marginLeft: 8,
                            }}
                          >
                            Open in Waze
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </Row>
                    {jobData?.note ? (
                      <>
                        {sectionTitle("NOTES", "clipboard")}

                        <View style={styles.divider} />

                        <Text
                          style={{
                            marginTop: 4,
                          }}
                        >
                          {jobData?.note}
                        </Text>
                      </>
                    ) : null}
                  </>
                )}
              </View>
              {!isRecentlyApproved && currentJob?.status >= 0 ? (
                <View style={{ marginTop: 40, marginHorizontal: 12 }}>
                  <Card>
                    <View style={{ margin: 20, alignItems: "center" }}>
                      <View style={{ marginBottom: 14 }}>
                        <Ionicons
                          name="ios-alert-circle-outline"
                          size={24}
                          color={"orange"}
                        />
                      </View>
                      <Text
                        style={{
                          textAlign: "center",
                          color: "rgba(0,0,0,0.6)",
                          marginHorizontal: 20,
                          fontSize: normalize(14),
                        }}
                      >
                        {`Clinic name, address and detailed instructions will be made available here.\nYou will be notified.`}
                      </Text>
                    </View>
                  </Card>
                </View>
              ) : null}
            </View>
            <View style={{ margin: 45 }} />
            {currentJob?.status === 1 ? (
              <Row
                style={{
                  marginLeft: 35,
                  alignItems: "center",
                  marginBottom: 40,
                }}
              >
                <MaterialCommunityIcons name="cancel" size={14} color="red" />
                <Text
                  onPress={() => {
                    onPressRejectJob();
                  }}
                  style={{
                    color: "red",
                    fontSize: normalize(12),
                    marginLeft: 4,
                  }}
                >
                  Reject this Job
                </Text>
              </Row>
            ) : null}

            {[2, 3].includes(currentJob?.status) ? (
              <Row
                style={{
                  marginLeft: 35,
                  alignItems: "center",
                  marginBottom: 40,
                }}
              >
                <MaterialCommunityIcons name="cancel" size={14} color="red" />
                <Text
                  onPress={() => {
                    setShowCancelModal(true);
                  }}
                  style={{
                    color: "red",
                    fontSize: normalize(12),
                    marginLeft: 4,
                  }}
                >
                  Cancel this Job
                </Text>
              </Row>
            ) : null}
          </View>
        </ScrollView>
        {true ? (
          <>
            {buttonWrapper(
              <View
                style={{
                  position: "absolute",
                  bottom: safeInsets.bottom + 2224,
                  right: 24,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    if (clinicView && allowEdit) {
                      props.navigation.navigate("PostJobScreen", {
                        editJobData: {
                          ...jobData,
                          facilities: jobFacilityData?.map((val) => val.slug),
                        },
                        jobData: jobData,
                      });
                    } else if (!clinicView) {
                      if ([1, undefined].includes(currentJob.status)) {
                        if (profileInfo?.is_approved) {
                          setShowConfirmModal(true);
                        } else {
                          setVerifyModalVisible(true);
                        }
                      } else if (currentJob.status === -3) {
                        !disablePendingCancellation &&
                          setShowCancelJobModal(true);
                      } else if (!isDoctor) {
                        setShowStatusModal(true);
                        setSelectedStatus(currentJob?.status);
                      }
                    }
                  }}
                >
                  <Status
                    showBorder
                    isRow
                    status={checkStatus(
                      clinicView ? status : currentJob?.status
                    )}
                    isEdit={clinicView && allowEdit}
                    doctorCount={jobCount}
                  />
                </TouchableOpacity>
              </View>
            )}
          </>
        ) : null}

        <ChangeStatusModal
          isVisible={showStatusModal}
          onHide={() => setShowStatusModal(false)}
          onConfirm={() => setShowStatusModal(false)}
          fromStatus={selectedStatus}
          toStatus={selectedStatus + 1}
        />
        <ApplyJobModal
          isVisible={showConfirmModal}
          onBackdropPress={() => setShowConfirmModal(false)}
          onConfirm={() => onPressApplyJob()}
          onCancel={() => setShowConfirmModal(false)}
          isAcceptJob={currentJob?.status === 1}
        />
        <CancelRequestModal
          isRaiseToAdmin={isRaiseToAdmin}
          isVisible={showCancelModal}
          onBackdropPress={() => setShowCancelModal(false)}
          onConfirm={() => onPressSubmitCancellation()}
          onCancel={() => {
            setShowCancelModal(false);
            setCancelError(false);
            setIsRaiseToAdmin(false);
          }}
          onChangeText={(text) => {
            setCancelReason(text);
            setCancelError(false);
          }}
          error={cancelError}
        />
        <CancelJobModal
          isVisible={showCancelJobModal}
          onBackdropPress={() => setShowCancelJobModal(false)}
          onConfirm={() => onPressAcceptCancellation()}
          onCancel={() => onPressRejectCancellation()}
          reason={cancelData?.request_reason}
          onModalHide={() => {
            if (isRaiseToAdmin) {
              setTimeout(() => {
                setShowCancelModal(true);
              }, 500);
            }
          }}
        />
        <VerificationModal
          isVisible={verifyModalVisible}
          onHide={() => setVerifyModalVisible(false)}
          onConfirm={() => {
            setVerifyModalVisible(false);
          }}
        />
        {/* <Loading visible={isLoading} /> */}
      </View>
    </>
  );
}

const styles = {
  divider: {
    borderBottomWidth: 1,
    borderColor: "rgba(0,0,0,0.05)",
    marginTop: 0,
    marginBottom: 12,
  },
  detailTitle: {
    color: colors.tertiary,
    fontWeight: "bold",
    fontSize: normalize(12),
    alignSelf: "flex-start",
    paddingRight: 8,
    marginLeft: 8,
  },
  openInMapButton: {
    marginTop: 20,
    marginLeft: 6,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: "rgba(0,0,0,0.2)",
    padding: 10,
    alignSelf: "flex-start",
    flexDirection: "row",
  },
  clinicLogo: {
    height: 70,
    width: 70,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: "rgba(0,0,0,0.2)",
    marginRight: 20,
  },
};

export default gestureHandlerRootHOC(Jobs);
