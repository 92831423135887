import { Ionicons, FontAwesome5, Feather } from "@expo/vector-icons";
import * as Haptics from "expo-haptics";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState, useEffect, useRef } from "react";
import {
  Dimensions,
  FlatList,
  Platform,
  Pressable,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  RefreshControl,
  Alert,
  Linking,
  AppState,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";

import {
  ChangeStatusModal,
  Chart,
  Header,
  JobCard,
  Row,
  StatusCard,
  StatusCountCard,
  UpcomingJob,
  Loading,
  GradientHeader,
  Card,
  RatingModal,
  Dropdown,
  FloatingButton,
  ModalButtons,
  MenuButton,
  VerificationModal,
  Modal,
} from "../../components/all";
import homeStub from "../../constants/stub/home.json";
import Styles from "../../constants/styles";
import { useQuery, useMutation } from "react-query";
import {
  getOverview,
  getUser,
  getNotifications,
  getTransactions,
} from "../../services/profile";
import {
  getAllJobs,
  getApplications,
  submitDoctorRating,
  postCompleteJob,
  submitCancellation,
  updateJob,
  getJobFacilities,
  postNotifyDoctors,
  exportTransactions,
} from "../../services/job";

import { getBankDetails } from "../../services/shared";
import colors from "../../constants/colors";
import statusFilter from "../../utils/statusFilter";
import { setNotifCount } from "../../reducers/auth/user";
import { normalize } from "../../utils/normalize";
import { TextInput } from "react-native-gesture-handler";
import { getVersion } from "../../services/auth";
import queryClient from "../../utils/queryClient";
import moment from "moment";
import * as Application from "expo-application";
import SelectDropdown from "react-native-select-dropdown";
import { setFreshRegister } from "../../reducers/profile/profile";
import api from "../../services/api";
import { removeNavParam } from "../../reducers/nav/params";
import { Picker } from "@react-native-picker/picker";

export default function App(props) {
  const safeInsets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const navFilter = props.route.params?.navFilter;
  const { routeName, data: routeParam } = useSelector((state) => state.params);

  const { isDoctor, slug, isDev } = useSelector((state) => state.user);
  const { freshRegister } = useSelector((state) => state.profile);

  const [selectedFilter, setSelectedFilter] = useState(0);
  const [jobData, setJobData] = useState(homeStub.jobs);
  const [selectedSort, setSelectedSort] = useState(0);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [upcomingJob, setUpcomingJob] = useState([]);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [ratingModalData, setRatingModalData] = useState([]);
  const [currentRatingIndex, setCurrentRatingIndex] = useState(0);
  const [isOpenApp, setIsOpenApp] = useState(true);
  const [isShowSort, setShowSort] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const [transactionList, setTransactionList] = useState([]);
  const [currentPageTransaction, setCurrentPageTransaction] = useState(1);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [showPickerModal, setShowPickerModal] = useState(false);
  const notifyDoctors = useMutation(postNotifyDoctors, {
    onError: (error) => {},
    onSuccess: (data) => {},
  });

  const {
    data: transactionsQuery,
    refetch: transactionsRefetch,
    isLoading: transactionsLoading,
    isFetched: transactionsFetched,
  } = useQuery({
    queryKey: ["transactions", { query: currentPageTransaction }],
    queryFn: () =>
      getTransactions({
        page: currentPageTransaction,
      }),
  });
  useEffect(() => {
    if (routeName === "PostJobSuccess") {
      setTimeout(() => {
        notifyDoctors.mutate({
          jobID: routeParam,
        });
        dispatch(removeNavParam());
      }, 1000)
 
    }
  }, [routeName]);
  useEffect(() => {
    if (freshRegister && !isDoctor) {
      dispatch(setFreshRegister(false));
      Alert.alert(
        "Hooray!",
        "You can start posting and filling positions now."
      );
    }
    const subscription = AppState.addEventListener(
      "change",
      _handleAppStateChange
    );
    return () => {
      subscription?.remove();
    };
  }, []);
  let transactionData = transactionsQuery?.data?.data;

  useEffect(() => {
    if (transactionData?.length) {
      let trans = [];
      if (currentPageTransaction > 1) {
        trans = [...transactionList, ...transactionData];
      } else {
        trans = transactionData;
      }

      setTransactionList(trans);
    }
  }, [transactionsQuery]);
  const {
    data: notificationsQuery,
    refetch: notificationsRefetch,
    isLoading: notificationsLoading,
    isFetched: notificationsFetched,
  } = useQuery({
    queryKey: ["notifications", { query: 1 }],
    queryFn: () =>
      getNotifications({
        uuid: slug,
        page: 1,
      }),
    keepPreviousData: true,
  });
  let notificationData = notificationsQuery?.data?.data;

  useEffect(() => {
    if (notificationData) {
      const unreadCount = notificationData.filter(
        (obj) => obj.read === 0
      ).length;
      dispatch(setNotifCount(unreadCount));
    }
  }, [notificationData]);

  const _handleAppStateChange = (nextAppState) => {
    if (nextAppState === "active") {
      queryClient.removeQueries("version");

      refetchVersionQuery();
    }
    if (nextAppState === "background") {
      setIsOpenApp(true);
    }

    appState.current = nextAppState;
    setAppStateVisible(appState.current);
  };

  const {
    data: userQuery,
    refetch: userRefetch,
    isLoading: userLoading,
  } = useQuery(
    "ratingDoctorProfile",
    () =>
      getUser(
        ratingModalData[currentRatingIndex]?.job_applications[0].applicant_slug
      ),
    {
      enabled: !!showRatingModal,
    }
  );

  const { data: jobFacilitiesQuery, refetch: refetchFacilities } = useQuery(
    "jobFacilitiesJob",
    () => getJobFacilities(ratingModalData[currentRatingIndex].uuid),
    {
      enabled: false,
    }
  );

  // const {
  //   data: bankDetailsQuery,
  //   isLoading,
  //   error: bankDetailError,
  // } = useQuery("bankDetail", () => getBankDetails(slug));

  // useEffect(() => {
  //   if (bankDetailError?.response?.status === 404) {
  //     if (isDoctor) {
  //       props.navigation.navigate("BankAccountScreen", {
  //         fromHome: true,
  //       });
  //     }
  //   }
  // }, [bankDetailError]);

  const {
    data: profileQuery,
    refetch: profileRefetch,
    isLoading: profileLoading,
  } = useQuery("overview", getOverview);
  let profileInfo = profileQuery?.data?.data;

  const { data: versionQuery, refetch: refetchVersionQuery } = useQuery(
    "version",
    getVersion
  );
  let currentVersion = versionQuery?.data?.data?.version;

  useEffect(() => {
    if (jobFacilitiesQuery?.data?.data) {
      convertUrgentJob.mutate({
        is_emergency: true,
        uuid: ratingModalData[currentRatingIndex]?.uuid,
        facilities: jobFacilitiesQuery.data.data.map((val) => val.slug),
      });
    }
  }, [jobFacilitiesQuery]);

  useEffect(() => {
    if (profileInfo) {
      if (!profileInfo?.is_approved) setVerifyModalVisible(true);
    }
  }, [profileInfo]);

  useEffect(() => {
    if (currentVersion && !__DEV__ && Platform.OS !== "web") checkUpdate();
  }, [currentVersion]);

  const compareVersion = (old, curr) => {
    const oldArr = old.split(".");
    const currArr = curr.split(".");
    for (let i = 0; i < oldArr.length; i++) {
      if (parseInt(oldArr[i]) < parseInt(currArr[i])) {
        return true;
      }
    }
    return false;
  };

  const checkUpdate = () => {
    const appVersion = Application.nativeApplicationVersion;
    if (compareVersion(appVersion, currentVersion)) {
      Alert.alert(
        "Version Update",
        "There is a newer version released, please update the app to continue",
        [
          {
            text: "Update Now",
            onPress: () => {
              const isAndroid = Platform.OS === "android";
              const doctorURL = isAndroid
                ? "https://play.google.com/store/apps/details?id=com.supermedic.supermedic"
                : "https://apps.apple.com/my/app/supermedic/id6443664230";
              const facilitiesURL = isAndroid
                ? "https://play.google.com/store/apps/details?id=com.supermedic.facilities"
                : "https://apps.apple.com/my/app/supermedic-facilities/id6443664368";
              Linking.openURL(isDoctor ? doctorURL : facilitiesURL);
            },
          },
        ],
        {
          cancelable: false,
        }
      );
    }
  };

  const {
    data: jobsQuery,
    refetch: jobRefetch,
    isLoading: jobLoading,
    isFetched,
  } = useQuery("applications", getApplications, {
    // Refetch the data every second
    refetchInterval: jobs?.length && !__DEV__ ? 5000 : null,
    enabled: isFocused,
  });

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    profileRefetch();
    jobRefetch();
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  }, []);

  const rateDoctor = useMutation(submitDoctorRating, {
    onError: (error) => {
      setShowRatingModal(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (data) => {
      setShowRatingModal(false);
      let nextIndex = currentRatingIndex + 1;
      setCurrentRatingIndex(nextIndex);
      if (nextIndex < ratingModalData.length) {
        setTimeout(() => {
          setShowRatingModal(true);
        }, 2000);
      }
    },
  });

  const onExportTransactions = useMutation(exportTransactions, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (data) => {
      alert("Locum report successfully sent to " + profileInfo.email);
    },
  });

  const completeJob = useMutation(postCompleteJob, {
    onError: (error) => {
      setShowRatingModal(false);

      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (data) => {
      setShowRatingModal(false);
      let nextIndex = currentRatingIndex + 1;
      setCurrentRatingIndex(nextIndex);
      if (nextIndex < ratingModalData.length) {
        setTimeout(() => {
          setShowRatingModal(true);
        }, 2000);
      }
    },
  });

  const convertUrgentJob = useMutation(updateJob, {
    onError: (error) => {
      queryClient.removeQueries("jobFacilitiesJob");
      setShowRatingModal(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (data) => {
      queryClient.removeQueries("jobFacilitiesJob");
      setShowRatingModal(false);
      let nextIndex = currentRatingIndex + 1;
      setCurrentRatingIndex(nextIndex);
      if (nextIndex < ratingModalData.length) {
        setTimeout(() => {
          setShowRatingModal(true);
        }, 2000);
      }
    },
  });

  const cancelJob = useMutation(submitCancellation, {
    onError: (error) => {
      setShowRatingModal(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (data) => {
      setShowRatingModal(false);
      let nextIndex = currentRatingIndex + 1;
      setCurrentRatingIndex(nextIndex);
      if (nextIndex < ratingModalData.length) {
        setTimeout(() => {
          setShowRatingModal(true);
        }, 2000);
      }
    },
  });

  const filters = isDoctor
    ? ["All", "Pending", "Approved", "Cancelled"]
    : ["All", "Unfilled", "Pending", "Payment", "Approved", "Cancelled"];

  useEffect(() => {
    if (jobsQuery?.data) {
      let jobQueryData = jobsQuery?.data?.data
        ? Object.values(jobsQuery?.data?.data)
        : [];
      setJobs(jobQueryData);
      let currentUpcomingJob = jobQueryData
        .sort((a, b) => {
          return new Date(a.start_timestamp) - new Date(b.start_timestamp);
        })
        .filter((val) => {
          return (
            jobFilter(val, isDoctor ? 2 : 3) &&
            moment(val.start_timestamp).diff(moment()) > 0
          );
        })
        ?.slice(0, 1);
      if (!isDoctor && isOpenApp) {
        let completedJob = jobQueryData.filter((val) => {
          return (
            val.job_application_status_code === 4 && val.is_rated === false
          );
        });
        let incompleteJob = jobQueryData.filter((val) => {
          return (
            val.job_application_status_code === 3 &&
            val.is_scanned === false &&
            moment().isAfter(moment(val.end_timestamp))
          );
        });
        // let toConvertJob = jobQueryData.filter((val) => {
        //   return (
        //     val.job_applications?.length < 1 &&
        //     !val.is_emergency &&
        //     moment(val.start_timestamp).format("DD/MM") ===
        //       moment().format("DD/MM")
        //   );
        // });
        if (
          completedJob.length ||
          incompleteJob.length
          // || toConvertJob.length
        ) {
          if (profileInfo?.is_approved) {
            setCurrentRatingIndex(0);
            setIsOpenApp(false);
            setShowRatingModal(true);
            setRatingModalData([
              ...completedJob,
              ...incompleteJob,
              // ...toConvertJob,
            ]);
          }
        }
      }
      if (currentUpcomingJob?.length === 0) {
        currentUpcomingJob = jobQueryData.filter((val) => {
          return jobFilter(val, isDoctor ? 2 : 4);
        });
      }
      setUpcomingJob(currentUpcomingJob);
    }
  }, [jobsQuery]);

  let jobInfo = jobsQuery?.data?.data
    ? Object.values(jobsQuery?.data?.data)
    : [];

  useEffect(() => {
    if (isFocused) {
      if (jobsQuery?.data) jobRefetch();
      if (navFilter) {
        setSelectedFilter(navFilter);
      }
    }
  }, [isFocused]);

  const selectFilter = (index) => {
    setSelectedFilter(index);
    const newData = homeStub.jobs.filter((item) =>
      index === 0 ? true : item.status === filters[index].toLowerCase()
    );
    setJobData(newData);
  };
  let cancelIndex = isDoctor ? 3 : 4;
  const jobFilter = (val, i) => {
    if (i === 0) {
      if (
        (val.job_application_status_code == -2 &&
          moment(val.start_timestamp).isBefore(moment().subtract(1, "days"))) ||
        (val.job_application_status_code == -1 &&
          moment(val.start_timestamp).isBefore(moment().subtract(1, "days"))) ||
        val.job_application_status_code == 4
      ) {
        return false;
      } else {
        return true;
      }
    }
    if (!isDoctor && i === 1) {
      return val.job_applications?.length < 1;
    }
    if (i === clinicIndex(1)) return statusFilter("pending", val);
    if (i === clinicIndex(2))
      return statusFilter(isDoctor ? "approved" : "cancelled", val, isDoctor);
    if (i === clinicIndex(3))
      return statusFilter(isDoctor ? "cancelled" : "approved", val, isDoctor);
    if (i === clinicIndex(cancelIndex))
      return val.job_application_status_code == -2;
  };
  let clinicIndex = (i) => {
    return isDoctor ? i : i + 1;
  };

  return (
    <>
      <GradientHeader />
      <MenuButton isTop={isTop} navigation={props?.navigation} />

      <ScrollView
        contentContainerStyle={{
          marginTop: safeInsets.top,
          backgroundColor: "rgba(0,0,0,0)",
          ...Styles.webContainer,
        }}
        scrollEventThrottle={24}
        onScroll={(event) => {
          if (event.nativeEvent.contentOffset.y > 40) {
            setIsTop(false);
          } else {
            setIsTop(true);
          }
        }}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {!isDoctor && transactionList?.length ? (
          <TouchableOpacity
            onPress={() => {
              let total = transactionList?.length;
              let endYear =
                transactionList[total - 1]?.payment_timestamp.split(" ")[5];
              let startYear =
                transactionList[0]?.payment_timestamp.split(" ")[5];

              setSelectedYear(endYear);

              let years = [];
              for (let i = startYear; i <= endYear; i++) {
                years.push({
                  label: i.toString(),
                  value: i.toString(),
                });
              }
              setShowPickerModal(years);
            }}
          >
            <View
              style={{
                flexDirection: "row",
                margin: 20,
                marginVertical: 0,
                alignItems: "center",
                alignSelf: "flex-end",
              }}
            >
              <Text
                style={{
                  textAlign: "right",
                  color: "white",
                  marginRight: 8,
                }}
              >{`Locum Report`}</Text>
              <Ionicons
                name="document-text-outline"
                size={18}
                color={"white"}
              />
            </View>
          </TouchableOpacity>
        ) : null}
        <View
          style={{
            paddingHorizontal: Dimensions.get("window").width > 780 ? 32 : 0,
            zIndex: 100,
          }}
        >
          <Header
            profileLoading={profileLoading}
            first_name={profileInfo?.first_name}
            last_name={profileInfo?.last_name}
            profile_image={profileInfo?.profile_image}
            data={profileInfo}
            onPress={() => {}}
            fromHome
            onPressVerify={() => {
              setVerifyModalVisible(true);
            }}
          />
        </View>
        {/* Reports */}
        <View
          style={{
            paddingTop: Platform.OS === "web" ? 100 : 0,
            backgroundColor: "white",
            borderTopLeftRadius: Platform.OS === "web" ? 32 : 0,
            borderTopRightRadius: Platform.OS === "web" ? 32 : 0,
            paddingHorizontal: Dimensions.get("window").width > 780 ? 24 : 0,
            ...Styles.shadows,
          }}
        >
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            snapToInterval={Dimensions.get("window").width * 0.7 + 24} //your element width
            contentContainerStyle={{
              alignItems: "center",
              paddingBottom: 20,
              zIndex: 100,
            }}
          >
            <View></View>
            <View
              style={{
                width:
                  Dimensions.get("window").width > 480
                    ? 325
                    : Dimensions.get("window").width * 0.75,
              }}
            >
              <View
                style={{
                  marginTop: 14,
                  marginRight: 8,
                  marginLeft: 8,
                  paddingBottom: 0,
                }}
              >
                <UpcomingJob
                  isDoctor={isDoctor}
                  upcomingIndex={0}
                  jobLoading={jobLoading}
                  data={upcomingJob?.length > 0 ? upcomingJob[0] : false}
                  navigation={props.navigation}
                />
              </View>
            </View>
            {isDoctor ? (
              <Card
                style={{
                  ...styles.statusCardStyle,
                  marginLeft: upcomingJob?.length == 0 ? 16 : 8,
                }}
              >
                <StatusCard
                  isHome
                  icon={<FontAwesome5 name="trophy" />}
                  data={{
                    title: `Current\nRating`,
                    value: isDev ? 4.5 : profileInfo?.average_rating || "n/a",
                  }}
                />
              </Card>
            ) : null}
            <Card
              style={{
                ...styles.statusCardStyle,
                marginLeft: upcomingJob?.length == 0 && !isDoctor ? 16 : 8,
              }}
            >
              <StatusCard
                isHome
                onPress={() => {
                  if (!isDoctor) {
                    selectFilter(2);
                  }
                }}
                icon={<FontAwesome5 name="clipboard-check" />}
                data={{
                  title: isDoctor ? `Completed\nJobs` : `Filled\nJobs`,
                  value: isDoctor
                    ? jobInfo?.filter((val) => statusFilter("completed", val))
                        ?.length || 0
                    : isDev
                    ? 4
                    : jobInfo?.filter((val) =>
                        [null, 0, 1, -3].includes(
                          val.job_application_status_code
                        )
                      )?.length || 0,
                }}
              />
            </Card>
            <Card style={styles.statusCardStyle}>
              <StatusCard
                isHome
                onPress={() => {
                  if (!isDoctor) {
                    selectFilter(1);
                  }
                }}
                icon={
                  isDoctor ? (
                    <FontAwesome5 name="clock" />
                  ) : (
                    <FontAwesome5 name="clipboard" />
                  )
                }
                data={{
                  title: isDoctor ? `Hours\nWorked` : `Unfilled\nJobs`,
                  value: isDoctor
                    ? profileInfo?.hours_worked?.toFixed() || 0
                    : isDev
                    ? 2
                    : jobInfo?.filter(
                        (val) =>
                          [null, 0, 1, -3].includes(
                            val.job_application_status_code
                          ) && val.job_applications?.length < 1
                      )?.length || 0,
                }}
              />
            </Card>
            <Card
              style={{
                ...styles.statusCardStyle,
                marginRight: 16,
              }}
            >
              <StatusCard
                onPress={() => {
                  if (!isDoctor) {
                    selectFilter(5);
                  }
                }}
                icon={
                  isDoctor ? (
                    <FontAwesome5 name="money-bill-alt" />
                  ) : (
                    <Feather name="x-circle" />
                  )
                }
                isHome
                data={{
                  title: isDoctor ? `Total\nEarned` : `Cancelled\nJobs`,
                  value: isDoctor
                    ? profileInfo?.amount_earned || 0
                    : jobInfo?.filter(
                        (val) => val.job_application_status_code === -2
                      )?.length || 0,
                }}
              />
            </Card>
          </ScrollView>

          <View
            style={{
              marginTop: 8,
              width: 400,
              alignSelf: "center",
              overflow: "hidden",
            }}
          >
            <Chart isDoctor={isDoctor} jobs={jobInfo} />
            <StatusCountCard
              isDoctor={isDoctor}
              jobs={jobInfo}
              data={homeStub.statusCount}
              selectFilter={(val) => selectFilter(val)}
            />
          </View>
          <View style={{ marginTop: 14 }} />
          <View style={styles.divider} />

          <Row style={{ justifyContent: "space-between" }}>
            <View>
              <Text
                style={styles.jobsAvailableTitle}
              >{`Job \nApplications`}</Text>
            </View>
            <View
              style={{
                justifyContent: "flex-end",
                paddingRight: 14,
                width: 120,
              }}
            >
              {Platform.OS === "web" ? null : (
                <Text
                  style={{
                    textAlign: "right",
                    marginRight: 14,
                    marginTop: 14,
                    color: "grey",
                    fontSize: normalize(14),
                  }}
                >
                  Sort by
                </Text>
              )}
              <SelectDropdown
                defaultValueByIndex={0}
                data={["Latest", "Oldest", "Urgent"]}
                buttonTextStyle={{
                  fontSize: normalize(14),
                  textAlign: "right",
                  fontWeight: "bold",
                }}
                dropdownStyle={{
                  marginTop: -40,
                  width: 90,
                  borderRadius: 12,
                }}
                rowTextStyle={{
                  fontSize: normalize(14),
                  textAlign: "right",
                  width: "auto",
                  marginRight: 24,
                }}
                selectedRowTextStyle={{
                  fontWeight: "bold",
                }}
                buttonStyle={{
                  alignSelf: "flex-end",
                  marginVertical: -10,
                  backgroundColor: "transparent",
                  width: 100,
                }}
                onSelect={(selectedItem, index) => {
                  setSelectedSort(selectedItem);
                }}
              />
            </View>
          </Row>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <Row style={{ marginHorizontal: 18 }}>
              {filters.map((label, index) => {
                let totalCount = jobInfo?.filter((val) => {
                  return jobFilter(val, index);
                }).length;
                return (
                  <Pressable
                    onPress={() => {
                      if (Platform.OS === "ios") {
                        Haptics.selectionAsync();
                      }
                      selectFilter(index);
                    }}
                  >
                    <View
                      style={{
                        paddingBottom: 2,
                        paddingTop: 14,
                        marginBottom: 20,
                        marginRight: 18,
                      }}
                    >
                      <Text
                        style={{
                          ...styles.filterText,
                          color: index === selectedFilter ? "black" : "#ccc",
                        }}
                      >
                        {label} {totalCount ? `(${totalCount})` : null}
                      </Text>
                    </View>
                  </Pressable>
                );
              })}
            </Row>
          </ScrollView>
          <View>
            {jobInfo
              ?.sort((a, b) => {
                if (["Latest", 0].includes(selectedSort))
                  return moment(a.start_timestamp) - moment(b.start_timestamp);
                if (selectedSort === "Urgent")
                  return a.is_emergency === b.is_emergency
                    ? 0
                    : a.is_emergency
                    ? -1
                    : 1;
                if (selectedSort === "Oldest")
                  return moment(b.start_timestamp) - moment(a.start_timestamp);
              })
              ?.filter((val) => {
                return jobFilter(val, selectedFilter);
              })
              ?.map((item, index) => {
                return (
                  <JobCard
                    isDoctor={isDoctor}
                    index={index}
                    navigation={props.navigation}
                    data={{
                      ...item,
                      status: item.job_application_status_code,
                      // status: -3,
                    }}
                    fromHome
                  />
                );
              })}
            {((isFetched && jobInfo?.length === 0) ||
              jobInfo?.filter((val) => {
                return jobFilter(val, selectedFilter);
              }).length === 0) && (
              <View
                style={{
                  marginVertical: 48,
                  alignItems: "center",
                }}
              >
                <Ionicons
                  name="alert-circle-outline"
                  size={24}
                  color={"rgba(0,0,0,0.3)"}
                />
                <Text style={{ color: "rgba(0,0,0,0.3)", marginTop: 14 }}>
                  There are no jobs applications.
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    props.navigation.navigate(
                      isDoctor ? "Jobs" : "PostJobScreen"
                    );
                  }}
                >
                  <Text style={{ color: colors.primary, margin: 10 }}>
                    {isDoctor ? "Apply a new job" : "Create a new job"}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
            <View style={{ margin: 70 }} />
          </View>
        </View>
      </ScrollView>
      {Platform.OS === "web" ? (
        <FloatingButton
          status={"rejected"}
          onPress={() => props.navigation.navigate("PostJobScreen")}
        >
          <View
            style={{
              borderRadius: 100,
              overflow: "hidden",
            }}
          >
            <LinearGradient
              start={{ x: -0.2, y: 0.2 }}
              // Button Linear Gradient
              colors={[
                "rgb(248, 100, 108)",
                "rgb(242, 49, 123)",
                "rgb(234,0,137)",
              ]}
            >
              <View
                style={{
                  height: 70,
                  width: 70,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Feather name={"plus-circle"} size={30} color="white" />
              </View>
            </LinearGradient>
          </View>
        </FloatingButton>
      ) : null}
      <ChangeStatusModal
        isVisible={showStatusModal}
        onHide={() => setShowStatusModal(false)}
        onConfirm={() => setShowStatusModal(false)}
        fromStatus={selectedStatus}
        toStatus={selectedStatus + 1}
      />
      <RatingModal
        isVisible={showRatingModal}
        ratingModalData={ratingModalData}
        currentRatingIndex={currentRatingIndex}
        setCurrentRatingIndex={setCurrentRatingIndex}
        userQuery={userQuery}
        convertJob={() => {
          refetchFacilities();
        }}
        rateDoctor={(val) => {
          rateDoctor.mutate({
            jobID: ratingModalData[currentRatingIndex].uuid,
            data: {
              rating: val,
            },
          });
        }}
        cancelJob={() => {
          cancelJob.mutate({
            uuid: ratingModalData[currentRatingIndex].uuid,
            data: {
              request_reason: "Doctor did not show up",
            },
          });
        }}
        completeJob={() => {
          completeJob.mutate({
            jobID: ratingModalData[currentRatingIndex].uuid,
          });
        }}
        onHide={() => {
          setShowRatingModal(false);
          let nextIndex = currentRatingIndex + 1;
          setCurrentRatingIndex(nextIndex);
          if (nextIndex < ratingModalData.length) {
            setTimeout(() => {
              setShowRatingModal(true);
            }, 2000);
          }
        }}
      />
      <VerificationModal
        isVisible={verifyModalVisible}
        profileInfo={profileInfo}
        onHide={() => setVerifyModalVisible(false)}
        onConfirm={() => {
          setVerifyModalVisible(false);
        }}
      />
      <Modal
        visible={!!showPickerModal}
        onBackdropPress={() => setShowPickerModal(false)}
        styles={{ width: "auto", padding: 0, ...Styles.shadows }}
      >
        <View
          style={{
            borderWidth: 1,
            borderRadius: 20,
            borderColor: "rgba(0,0,0,0.1)",
          }}
        >
          <Picker
            selectedValue={selectedYear}
            onValueChange={(itemValue, itemIndex) => setSelectedYear(itemValue)}
          >
            {showPickerModal &&
              showPickerModal?.map((pickerData) => (
                <Picker.Item
                  label={pickerData.label}
                  value={pickerData.value}
                />
              ))}
          </Picker>
          <ModalButtons
            onCancel={() => setShowPickerModal(false)}
            onConfirm={() => {
              setShowPickerModal(false);
              Alert.alert(
                "Locum Report " + selectedYear,
                "Are you sure you want to generate the locum report? It will be sent to your email (" +
                  profileInfo.email +
                  ").",
                [
                  {
                    text: "Cancel",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel",
                  },
                  {
                    text: "Confirm",
                    onPress: () => {
                      onExportTransactions.mutate({
                        data: {
                          year: selectedYear,
                        },
                      });
                    },
                  },
                ]
              );
            }}
          />
        </View>
      </Modal>
      {Platform.OS !== "web" && <Loading isVisible={jobLoading} />}
    </>
  );
}

const styles = {
  title: {
    margin: 18,
  },
  jobsAvailableTitle: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 18,
    marginBottom: 10,
  },
  filterText: {
    fontWeight: "bold",
    fontSize: normalize(14),
  },
  divider: {
    alignSelf: "center",
    width: "90%",
    height: 0.5,
    backgroundColor: "#eee",
    marginBottom: 24,
  },
  sortContainer: {
    marginTop: -18,
    alignItems: "flex-end",
    marginRight: 18,
  },
  sortByText: {
    fontSize: normalize(12),
    marginBottom: 2,
  },
  sortText: {
    fontWeight: "bold",
  },
  statusCardStyle: {
    width:
      Dimensions.get("window").width > 420
        ? 110
        : Dimensions.get("window").width / 3.5,
    marginHorizontal: 8,
    alignItems: "center",
    justifyContent: "center",
    minHeight: 130,
    marginTop: 12,
  },
};
