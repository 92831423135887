import PropTypes from "prop-types";
import * as React from "react";
import { View } from "react-native";

const Steps = (props) => {
  let totalPages = Array.from(Array(props.totalPages || 4).keys());
  let currentPage = props.currentPage || 1;
  return (
    <View
      {...props}
      style={{ flexDirection: "row", marginBottom: 24, ...props.style }}
    >
      {totalPages.map((page, index) => {
        return (
          <View
            key={index}
            style={{
              width: 10,
              height: 10,
              marginHorizontal: 8,
              borderRadius: 20,
              backgroundColor:
                currentPage === index + 1 ? "black" : "rgba(0,0,0,0.1)",
            }}
          />
        );
      })}
    </View>
  );
};

Steps.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default Steps;
