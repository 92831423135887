const professions = [
  {
    id: 1,
    name: "Doctor",
    slug: "doctor",
    types: [
      {
        id: 1,
        name: "General Practitioner",
        slug: "general-practitioner",
      },
      {
        id: 1,
        name: "Specialist",
        slug: "specialist",
        isSpecialist: true,
      },
    ]
  },
  {
    id: 5,
    name: "Nurse",
    slug: "nurse",
    types: [
      {
        id: 5,
        name: "Clinical Assistant",
        slug: "clinical-assistant",
      },
      {
        id: 6,
        name: "Staff Nurse",
        slug: "staff-nurse",
      },
      {
        id: 7,
        name: "Senior Staff Nurse",
        slug: "senior-staff-nurse",
      },
      {
        id: 8,
        name: "Head of Nurses",
        slug: "head-of-nurses",
      },
      {
        id: 9,
        name: "Director of Nursing",
        slug: "director-of-nursing",
      },
    ]
  },
  {
    id: 2,
    name: "Dentist",
    slug: "dentist",
    types: [
      {
        id: 2,
        name: "General Dentist",
        slug: "general-dentist",
      },
      {
        id: 2,
        name: "Specialist",
        slug: "specialist",
        isSpecialist: true,
      },
    ]
  },
  {
    id: 3,
    name: `Dental\nAssistant`,
    slug: "dentist-assistant",
  },
  {
    id: 4,
    name: `Medical\nAssistant`,
    slug: "nurse-assistant",
  },
];

export default professions;
