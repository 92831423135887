import PropTypes from "prop-types";
import * as React from "react";
import { View, Text } from "react-native";
import { useWindowDimensions } from "react-native";
import { BarChart } from "react-native-chart-kit";
import { normalize } from "../utils/normalize";
import statusFilter from "../utils/statusFilter";

const Chart = (props) => {
  const window = useWindowDimensions();
  const jobs = props?.jobs || [];
  const noJobs = jobs?.length === 0;
  const status = {
    pending: noJobs
      ? 5
      : jobs.filter((val) => {
          return statusFilter("pending", val);
        }).length,
    cancelled: noJobs
      ? 2
      : jobs.filter((val) => {
          return statusFilter("cancelled", val, props.isDoctor);
        }).length,
    approved: noJobs
      ? 4
      : jobs.filter((val) => {
          return statusFilter("approved", val, props.isDoctor);
        }).length,
    completed: noJobs
      ? 3
      : jobs.filter((val) => {
          return statusFilter("completed", val);
        }).length,
  };
  let data = {
    labels: ["Pending", "Approved", "Cancelled", "Completed"],

    datasets: [
      {
        data: [
          status.pending,
          status.cancelled,
          status.approved,
          status.completed,
        ],
        colors: [
          (opacity = 1) => (noJobs ? "#e8e8e8" : `#FFB454`),
          (opacity = 1) => (noJobs ? "#e8e8e8" : `#FC6767`),
          (opacity = 1) => (noJobs ? "#e8e8e8" : `#59C2FF`),
          (opacity = 1) => (noJobs ? "#e8e8e8" : `#AAD94C`),
        ],
      },
    ],
  };
  return (
    <View style={{ marginLeft: -100, alignItems: "center", marginBottom: -14 }}>
      <BarChart
        data={data}
        height={130}
        width={420}
        withVerticalLabels={false}
        withHorizontalLabels={false}
        withInnerLines={false}
        showBarTops={false}
        fromZero
        withCustomBarColorFromData={true}
        flatColor={true}
        chartConfig={{
          backgroundColor: "#fff",
          backgroundGradientFrom: "#ffffff",
          backgroundGradientTo: "#ffffff",
          color: (opacity = 1) => `rgba(0, 0, 244, ${opacity})`,
          decimalPlaces: 0,
          barRadius: 4,
          barPercentage: 1.75,
        }}
      />
    </View>
  );
};

const styles = {
  cardStyle: {
    paddingBottom: 0,
  },
};

Chart.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default Chart;
