import { Dimensions } from "react-native";

const styles = {
  divider: {
    borderBottomWidth: 1,
    borderColor: "rgba(0,0,0,0.1)",
  },
  shadows: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 14,
    },
    shadowOpacity: 0.05,
    shadowRadius: 15.84,
    elevation: 1,
    zIndex: -8,
  },
  horizontalMargin: {
    marginHorizontal: 12,
  },
  row: {
    flexDirection: "row",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  webContainer: {
    width:
      Dimensions.get("window").width > 780
        ? 780
        : Dimensions.get("window").width,
    alignSelf: "center",
  },
  webSmallContainer: {
    alignSelf: Dimensions.get("window").width < 480 ? "flex-start" : "center",
    width:
      Dimensions.get("window").width > 480
        ? 480
        : Dimensions.get("window").width,
  },
};

export default styles;
