import { Feather, Ionicons } from "@expo/vector-icons";

import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  Platform,
  FlatList,
  Alert,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector, useDispatch } from "react-redux";
import {
  BottomModal,
  RatingModal,
  ModalButtons,
  Row,
  Modal,
  Title,
  MenuButton,
  ScreenScrollView,
  GradientHeader,
} from "../../components/all";
import {
  getTransactions,
  getNotifications,
  readNotifications,
  getUser,
  getOverview,
} from "../../services/profile";
import {
  exportTransactions,
  getApplication,
  getApplications,
  submitDoctorRating,
} from "../../services/job";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { normalize } from "../../utils/normalize";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import Styles from "../../constants/styles";
import { Picker } from "@react-native-picker/picker";

import { setNotifCount } from "../../reducers/auth/user";
const years = Array.from({ length: 5 }, (v, k) => {
  let date = new Date().getFullYear() - k;
  return {
    label: date.toString(),
    value: date.toString(),
  };
});
export default function App(props) {
  const { isDoctor, slug } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const safeInsets = useSafeAreaInsets();
  const [refreshing, setRefreshing] = React.useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [ratingModalData, setRatingModalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTransaction, setCurrentPageTransaction] = useState(1);

  const [notificationList, setNotificationList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const {
    data: applicationsQuery,
    refetch: applicationsRefetch,
    isLoading: applicationsLoading,
    isFetched,
  } = useQuery("applications", getApplications, {});
  const {
    data: transactionsQuery,
    refetch: transactionsRefetch,
    isLoading: transactionsLoading,
    isFetched: transactionsFetched,
  } = useQuery({
    queryKey: ["transactions", { query: currentPageTransaction }],
    queryFn: () =>
      getTransactions({
        page: currentPageTransaction,
      }),
  });

  const {
    data: userQuery,
    refetch: userRefetch,
    isLoading: userLoading,
  } = useQuery(
    "ratingDoctorProfile",
    () => getUser(ratingModalData?.applicant_slug),
    {
      enabled: !!showRatingModal,
    }
  );
  const {
    data: notificationsQuery,
    refetch: notificationsRefetch,
    isLoading: notificationsLoading,
    isFetched: notificationsFetched,
  } = useQuery({
    queryKey: ["notifications", { query: currentPage }],
    queryFn: () =>
      getNotifications({
        uuid: slug,
        page: currentPage,
      }),
  });

  const [selectedUUID, setSelectedUUID] = useState(null);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [showPickerModal, setShowPickerModal] = useState(false);
  const {
    data: profileQuery,
    refetch,
    isLoading: profileLoading,
  } = useQuery("overview", getOverview);
  let profileInfo = profileQuery?.data?.data;

  const isFocused = useIsFocused();

  useEffect(() => {
    setSelectedUUID();
    setCurrentPage(1);
    notificationsRefetch();
    setCurrentPageTransaction(1);
    transactionsRefetch();
  }, [isFocused]);

  const {
    data: jobApplicationQuery,
    refetch: jobApplicationRefetch,
    isLoading: jobApplicationLoading,
    isFetched: jobApplicationFetched,
  } = useQuery(["jobApplication", { query: selectedUUID }], getApplication, {});
  const setReadNotifications = useMutation(readNotifications, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (data) => {
      console.log("success");
    },
  });
  const onExportTransactions = useMutation(exportTransactions, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (data) => {
      alert("Locum report successfully sent to " + profileInfo.email);
    },
  });
  const rateDoctor = useMutation(submitDoctorRating, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (data) => {
      setShowRatingModal(false);
      setSelectedUUID();
    },
  });
  useEffect(() => {
    if (jobApplicationQuery?.data) {
      const jobData = jobApplicationQuery.data.data;
      if (jobData.status_code === 4) {
        if (isDoctor) {
          return;
        } else {
          if (jobData.job.is_rated === false) {
            setRatingModalData(jobData);
            setShowRatingModal(true);
          }
        }
      } else {
        props.navigation.navigate(
          isDoctor ? "DetailsScreen" : "JobDescriptionScreen",
          {
            data: {
              ...jobData?.job,
              status: jobData.status_code,
              job_application: {
                uuid: jobData?.uuid,
              },
              isScanned: false,
            },
          }
        );
      }
    }
  }, [jobApplicationQuery]);

  const onRefresh = () => {
    setRefreshing(true);
    if (selectedTab === 0) {
      setCurrentPage(1);
      notificationsRefetch();
    } else {
      setCurrentPageTransaction(1);
      transactionsRefetch();
    }
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  };
  let notificationData = notificationsQuery?.data?.data;
  let transactionData = transactionsQuery?.data?.data;
  useEffect(() => {
    if (transactionData?.length) {
      let trans = [];
      if (currentPageTransaction > 1) {
        trans = [...transactionList, ...transactionData];
      } else {
        trans = transactionData;
      }

      setTransactionList(trans);
    }
  }, [transactionsQuery, jobApplicationQuery]);
  useEffect(() => {
    if (notificationData?.length) {
      let notifs = [];
      if (currentPage > 1) {
        notifs = [...notificationList, ...notificationData];
      } else {
        console.log("refetch", notificationData);

        notifs = notificationData;
      }
      setNotificationList(notifs);

      let unreadIds = notificationData
        .filter((obj) => obj.read === 0)
        .map((obj) => obj.id);
      if (unreadIds.length) {
        dispatch(setNotifCount(0));
        setReadNotifications.mutate({
          uuid: slug,
          data: {
            ids: unreadIds,
          },
        });
      }
    }
  }, [notificationsQuery]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showChangeTab, setShowChangeTab] = useState(false);
  let cashJobs = applicationsQuery.data.data
    .filter((job) => job.job_application_status_code === 4 && job.is_cash)
    .map((job) => {
      return {
        job_title: job.title,
        job_price: job.price + ".00 MYR",
        job_end_timestamp: job.end_timestamp,
        payment_timestamp: job.end_timestamp,
        uuid: job.uuid,
        is_cash: true,
        ...job,
      };
    })
    .reverse();
  return (
    <>
      {Platform.OS === "web" && <GradientHeader />}

      <MenuButton isTop={true} navigation={props?.navigation} />
      <ScreenScrollView
        style={{
          backgroundColor: "rgba(0,0,0,0)",
          width:
            Dimensions.get("window").width > 780
              ? 780
              : Dimensions.get("window").width,
          alignSelf: "center",
        }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            marginTop: safeInsets.top + 24,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 32,
              marginLeft: 24,
            }}
          >
            Inbox
          </Text>
          {selectedTab === 1 && !isDoctor && transactionList?.length ? (
            <TouchableOpacity
              onPress={() => {
                let total = transactionList?.length;
                let endYear =
                  transactionList[total - 1]?.payment_timestamp.split(" ")[5];
                let startYear =
                  transactionList[0]?.payment_timestamp.split(" ")[5];

                setSelectedYear(endYear);

                let years = [];
                for (let i = startYear; i <= endYear; i++) {
                  years.push({
                    label: i.toString(),
                    value: i.toString(),
                  });
                }
                setShowPickerModal(years);
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  margin: 20,
                  marginVertical: 10,
                  alignItems: "center",
                  alignSelf: "center",
                  marginTop: 8,
                }}
              >
                <Text
                  style={{
                    textAlign: "right",
                    color: "grey",
                    marginRight: 8,
                  }}
                >{`Locum Report`}</Text>
                <Ionicons
                  name="document-text-outline"
                  size={18}
                  color={"rgba(0,0,0,0.3)"}
                />
              </View>
            </TouchableOpacity>
          ) : null}
        </View>
        <View
          style={{
            flexDirection: "row",
            marginTop: 12,
            marginBottom: 12,
            width: "100%",
            justifyContent: "space-between",
            alignSelf: "center",
            paddingHorizontal: 12,
          }}
        >
          {[
            {
              title: "Notifications",
              icon: "notifications",
            },
            {
              title: isDoctor ? "Completed" : "Transaction",
              icon: "reader",
            },
          ].map((item, index) => (
            <TouchableOpacity
              style={{ width: "50%" }}
              onPress={() => {
                setCurrentPage(1);
                setCurrentPageTransaction(1);
                setSelectedTab(index);
                setShowChangeTab(false);
                if (index === 1) transactionsRefetch();
              }}
            >
              <Row
                centered
                style={{
                  backgroundColor:
                    selectedTab === index
                      ? "rgb(239, 72, 116)"
                      : "rgba(0,0,0,0.08)",
                  paddingVertical: 12,
                  paddingHorizontal: 24,
                  borderRadius: 20,
                  justifyContent: "center",
                  margin: 4,
                }}
              >
                <Row centered>
                  <View style={{ marginRight: 8 }}>
                    <Ionicons
                      name={item.icon}
                      size={14}
                      color={selectedTab === index ? "white" : "black"}
                    />
                  </View>
                  <Title
                    label={item.title}
                    style={{
                      color: selectedTab === index ? "white" : "black",
                      fontSize: 14,
                    }}
                  />
                </Row>
              </Row>
            </TouchableOpacity>
          ))}
        </View>
        {selectedTab === 0 &&
          notificationsFetched &&
          notificationList === 0 && (
            <View
              style={{
                paddingVertical: 48,
                backgroundColor: "white",
                minHeight: Dimensions.get("window").height - 130,
                alignItems: "center",
                marginTop: 0,
              }}
            >
              <Ionicons
                name="alert-circle-outline"
                size={24}
                color={"rgba(0,0,0,0.3)"}
              />
              <Text style={{ color: "rgba(0,0,0,0.3)", marginTop: 14 }}>
                There are no notifications
              </Text>
            </View>
          )}
        {selectedTab !== 0 &&
          transactionsFetched &&
          transactionList?.length === 0 && (
            <View
              style={{
                paddingVertical: 48,
                alignItems: "center",
                backgroundColor: "white",
                minHeight: Dimensions.get("window").height - 130,
                marginTop: 0,
              }}
            >
              <Ionicons
                name="alert-circle-outline"
                size={24}
                color={"rgba(0,0,0,0.3)"}
              />
              <Text style={{ color: "rgba(0,0,0,0.3)", marginTop: 14 }}>
                {isDoctor
                  ? "No information available"
                  : "There are no transactions"}
              </Text>
            </View>
          )}
        {selectedTab === 0 ? (
          <>
            <FlatList
              data={notificationList}
              style={{
                height: Dimensions.get("window").height - 130,
                marginTop: 0,
                backgroundColor: "white",
                ...Styles.webContainer,
                borderTopLeftRadius: Platform.OS === "web" ? 32 : 0,
                borderTopRightRadius: Platform.OS === "web" ? 32 : 0,
              }}
              onRefresh={() => onRefresh()}
              refreshing={refreshing}
              onEndReachedThreshold={0.5}
              onEndReached={({ distanceFromEnd }) => {
                if (distanceFromEnd < 0) return;
                setCurrentPage(currentPage + 1);
              }}
              contentOffset={{ y: 40 }}
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  onPress={() => {
                    if (item?.link?.split("/")[1] === "jobs") {
                      props.navigation.navigate("JobDescriptionScreen", {
                        data: {
                          uuid: item?.link?.split("/")[2],
                        },
                      });
                    } else {
                      setSelectedUUID(item?.link?.split("/")[2]);
                    }
                  }}
                >
                  <View
                    style={{
                      paddingVertical: 24,
                      paddingHorizontal: 20,
                      paddingLeft: 24,
                      borderTopWidth: index === 0 ? 1 : 0,
                      borderBottomWidth: 1,
                      borderColor: "rgba(0,0,0,0.1)",
                      backgroundColor:
                        item?.read === 0 ? "rgba(252,241,212,0.5)" : null,
                    }}
                  >
                    <Row style={{ justifyContent: "space-between" }}>
                      <Row
                        style={{
                          flex: 1,
                          marginRight: 30,
                        }}
                      >
                        <Feather
                          name="alert-circle"
                          size={20}
                          color="#FF4B4B"
                        />
                        <View style={{ marginLeft: 20 }}>
                          <Text
                            style={{
                              fontSize: normalize(14),
                              fontWeight: "bold",
                              width:
                                Dimensions.get("window").width > 780
                                  ? 600
                                  : Dimensions.get("window").width - 150,
                            }}
                          >
                            {item.title}
                          </Text>
                          <Text
                            style={{
                              marginTop: 10,
                              fontSize: normalize(12),
                              width:
                                Dimensions.get("window").width > 780
                                  ? 600
                                  : Dimensions.get("window").width - 150,
                            }}
                          >
                            {item.body}
                          </Text>
                          {item?.note && (
                            <Text
                              style={{
                                marginTop: 10,
                                fontSize: normalize(12),
                                color: "rgba(0,0,0,0.5)",
                                width:
                                  Dimensions.get("window").width > 780
                                    ? 600
                                    : Dimensions.get("window").width - 150,
                              }}
                            >
                              {item?.note}
                            </Text>
                          )}
                        </View>
                      </Row>
                      <Text
                        style={{
                          color: "rgba(0,0,0,0.4)",
                          fontSize: normalize(12),
                        }}
                      >
                        {moment(item?.updated_at).fromNow()}
                      </Text>
                    </Row>
                  </View>
                </TouchableOpacity>
              )}
            />
          </>
        ) : (
          <>
            <FlatList
              data={[...cashJobs, ...transactionList]
                .sort((a, b) => {
                  if (isDoctor) {
                    return (
                      moment(b?.job_end_timestamp).unix() -
                      moment(a?.job_end_timestamp).unix()
                    );
                  }
                  return (
                    moment(b?.payment_timestamp).unix() -
                    moment(a?.payment_timestamp).unix()
                  );
                })
                .filter((o) => o.uuid)}
              style={{
                height: Dimensions.get("window").height - 130,
                marginTop: 0,
                backgroundColor: "white",
                ...Styles.webContainer,
                borderTopLeftRadius: Platform.OS === "web" ? 32 : 0,
                borderTopRightRadius: Platform.OS === "web" ? 32 : 0,
              }}
              onRefresh={() => onRefresh()}
              refreshing={refreshing}
              onEndReachedThreshold={0.5}
              onEndReached={({ distanceFromEnd }) => {
                if (distanceFromEnd < 0) return;

                setCurrentPageTransaction(currentPageTransaction + 1);
              }}
              contentOffset={{ y: 40 }}
              renderItem={({ item }) => (
                <TouchableOpacity
                  onPress={() =>
                    props.navigation.navigate("TransactionScreen", {
                      data: item,
                    })
                  }
                >
                  <View
                    style={{
                      paddingVertical: 24,
                      paddingLeft: 10,
                      marginHorizontal: 20,
                      borderBottomWidth: 1,
                      borderColor: "rgba(0,0,0,0.1)",
                    }}
                  >
                    <Row style={{ justifyContent: "space-between" }}>
                      <Row
                        style={{
                          maxWidth: Dimensions.get("window").width - 150,
                        }}
                      >
                        <Ionicons name="receipt-outline" size={20} />
                        <View style={{ marginLeft: 20 }}>
                          <Text
                            style={{
                              fontSize: normalize(16),
                              fontWeight: "bold",
                              width:
                                Dimensions.get("window").width > 780
                                  ? 600
                                  : Dimensions.get("window").width - 150,
                            }}
                          >
                            {item.job_title}
                          </Text>
                          <Text
                            style={{
                              marginTop: 4,
                              fontSize: normalize(12),
                              color: "rgba(0,0,0,0.5)",
                            }}
                          >
                            {isDoctor || item.is_cash ? (
                              <>
                                {moment(item.job_end_timestamp).format(
                                  "MMM DD YYYY"
                                )}
                              </>
                            ) : (
                              <>
                                {item.payment_timestamp
                                  ?.split(" ")
                                  .map((t, i) => {
                                    if ([0, 3, 4].includes(i)) return;
                                    return <Text>{t} </Text>;
                                  })}
                              </>
                            )}
                          </Text>
                        </View>
                      </Row>
                      <View>
                        <Text
                          style={{
                            fontSize: normalize(14),
                            fontWeight: "bold",
                          }}
                        >
                          <Text style={{ fontSize: normalize(10) }}> </Text>
                          {item.job_price}
                        </Text>
                      </View>
                    </Row>
                  </View>
                </TouchableOpacity>
              )}
            />
          </>
        )}
      </ScreenScrollView>
      <BottomModal visible={modalVisible} hide={() => setModalVisible(false)} />
      <RatingModal
        fromInbox
        isVisible={showRatingModal}
        ratingModalData={[ratingModalData.job]}
        currentRatingIndex={0}
        userQuery={userQuery}
        rateDoctor={(val) => {
          rateDoctor.mutate({
            jobID: ratingModalData.job.uuid,
            data: {
              rating: val,
            },
          });
        }}
        onHide={() => {
          setShowRatingModal(false);
          setSelectedUUID();
        }}
      />
      <Modal
        visible={!!showPickerModal}
        onBackdropPress={() => setShowPickerModal(false)}
        styles={{ width: "auto", padding: 0, ...Styles.shadows }}
      >
        <View
          style={{
            borderWidth: 1,
            borderRadius: 20,
            borderColor: "rgba(0,0,0,0.1)",
          }}
        >
          <Picker
            selectedValue={selectedYear}
            onValueChange={(itemValue, itemIndex) => setSelectedYear(itemValue)}
          >
            {showPickerModal &&
              showPickerModal?.map((pickerData) => (
                <Picker.Item
                  label={pickerData.label}
                  value={pickerData.value}
                />
              ))}
          </Picker>
          <ModalButtons
            onCancel={() => setShowPickerModal(false)}
            onConfirm={() => {
              setShowPickerModal(false);
              Alert.alert(
                "Locum Report " + selectedYear,
                "Are you sure you want to generate the locum report? It will be sent to your email (" +
                  profileInfo.email +
                  ").",
                [
                  {
                    text: "Cancel",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel",
                  },
                  {
                    text: "Confirm",
                    onPress: () => {
                      onExportTransactions.mutate({
                        data: {
                          year: selectedYear,
                        },
                      });
                    },
                  },
                ]
              );
            }}
          />
        </View>
      </Modal>
    </>
  );
}

const styles = {
  title: {
    margin: 18,
  },
  jobsAvailableTitle: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 18,
    marginBottom: 10,
  },
  filterText: {
    fontWeight: "bold",
  },
  divider: {
    alignSelf: "center",
    width: "90%",
    height: 0.5,
    backgroundColor: "#eee",
    marginBottom: 24,
  },
  sortContainer: {
    marginTop: -18,
    alignItems: "flex-end",
    marginRight: 18,
  },
  sortByText: {
    fontSize: normalize(12),
    marginBottom: 2,
  },
  sortText: {
    fontWeight: "bold",
  },
  line: {
    marginTop: 20,
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  lineTitle: {
    padding: 10,
    backgroundColor: "white",
    marginTop: -18,
  },
};
