import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import React, { useState } from "react";
import {
  Alert,
  Dimensions,
  ScrollView,
  Text,
  View,
  StyleSheet,
  Platform,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Centered,
  Loading,
  Row,
  StepHeader,
  ModalButtons,
  Modal,
} from "../../../components/all";
import { addFields } from "../../../reducers/auth/register";
import { setUserToken } from "../../../reducers/auth/user";
import { register, requestOTP } from "../../../services/auth";
import { WebView } from "react-native-webview";
import { normalize } from "../../../utils/normalize";
import Styles from "../../../constants/styles";
import { setFreshRegister } from "../../../reducers/profile/profile";

Object.map = function (o, f, ctx) {
  ctx = ctx || this;
  var result = {};
  Object.keys(o).forEach(function (k) {
    result[k] = f.call(ctx, o[k], k, o);
  });
  return result;
};
const placeholderProfile = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAAAAABVicqIAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAd0SU1FB+cIAgU5LRB8EfQAAAN7SURBVGje7dfZdtowEAbgvP+TRSPJxnFYY5ZAglltSxpx3ZHdtGmJWRSS056j/4oA0ceMFtt33W/IHf+G3IlvSEACEpCABCQgAQlIQP4bRH45IgUH/pWIlAIYxN3onOKNUAmMycfJqrTrL0Fcj0A8PL3stbVGmwe4MeIAxjuD+VZZiiq1xic4PflXIW4SAKLedF0hAabIJ49ybPD1ZpW4SQCZjpeFIQCrzXMvppogVqY4s4ovQ+oeiWREk+BKUNvFMKGiuNsjfI36ET6J0Dj0g+P+fKMcoItllhJwz5qBJZsiTk5PymlE1j2KerN16XpkytWkG7kKRJQtsnp/SN43uOKeiKwXqnzM8hItIlbrWS8CxtzInK8sHrYC3GOnLEwZ1a+uRtxOSEYL2glotC7zSTeOoriTdChJlFmllB3LxP2Rox5G7v02pg3h8eB5o9xWo9GUrvb7onoX1aR0r8tKq/pNPb8OgYGqJ6FSul5P9nA42NNBjW1L+WNEsrlVejfrd5U2y9n8gsw2xuyvQ6Dv/oWxROGGMTprPwy9zRg0n953KrwOoSkpcUfL80FhDrI9IGm2Ja1EKaLiWkSA28e8QURr+NO+2qb1F3hUXotImKKdwGlEwuBgtC1j7ocI3jM0/DlkiUorHLlDxQuhFtM+Pt0uyBuEeSKCfqXpsTPIkDYrVt7tkixDnN6/Q+iu5GgMPi7VrsuFJyIg1bh51y6Yrh/Y0SAg6boivBEhd9SI5A3hqbFqwI6+9etM9EPYArEX6wahhWSVwVnTMncJONJ8EAkjxNkbAl3UFJtHNDxfUOduggjuzq23dvFVjSgsUnY/PKAa87+K8UME32LVLy0hdF7WBilGZ2lhlLarDrsBIuEZzaxwCHlG/4xBbeqaquyPNe1ZiTvuN64SyUZW/x26qK0S9mmEx5VR9ZzIvdHHUaiefhfjibjZVrS6OIzpMvmRQqsths8hdNtmm0p2qFtiyxFvivGtBLrUJcxZR7UZNDP4GvtetJpEtFgJSSrdrtDWGbhbSl+kPkvqStC0h26ZXumw90ZY5hAuX4qT2aucRvdtF6T0O5f0DCLORHL6jidSb5AyjTrx+ciRNnvhgUh4QTqtyuqS0InjVYkEOnAR7UVBPBQ+lRAzz5cXJx9e+ejwlrbb4I/jV8ltEpCABCQgAQlIQAISkID8g8gPRGmu2gD9qqQAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDgtMDJUMDU6NTc6MDErMDA6MDCTNvzIAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTA4LTAyVDA1OjU3OjAxKzAwOjAw4mtEdAAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMy0wOC0wMlQwNTo1Nzo0NSswMDowMMV7T0IAAAAASUVORK5CYII=`;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function Step4({ editProfile, navigation }) {
  const safeInsets = useSafeAreaInsets();

  const dispatch = useDispatch();
  const { fields, errorFields } = useSelector((state) => state.register);
  const { isDoctor } = useSelector((state) => state.user);

  const [verifying, setVerifying] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const links = {
    doctorTerms:
      "https://docs.google.com/document/d/e/2PACX-1vR7TPQsU-svthrBmcWp0-TFEkVBdcJIBN7ICZoiAI3onDsMbhLprh9exxvMMn3npg/pub?embedded=true",
  };
  let iframeHTML = (url) =>
    `<iframe width="100%" height="100%" style="border:none;overflow:hidden;" src=${url}></iframe>`;
  const registerUser = useMutation(register, {
    onError: (error) => {
      setVerifying(false);
      if (error.response.data?.message) {
        Alert.alert("Registration Error", error.response.data.message);
        return;
      }
      let formattedError = "";
      Object.map(error.response.data.errors, (v, k) => {
        let errorMessage = v.join("\n");
        formattedError += `\n${capitalizeFirstLetter(k)}\n${errorMessage}\n`;
      });
      Alert.alert("Registration Error", formattedError);
    },
    onSuccess: (data) => {
      dispatch(setUserToken(data.data.token));
      sendOTP.mutate({
        email: fields.email,
      });
    },
  });

  const sendOTP = useMutation(requestOTP, {
    onError: (error) => {
      setVerifying(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      setVerifying(false);
      dispatch(setFreshRegister(true));
      dispatch(addFields({ ...fields, nonce: res.data.data.nonce }));
      navigation.navigate("ValidateOTPScreen");
    },
  });

  const submitFields = () => {
    setVerifying(true);
    let extraParams = {};
    if (isDoctor) {
      extraParams = {
        scope: "doctor",
        about: fields.about,
        nric_passport_number: fields.nric_passport_number,
        apc: fields.apc,
        mmc: fields.mmc,
        nsr: fields.nsr,
        expertise: fields.expertise,
      };
    } else {
      extraParams = {
        scope: "clinic_owner",
        clinic_phone_number: fields.clinic_phone_number,
        clinic_name: fields.clinic_name,
        business_registration_number: fields.business_registration_number,
        clinic_profile_image:
          fields?.clinic_profile_image || placeholderProfile,
        longitude: fields.longitude?.toFixed(5),
        latitude: fields.latitude?.toFixed(5),
      };
    }

    registerUser.mutate({
      email: fields.email,
      password: fields.password,
      first_name: fields.first_name,
      last_name: fields.last_name,
      phone_number: fields.phone_number,
      address: fields.address,
      city: fields.city,
      state: fields.state,
      postcode: fields.postcode,
      emergency_name: fields.emergency_name,
      emergency_number: fields.emergency_number,
      profile_image: fields?.profile_image || placeholderProfile,
      education: fields.education,
      facilities: fields.facilities,
      ...extraParams,
    });
  };

  return (
    <>
      <View
        style={{
          ...Styles.webSmallContainer,
          marginTop: 120,
        }}
      >
        <StepHeader
          label={`Terms & Condition`}
          icon={<FontAwesome5 name="list" size={24} color="black" />}
          subtitle={`Please read the terms and conditions carefully.`}
        />

        {Platform.OS === "web" ? (
          <iframe
            src={links.doctorTerms}
            height={"100%"}
            width={"100%"}
            style={{
              borderWidth: 0,
              height: Dimensions.get("window").height - safeInsets.top,
            }}
          />
        ) : (
          <View
            style={{
              width: "100%",
              height: Dimensions.get("window").height - 100,
            }}
          >
            <WebView
              source={{
                html: iframeHTML(links.doctorTerms),
              }}
              originWhitelist={["*"]}
              scalesPageToFit={true}
              style={{ height: "80%", marginLeft: 48 }}
            />
            <View style={{ margin: 120 }} />
          </View>
        )}
      </View>
      <View
        style={{
          paddingBottom: safeInsets.bottom,
          ...styles.termsContainer,
        }}
      >
        <Centered>
          <Row style={{ marginBottom: 10 }}>
            <Text>Do you agree to the terms & conditions above.</Text>
          </Row>
          <Row style={{ marginBottom: 24 }}>
            <Button
              label="Back"
              type={"secondary"}
              onPress={() => {
                navigation.goBack();
              }}
            />
            <Button
              label="Agree"
              type={"primary"}
              onPress={() => {
                setShowConfirmModal(true);
              }}
            />
          </Row>
        </Centered>
      </View>
      <Modal
        isVisible={showConfirmModal}
        onBackdropPress={() => setShowConfirmModal(false)}
      >
        <View style={{ alignItems: "center", marginBottom: 12 }}>
          <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
        </View>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: normalize(16),
            textAlign: "center",
          }}
        >
          Complete Registration
        </Text>
        <Text
          style={{
            textAlign: "center",
            marginTop: 10,
            marginBottom: 24,
            marginHorizontal: 24,
          }}
        >
          Please double check the fields before submitting, a verification will
          be sent to your email.
        </Text>
        <ModalButtons
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            submitFields();
          }}
        />
      </Modal>
      <Loading visible={verifying && Platform.OS !== "web"} />
    </>
  );
}

const styles = StyleSheet.create({
  termsContainer: {
    paddingTop: 24,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
  },
});
