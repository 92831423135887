import { Feather, MaterialIcons, FontAwesome } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import * as React from "react";
import { Alert, Text, View, Image, Linking } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import {
  BottomModal,
  Header,
  Row,
  Input,
  Centered,
  BackButton,
  Button,
} from "../../../components/all";
import homeStub from "../../../constants/stub/home.json";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { normalize } from "../../../utils/normalize";
import { useMutation } from "react-query";
import { submitHelp } from "../../../services/shared";

import Styles from "../../../constants/styles";
export default function Settings(props) {
  const safeInsets = useSafeAreaInsets();

  const [modalVisible, setModalVisible] = React.useState(false);
  const [helpMessage, setHelpMessage] = React.useState("");
  const [error, setError] = React.useState(false);
  const { isDoctor } = useSelector((state) => state.user);

  const FAQList = isDoctor
    ? [
        {
          question: "When do I get paid?",
          answer:
            "Payment may take up to 24 hours and in some exceptional cases, 3 days. You may check your bank account periodically. Reachout to us at  support@supermedic.health for further enquiries. ",
        },
        {
          question: "I need to cancel my shift",
          answer:
            "You will not receive payments if you have initiated cancellation, this may also affect your ratings.",
        },
        {
          question: "What does supermedic charge?",
          answer:
            "Supermedic charges a 3% fee for payments processing and does not charge any other additional fees.",
        },
        {
          question: "The facility has cancelled my shift",
          answer:
            "You may receive compensation at the discretion of Supermedic if deemed acceptable. t&c will apply. However you may not receive any compensation if you have no proof of check-in within the Supermedic platform.",
        },
        {
          question: "What happens if I do not check-in?",
          answer:
            "It is the Doctors responsibility to be on time and to request for the QR code from the clinic or facility. Within a check-in in the Supermedic platform, doctors are deemed absent and will not receive payments.",
        },
      ]
    : [
        {
          question: "Will I be charged to post a shift?",
          answer: "It is absolutely free to post a shift on Supermedic.",
        },
        {
          question: "How will the doctor know when to arrive and who to see?",
          answer:
            "Supermedic provides detailed instructions and timely reminders from Application to completion of each shift. You may include specific instructions under notes when posting a shift. ",
        },
        {
          question: "How do I pay the doctors?",
          answer:
            "When an applicant is successfully confirmed, you are required to make a payment via bank transfer to the supermedic platform. Supermedic will release the payment to doctors upon shift completion.",
        },
        {
          question: "How does shift coverage work?",
          answer:
            "If doctors cancel on the same day of a shift or cancel upon successful payment by facility on the same day for urgent shifts, facilities will be reimbursed 100%",
        },
        {
          question: "How do I post an urgent shift?",
          answer:
            "Any shift posted on the same day as it is required to be filled is automatically labelled as an urgent shift and will be highlighted on the platform. Urgent shifts are also broadcasted to qualified doctors for said position.",
        },
        {
          question: "Shifts that are cancelled by you.",
          answer:
            "50% of the total amount will be refunded to you and 50% of the total fee will be provided as compensation to the doctor as he or she already allocated time and in turn will need to make changes to their arrangements. If doctors do not show up for shifts that are successfully booked or if a doctor cancels on facilities, 100% of shift fee will be refunded to you.",
        },
      ];

  const onSubmit = useMutation(submitHelp, {
    onError: (error) => alert("Oops! " + error.response.data.message),
    onSuccess: (data) => {
      setHelpMessage("");
      Alert.alert(
        "Question Submitted",
        "Thanks for submitting your question, we will get back to you as soon as possible!"
      );
    },
  });

  return (
    <>
      <View style={{ position: "absolute", zIndex: 10 }}>
        <BackButton color="white" />
      </View>
      <LinearGradient
        start={{ x: 0, y: 1 }}
        // Button Linear Gradient
        colors={["#72C6EF", "rgb(86,161,209)", "rgb(54,124,180)"]}
      >
        <KeyboardAwareScrollView
          extraHeight={180}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            paddingTop: safeInsets.top + 70,
            ...Styles.webContainer,
          }}
        >
          <>
            <View style={{ ...styles.headerContainer }}>
              <MaterialIcons name="help-outline" size={24} />

              <View style={styles.header}>
                <Text style={styles.headerName}>{`Help & Support`}</Text>
              </View>
            </View>
            <Image
              source={require("../../../assets/images/wave.png")}
              style={{ width: "100%", zIndex: -10, marginTop: -85 }}
            />
          </>
          <View style={{ padding: 20, backgroundColor: "white" }} />
          <View style={{ backgroundColor: "white", paddingHorizontal: 12 }}>
            <View style={{ marginLeft: 20 }}>
              <View style={{ marginBottom: 32 }}>
                <Text
                  style={{
                    fontSize: normalize(16),
                    marginTop: 0,
                    fontWeight: "bold",
                    color: "#3D3D3D",
                    marginBottom: 4,
                  }}
                >
                  Email Address
                </Text>
                <Text
                  onPress={() => {
                    Linking.openURL(`mailto:${`hello@supermedic.health`}`);
                  }}
                  style={{ color: "rgba(0,0,0,0.4)" }}
                >
                  hello@supermedic.health
                </Text>
              </View>
              <View
                style={{
                  marginBottom: 24,
                  height: 0.2,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  marginRight: 24,
                }}
              />
              <Text
                style={{
                  fontSize: normalize(18),
                  fontWeight: "bold",
                }}
              >
                Frequently Asked Question
              </Text>

              {FAQList.map((item, index) => (
                <View
                  key={index}
                  style={{
                    marginTop: 20,
                    paddingRight: 30,
                    paddingVertical: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: normalize(16),
                      fontWeight: "bold",
                      color: "#3D3D3D",
                      marginBottom: 4,
                    }}
                  >
                    {item.question}
                  </Text>
                  <Text style={{ color: "rgba(0,0,0,0.4)" }}>
                    {item.answer}
                  </Text>
                </View>
              ))}
            </View>
            <Centered style={{ width: "100%" }}>
              <Input
                onChangeText={setHelpMessage}
                value={helpMessage}
                onFocus={() => {
                  setError(false);
                }}
                placeholder="Still having trouble? Send us a question!"
                containerStyle={{ margin: 18, marginTop: 24 }}
                multiline={true}
                error={error}
              />
            </Centered>
            <Centered style={{ width: "100%" }}>
              <Row>
                <Button
                  label={`Back`}
                  onPress={() => {
                    props.navigation.goBack();
                  }}
                  type={"secondary"}
                  style={{
                    marginTop: 24,
                    alignItems: "center",
                  }}
                  textStyle={{
                    textAlign: "center",
                  }}
                />
                <Button
                  label={`Send Question`}
                  onPress={() => {
                    if (helpMessage) {
                      onSubmit.mutate({
                        type: "question",
                        content: helpMessage,
                      });
                    } else {
                      setError(true);
                    }
                  }}
                  type={"primary"}
                  style={{
                    marginTop: 24,
                    alignItems: "center",
                  }}
                  textStyle={{
                    textAlign: "center",
                  }}
                />
              </Row>
            </Centered>
            <View
              style={{
                margin: 80,
              }}
            />
          </View>
        </KeyboardAwareScrollView>
      </LinearGradient>
      <BottomModal visible={modalVisible} hide={() => setModalVisible(false)} />
    </>
  );
}

const styles = {
  title: {
    margin: 18,
  },
  jobsAvailableTitle: {
    fontSize: 24,
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 18,
    marginBottom: 10,
  },
  filterText: {
    fontWeight: "bold",
  },
  divider: {
    alignSelf: "center",
    width: "90%",
    height: 0.5,
    backgroundColor: "#eee",
    marginBottom: 24,
  },
  sortContainer: {
    marginTop: -18,
    alignItems: "flex-end",
    marginRight: 18,
  },
  sortByText: {
    fontSize: 12,
    marginBottom: 2,
  },
  sortText: {
    fontWeight: "bold",
  },
  line: {
    marginTop: 20,
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  lineTitle: {
    padding: 10,
    backgroundColor: "white",
    marginTop: -18,
  },
  headerContainer: {
    margin: 30,
    marginTop: 40,
    marginBottom: 4,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerName: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginTop: 10,
  },
};
