import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Dimensions,
  ScrollView,
  Text,
  View,
  StyleSheet,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Centered,
  Loading,
  Row,
  StepHeader,
  ModalButtons,
  Modal,
} from "../../../components/all";
import { WebView } from "react-native-webview";
import { normalize } from "../../../utils/normalize";
import Styles from "../../../constants/styles";
import { setDocsUploaded } from "../../../reducers/auth/user";
Object.map = function (o, f, ctx) {
  ctx = ctx || this;
  var result = {};
  Object.keys(o).forEach(function (k) {
    result[k] = f.call(ctx, o[k], k, o);
  });
  return result;
};

export default function Step4({ editProfile, navigation }) {
  const safeInsets = useSafeAreaInsets();

  const dispatch = useDispatch();
  const { fields, errorFields } = useSelector((state) => state.register);
  const { isDoctor } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(setDocsUploaded(false));
  }, []);
  const INJECTED_JAVASCRIPT = `document.addEventListener("click", function(){
    setTimeout(() => {
      let bodyHTML = document.body.innerHTML;
      window.ReactNativeWebView.postMessage(JSON.stringify(bodyHTML));
    }, 2000)


  });`;
  const [verifying, setVerifying] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const name = fields.first_name + "+" + fields.last_name;

  const airtableURL =
    Platform.OS === "web"
      ? "https://airtable.com/embed/"
      : "https://airtable.com/";
  const links = {
    doctorTerms: fields?.nsr
      ? airtableURL + `shrW39tnb9YghKdTy?prefill_Name=${name}`
      : airtableURL + `shrVz3Kn8iZVhT8nF?prefill_Name=${name}`,
    clinicTerms:
      airtableURL +
      `shrrnHBt7prAoSYj3?prefill_Clinic+Name=${fields.clinic_name}`,
  };

  return (
    <>
      <View
        style={{
          ...Styles.webSmallContainer,
          marginTop: 40,
        }}
      >
        <>
          <ScrollView
            contentContainerStyle={{
              width: "100%",
              height: Dimensions.get("window").height - 180,
            }}
          >
            {isLoading && (
              <View style={{ marginTop: 100 }}>
                <ActivityIndicator size="large" />
              </View>
            )}
            {Platform.OS === "web" ? (
              <iframe
                src={isDoctor ? links.doctorTerms : links.clinicTerms}
                height={"100%"}
                width={"100%"}
                style={{
                  borderWidth: 0,
                  height: Dimensions.get("window").height - safeInsets.top,
                }}
              />
            ) : (
              <WebView
                source={{
                  uri: isDoctor ? links.doctorTerms : links.clinicTerms,
                }}
                onLoadStart={() => {
                  setIsLoading(true);
                }}
                onLoadEnd={() => {
                  setIsLoading(false);
                }}
                injectedJavaScript={INJECTED_JAVASCRIPT}
                javaScriptEnabled={true}
                originWhitelist={["*"]}
                scalesPageToFit={true}
                onMessage={(event) => {
                  let innerHTML = event.nativeEvent.data;
                  if (innerHTML.includes("Now create your own form")) {
                    dispatch(setDocsUploaded(true));
                    setIsFileUploaded(true);
                  }
                }}
              />
            )}
          </ScrollView>
        </>
      </View>
      <View
        style={{
          paddingBottom: safeInsets.bottom,
          ...styles.termsContainer,
        }}
      >
        <Centered style={{ marginBottom: 24 }}>
          <Row>
            <Button
              label="Back"
              type={"secondary"}
              onPress={() => {
                navigation.goBack();
              }}
            />
            <Button
              label="Next"
              type={"primary"}
              onPress={() => {
                if (Platform.OS === "web") {
                  navigation.navigate("TermsConditionScreen");
                } else if (isFileUploaded) {
                  navigation.navigate("TermsConditionScreen");
                } else {
                  setShowConfirmModal(true);
                }
              }}
            />
          </Row>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("TermsConditionScreen");
            }}
          >
            <Text style={{ color: "grey", marginTop: 8 }}>Skip for now</Text>
          </TouchableOpacity>
        </Centered>
      </View>
      <Modal
        isVisible={showConfirmModal}
        onBackdropPress={() => setShowConfirmModal(false)}
      >
        <View style={{ alignItems: "center", marginBottom: 12 }}>
          <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
        </View>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: normalize(16),
            textAlign: "center",
          }}
        >
          File Not Uploaded
        </Text>
        <Text
          style={{
            textAlign: "center",
            marginTop: 10,
            marginBottom: 24,
            marginHorizontal: 24,
          }}
        >
          Please upload your documents to complete your registration.
        </Text>
        <ModalButtons
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            // submitFields();
          }}
        />
      </Modal>
      <Loading visible={verifying && Platform.OS !== "web"} />
    </>
  );
}

const styles = StyleSheet.create({
  termsContainer: {
    paddingTop: 24,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
  },
});
