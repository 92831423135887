import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Fonts from "../constants/fonts";
import Styles from "../constants/styles";
import Card from "./Card";
import Row from "./Row";
import Status from "./Status";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { normalize } from "../utils/normalize";

const UpcomingJob = (props) => {
  const data = props.data;
  let titleConfig = {};

  if (!props.isDescription) {
    titleConfig = {
      numberOfLines: 1,
    };
  }

  return (
    <Card
      onClosePressed={() => {
        if (props.onPressBack) {
          props.onPressBack();
        } else {
          props.navigation.goBack();
        }
      }}
      style={{ marginHorizontal: 0, marginLeft: props.isDescription ? 0 : 8 }}
      header
      data={data}
      isDescription={props.isDescription}
      title={
        props.isDescription
          ? "Job Application"
          : props.upcomingIndex > 0
          ? "Following Job"
          : "Upcoming Job"
      }
      onPress={() => {
        data &&
          props.navigation.navigate(
            props.isDoctor ? "DetailsScreen" : "JobDescriptionScreen",
            {
              data: { ...data, status: data.job_application_status_code },
            }
          );
      }}
    >
      {data ? (
        <View
          style={{
            ...styles.jobContainer,
            paddingHorizontal: props.isDescription ? 12 : 4,
            paddingTop: props.isDescription ? 30 : 14,
          }}
        >
          <Row style={{ justifyContent: "space-between" }}>
            <View style={{ flex: 1, marginRight: 8 }}>
              <Row style={{ alignItems: "flex-end" }}>
                <Text
                  style={{
                    ...Fonts.bold,
                    fontSize: props.isDescription
                      ? normalize(24)
                      : normalize(14),
                    marginBottom: props.isDescription ? 4 : 0,
                  }}
                  {...titleConfig}
                >
                  {data?.title}
                </Text>
              </Row>
              <Text style={styles.subtitle} numberOfLines={2}>
                {data?.description}
              </Text>
              {props?.isDescription ? (
                <Row centered>
                  <Text
                    style={[
                      Fonts.bold,
                      styles.salary,
                      {
                        marginTop: props.isDescription ? 14 : 10,
                        fontSize: props.isDescription
                          ? normalize(14)
                          : normalize(12),
                      },
                    ]}
                  >
                    RM{data?.price}
                  </Text>
                  <View
                    style={{
                      paddingHorizontal: 10,
                      paddingVertical: 4,
                      marginTop: 14,
                      marginLeft: 4,
                      borderWidth: 1,
                      borderColor: !data?.is_cash ? "#FC6767" : "grey",
                      borderRadius: 10,
                      alignSelf: "flex-start",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: normalize(11),
                        color: !data?.is_cash ? "#FC6767" : "grey",
                        fontWeight: "bold",
                      }}
                    >
                      {data?.is_cash ? "CASH" : "S-PAY"}
                    </Text>
                  </View>
                </Row>
              ) : null}
            </View>
            <View>
              <View style={styles.timeContainer}>
                <View
                  style={{
                    alignItems: "flex-end",
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginBottom: props.isDescription ? 4 : 0,
                      fontSize: props.isDescription
                        ? normalize(16)
                        : normalize(14),
                    }}
                  >
                    {moment(props.data?.start_timestamp).format(
                      props.isDescription ? "Do MMMM" : "D MMM"
                    )}
                  </Text>
                  {props?.isDescription &&
                  moment(props.data?.start_timestamp).format("DD") !==
                    moment(props.data?.end_timestamp).format("DD") ? (
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginBottom: props.isDescription ? 4 : 0,
                        fontSize: props.isDescription
                          ? normalize(16)
                          : normalize(14),
                      }}
                    >
                      {moment(props.data?.end_timestamp).format(
                        props.isDescription ? "Do MMMM" : "D MMM"
                      )}
                    </Text>
                  ) : null}
                </View>

                <View style={Styles.row}>
                  <Text
                    style={{
                      ...styles.time,
                      fontSize: props.isDescription
                        ? normalize(13)
                        : normalize(11),
                    }}
                  >
                    {moment(props.data?.start_timestamp).format("h:mm")}
                  </Text>
                  <Text
                    style={{
                      ...styles.ampm,
                      fontSize: props.isDescription
                        ? normalize(8)
                        : normalize(6),
                    }}
                  >
                    {moment(props.data?.start_timestamp).format("A")}
                  </Text>
                </View>
                <View style={{ ...Styles.row, marginTop: 2 }}>
                  <Text
                    style={{
                      ...styles.time,
                      fontSize: props.isDescription
                        ? normalize(13)
                        : normalize(11),
                    }}
                  >
                    {moment(props.data?.end_timestamp).format("h:mm")}
                  </Text>
                  <Text
                    style={{
                      ...styles.ampm,
                      fontSize: props.isDescription
                        ? normalize(8)
                        : normalize(6),
                    }}
                  >
                    {moment(props.data?.end_timestamp).format("A")}
                  </Text>
                </View>
                {props.isDescription && typeof data?.status === "number" ? (
                  <View style={{ marginTop: 20 }}>
                    <Status
                      isRow
                      status={
                        data?.status === 3 && data?.is_scanned === true
                          ? 6
                          : data.status
                      }
                      doctorCount={data?.job_applications?.length}
                    />
                  </View>
                ) : (
                  <View style={{ marginTop: -10 }} />
                )}
              </View>
            </View>
          </Row>
          {props.isDescription &&
          data?.status === 3 &&
          data?.is_scanned === false ? (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <TouchableOpacity onPress={() => props.showQR()}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 18,
                  }}
                >
                  <MaterialCommunityIcons
                    name="qrcode-scan"
                    size={50}
                    color="#03a5fc"
                  />
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: normalize(16),
                      color: "#03a5fc",
                      marginLeft: 16,
                    }}
                  >
                    {`Doctor\nCheck-in\nQR Code`}
                  </Text>
                </View>
              </TouchableOpacity>
              {/* {props.data?.is_cash ? (
                <View style={{ width: "50%", marginLeft: 20, paddingTop: 10 }}>
                  <Row centered>
                    <MaterialCommunityIcons
                      name="alert"
                      size={14}
                      color="#FC6767"
                    />
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginLeft: 4,
                        color: "#FC6767",
                      }}
                    >
                      Reminder
                    </Text>
                  </Row>
                  <Text style={{ fontSize: normalize(12), marginTop: 4 }}>
                    You are required to pay the total shift amount to the
                    Doctor. You are required to liaise with the doctor directly.
                  </Text>
                </View>
              ) : null} */}
            </View>
          ) : null}
          <View style={styles.statusContainer}>
            <Text style={styles.statusText}></Text>
          </View>
        </View>
      ) : (
        <View style={{ alignItems: "center", paddingVertical: 32 }}>
          <Text style={{ color: "rgba(0,0,0,0.4)", fontSize: normalize(12) }}>
            {props.jobLoading ? "" : "No Upcoming Job"}
          </Text>
        </View>
      )}
    </Card>
  );
};

const styles = {
  jobContainer: {
    paddingTop: 14,
  },
  title: {
    ...Fonts.bold,
  },
  subtitle: {
    fontSize: normalize(10),
    marginTop: 4,
    color: "rgba(0,0,0,0.5)",
  },
  clinicContainer: {
    marginVertical: 4,
  },
  rightContainer: {
    justifyContent: "center",
    alignItems: "center",
    borderLeftWidth: 1,
    paddingLeft: 8,
    borderColor: "rgba(0,0,0,0.1)",
  },
  rm: {
    fontSize: normalize(8),
  },
  statusContainer: {
    marginTop: 10,
    marginHorizontal: 10,
  },
  statusText: {
    fontWeight: "bold",
    color: "rgba(0,0,0,0.8)",
  },
  time: {
    fontWeight: "bold",
    fontSize: normalize(11),
    color: "black",
    marginLeft: 18,
    textAlign: "right",
  },
  ampm: {
    fontSize: normalize(6),
    color: "black",
    fontWeight: "bold",
    margin: 2,
  },
  timeContainer: {
    marginRight: 10,
    alignItems: "flex-end",
  },
  ringgit: {
    fontSize: normalize(9),
  },
  salary: {
    marginLeft: 0,
    marginTop: 6,
    borderWidth: 1,
    paddingHorizontal: 8,
    paddingVertical: 3,
    borderRadius: 10,
    borderColor: "#FC6767",
    alignSelf: "flex-start",
    color: "#FC6767",
    fontWeight: "bold",
    fontSize: normalize(12),
  },
};

UpcomingJob.PropTypes = {
  data: PropTypes.object,
};

export default UpcomingJob;
