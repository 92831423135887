import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fields: {},
  errorFields: [],
  freshRegister: false,
};

export const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setFields: (state, action) => {
      state.fields = action.payload;
    },
    addFields: (state, action) => {
      const { payload } = action;
      state.fields = { ...state.fields, ...payload };
    },
    resetFields: (state) => {
      state.fields = {};
    },
    resetEmptyFields: (state) => {
      state.errorFields = initialState.errorFields;
    },
    setErrorFields: (state, action) => {
      state.errorFields = action.payload;
    },
    checkEmptyFields: (state, action) => {
      const { isDoctor, isClinic } = action.payload;
      let { fields } = state;
      let toValidate = [];
      toValidate = [
        "first_name",
        "last_name",
        "profile_image",
        "phone_number",
        "emergency_number",
      ];
      if (isDoctor) {
        toValidate = [...toValidate, "nric_passport_number", "mmc", "apc"];
        fields.mmc?.length < 4 && toValidate.push("mmcError");
        fields.apc?.length < 9 && toValidate.push("apcError");
        fields.nsr?.length < 4 && toValidate.push("nsrError");
        fields.nric_passport_number?.length < 6 &&
          toValidate.push("nric_passport_numberError");
      }

      if (isClinic) {
        fields.business_registration_number?.length < 4 &&
          toValidate.push("business_registration_numberError");
        toValidate = [
          "clinic_name",
          "clinic_profile_image",
          "clinic_phone_number",
          "business_registration_number",
          "address",
          "city",
          "postcode",
          "state",
        ];
      }

      if (fields.postcode?.length < 5 && fields.postcode?.length > 0)
        toValidate.push("postcodeError");

      if (
        fields.phone_number?.length < 7 ||
        fields.emergency_number?.length < 7
      )
        toValidate.push("phoneError");

      const result = toValidate.filter((val) => {
        return fields[val] === undefined || fields[val] === "";
      });

      state.errorFields = result;
    },
    setFreshRegister: (state, action) => {
      state.freshRegister = action.payload;
    },
  },
});

export const {
  checkEmptyFields,
  addFields,
  setFields,
  resetFields,
  resetEmptyFields,
  setErrorFields,
  setFreshRegister,
} = profile.actions;

export default profile.reducer;
