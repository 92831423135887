const states = [
  {
    label: "Selangor",
    value: "Selangor",
    coord: "3.066695996,101.5499977",
  },
  {
    label: "Kuala Lumpur",
    value: "Kuala Lumpur",
    coord: "3.166665872,101.6999833",
  },
  {
    label: "Melaka",
    value: "Melaka",
    coord: "2.206414407,102.2464615",
  },
  {
    label: "Putrajaya",
    value: "Putrajaya",
    coord: "2.914019795,101.701947",
  },
  {
    label: "Johor",
    value: "Johor",
    coord: "2.033737609,102.566597",
  },
  {
    label: "Kedah",
    value: "Kedah",
    coord: "5.649718444,100.4793343",
  },
  {
    label: "Kelantan",
    value: "Kelantan",
    coord: "6.119973978,102.2299768",
  },

  {
    label: "Negeri Sembilan",
    value: "Negeri Sembilan",
    coord: "2.710492166,101.9400203",
  },
  {
    label: "Pahang",
    value: "Pahang",
    coord: "4.18400112,102.0420006",
  },
  {
    label: "Perak",
    value: "Perak",
    coord: "4.01185976,101.0314453",
  },
  {
    label: "Perlis",
    value: "Perlis",
    coord: "6.433001991,100.1899987",
  },
  {
    label: "Pulau Pinang",
    value: "Pulau Pinang",
    coord: "5.417071146,100.4000109",
  },
  {
    label: "Terengganu",
    value: "Terengganu",
    coord: "4.233241596,103.4478869",
  },

  {
    label: "Sabah",
    value: "Sabah",
    coord: "5.046396097,118.3359704",
  },

  {
    label: "Sarawak",
    value: "Sarawak",
    coord: "3.16640749,113.0359838",
  },
];

export default states;
