import { FontAwesome, Feather } from "@expo/vector-icons";
import React from "react";
import {
  Dimensions,
  View,
  Text,
  TouchableOpacity,
  Platform,
} from "react-native";
import { useSelector } from "react-redux";
import { Button, StepHeader, GoInboxButton } from "../../../components/all";
import Colors from "../../../constants/colors";
import { normalize } from "../../../utils/normalize";
import Styles from "../../../constants/styles";

export default function Step5({ navigation }) {
  const { fields, errorFields } = useSelector((state) => state.register);

  return (
    <View
      style={{
        ...Styles.webSmallContainer,
        marginTop: 250,
      }}
    >
      <StepHeader
        label={`Registration Complete`}
        icon={<FontAwesome name="thumbs-o-up" size={24} color="black" />}
        presubtitle={`Thank you for registering, we will verify your account\nand send you an email once it is approved.`}
        subtitle={""}
      />
      <View style={{ alignItems: "center", margin: 18, marginTop: 50 }}>
        {Platform.OS === "web" ? null : (
          <GoInboxButton
            onPress={() => {
              navigation.replace("AuthScreen");
            }}
          />
        )}
        {/* <TouchableOpacity
          onPress={() => {
            navigation.replace("AuthScreen");
          }}
        >
          <Text
            style={{
              margin: 10,
              fontSize: normalize(12),
              color: Colors.primary,
            }}
          >
            Back to Login
          </Text>
        </TouchableOpacity> */}
      </View>
    </View>
  );
}
