import { Feather, FontAwesome5 } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState, useEffect } from "react";
import * as Random from "expo-random";
import { Dimensions, View, Alert, Text, Platform } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import {
  Centered,
  ErrorMessage,
  Input,
  Row,
  StepHeader,
  BackButton,
  FloatingButton,
  Button,
  Loading,
  Categories,
  CategoryTab,
} from "../../../components/all";
import {
  postClinicFacilities,
  postFacilities,
} from "../../../services/profile";
import { checkEmptyFields } from "../../../reducers/profile/profile";
import { useMutation, useQuery } from "react-query";
import Fade from "react-native-fade";
import { getFacilities } from "../../../services/auth";
import Styles from "../../../constants/styles";
import professions from "../../../constants/lov/professions";
const EditFacility = (props) => {
  const safeInsets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const { fields, errorFields } = useSelector((state) => state.profile);
  const { isDoctor } = useSelector((state) => state.user);
  const [selectedCategory, setSelectedCategory] = useState("Doctor");
  const [selectedCategoryID, setSelectedCategoryID] = useState(1);

  const [allCategories, setAllCategories] = useState([]);

  const [allFields, setAllFields] = useState({ education: [""] });
  const [verifying, setVerifying] = useState(false);
  const [selectedSpecialization, setSelectedSpecialization] = useState(
    allFields?.facilities || []
  );
  const [facilityData, setFacilityData] = useState(null);

  const [filterInput, setFilterInput] = useState("");
  const [selectedFacilities, setSelectedFacilities] = useState([]);

  const {
    data: facilitiesQuery,
    refetch,
    isLoading,
  } = useQuery("facilities", getFacilities);

  const pickedItem = (index, children) => {
    let newSelected = [...selectedSpecialization];
    if (newSelected.includes(index)) {
      newSelected = newSelected.filter((item) => {
        let childrenIndex = [];
        if (children) {
          children.forEach((child) => {
            childrenIndex.push(child.id);
          });
        }
        return ![index, ...childrenIndex].includes(item);
      });
    } else {
      newSelected.push(index);
      if (children) {
        children.map((item) => {
          newSelected.push(item.id);
        });
      }
    }
    setSelectedSpecialization(newSelected);
    setSelectedFacilities(newSelected);
  };

  const input = {
    specializationError: "specializationError",
  };

  useEffect(() => {
    if (facilitiesQuery?.data) {
      setFacilityData(facilitiesQuery.data.data);
      let cats = [];
      facilitiesQuery.data.data.map((item) => {
        cats.push(item.category);
      });
      setAllCategories([...new Set(cats)]);
    }
  }, [facilitiesQuery]);

  useEffect(() => {
    if (fields?.facilities) {
      let facilitiesArray = fields.facilities.map((item) => {
        return item.slug;
      });
      setSelectedSpecialization(facilitiesArray);
      if (isDoctor) {
        let id = fields.expertise;
        let prof = professions;
        if (id > 5) id = 5;
        let p = prof.find((item) => item.id === id);
        setSelectedCategory(p.slug.split("-")[0]);
      }
      setAllFields({
        ...allFields,
        ...fields,
      });
    }
  }, [fields?.facilities]);

  const onUpdateFacilities = useMutation(
    isDoctor ? postFacilities : postClinicFacilities,
    {
      onError: (error) => {
        setVerifying(false);
        alert("Oops! " + error.response.data.message);
      },
      onSuccess: (res) => {
        setVerifying(false);
        props.navigation.navigate({
          name: "ProfileScreen",
          params: {
            profileEditSuccess: Random.getRandomBytes(1),
          },
          merge: true,
        });
      },
    }
  );

  const setField = (field, value) =>
    setAllFields({ ...allFields, [field]: value });

  const onPressUpdate = () => {
    onUpdateFacilities.mutate({
      facilities: { facilities: selectedFacilities },
      uuid: fields.uuid,
    });
  };
  return (
    <>
      <View style={{ backgroundColor: "white", ...Styles.webSmallContainer }}>
        <BackButton />
        <KeyboardAwareScrollView extraHeight={180}>
          <StepHeader
            label={`Edit Expertise`}
            icon={<FontAwesome5 name="notes-medical" size={24} color="black" />}
            subtitle={`Updating your expertise will require us to\nverify your identity again.`}
          />
          <View style={{ margin: 24 }} />
          <View style={{ width: Dimensions.get("window").width }}>
            <View style={{ marginTop: -30 }} />

            <View style={{ padding: 20 }}>
              <Input
                onChangeText={(text) => setFilterInput(text)}
                value={filterInput}
                placeholder={"Search specializations"}
              />
              <Centered style={{ width: "100%" }}>
                <Fade
                  visible={errorFields.includes(input.specializationError)}
                  duration={300}
                >
                  <Text style={{ fontSize: 10, color: "red", marginTop: 10 }}>
                    Please select at least one specialty
                  </Text>
                </Fade>
              </Centered>
              {isDoctor ? null : (
                <CategoryTab
                  selectedCategoryID={selectedCategoryID}
                  setSelectedCategoryID={setSelectedCategoryID}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              )}
              {allCategories.map((category, idx) => {
                let type = category.split("-")[0];
                if (
                  allFields.nsr == null &&
                  category.split("-")[1] === "Specialist"
                ) {
                  return null;
                }
                if (type.toLowerCase() !== selectedCategory.toLowerCase()) {
                  return null;
                }
                return (
                  <Categories
                    show={false}
                    data={facilityData}
                    selected={selectedSpecialization}
                    onPress={(index, children) => pickedItem(index, children)}
                    title={category.split("-")[1]}
                    isCheckbox
                    filterText={filterInput}
                    category={category}
                    isDoctor={isDoctor}
                  />
                );
              })}
            </View>
          </View>
          <View style={{ margin: 140 }} />
        </KeyboardAwareScrollView>
      </View>
      <FloatingButton status={"rejected"} onPress={() => onPressUpdate()}>
        <View
          style={{
            borderRadius: 100,
            overflow: "hidden",
          }}
        >
          <LinearGradient
            start={{ x: -0.2, y: 0.2 }}
            // Button Linear Gradient
            colors={[
              "rgb(248, 100, 108)",
              "rgb(242, 49, 123)",
              "rgb(234,0,137)",
            ]}
          >
            <View
              style={{
                height: 70,
                width: 70,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Feather name={"check-circle"} size={30} color="white" />
            </View>
          </LinearGradient>
        </View>
      </FloatingButton>
      {Platform.OS !== "web" ? (
        <Loading visible={verifying || isLoading} />
      ) : null}
    </>
  );
};

export default EditFacility;
