import * as Haptics from "expo-haptics";
import PropTypes from "prop-types";
import * as React from "react";
import { Platform, Pressable, Text, View } from "react-native";
import colors from "../constants/colors";
import Styles from "../constants/styles";
import Row from "./Row";

const defaultStyles = {
  borderRadius: 12,
  margin: 10,
};

const Button = (props) => {
  const [forceDisable, setForceDisable] = React.useState(false);

  let textStyle = {};

  let buttonStyles = {};
  switch (props.type) {
    case "primary":
      buttonStyles = {
        backgroundColor: colors.primary,
        ...defaultStyles,
      };
      textStyle = {
        color: "white",
        fontWeight: "bold",
      };
      break;
    case "secondary":
      buttonStyles = {
        backgroundColor: "#ededed",
        ...defaultStyles,
      };
      break;
  }
  return (
    <Pressable
      disabled={props.disabled || forceDisable}
      onPress={() => {
        props.onPress && props.onPress();
        setForceDisable(true);
        setTimeout(() => {
          setForceDisable(false);
        }, 1000);
      }}
    >
      <View
        {...props}
        style={{
          paddingHorizontal: 48,
          paddingVertical: 12,
          ...Styles.shadows,
          ...buttonStyles,
          ...props.style,
        }}
      >
        <Row centered>
          {props.icon ? (
            <View style={{ marginRight: 8 }}>{props.icon}</View>
          ) : null}
          <Text style={{ ...textStyle, ...props.textStyle }}>
            {props.label}
          </Text>
        </Row>
      </View>
    </Pressable>
  );
};

Button.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default Button;
