import React, { useState, useEffect } from "react";
import {
  Image,
  ScrollView,
  View,
  Text,
  Alert,
  Dimensions,
  TouchableOpacity,
  RefreshControl,
  Platform,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
  CancelRequestModal,
  CancelJobModal,
  ChangeStatusModal,
  JobCard,
  Modal,
  UpcomingJob,
  Centered,
  Row,
  Loading,
  GradientHeader,
} from "../../components/all";
import { useQuery, useMutation } from "react-query";
import {
  deleteJob,
  getJobApplications,
  getJob,
  approveJob,
  reapproveJob,
  rejectJob,
  getJobQR,
  submitCancellation,
  rejectCancellation,
  acceptCancellation,
  getCancellationList,
  getApplication,
} from "../../services/job";
import queryClient from "../../utils/queryClient";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import QRCode from "react-native-qrcode-svg";
import { normalize } from "../../utils/normalize";
import { removeNavParam } from "../../reducers/nav/params";

const filters = ["Pending", "Approved", "Completed"];

export default function App(props) {
  const dispatch = useDispatch();
  const { routeName, data: routeParam } = useSelector((state) => state.params);

  const currentJobData =
    routeName === "JobDescriptionScreen"
      ? routeParam.data
      : props.route.params?.data;
  const [refreshing, setRefreshing] = useState(false);

  const [showQRModal, setShowQRModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCancelJobModal, setShowCancelJobModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelError, setCancelError] = useState();
  const [cancelData, setCancelData] = useState();
  const [isRaiseToAdmin, setIsRaiseToAdmin] = useState(false);

  const [jobData, setJobData] = useState(null);
  const [jobQR, setJobQR] = useState(null);
  const [disablePendingCancellation, setDisablePendingCancellation] =
    useState(true);
  const onRefresh = () => {
    setRefreshing(true);
    jobApplicationsRefetch();
    setTimeout(() => {
      setRefreshing(false);
    }, 1000);
  };

  const {
    data: jobQuery,
    refetch: jobRefetch,
    isLoading: jobLoading,
  } = useQuery("job", () => getJob(currentJobData.uuid));

  const {
    data: jobQRQuery,
    refetch: jobQRRefetch,
    isLoading: jobQRLoading,
  } = useQuery("jobQR", () => getJobQR(currentJobData.uuid), {
    enabled: currentJobData?.status === 3,
  });

  const {
    data: cancelQuery,
    refetch: cancelRefetch,
    isLoading: cancelLoading,
  } = useQuery("cancellationList", getCancellationList, {
    enabled: currentJobData?.status === -3,
  });
  const {
    data: jobApplicationsQuery,
    refetch: jobApplicationsRefetch,
    isLoading: jobApplicationsLoading,
    isFetched: jobApplicationsFetched,
  } = useQuery("jobApplications", () =>
    getJobApplications(currentJobData.uuid)
  );

  const {
    data: jobApplicationQuery,
    refetch: jobApplicationRefetch,
    isLoading: jobApplicationLoading,
    isFetched: jobApplicationFetched,
  } = useQuery("jobApplication", () => getApplication(currentJobData.uuid));

  useEffect(() => {
    console.log("check job query", jobApplicationQuery?.data);
  }, [jobApplicationQuery]);

  const onApproveDoctor = useMutation(
    currentJobData?.status === -2 ? reapproveJob : approveJob,
    {
      onError: (error) => {
        setShowStatusModal(false);
        alert("Oops! " + error.response.data.message);
      },
      onSuccess: (res) => {
        // props.navigation.goBack();
        jobApplicationsRefetch();
        setShowStatusModal(false);
        setTimeout(() => {
          Alert.alert("Approved", "You have successfully approved this doctor");
        }, 500);
      },
    }
  );

  const onDeleteJob = useMutation(deleteJob, {
    onError: (error) => {
      setVerifying(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      props.navigation.goBack();
    },
  });
  const onSubmitCancellation = useMutation(submitCancellation, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      Alert.alert(
        "Cancellation Submitted",
        "You have submitted a cancellation for this job"
      );
    },
  });
  const onAcceptCancellation = useMutation(acceptCancellation, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      Alert.alert(
        "Cancellation Accepted",
        "You have accepted the cancellation for this job"
      );
    },
  });

  const onRejectCancellation = useMutation(rejectCancellation, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      Alert.alert(
        "Cancellation Rejected",
        "You have rejected the cancellation for this job"
      );
    },
  });
  useEffect(() => {
    if (jobQuery?.data) {
      setJobData(jobQuery.data.data);
    }
  }, [jobQuery]);

  useEffect(() => {
    console.log("check if canceled", currentJobData);
    let receivedCancellations = cancelQuery?.data?.data?.received;
    if (receivedCancellations?.length > 0) {
      receivedCancellations.map((cancellation) => {
        if (cancellation.job_uuid === currentJobData.uuid) {
          setCancelData(cancellation);
          setTimeout(() => {
            setShowCancelJobModal(true);
            setDisablePendingCancellation(false);
          }, 1000);
        }
      });
    }
  }, [cancelQuery]);

  let jobApplicationData = jobApplicationsQuery?.data?.data;
  useEffect(
    () =>
      props.navigation.addListener("beforeRemove", (e) => {
        // Prevent default behavior of leaving the screen (if needed)
        queryClient.removeQueries("job");
        queryClient.removeQueries("jobApplications");
        queryClient.removeQueries("cancellationList");
      }),
    [props.navigation]
  );

  const { isDoctor } = useSelector((state) => state.user);

  const onConfirmChangeStatus = () => {
    onApproveDoctor.mutate({
      uuid: showStatusModal,
    });
  };
  const onPressSubmitCancellation = () => {
    setCancelError(false);
    console.log("arrived herasde?", currentJobData);

    if (cancelReason) {
      console.log("arrived here? 2");

      if (isRaiseToAdmin) {
        onRejectCancellation.mutate({
          uuid: cancelData.id,
          data: {
            reject_reason: cancelReason,
          },
        });
      } else {
        console.log("arrived here?");
        onSubmitCancellation.mutate({
          uuid: currentJobData?.uuid,
          data: {
            request_reason: cancelReason,
          },
        });
      }

      setShowCancelModal(false);
      props.navigation.navigate({
        name: "HomeScreen",
      });
    } else {
      setCancelError(true);
    }
  };

  const onPressRejectCancellation = () => {
    setIsRaiseToAdmin(true);
    setShowCancelJobModal(false);
  };

  const onPressAcceptCancellation = () => {
    onAcceptCancellation.mutate({
      uuid: cancelData.id,
    });
    setShowCancelJobModal(false);
    props.navigation.goBack();
  };

  const onPressDelete = () => {
    if (Platform.OS === "web") {
      if (confirm("Do you want to delete this job?") == true) {
        onDeleteJob.mutate(currentJobData.uuid);
      }
    } else {
      Alert.alert("Are you sure?", "Do you want to delete this job?", [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "OK",
          onPress: () => {
            onDeleteJob.mutate(currentJobData.uuid);
          },
        },
      ]);
    }
  };
  let isCancelled = jobApplicationData?.filter((val) => {
    return val.status_code === -4;
  }).length;
  let approvedDoctors = jobApplicationData?.filter((val) => {
    return val.status_code > 0;
  }).length;
  let unapprovedDoctors = jobApplicationData?.filter((val) => {
    return val.status_code < 1;
  }).length;
  return (
    <>
      {Platform.OS === "web" ? <GradientHeader /> : null}

      <View>
        <View
          style={{
            backgroundColor: "white",
            borderRadius: 32,
            marginTop:
              Dimensions.get("window").width > 780
                ? 40
                : Platform.OS === "web"
                ? 24
                : 0,
            width:
              Dimensions.get("window").width > 780
                ? 780
                : Dimensions.get("window").width,
            alignSelf: "center",
          }}
        >
          <UpcomingJob
            data={currentJobData?.title ? currentJobData : jobData}
            navigation={props.navigation}
            isDescription
            showQR={() => setShowQRModal(true)}
            onPressBack={() => {
              queryClient.removeQueries("job");
              queryClient.removeQueries("jobApplications");
              dispatch(removeNavParam());
              props.navigation.navigate({
                name: "HomeScreen",
              });
            }}
          />

          <View style={{ marginBottom: -24 }} />
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate("DetailsScreen", {
                data: jobData,
                clinicView: true,
                jobCount: jobApplicationData?.length,
                status: currentJobData.status,
                isScanned: currentJobData?.is_scanned,
              });
            }}
          >
            <View
              style={{
                ...styles.editJobButton,
              }}
            >
              <Text style={{ color: "grey", fontSize: normalize(12) }}>
                View Job Description
              </Text>
            </View>
          </TouchableOpacity>
          <ScrollView
            contentContainerStyle={{
              paddingBottom: 200,
            }}
            style={{
              height: Dimensions.get("window").height * 0.6,
            }}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
          >
            {approvedDoctors > 0 && (
              <>
                <View style={{ margin: 4 }} />
                <Text style={styles.listTitle}>
                  {currentJobData?.status < 2 ? "SELECTED" : "APPROVED"}{" "}
                  APPLICANTS
                </Text>
              </>
            )}
            {jobApplicationData
              ?.filter((val) => {
                return val.status_code > 0;
              })
              .map((item, index) => {
                let doctor = item.doctor;
                return (
                  <>
                    <JobCard
                      doctorCard
                      currentJobData={currentJobData}
                      isCancelled={isCancelled}
                      isDoctor={isDoctor}
                      navigation={props.navigation}
                      data={{
                        ...doctor,
                        status:
                          isCancelled && item.status_code !== -2
                            ? 5
                            : item.status_code,
                        title: doctor.first_name + " " + doctor.last_name[0],
                        description: doctor.about,
                        profile_image: doctor.profile_image,
                      }}
                      doctorChangeStatus={(id, status) => {
                        if (status === 2) {
                          props.navigation.navigate("SelectBankScreen", {
                            jobID: currentJobData.uuid,
                          });
                        } else if (![1, 3, 4, 5, -1, -2].includes(status)) {
                          setCurrentStatus(status);
                          setShowStatusModal(item.uuid);
                        } else if (status === 5) {
                          setCurrentStatus(0);
                          setShowStatusModal(item.uuid);
                        }
                      }}
                    />
                  </>
                );
              })}
            {[2, 3].includes(currentJobData?.status) ? (
              <>
                <Row centered style={{ marginLeft: 20, marginBottom: 10 }}>
                  <MaterialCommunityIcons
                    name="alert"
                    size={14}
                    color="#FC6767"
                  />
                  <Text
                    style={{
                      fontWeight: "bold",
                      marginLeft: 4,
                      color: "#FC6767",
                      textAlign: "center",
                    }}
                  >
                    Reminder
                  </Text>
                </Row>
                {currentJobData?.is_cash ? (
                  <Text style={{ paddingHorizontal: 20, color: "grey" }}>
                    {`You are required to pay the total shift amount to the Doctor upon completion of the shift.\n\nBy booking this shift you are agreeing to directly liaise with the doctor. `}
                  </Text>
                ) : (
                  <Text style={{ paddingHorizontal: 20, color: "grey" }}>
                    {`You will be paying the total shift fee upfront to Supermedic upon successful confirmation of the Doctor.\n\nPayments will be made directly from Supermedic to the Doctor. `}
                  </Text>
                )}
              </>
            ) : null}
            {unapprovedDoctors > 0 && (
              <>
                <View style={{ margin: 14 }} />
                <Text style={styles.listTitle}>ALL APPLICANTS</Text>
              </>
            )}

            {jobApplicationData
              ?.filter((val) => {
                return val.status_code < 1;
              })
              .map((item, index) => {
                let doctor = item.doctor;
                return (
                  <>
                    <JobCard
                      doctorCard
                      isDoctor={isDoctor}
                      navigation={props.navigation}
                      data={{
                        ...doctor,
                        status:
                          isCancelled && item.status_code !== -2
                            ? 5
                            : item.status_code,
                        title: doctor.first_name + " " + doctor.last_name[0],
                        description: doctor.about,
                        profile_image: doctor.profile_image,
                      }}
                      isJobCancelled={currentJobData.status === -2}
                      doctorChangeStatus={(id, status) => {
                        if (status === 2) {
                          props.navigation.navigate("SelectBankScreen", {
                            jobID: currentJobData.uuid,
                          });
                        } else if (status === -3) {
                          !disablePendingCancellation &&
                            setShowCancelJobModal(item.uuid);
                        } else if (status === 5) {
                          setCurrentStatus(0);
                          setShowStatusModal(item.uuid);
                        } else if (
                          status === -1 &&
                          currentJobData.status === -2
                        ) {
                          // if job cancelled but allow clinic reselect rejected doctors
                          setCurrentStatus(0);
                          setShowStatusModal(item.uuid);
                        } else if (![1, 3, 4, 5, -1, -2].includes(status)) {
                          setCurrentStatus(status);
                          setShowStatusModal(item.uuid);
                        }
                      }}
                    />
                  </>
                );
              })}
            {jobApplicationsLoading ? (
              <View style={{ marginTop: 42 }}>
                {currentJobData?.job_applications?.length > 0 &&
                  currentJobData?.job_applications.map(() => {
                    return <JobCard doctorCard empty />;
                  })}
              </View>
            ) : null}
            {jobApplicationsFetched &&
              jobApplicationData?.length === 0 &&
              !jobApplicationsLoading && (
                <Centered style={{ marginVertical: 50 }}>
                  <MaterialCommunityIcons
                    name="timer-sand-empty"
                    size={20}
                    color="rgba(0,0,0,0.2)"
                  />
                  <Text style={{ color: "rgba(0,0,0,0.2)", marginTop: 18 }}>
                    No applications yet
                  </Text>
                </Centered>
              )}
            <View style={{ margin: 20 }} />
            {currentJobData?.status == null ? (
              <Centered
                style={{
                  flex: 1,
                  justifyContent: "flex-end",
                  paddingBottom: 100,
                }}
              >
                <Row centered>
                  <MaterialCommunityIcons
                    name={"delete-outline"}
                    size={14}
                    color="red"
                    style={{ opacity: 0.6 }}
                  />
                  <TouchableOpacity onPress={() => onPressDelete()}>
                    <Text
                      style={{
                        color: "red",
                        fontSize: normalize(12),
                        marginLeft: 4,
                        opacity: 0.6,
                      }}
                    >
                      Delete This Job
                    </Text>
                  </TouchableOpacity>
                </Row>
              </Centered>
            ) : null}

            {[2, 3].includes(currentJobData?.status) ? (
              <Centered style={{ marginTop: 200 }}>
                <Row centered>
                  <MaterialCommunityIcons
                    name={"cancel"}
                    size={14}
                    color="red"
                    style={{ opacity: 0.6 }}
                  />
                  <Text
                    onPress={() => setShowCancelModal(true)}
                    style={{
                      color: "red",
                      fontSize: normalize(12),
                      marginLeft: 4,
                      opacity: 0.6,
                    }}
                  >
                    Cancel This Job
                  </Text>
                </Row>
              </Centered>
            ) : null}
          </ScrollView>
        </View>
      </View>
      <Modal
        isVisible={showQRModal}
        onBackdropPress={() => setShowQRModal(false)}
      >
        <View
          style={{
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          {jobQRQuery?.data.data?.qr && (
            <QRCode
              value={jobQRQuery.data.data.qr}
              // logo={{uri: base64Logo}}
              size={
                Dimensions.get("window").width > 450
                  ? 450
                  : Dimensions.get("window").width - 100
              }
              logoBackgroundColor="transparent"
            />
          )}
        </View>
      </Modal>
      <ChangeStatusModal
        isVisible={showStatusModal}
        onHide={() => setShowStatusModal(false)}
        onConfirm={() => {
          onConfirmChangeStatus();
        }}
        isReject={currentStatus === 1}
        fromStatus={currentStatus}
        toStatus={currentStatus + 1}
      />
      <CancelRequestModal
        isVisible={showCancelModal}
        onBackdropPress={() => setShowCancelModal(false)}
        onConfirm={() => onPressSubmitCancellation()}
        onCancel={() => {
          setShowCancelModal(false);
          setCancelError(false);
          setIsRaiseToAdmin(false);
        }}
        onChangeText={(text) => {
          setCancelReason(text);
          setCancelError(false);
        }}
        isRaiseToAdmin={isRaiseToAdmin}
        // error={cancelError}
      />
      <CancelJobModal
        isVisible={showCancelJobModal}
        onBackdropPress={() => setShowCancelJobModal(false)}
        onConfirm={() => onPressAcceptCancellation()}
        onCancel={() => onPressRejectCancellation()}
        reason={cancelData?.request_reason}
        onModalHide={() => {
          if (isRaiseToAdmin) {
            setTimeout(() => {
              setShowCancelModal(true);
            }, 500);
          }
        }}
      />
    </>
  );
}

const styles = {
  title: {
    margin: 18,
  },
  jobsAvailableTitle: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 18,
    marginBottom: 10,
  },
  filterText: {
    fontWeight: "bold",
  },
  divider: {
    alignSelf: "center",
    width: "90%",
    height: 0.5,
    backgroundColor: "#eee",
    marginBottom: 24,
  },
  sortContainer: {
    marginTop: -18,
    alignItems: "flex-end",
    marginRight: 18,
  },
  sortByText: {
    fontSize: normalize(12),
    marginBottom: 2,
  },
  sortText: {
    fontWeight: "bold",
  },
  editJobButton: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 16,
    marginTop: 8,
    marginHorizontal: 20,
    borderRadius: 8,
    borderColor: "#E5E5E5",
    borderWidth: 1,
    padding: 10,
  },
  listTitle: {
    fontSize: normalize(14),
    fontWeight: "bold",
    color: "rgba(0,0,0,0.4)",
    margin: 16,
    marginTop: 0,
  },
};
