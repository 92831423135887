import {
  Feather,
  FontAwesome,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import * as ImagePicker from "expo-image-picker";
import React, { useState, useEffect } from "react";
import { Dimensions, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import {
  Centered,
  ErrorMessage,
  Input,
  Row,
  StepHeader,
} from "../../../components/all";
import states from "../../../constants/states";
import { resetEmptyFields } from "../../../reducers/auth/register";
import { resetEmptyFields as resetProfileEmptyFields } from "../../../reducers/profile/profile";
import Styles from "../../../constants/styles";

export default function Step4({
  setFocusedInput,
  focusedInput,
  setField,
  allFields,
  editProfile,
  setEditProfileImage,
  isWeb,
  currentStep,
}) {
  const dispatch = useDispatch();
  const { isDoctor } = useSelector((state) => state.user);
  const { errorFields } = useSelector(
    (state) => state[editProfile ? "profile" : "register"]
  );
  const setErrorFields = () => {
    dispatch(editProfile ? resetProfileEmptyFields() : resetEmptyFields());
  };

  const input = {
    clinic_name: "clinic_name",
    phone_number: isDoctor ? "phone_number" : "clinic_phone_number",
    emergency_number: "emergency_number",
    emergency_name: "emergency_name",
    address: "address",
    city: "city",
    postcode: "postcode",
    state: "state",
    clinic_profile_image: "clinic_profile_image",
    business_registration_number: "business_registration_number",
  };

  const [imageUrl, setImageUrl] = useState(
    allFields?.clinic_profile_image || null
  );

  const onPressImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert(
        "Sorry, we need camera roll permissions to make this work. Please go to Settings > Permissions and allow it."
      );
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 0.5,
        base64: true,
        height: 100,
        width: 100,
      });
      if (result) {
        setImageUrl(result.base64);
        setField(input.clinic_profile_image, result.base64);
      }
    }
  };

  useEffect(() => {
    if (allFields?.clinic_profile_image) {
      setImageUrl(allFields?.clinic_profile_image);
    }
  }, [allFields?.clinic_profile_image]);
  if (isWeb && currentStep !== 3) return <></>;

  return (
    <View
      style={{
        ...Styles.webSmallContainer,
      }}
    >
      {editProfile && isDoctor ? (
        <View style={{ marginTop: -45 }} />
      ) : (
        <StepHeader
          label={isDoctor ? `Contact \nInformation` : `Clinic \nInformation`}
          icon={
            isDoctor ? (
              <Feather name="smartphone" size={24} color="black" />
            ) : (
              <FontAwesome5 name="clinic-medical" size={24} color="black" />
            )
          }
          subtitle={
            isDoctor ? `How can we reach you?` : `Describe your clinic detail.`
          }
          showImage={!isDoctor}
          imageUrl={imageUrl}
          imageError={errorFields.includes(input.clinic_profile_image)}
          onPressImage={() => {
            setEditProfileImage && setEditProfileImage(true);
            onPressImage();
          }}
          imageUrl={imageUrl}
        />
      )}
      <Centered
        style={{ width: Dimensions.get("window").width > 480 ? 480 : "100%" }}
      >
        {isDoctor ? null : (
          <>
            <Input
              onChangeText={(val) => {
                setField(input.clinic_name, val);
              }}
              value={allFields[input.clinic_name]}
              icon={<FontAwesome5 name="hospital" size={14} color="grey" />}
              placeholder="Clinic Name"
              containerStyle={{ width: "90%", marginTop: 24 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.clinic_name);
              }}
              focused={focusedInput === input.clinic_name}
              error={errorFields.includes(input.clinic_name)}
              autoCapitalize="words"
            />
            <Input
              onChangeText={(val) => {
                setField(input.business_registration_number, val);
              }}
              value={allFields[input.business_registration_number]}
              placeholder="Business Registration No. (Borang F/SSM)"
              icon={<FontAwesome name="id-card-o" size={14} color="grey" />}
              containerStyle={{ width: "90%", marginTop: 10 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.business_registration_number);
              }}
              error={
                errorFields.includes(input.business_registration_number) ||
                errorFields.includes("business_registration_numberError")
              }
              focused={focusedInput === input.business_registration_number}
              maxLength={20}
            />
          </>
        )}
        <Input
          onChangeText={(val) => {
            setField(input.phone_number, val);
          }}
          value={allFields[input.phone_number]}
          icon={<FontAwesome name="phone" size={14} color="grey" />}
          placeholder="Phone No."
          containerStyle={{ width: "90%", marginTop: isDoctor ? 24 : 10 }}
          onBlur={() => setFocusedInput(null)}
          keyboardType="numeric"
          maxLength={15}
          onFocus={() => {
            setErrorFields();
            setFocusedInput(input.phone_number);
          }}
          focused={focusedInput === input.phone_number}
          error={
            errorFields.includes(input.phone_number) ||
            errorFields.includes("phoneError")
          }
        />

        {!isDoctor ? null : (
          <>
            <Input
              onChangeText={(val) => {
                setField(input.emergency_number, val);
              }}
              value={allFields[input.emergency_number]}
              icon={<FontAwesome name="mobile-phone" size={14} color="grey" />}
              placeholder="Emergency Number"
              containerStyle={{ width: "90%", marginTop: 10 }}
              onBlur={() => setFocusedInput(null)}
              keyboardType="numeric"
              maxLength={15}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.emergency_number);
              }}
              focused={focusedInput === input.emergency_number}
              error={
                errorFields.includes(input.emergency_number) ||
                errorFields.includes("phoneError")
              }
            />
            <Input
              onChangeText={(val) => {
                setField(input.emergency_name, val);
              }}
              value={allFields[input.emergency_name]}
              icon={<FontAwesome name="user" size={14} color="grey" />}
              placeholder="Emergency Name"
              containerStyle={{ width: "90%", marginTop: 10 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields();
                setFocusedInput(input.emergency_name);
              }}
              focused={focusedInput === input.emergency_name}
              error={errorFields.includes(input.emergency_name)}
              autoCapitalize="words"
            />
          </>
        )}
        <Input
          onChangeText={(val) => {
            setField(input.address, val);
          }}
          value={allFields[input.address]}
          icon={<FontAwesome name="home" size={14} color="grey" />}
          placeholder={isDoctor ? "Home Address" : "Clinic Address"}
          containerStyle={{ width: "90%", marginTop: 10 }}
          onBlur={() => setFocusedInput(null)}
          onFocus={() => {
            setErrorFields();
            setFocusedInput(input.address);
          }}
          focused={focusedInput === input.address}
          error={errorFields.includes(input.address)}
          autoCapitalize="words"
        />
        <Input
          onChangeText={(val) => {
            setField(input.city, val);
          }}
          value={allFields[input.city]}
          icon={<MaterialIcons name="location-city" size={14} color="grey" />}
          placeholder="City/Town"
          containerStyle={{ width: "90%", marginTop: 10 }}
          onBlur={() => setFocusedInput(null)}
          onFocus={() => {
            setErrorFields();
            setFocusedInput(input.city);
          }}
          error={errorFields.includes(input.city)}
          focused={focusedInput === input.city}
          autoCapitalize="words"
        />
        <Row>
          <Input
            onChangeText={(val) => {
              setField(input.postcode, val);
            }}
            value={allFields[input.postcode]}
            icon={<FontAwesome name="location-arrow" size={14} color="grey" />}
            placeholder="Postal Code"
            containerStyle={{
              width:
                Dimensions.get("window").width > 480
                  ? 210
                  : Dimensions.get("window").width / 2.28,
              marginTop: 10,
              marginRight: 10,
            }}
            keyboardType="numeric"
            onBlur={() => setFocusedInput(null)}
            maxLength={5}
            onFocus={() => {
              setErrorFields();
              setFocusedInput(input.postcode);
            }}
            error={
              errorFields.includes(input.postcode) ||
              errorFields.includes("postcodeError")
            }
            focused={focusedInput === input.postcode}
            inputStyle={{ width: "50%" }}
          />
          <Input
            isPicker
            onChangeText={(val) => {
              setField(input.state, val);
            }}
            value={allFields[input.state]}
            placeholder="State"
            containerStyle={{
              width:
                Dimensions.get("window").width > 480
                  ? 210
                  : Dimensions.get("window").width / 2.28,
              marginTop: 10,
            }}
            onBlur={() => setFocusedInput(null)}
            onFocus={() => {
              setErrorFields();
              setFocusedInput(input.state);
            }}
            error={errorFields.includes(input.state)}
            focused={focusedInput === input.state}
            pickerItems={states}
          />
        </Row>
      </Centered>
      <View style={{ margin: 10, marginLeft: 24, marginBottom: 20 }}>
        <ErrorMessage
          visible={
            errorFields.includes(input.phone_number) ||
            errorFields.includes(input.emergency_number) ||
            errorFields.includes(input.address) ||
            errorFields.includes(input.city) ||
            errorFields.includes(input.postcode) ||
            errorFields.includes(input.state)
          }
          label="Please fill in the required fields."
        />
        <ErrorMessage
          visible={errorFields.includes("phoneError")}
          label="Please enter a valid Mobile number."
        />
        <ErrorMessage
          visible={errorFields.includes("postcodeError")}
          label="Please enter a valid postcode."
        />
        <ErrorMessage
          visible={errorFields.includes("business_registration_numberError")}
          label={"Please enter a valid Business Registration Number."}
        />
      </View>
    </View>
  );
}
