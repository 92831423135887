import { FontAwesome, Feather } from "@expo/vector-icons";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Dimensions,
  Text,
  View,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  Platform,
} from "react-native";
import Fade from "react-native-fade";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Centered,
  Input,
  StepHeader,
  Button,
  Loading,
  BackButton,
  Row,
} from "../../components/all";

import {
  submitBankDetails,
  getAllBanks,
  getBankDetails,
  updateBankDetails,
} from "../../services/shared";
import Styles from "../../constants/styles";
import { normalize } from "../../utils/normalize";
import oldBankList from "../../constants/lov/banks";
export default function StepPayment({ navigation, route }) {
  const [gotoURL, setGoToURL] = useState(route.params?.gotoURL);
  const [showBackButton, setShowBackButton] = useState(false);
  const dispatch = useDispatch();
  const { fields } = useSelector((state) => state.register);
  const { isDoctor } = useSelector((state) => state.user);
  const { data: bankListQuery } = useQuery("bankList", getAllBanks);
  const {
    data: bankDetailsQuery,
    isLoading,
    isError,
  } = useQuery("bankDetail", () => getBankDetails(route.params?.slug), {
    enabled: !!gotoURL,
  });
  let allBankList = bankListQuery?.data?.data || [];
  let existingBankDetails = bankDetailsQuery?.data?.data;
  const [focusedInput, setFocusedInput] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [allFields, setAllFields] = useState({
    full_name: "",
    bank_code: "",
    account_number: "",
  });
  const input = {
    bank_code: "bank_code",
    full_name: "full_name",
    account_number: "account_number",
  };
  const setField = (field, value) => {
    setAllFields({ ...allFields, [field]: value });
  };

  useEffect(() => {
    // fallback if curclec bank error fails
    if (isError) {
      allBankList = oldBankList;
    }
  }, [isError]);

  useEffect(() => {
    if (!gotoURL && fields?.first_name) {
      setField(input.full_name, `${fields.first_name} ${fields.last_name}`);
    }
  }, []);

  useEffect(() => {
    if (existingBankDetails?.full_name) {
      setShowBackButton(true);
      setAllFields(existingBankDetails);
    } else if (gotoURL) {
      setShowBackButton(true);
      setGoToURL(false);
    }
  }, [existingBankDetails]);
  const sendBankDetails = useMutation(
    gotoURL ? updateBankDetails : submitBankDetails,
    {
      onError: (error) => {
        setVerifying(false);
        if (
          error.response.data.message ===
          "One or more bank account added already exist"
        ) {
          Alert.alert(
            "Oops!",
            "Bank account already exist. Please contact help@supermedic.health for assistance.",
            [
              {
                text: "Cancel",
                style: "cancel",
              },
              {
                text: "Okay",
                onPress: () => {
                  setAllFields({
                    full_name: "",
                    bank_code: "",
                    account_number: "",
                  });
                },
              },
            ]
          );
          // setAllFields({
          //   full_name: "",
          //   bank_code: "",
          //   account_number: "",
          // });
          // route.params?.fromHome
          //   ? navigation.goBack()
          //   : navigation.navigate("CompleteRegistrationScreen");
        } else {
          alert("Oops! " + error.response.data.message);
        }
      },
      onSuccess: (res) => {
        setVerifying(false);
        if (gotoURL) {
          Alert.alert(
            "Success!",
            "Your bank details have been updated successfully.",
            [
              {
                text: "Okay",
                onPress: () => {
                  setAllFields({
                    full_name: "",
                    bank_code: "",
                    account_number: "",
                  });
                  navigation.goBack();
                },
              },
            ]
          );
        } else {
          setAllFields({
            full_name: "",
            bank_code: "",
            account_number: "",
          });
          route.params?.fromHome
            ? navigation.goBack()
            : navigation.navigate("CompleteRegistrationScreen");
        }
      },
    }
  );

  const onSubmit = () => {
    Keyboard.dismiss();
    setErrorFields([]);

    const result = ["full_name", "bank_code", "account_number"].filter(
      (val) => {
        return (
          allFields[val] === undefined ||
          allFields[val] === "" ||
          allFields[val] === null
        );
      }
    );

    if (allFields.account_number?.length < 5) {
      result.push(input.account_number);
    }

    if (result.length === 0) {
      setVerifying(true);
      sendBankDetails.mutate({
        data: {
          bank_code: allFields.bank_code,
          full_name: allFields.full_name,
          account_number: allFields.account_number,
        },
        slug: route.params?.slug,
      });
    } else {
      setErrorFields(result);
    }
  };

  return (
    <>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          ...Styles.webSmallContainer,
        }}
        keyboardShouldPersistTaps="handled"
      >
        <>
          {showBackButton ? (
            <BackButton
              navigation={navigation}
              ignoreBack
              onPressBack={() => {
                navigation.goBack();
              }}
            />
          ) : (
            <View style={{ margin: 48 }} />
          )}
          <View
            style={{
              marginTop: 20,
              flex: 1,
            }}
          >
            <StepHeader
              label={`Bank Account\nInformation`}
              icon={<FontAwesome name="bank" size={24} color="black" />}
              presubtitle={
                isDoctor
                  ? `Enter valid bank account details, shift payment from clinics will be sent to this account number you provide. `
                  : `Enter valid bank account details, this information will be used in an event of a refund.`
              }
            />
            <Centered>
              <Input
                onChangeText={(val) => {
                  setErrorFields([]);
                  setField(input.full_name, val);
                }}
                value={allFields[input.full_name]}
                icon={<FontAwesome name="user" size={14} color="grey" />}
                placeholder="Full Name"
                containerStyle={{ width: "90%", marginTop: 24 }}
                onBlur={() => setFocusedInput(null)}
                onFocus={() => {
                  setErrorFields([]);
                  setFocusedInput(input.full_name);
                }}
                error={errorFields.includes(input.full_name)}
                focused={focusedInput === input.full_name}
              />
              <Input
                isPicker
                pickerItems={allBankList.map((val) => {
                  return {
                    label: val.name,
                    value: val.code,
                  };
                })}
                onChangeText={(val) => {
                  setErrorFields([]);
                  setField(input.bank_code, val);
                }}
                displayValue={
                  allBankList?.find(
                    (val) => val.code === allFields[input.bank_code]
                  )?.name || false
                }
                value={allFields[input.bank_code]}
                icon={<FontAwesome name="bank" size={14} color="grey" />}
                placeholder="Bank Name"
                containerStyle={{ width: "90%", marginTop: 16 }}
                onBlur={() => setFocusedInput(null)}
                onFocus={() => {
                  setErrorFields([]);
                  setFocusedInput(input.bank_code);
                }}
                error={errorFields.includes(input.bank_code)}
                focused={focusedInput === input.bank_code}
              />
              <Input
                keyboardType="numeric"
                onChangeText={(val) => {
                  setErrorFields([]);
                  setField(input.account_number, val);
                }}
                value={allFields[input.account_number]}
                icon={<FontAwesome name="bank" size={14} color="grey" />}
                placeholder="Bank Account Number"
                containerStyle={{ width: "90%", marginTop: 16 }}
                onBlur={() => setFocusedInput(null)}
                onFocus={() => {
                  setErrorFields([]);
                  setFocusedInput(input.account_number);
                }}
                error={errorFields.includes(input.account_number)}
                focused={focusedInput === input.account_number}
              />
            </Centered>
            <View style={{ margin: 10, marginLeft: 24 }}>
              <Fade visible={errorFields.length} duration={300}>
                <Text style={{ fontSize: normalize(10), color: "red" }}>
                  Please fill in the required field
                </Text>
              </Fade>
            </View>
            <View style={{ marginHorizontal: 24 }}>
              <Text style={{ color: "#FF4B4B", fontSize: 12 }}>
                Supermedic is only able to send you payment due if any with this
                account number including reimbursements. Information is kept
                private and confidential.
              </Text>
            </View>
            <Centered style={{ width: "100%" }}>
              <Row>
                {gotoURL ? (
                  <Button
                    label={`Cancel`}
                    onPress={() => navigation.goBack()}
                    type={"secondary"}
                    style={{
                      marginTop: 80,
                      alignItems: "center",
                    }}
                    textStyle={{
                      textAlign: "center",
                    }}
                  />
                ) : null}
                <Button
                  label={gotoURL ? `Update` : `Submit`}
                  onPress={onSubmit}
                  type={"primary"}
                  style={{
                    marginTop: 80,
                    alignItems: "center",
                  }}
                  textStyle={{
                    textAlign: "center",
                  }}
                />
              </Row>
            </Centered>
          </View>
        </>
      </ScrollView>
      {Platform.OS === "web" ? null : (
        <Loading visible={verifying || isLoading} />
      )}
    </>
  );
}
