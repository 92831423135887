import { Dimensions, Platform, PixelRatio } from "react-native";

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");

// based on iphone 5s's scale
const scale = SCREEN_WIDTH / 428;

export function normalize(size) {
  const newSize = size * scale;
  if (Platform.OS === "ios") {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else if (Platform.OS === "web") {
    const webScale = SCREEN_WIDTH / 520;
    const webSize = size * webScale;

    return SCREEN_WIDTH > 780 ? size : webSize;
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  }
}
