import PropTypes from "prop-types";
import * as React from "react";
import { Text } from "react-native";
import { normalize } from "../utils/normalize";

const ErrorMessage = (props) => {
  if (!props.visible) return null;
  return (
    <Text
      {...props}
      style={[
        {
          fontSize: normalize(11),
          color: "red",
        },
        props.style,
      ]}
    >
      {props.label}
    </Text>
  );
};

ErrorMessage.PropTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default ErrorMessage;
