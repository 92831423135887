import axios from "axios";
import axiosRetry from "axios-retry";
import { Alert, Platform } from "react-native";

axiosRetry(axios, { retries: 1 });

let api = axios.create({
  baseURL: "https://supermedic.app/api",
  // baseURL: "http://127.0.0.1:8000/api",
  headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
  },
});
let isLoggingOut = true; // global

let interceptor = (onUnauthenticated, loggedIn) => {
  const onRequestSuccess = (config) => {
    return config;
  };
  const onRequestFail = (error) => {
    return Promise.reject(error);
  };
  api.interceptors.request.use(onRequestSuccess, onRequestFail);

  const onResponseSuccess = (response) => {
    isLoggingOut = true;
    return response;
  };
  const onResponseFail = (error) => {
    const status = error.status || error.response?.status;

    if (status === 401 && loggedIn) {
      if (isLoggingOut) {
        onUnauthenticated();
        isLoggingOut = false;
      }
    }

    if (status >= 500) {
      if (Platform.OS === "web") {
        const response = confirm(
          "Oops! " + "Server error. Please try again later."
        );
        if (response) {
          onUnauthenticated();
        }
      } else {
        console.log(JSON.stringify(error))
        Alert.alert("Oops!", "Server error. Please try again later.", [
          {
            text: "Cancel",
            onPress: () => console.log("Cancel Pressed"),
            style: "cancel",
          },
          { text: "Confirm", onPress: () => console.log("confirm") },
        ]);

      }
    } else {
      return Promise.reject(error);
    }
  };
  api.interceptors.response.use(onResponseSuccess, onResponseFail);
};

export { interceptor };

export default api;
