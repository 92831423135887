import { FontAwesome, Feather } from "@expo/vector-icons";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Dimensions,
  Text,
  View,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import Fade from "react-native-fade";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Centered,
  Input,
  StepHeader,
  Button,
  Loading,
  BackButton,
} from "../../../components/all";
import { resetEmptyFields, addFields } from "../../../reducers/auth/register";
import { validateOTP, requestOTP } from "../../../services/auth";
import { normalize } from "../../../utils/normalize";
import api from "../../../services/api";
import Styles from "../../../constants/styles";
export default function Step5({ navigation }) {
  const dispatch = useDispatch();
  const { isDoctor } = useSelector((state) => state.user);

  const { fields, errorFields } = useSelector((state) => state.register);
  const setErrorFields = () => {
    dispatch(resetEmptyFields());
  };

  const [otpInput, setOTPInput] = useState("");
  const [focusedInput, setFocusedInput] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const [verifying, setVerifying] = useState(false);

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const input = {
    emailCode: "emailCode",
  };

  const submitOTP = useMutation(validateOTP, {
    onError: (error) => alert("Oops! " + error.response.data.message),
    onSuccess: (res) => {
      // api.defaults.headers.common.Authorization = `Bearer ${res.data.data.token}`;

      navigation.navigate("CompleteRegistrationScreen");
    },
  });

  const sendOTP = useMutation(requestOTP, {
    onError: (error) => {
      setVerifying(false);
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {
      setVerifying(false);
      setTimeLeft(60);
      dispatch(addFields({ ...fields, nonce: res.data.data.nonce }));
    },
  });

  const checkOTP = (otpVal) => {
    submitOTP.mutate({
      otp: otpVal,
      nonce: fields.nonce,
      email: fields.email,
    });
  };

  const resendOTP = () => {
    setVerifying(true);
    sendOTP.mutate({
      email: fields.email,
    });
  };

  return (
    <>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <>
          <BackButton
            navigation={navigation}
            ignoreBack
            onPressBack={() => {
              Alert.alert(
                "Are you sure?",
                "This action will bring you back to the login page",
                [
                  {
                    text: "Cancel",
                    style: "cancel",
                  },
                  {
                    text: "OK",
                    onPress: () => {
                      navigation.replace("AuthScreen");
                    },
                  },
                ]
              );
            }}
          />
          <View
            style={{
              ...Styles.webSmallContainer,
              marginTop: 50,
              flex: 1,
            }}
          >
            <StepHeader
              label={`Email \nVerification`}
              icon={<FontAwesome name="envelope-o" size={24} color="black" />}
              presubtitle={`We have sent an email to `}
              subtitle={fields.email}
            />

            <Centered style={{ width: "100%" }}>
              <Input
                keyboardType="numeric"
                onChangeText={(val) => {
                  setErrorFields();
                  setOTPInput(val);
                  if (val?.length === 6) {
                    checkOTP(val);
                  }
                }}
                value={otpInput}
                icon={<FontAwesome name="envelope" size={14} color="grey" />}
                placeholder="Enter your 6-digit code"
                containerStyle={{ width: "90%", marginTop: 24 }}
                maxLength={6}
                onBlur={() => setFocusedInput(null)}
                onFocus={() => {
                  setErrorFields();
                  setFocusedInput(input.emailCode);
                }}
                error={errorFields.includes(input.emailCode)}
                focused={focusedInput === input.emailCode}
              />
            </Centered>
            <Text
              style={{
                marginHorizontal: 30,
                marginTop: 12,
                fontSize: normalize(12),
                color: "#FF4B4B",
              }}
            >
              It may take a few seconds to receive you may also check your spam
              folder for the OTP.
            </Text>

            <Centered style={{ width: "100%" }}>
              <Button
                label={`Resend Email Verification`}
                onPress={resendOTP}
                icon={
                  <Feather
                    name="mail"
                    size={14}
                    color={timeLeft > 0 ? "black" : "white"}
                  />
                }
                type={timeLeft > 0 ? "secondary" : "primary"}
                style={{
                  marginTop: 80,
                  alignItems: "center",
                }}
                textStyle={{
                  textAlign: "center",
                  color: timeLeft > 0 ? "black" : "white",
                }}
                disabled={timeLeft > 0}
              />
            </Centered>
            <Centered style={{ width: "100%", marginTop: 0 }}>
              <Text style={{ color: "grey", fontSize: normalize(12) }}>
                {timeLeft === 0
                  ? "Did not receive email?"
                  : "Please wait for " + timeLeft + " seconds"}
              </Text>
            </Centered>
            <View style={{ margin: 10, marginLeft: 24 }}>
              <Fade
                visible={errorFields.includes(input.emailCode)}
                duration={300}
              >
                <Text style={{ fontSize: normalize(10), color: "red" }}>
                  Please fill in the required field
                </Text>
              </Fade>
            </View>
          </View>
        </>
      </TouchableWithoutFeedback>

      <Loading visible={verifying} />
    </>
  );
}
