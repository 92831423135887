import {
  AntDesign,
  Feather,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import {
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Alert,
  Dimensions,
  Platform,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  Categories,
  CategoryTab,
  DateTimeSelect,
  FloatingButton,
  Input,
  Modal,
  Row,
  ModalButtons,
  JobCard,
  GradientHeader,
  Loading,
} from "../../components/all";
import specializations from "../../constants/stub/specializations.json";
import { getFacilities } from "../../services/auth";
import { postJob, updateJob } from "../../services/job";
import {
  getClinics,
  getProfile,
  getProfileFacilities,
} from "../../services/profile";
import moment from "moment";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { useQuery, useMutation } from "react-query";
import { normalize } from "../../utils/normalize";
import { useDispatch, useSelector } from "react-redux";
import { addNavParam } from "../../reducers/nav/params";

const PostJob = (props) => {
  const { isDev } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const {
    data: facilitiesQuery,
    refetch,
    isLoading,
  } = useQuery("facilities", getFacilities);
  const editJobData = props.route.params?.editJobData;

  const safeInsets = useSafeAreaInsets();
  const [filterText, setFilterText] = useState("");
  const [selectedSpecialization, setSelectedSpecialization] = useState([]);
  const [allDateTime, setAllDateTime] = useState({});
  const [isUrgent, setIsUrgent] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [focusedInput, setFocusedInput] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [facilityData, setFacilityData] = useState(null);
  const [hourlyRate, setHourlyRate] = useState(0);
  const [isSpecialist, setIsSpecialist] = useState(false);
  const [isCash, setIsCash] = useState(1);
  const [isTimeSet, setIsTimeSet] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [verifying, setVerifying] = useState(false);

  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Doctor");
  const [selectedCategoryID, setSelectedCategoryID] = useState(1);


  const [allFields, setAllFields] = useState({
    description: "",
    price: "",
  });
  const [title, setTitle] = useState("");

  const input = {
    title: "title",
    description: "description",
    price: "price",
    search: "search",
    note: "note",
    hours: "hours",
  };
  const {
    data: clinicsQuery,
    refetch: clinicsRefetch,
    isLoading: clinicLoading,
  } = useQuery("clinics", getClinics);
  const {
    data: profileQuery,
    refetch: profileRefetch,
    isLoading: profileLoading,
  } = useQuery("profileFacilities", getProfileFacilities, {});

  useEffect(() => {
    let doctorFacilities = profileQuery?.data?.data;
    if (profileQuery && selectedSpecialization?.length === 0) {
      let preSelectFacilities = doctorFacilities?.map((val) => val.slug);
      setSelectedSpecialization(
        preSelectFacilities?.length ? [...preSelectFacilities[0]] : []
      );
    }
  }, [profileQuery]);

  useEffect(() => {
    if (editJobData) {
      setTitle([editJobData?.title]);
      setAllFields({
        ...editJobData,
        startDate: moment(editJobData.start_timestamp).valueOf(),
        endDate: moment(editJobData.end_timestamp).valueOf(),
        startTime: moment(editJobData.start_timestamp).valueOf(),
        endTime: moment(editJobData.end_timestamp).valueOf(),
      });
      setSelectedSpecialization(editJobData?.facilities || []);
      setIsUrgent(editJobData?.is_emergency);
    } else {
      const startTime = moment();
      const remainder = 30 - (startTime.minute() % 30);
      setAllFields({
        ...allFields,
      });
    }
  }, [editJobData]);

  useEffect(() => {
    if (allFields.startTime) {
      if (
        moment(
          moment(
            `${moment(allFields.startDate).format("YYYY-MM-DD")} ${moment(
              allFields.startTime
            ).format("H:mm A")}`,
            "YYYY-MM-DD H:mm A"
          )
        ).isBefore(moment().add(24, "hours"))
      ) {
        setIsUrgent(true);
      } else {
        setIsUrgent(false);
      }
    }
  }, [allFields]);

  useEffect(() => {
    if (facilitiesQuery?.data) {
      setFacilityData(facilitiesQuery.data.data);
      let cats = [];
      facilitiesQuery.data.data.map((item) => {
        cats.push(item.category);
      });
      setAllCategories([...new Set(cats)]);
    }
  }, [facilitiesQuery]);

  const onPostJob = useMutation(editJobData ? updateJob : postJob, {
    onError: (error) => {
      setVerifying(false);
      Alert.alert("Post Job Error", error?.response.data.message);
    },
    onSuccess: (res) => {
      setVerifying(false);

      const uuid = res.data.data.uuid;
      dispatch(
        addNavParam({
          routeName: "PostJobSuccess",
          data: uuid,
        })
      );
      props.navigation.navigate("Home");

      // Alert.alert("Post Job Success", JSON.stringify(res));
    },
  });

  const onConfirmPostJob = () => {
    setVerifying(true);
    let { clinic, ...restFields } = allFields;
    setShowConfirmModal(false);
    onPostJob.mutate({
      ...restFields,
      start_timestamp: moment(
        `${moment(allFields.startDate).format("YYYY-MM-DD")} ${moment(
          allFields.startTime
        ).format("H:mm A")}`,
        "YYYY-MM-DD H:mm A"
      ).valueOf(),
      end_timestamp: moment(
        `${moment(allFields.endDate).format("YYYY-MM-DD")} ${moment(
          allFields.endTime
        ).format("H:mm A")}`,
        "YYYY-MM-DD H:mm A"
      ).valueOf(),
      is_emergency: isUrgent,
      facilities: selectedSpecialization,
      title: title.length > 1 ? title.join(", ") : title[0],
      price: parseInt(totalPrice),
      is_cash: !!isCash,
    });
  };

  const pickedItem = (index, children, name, specialist) => {
    let newSelected = [];
    let newTitle = [];
    if (newSelected.includes(index)) {
      newTitle = newTitle.filter((item) => item !== name);
      newSelected = newSelected.filter((item) => {
        let childrenIndex = [];
        if (children) {
          children.forEach((child) => {
            childrenIndex.push(child.id);
          });
        }
        return ![index, ...childrenIndex].includes(item);
      });
    } else {
      newTitle.push(name);
      newSelected.push(index);
      if (children) {
        children.map((item) => {
          newSelected.push(item.id);
        });
      }
    }
    setIsSpecialist(specialist)
    setTitle(newTitle);
    setSelectedSpecialization(newSelected);
  };

  const setField = (field, value) => {
    setAllFields({ ...allFields, [field]: value });
  };

  const onPressDone = () => {
    const result = [
      "description",
      "price",
      "startTime",
      "endTime",
      "startDate",
    ].filter((val) => {
      return allFields[val] === undefined || allFields[val] === "";
    });
    if (selectedSpecialization.length === 0) {
      result.push("specialization");
    }
    if (hourlyRate < 1) {
      result.push("price");
    }
    if (hourlyRate * getDateDifference().toFixed(2) <= 0) {
      result.push("endTime");
    }
    if (result.length === 0) {
      setShowConfirmModal(true);
    } else {
      setErrorFields(result);
    }
  };

  const getDateDifference = () => {
    let diff = 0;
    if (allFields.startDate && allFields.endDate) {
      const startDate = moment(
        `${moment(allFields.startDate).format("YYYY-MM-DD")} ${moment(
          allFields.startTime
        ).format("H:mm A")}`,
        "YYYY-MM-DD H:mm A"
      );
      const endDate = moment(
        `${moment(allFields.endDate).format("YYYY-MM-DD")} ${moment(
          allFields.endTime
        ).format("H:mm A")}`,
        "YYYY-MM-DD H:mm A"
      );
      diff = endDate.diff(startDate, "minutes") / 60;
    }

    return parseFloat(diff.toFixed(1));
  };
  return (
    <>
      <GradientHeader
        // Button Linear Gradient
        colors={
          isUrgent
            ? ["rgb(248, 100, 108)", "rgb(242, 49, 123)", "rgb(234,0,137)"]
            : ["#72C6EF", "rgb(86,161,209)", "rgb(54,124,180)"]
        }
      />

      <ScrollView style={{ paddingTop: 10 }} extraHeight={100}>
        <Row
          style={{
            justifyContent: "flex-end",
            width:
              Dimensions.get("window").width > 780
                ? 800
                : Dimensions.get("window").width,
            alignSelf: "center",
          }}
        >
          <TouchableOpacity onPress={() => props.navigation.goBack()}>
            <View style={styles.backButtonContainer}>
              <Ionicons name="close" size={28} color="white" />
            </View>
          </TouchableOpacity>
        </Row>

        <View
          style={{
            marginTop: 20,
            width:
              Dimensions.get("window").width > 780
                ? 780
                : Dimensions.get("window").width,
            alignSelf: "center",
          }}
        >
          <Row
            style={{
              alignItems: "center",
              marginBottom: 12,
              paddingLeft: 24,
              zIndex: 100,
            }}
          >
            <Feather name="inbox" size={24} color="black" />
            <Text style={styles.jobsAvailableTitle}>
              {editJobData ? "Edit" : "Post"} Job
            </Text>
          </Row>
          <Text
            style={{
              marginLeft: 24,
              color: "rgba(0,0,0,0.5)",
              zIndex: 100,
            }}
          >
            {editJobData
              ? "Edit the description of job for doctors"
              : "Add a new job for doctors to apply."}
          </Text>
          <Image
            source={require("../../assets/images/wave.png")}
            style={{ width: "100%", zIndex: -10, marginTop: -98 }}
          />

          <View
            style={{
              borderTopLeftRadius: Platform.OS === "web" ? 32 : 0,
              borderTopRightRadius: Platform.OS === "web" ? 32 : 0,
              backgroundColor: "white",
              paddingHorizontal: 24,
              paddingTop: Platform.OS === "web" ? 100 : 0,
            }}
          >
            <View style={{ marginTop: 12, marginHorizontal: -18 }}>
              <JobCard
                clinicJobList
                forceIsDoctor={true}
                data={{
                  is_specialist_job: isSpecialist,
                  is_emergency: isUrgent,
                  start_timestamp: moment(
                    `${moment(allFields.startDate).format(
                      "YYYY-MM-DD"
                    )} ${moment(allFields.startTime).format("H:mm A")}`,
                    "YYYY-MM-DD H:mm A"
                  ).valueOf(),
                  end_timestamp: moment(
                    `${moment(allFields.endDate).format("YYYY-MM-DD")} ${moment(
                      allFields.endTime
                    ).format("H:mm A")}`,
                    "YYYY-MM-DD H:mm A"
                  ).valueOf(),
                  is_cash: isCash,
                  status: false,
                  price:
                    hourlyRate > 0
                      ? (hourlyRate * getDateDifference()).toFixed(0)
                      : 150,
                  clinic: {
                    city: clinicsQuery?.data?.data[0]?.city,
                    name: clinicsQuery?.data?.data[0]?.name,
                  },
                  title:
                    (title.length > 1 ? title.join(", ") : title[0]) ??
                    "General Practitioner",
                  description:
                    allFields["description"] === ""
                      ? "Job description will be shown here"
                      : allFields["description"],
                }}
                fromHome
              />
            </View>
            {isUrgent ? (
              <Row
                centered
                style={{
                  marginBottom: 0,
                  margin: 14,
                  marginTop: 0,
                  justifyContent: "center",
                }}
              >
                <Feather name={"alert-triangle"} color={"red"} />
                <Text
                  style={{
                    fontSize: normalize(12),
                    marginLeft: 4,
                    color: "red",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>
                    This is an Urgent Job
                  </Text>{" "}
                  - it will be displayed on top of the list.
                </Text>
              </Row>
            ) : null}
            {/* <Input
              onChangeText={(val) => {
                setField(input.title, val);
              }}
              value={allFields[input.title]}
              icon={<Feather name="bookmark" size={14} color="grey" />}
              placeholder="Job Title"
              containerStyle={{ width: "100%", marginTop: 18 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields([]);
                setFocusedInput(input.title);
              }}
              error={errorFields.includes(input.title)}
              focused={focusedInput === input.title}
              bordered={true}
            /> */}
            <View style={{ marginTop: 0 }}>
              <DateTimeSelect
                errors={errorFields}
                initialData={allFields}
                isEdit={editJobData}
                limitDate
                onChange={(dates) => {
                  setErrorFields([]);
                  setAllDateTime(dates);
                  setAllFields({ ...allFields, ...dates });
                  setIsTimeSet(true);
                }}
              />
            </View>
            <Row centered>
              <Input
                onChangeText={(val) => {
                  const cleanNumber = val.replace(/[^0-9]/g, "");

                  setField(input.price, cleanNumber);
                  setHourlyRate(cleanNumber);
                  setTotalPrice(
                    Math.ceil(cleanNumber * getDateDifference()).toString()
                  );
                }}
                returnKeyType="done"
                value={allFields[input.price]?.toString()}
                icon={
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: normalize(12),
                      color: "grey",
                    }}
                  >
                    RM
                  </Text>
                }
                disabled={!isTimeSet}
                placeholder="Price"
                keyboardType="numeric"
                containerStyle={{
                  width: "60%",
                  marginTop: 18,
                  marginBottom: 0,
                }}
                inputStyle={{ width: "60%" }}
                onBlur={() => setFocusedInput(null)}
                maxLength={4}
                onFocus={() => {
                  setErrorFields([]);
                  setFocusedInput(input.price);
                }}
                error={errorFields.includes(input.price)}
                focused={focusedInput === input.price}
                bordered={true}
                backComponent={() => (
                  <Text>
                    x{" "}
                    <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      {getDateDifference().toFixed(1)}
                    </Text>{" "}
                    hour
                    {getDateDifference() > 1 ? "s " : " "}
                    {" = "}
                    {/* <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                      RM
                      {Math.ceil(hourlyRate * getDateDifference())}
                    </Text>{" "}
                    total */}
                  </Text>
                )}
              />
              <Input
                bordered={true}
                containerStyle={{
                  width: "35%",
                  marginTop: 18,
                  marginBottom: 0,
                  marginLeft: 10,
                }}
                disabled={!isTimeSet}
                onChangeText={(val) => {
                  setTotalPrice(val);
                }}
                returnKeyType="done"
                placeholder="Total"
                onBlur={() => {
                  const cleanNumber = totalPrice.replace(/[^0-9]/g, "");
                  const p = (cleanNumber / getDateDifference()).toFixed(2);

                  setField(input.price, p);
                  setHourlyRate(p);
                  if (parseInt(cleanNumber) < getDateDifference()) {
                    setTotalPrice(getDateDifference().toString());
                  }
                }}
                inputStyle={{ width: "35%" }}
                value={totalPrice}
                keyboardType="numeric"
                icon={
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: normalize(12),
                      color: "grey",
                    }}
                  >
                    RM
                  </Text>
                }
              />
            </Row>
            <View
              style={{
                width: "100%",
              }}
            >
              {[
                // {
                //   title: "Super-Pay",
                //   subtitle: "S-PAY",
                //   description: `Free booking when you pay the total shift rate. Payments will be distributed by Supermedic to the doctor.\n`,
                // },
                {
                  title: "Cash Payment",
                  subtitle: "CASH",
                  description:
                    "Free to book. \nYou are required to make all payments to the locum professional directly",
                },
              ].map((item, index) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      // setIsCash(index);
                    }}
                  >
                    <View
                      centered
                      style={{
                        // margin: 4,
                        marginTop: 16,
                        borderWidth: 1,
                        borderColor: "rgba(0,0,0,0.25)",
                        // isCash === index
                        //   ? "rgb(242, 49, 123)"
                        //   : "rgba(0,0,0,0.25)",
                        padding: 12,
                        borderRadius: 16,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          // justifyContent: "space-between",
                        }}
                      >
                        <View
                          style={{
                            paddingHorizontal: 10,
                            paddingVertical: 4,
                            borderWidth: 1,
                            borderColor: !index ? "#FC6767" : "grey",
                            borderRadius: 10,
                            alignSelf: "flex-start",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: normalize(10),
                              color: !index ? "#FC6767" : "grey",
                              fontWeight: "bold",
                            }}
                          >
                            {item.subtitle}
                          </Text>
                        </View>
                        {/* {index === 1 ? (
                          <View>
                            <Text
                              style={{
                                fontSize: normalize(10),
                                color: "grey",
                                marginBottom: 2,
                              }}
                            >
                              Booking Fee
                            </Text>
                            <Text
                              style={{
                                fontSize: normalize(12),
                                fontWeight: "bold",
                                textAlign: "right",
                                marginBottom: -4,
                              }}
                            >
                              RM1.00
                            </Text>
                          </View>
                        ) : null} */}
                        <Text
                          style={{
                            fontWeight: "bold",
                            marginLeft: 8,
                            fontSize: normalize(16),
                          }}
                        >
                          {item.title}
                        </Text>
                      </View>

                      <Text
                        style={[
                          {
                            fontSize: normalize(12),
                            color: "grey",
                            marginTop: 6,
                          },
                          Platform.OS === "web"
                            ? {
                                width:
                                  Dimensions.get("window").width > 780
                                    ? 780 / 2.33
                                    : Dimensions.get("window").width / 2.6,
                              }
                            : {},
                        ]}
                      >
                        {item.description}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>

            <Input
              onChangeText={(val) => {
                setField(input.description, val);
              }}
              value={allFields[input.description]}
              icon={<Feather name="info" size={14} color="grey" />}
              placeholder={`Description:\nPlease describe incentives for procedures or treatments if any e.g. RTK/PCR/Injection additional allowance e.g. parking, meals, petrol if any.\nPreference of doctor e.g. female, good with kids, spoken language.`}
              containerStyle={{ width: "100%", marginTop: 18, height: 160 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields([]);
                setFocusedInput(input.description);
              }}
              minHeight={150}
              maxLength={300}
              error={errorFields.includes(input.description)}
              focused={focusedInput === input.description}
              multiline={true}
              bordered={true}
              multilineNumber={7}
            />

            {/* <Input
              onChangeText={(val) => {
                setField(input.note, val);
              }}
              minHeight={110}
              value={allFields[input.note]}
              icon={<Feather name="clipboard" size={14} color="grey" />}
              placeholder={`Notes:\nPlease describe what the doctor needs to do when he or she arrives.\nE.g. Call or look for Mrs/Mr when you arrive or go to the main desk.`}
              maxLength={200}
              containerStyle={{ width: "100%", marginTop: 18, height: 130 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields([]);
                setFocusedInput(input.note);
              }}
              error={errorFields.includes(input.note)}
              focused={focusedInput === input.note}
              multiline={true}
              bordered={true}
              multilineNumber={6}
            /> */}
            <Input
              onChangeText={(val) => {
                setFilterText(val);
              }}
              value={filterText}
              icon={<MaterialIcons name="search" size={14} color="grey" />}
              placeholder="Search specialization"
              containerStyle={{ width: "100%", marginTop: 18 }}
              onBlur={() => setFocusedInput(null)}
              onFocus={() => {
                setErrorFields([]);
                setFocusedInput(input.search);
              }}
              error={errorFields.includes(input.search)}
              focused={focusedInput === input.search}
              bordered={true}
            />
            {errorFields.includes("specialization") ? (
              <Text
                style={{ fontSize: normalize(10), color: "red", marginTop: 10 }}
              >
                Please select at least one specialty
              </Text>
            ) : null}
            <CategoryTab
              selectedCategoryID={selectedCategoryID}
              setSelectedCategoryID={setSelectedCategoryID}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
            {allCategories.map((category, idx) => {
              let type = category.split("-")[0];
              let specialist = false

              if (type.toLowerCase() !== selectedCategory.toLowerCase()) {
                return null;
              }
                if(category.split("-")[1].toLowerCase() === "specialist") {
                specialist = true
              }
              return (
                <Categories
                  show={false}
                  data={facilityData}
                  selected={selectedSpecialization}
                  onPress={(index, children, name) => pickedItem(index, children,name, specialist)}
                  title={category.split("-")[1]}
                  isCheckbox
                  category={category}
                  isDoctor={true}
                  filterText={filterText}
                />
              );
            })}

            <View style={{ margin: 50 }} />
          </View>
        </View>
      </ScrollView>
      <FloatingButton
        status={"rejected"}
        onPress={() => {
          onPressDone();
        }}
      >
        <View
          style={{
            borderRadius: 100,
            overflow: "hidden",
          }}
        >
          <LinearGradient
            start={{ x: -0.2, y: 0.2 }}
            // Button Linear Gradient
            colors={[
              "rgb(248, 100, 108)",
              "rgb(242, 49, 123)",
              "rgb(234,0,137)",
            ]}
          >
            <View
              style={{
                height: 70,
                width: 70,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Feather name={"check-circle"} size={30} color="white" />
            </View>
          </LinearGradient>
        </View>
      </FloatingButton>
      <Modal
        isVisible={showConfirmModal}
        onBackdropPress={() => setShowConfirmModal(false)}
      >
        <View style={{ alignItems: "center", marginBottom: 12 }}>
          <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
        </View>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: normalize(16),
            textAlign: "center",
          }}
        >
          Confirm Job
        </Text>
        <Text
          style={{
            textAlign: "center",
            marginTop: 10,
            marginBottom: 24,
            marginHorizontal: 24,
          }}
        >
          {editJobData
            ? "Are you sure you want to edit this job?"
            : " By creating this job, it will be posted in the jobs page and you are required to pay the approved doctor."}
        </Text>
        <ModalButtons
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={onConfirmPostJob}
        />
      </Modal>
      {Platform.OS === "web" ? null : <Loading visible={verifying} />}
    </>
  );
};

function* range(start, end, step) {
  while (start < end) {
    yield start;
    start += step;
  }
}

const styles = {
  jobsAvailableTitle: {
    fontSize: 24,
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginLeft: 10,
  },
  backButtonContainer: {
    backgroundColor: "rgba(0,0,0,0)",
    justifyContent: "center",
    paddingRight: 18,
    borderRadius: 14,
    paddingVertical: 10,
    marginBottom: 40,
    marginLeft: 24,
    marginTop: 14,
  },
  modalButton: {
    marginTop: 20,
    paddingHorizontal: 20,
  },
};

export default PostJob;
