import React from "react";
import { Text, View, Dimensions, Platform, Image } from "react-native";
import { WebView } from "react-native-webview";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentSetup } from "../../reducers/auth/user";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Rating, AirbnbRating } from "react-native-ratings";
import { normalize } from "../../utils/normalize";
import { Feather, MaterialIcons, FontAwesome5 } from "@expo/vector-icons";
import { BackButton, Button } from "../../components/all";

const RatingScreen = (props) => {
  const dispatch = useDispatch();
  const safeInsets = useSafeAreaInsets();
  const { isDoctor } = useSelector((state) => state.user);
  const profileImg = props.route.params?.profileImg;
  const allowEdit = props.route.params?.allowEdit;

  const ratingCompleted = (val) => {
    console.log("check val", val);
  };
  return (
    <View style={{ flex: 1 }}>
      <BackButton isClose noTopPadding />

      <View
        style={{
          backgroundColor: "white",
          borderRadius: 24,
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <View style={{ ...styles.headerContainer }}>
          <View
            style={{
              alignItems: "center",
              marginTop: 42,
              marginHorizontal: 40,
            }}
          >
            <Image
              source={{
                uri: profileImg
                  ? "data:image/jpg;base64," + profileImg
                  : "https://i0.wp.com/www.themarginalian.org/wp-content/uploads/2014/01/alanwatts.jpg?w=680&ssl=1",
              }}
              style={{
                height: 90,
                width: 90,
                marginVertical: 10,
                borderRadius: 100,
              }}
            />
            <Text style={styles.headerName}>{`Professionalism`}</Text>

            <Text style={{ marginTop: 8, textAlign: "center" }}>
              A good communicator, knowledgeable and able to carry out the
              required work.
            </Text>
            <Rating
              jumpValue={1}
              startingValue={3}
              fractions={0}
              onFinishRating={ratingCompleted}
              readonly={!allowEdit}
              style={{ paddingVertical: 10 }}
            />

            <Text style={styles.headerName}>{`Punctuality`}</Text>

            <Text style={{ marginTop: 8, textAlign: "center" }}>
              On time for assigned locum shift.
            </Text>
            <Rating
              jumpValue={1}
              startingValue={3}
              fractions={0}
              readonly={!allowEdit}
              onFinishRating={ratingCompleted}
              style={{ paddingVertical: 10 }}
            />

            <Text style={styles.headerName}>{`Personality`}</Text>

            <Text style={{ marginTop: 8, textAlign: "center" }}>
              Warm personality, detailed oriented, responsible and motivated.
            </Text>
            <Rating
              jumpValue={1}
              startingValue={3}
              fractions={0}
              readonly={!allowEdit}
              onFinishRating={ratingCompleted}
              style={{ paddingVertical: 10 }}
            />
          </View>
        </View>
        <View>
          {allowEdit ? (
            <Button
              label={`Submit`}
              onPress={() => {}}
              type={"primary"}
              style={{
                marginBottom: 50,
                marginHorizontal: 24,
                alignItems: "center",
              }}
              textStyle={{
                textAlign: "center",
              }}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
};
const styles = {
  container: {
    flex: 1,
    width: "100%",
    alignSelf: "center",
  },
  headerContainer: {
    margin: 30,
    marginTop: 0,
    marginBottom: 4,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerName: {
    fontSize: normalize(24),
    fontFamily: "NotoSerif",
    letterSpacing: -1,
    marginTop: 24,
  },
};
export default RatingScreen;
