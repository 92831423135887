import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  Platform,
  Alert,
} from "react-native";
import { WebView } from "react-native-webview";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentSetup } from "../../reducers/auth/user";
import { BackButton, Title, Button, Row } from "../../components/all";
import { FontAwesome5 } from "@expo/vector-icons";
import { ScrollView } from "react-native-gesture-handler";
import { useQuery, useMutation } from "react-query";
import { getBanks, postBank } from "../../services/job";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { normalize } from "../../utils/normalize";
import * as Linking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import Styles from "../../constants/styles";
const SelectBankScreen = (props) => {
  const dispatch = useDispatch();
  const safeInsets = useSafeAreaInsets();
  const jobID = props.route.params?.jobID;

  const { data: bankQuery, refetch, isLoading } = useQuery("banks", getBanks);
  const [loadingBank, setLoadingBank] = useState(false);

  const onSubmitBank = useMutation(postBank, {
    onError: (error) => alert("Oops! " + error.response.data.message),
    onSuccess: async (data) => {
      if (Platform.OS === "web") {
        // Linking.openURL(data.data.data.url);
        let result = await WebBrowser.openBrowserAsync(data.data.data.url);
        console.log("web browser result", result);
        props.navigation.navigate("Home");
      } else {
        props.navigation.navigate("MakePaymentScreen", {
          gotoURL: data.data.data.url,
        });
      }
    },
  });

  const [isPersonalAccount, setIsPersonalAccount] = useState(true);
  const [selectedIndex, setselectedIndex] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);

  const onSelectAccountType = (bool) => {
    setLoadingBank(true);
    setselectedIndex(null);
    setIsPersonalAccount(bool);

    setTimeout(() => {
      setLoadingBank(false);
    }, 200);
  };

  const onPressSubmit = () => {
    if (!selectedIndex) Alert.alert("Please select a bank");
    onSubmitBank.mutate({
      bank_name: selectedBank.name,
      bank_code: selectedBank.code,
      account_type: isPersonalAccount ? "personal" : "business",
      job: jobID,
    });
  };

  const accountTypeButton = (isPersonal) => {
    return (
      <TouchableOpacity
        onPress={() => {
          onSelectAccountType(isPersonal);
        }}
      >
        <View
          style={{
            ...styles.accountTypeButton,
            width:
              Dimensions.get("window").width > 780
                ? 780 / 2.3
                : Dimensions.get("window").width / 2.4,
            borderWidth: isPersonal === isPersonalAccount ? 1 : 0,
            backgroundColor:
              isPersonal === isPersonalAccount
                ? "rgba(0,0,0,0.02)"
                : "rgba(0,0,0,0.05)",
          }}
        >
          <FontAwesome5
            name={isPersonal ? "user-alt" : "briefcase"}
            size={20}
          />
          <Text style={{ marginTop: 14, textAlign: "center" }}>
            {isPersonal ? "Personal\nAccount" : "Business\nAccount"}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <>
      <ScrollView
        style={{
          ...Styles.webContainer,
        }}
      >
        <View
          style={{
            paddingTop: safeInsets.top + 40,
          }}
        />
        <View style={styles.container}>
          <FontAwesome5 name={"money-bill-wave-alt"} size={24} />
          <Title
            label="Make Payment"
            style={{ fontSize: normalize(32), marginVertical: 24 }}
          />

          <Title label="Account Type" style={styles.title} />
          <View style={styles.accountTypeContainer}>
            {accountTypeButton(true)}
            {accountTypeButton(false)}
          </View>
          <View style={{ margin: 20 }} />

          <Title label="Select a Bank" style={styles.title} />
          {isLoading || loadingBank ? (
            <View style={{ marginTop: 100 }}>
              <ActivityIndicator />
            </View>
          ) : (
            <>
              {bankQuery?.data?.data?.banks[
                isPersonalAccount ? 0 : 1
              ].banks.map((bank, index) => (
                <TouchableOpacity
                  onPress={() => {
                    if (selectedIndex === index) {
                      setselectedIndex(null);
                      setSelectedBank(null);
                    } else {
                      setselectedIndex(index);
                      setSelectedBank(bank);
                    }
                  }}
                >
                  <View style={styles.bankContainer}>
                    <View>
                      <FontAwesome5
                        name={
                          selectedIndex === index ? "check-circle" : "circle"
                        }
                        size={20}
                        color={
                          selectedIndex === index ? "#000" : "rgba(0,0,0,0.2)"
                        }
                      />
                    </View>
                    <Row centered>
                      <Text
                        style={{
                          marginLeft: 10,
                          width:
                            Dimensions.get("window").width > 780
                              ? 780 - 240
                              : Dimensions.get("window").width - 240,
                          fontSize: normalize(16),
                        }}
                      >
                        {bank.display_name}
                      </Text>
                      <Image
                        style={styles.bankLogo}
                        source={{ uri: bank.logo }}
                      />
                    </Row>
                  </View>
                </TouchableOpacity>
              ))}
            </>
          )}
        </View>
        <View style={{ margin: 20 }} />
      </ScrollView>
      <View>
        <Row
          style={{
            justifyContent: "center",
            paddingBottom: safeInsets.bottom,
            paddingTop: 8,
          }}
        >
          <Button
            type={"secondary"}
            onPress={() => props.navigation.goBack()}
            label={"Back"}
          />
          <Button
            onPress={() => onPressSubmit()}
            type={"primary"}
            label={"Submit"}
          />
        </Row>
      </View>
    </>
  );
};
const styles = {
  container: {
    paddingHorizontal: 24,
  },
  title: {
    marginBottom: 16,
    color: "rgba(0,0,0,0.7)",
  },
  bankLogo: {
    width: 140,
    height: 32,
  },
  bankContainer: {
    flexDirection: "row",
    marginVertical: 16,
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
  },
  accountTypeButton: {
    padding: 20,
    borderRadius: 12,
    alignItems: "center",
    alignSelf: "stretch",
    borderColor: "rgba(0,0,0,0.3)",
  },
  accountTypeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
};
export default SelectBankScreen;
