import { Ionicons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import React from "react";
import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import Modal from "./Modal";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Styles from "../../constants/styles";
import { Row, Input, ModalButtons } from "../all";
import { normalize } from "../../utils/normalize";

const CancelJobModal = (props) => {
  const safeInsets = useSafeAreaInsets();
  const { isDoctor } = useSelector((state) => state.user);

  return (
    <Modal
      isVisible={props.isVisible}
      onBackdropPress={props.onBackdropPress}
      onModalHide={props.onModalHide}
    >
      <View
        style={{
          marginHorizontal: 24,
        }}
      >
        <View style={{ alignItems: "flex-start", marginVertical: 12 }}>
          <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
        </View>
        <Text style={{ fontWeight: "bold", fontSize: normalize(24) }}>
          Job Cancellation
        </Text>
        <Text style={{ marginTop: 10, marginBottom: 24 }}>
          This {isDoctor ? "clinic" : "doctor"} has requested for a
          cancellation, the reason for cancellation is as follows:
        </Text>
        <View
          style={{
            fontWeight: "bold",
            paddingLeft: 18,
            paddingVertical: 12,
            backgroundColor: "rgba(0,0,0,0.05)",
            borderRadius: 8,
          }}
        >
          <Text style={{}}>{props.reason}</Text>
        </View>
      </View>
      <View style={{ margin: 10 }} />
      <ModalButtons
        onCancel={props.onCancel}
        leftLabel={"Reject"}
        rightLabel={"Approve"}
        onConfirm={props.onConfirm}
      />
    </Modal>
  );
};

const styles = {
  modalStyle: {
    margin: 0,
    justifyContent: "flex-end",
  },
  modalContainer: {
    backgroundColor: "white",
    height: "60%",
    borderTopLeftRadius: 48,
    borderTopRightRadius: 48,

    marginHorizontal: 0,
    margin: 0,
    ...Styles.shadows,
  },
  calendarContainer: {
    margin: 48,
    marginTop: 12,
  },
  saveButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  resetButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.05)",
  },
};

CancelJobModal.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default CancelJobModal;
