import { Ionicons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import React from "react";
import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Styles from "../../constants/styles";
import { normalize } from "../../utils/normalize";
import { Row, Status, ModalButtons } from "../all";

const ChangeStatusModal = (props) => {
  const safeInsets = useSafeAreaInsets();
  const { isDoctor } = useSelector((state) => state.user);

  return (
    <Modal
      backdropTransitionOutTiming={0}
      animationIn={"fadeIn"}
      animationOut={"fadeOut"}
      onBackdropPress={() => props.onHide()}
      {...props}
    >
      <View
        style={{
          width:
            Dimensions.get("window").width > 480
              ? 480
              : Dimensions.get("window").width - 40,
          alignSelf: "center",
          backgroundColor: "white",
          paddingTop: 20,
          borderRadius: 20,
          borderTopLeftRadius: 20,
        }}
      >
        <View style={{ alignItems: "center", marginBottom: 12 }}>
          <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
        </View>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: normalize(20),
            textAlign: "center",
          }}
        >
          {props.isReject ? "Reject Doctor" : "Update Status"}
        </Text>

        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginVertical: 20,
          }}
        >
          <Status status={props.fromStatus} doctorCard />
          <View style={{ marginHorizontal: 20 }}>
            <Ionicons
              name="arrow-forward-sharp"
              size={20}
              color={"rgba(0,0,0,0.7)"}
            />
          </View>
          <Status status={props.isReject ? -1 : props.toStatus} doctorCard />
        </Row>

        <Text
          style={{
            textAlign: "center",
            marginTop: 12,
            marginBottom: 14,
            marginHorizontal: 24,
          }}
        >
          You will be updating this {isDoctor ? `job` : `doctor`} status. Are
          you sure you want to perform this action?
        </Text>

        <ModalButtons onCancel={props.onHide} onConfirm={props.onConfirm} />
      </View>
    </Modal>
  );
};

const styles = {
  modalStyle: {
    margin: 0,
    justifyContent: "flex-end",
  },
  modalContainer: {
    backgroundColor: "white",
    height: "60%",
    borderTopLeftRadius: 48,
    borderTopRightRadius: 48,

    marginHorizontal: 0,
    margin: 0,
    ...Styles.shadows,
  },
  calendarContainer: {
    margin: 48,
    marginTop: 12,
  },
  saveButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
  resetButton: {
    alignItems: "center",
    width: Dimensions.get("window").width / 2 - 50,
    backgroundColor: "rgba(0,0,0,0.05)",
  },
};

ChangeStatusModal.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default ChangeStatusModal;
