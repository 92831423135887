import PropTypes from "prop-types";
import * as React from "react";
import { Dimensions, Image, Pressable, Text, View } from "react-native";
import Fonts from "../constants/fonts";
import Card from "./Card";
import { FontAwesome5 } from "@expo/vector-icons";
import { normalize } from "../utils/normalize";

const JobCard = (props) => {
  const data = props.data;
  let prepend = "";
  let postpend = "";
  let value = data.value;
  const cardTitle = props.data?.title;
  const titleArray = cardTitle;
  switch (props.data?.title) {
    case "Total\nEarned":
      prepend = <Text style={styles.rm}>RM</Text>;
      value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      break;
    case "Hours\nWorked":
      value = data.value ? Math.abs(data.value) : 0;
      postpend = (
        <Text
          style={{ fontSize: props.horizontal ? normalize(10) : normalize(8) }}
        >
          hr
        </Text>
      );
      break;
    case "Current\nRating":
      if (value !== "N/A") {
        postpend = (
          <View style={{ paddingLeft: 4, marginRight: -10 }}>
            <Image source={require("../assets/images/star.png")} />
          </View>
        );
      }
      break;
  }
  const centerStyle = props.horizontal
    ? {}
    : {
        alignItems: "center",
        textAlign: "center",
      };
  const horizontalTextContainer = props.horizontal
    ? { justifyContent: "flex-end" }
    : null;
  const horizontalContainer = props?.horizontal
    ? {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingRight: 4,
      }
    : null;
  return (
    <Pressable
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}
    >
      <View
        style={{
          marginHorizontal: 0,
          marginHorizontal: 8,
          minWidth: 100,
          ...horizontalContainer,
        }}
      >
        <View
          style={{
            ...centerStyle,
          }}
        >
          {props?.icon}
          <Text
            style={[
              styles.title,
              centerStyle,
              { marginTop: 12, color: "rgba(0,0,0,0.8)" },
            ]}
          >
            {cardTitle}
          </Text>
        </View>
        <View style={{ ...styles.jobContainer, ...horizontalTextContainer }}>
          <Text
            style={{
              ...styles.statusText,
              marginTop: props.isHome ? 4 : 0,
              fontSize: props.horizontal
                ? normalize(28)
                : props?.smallValue
                ? normalize(11)
                : normalize(18),
            }}
          >
            {prepend}
            {value}
            {postpend}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

const styles = {
  jobContainer: {
    paddingBottom: 8,
    alignItems: "center",
  },
  title: {
    ...Fonts.bold,
    fontSize: normalize(10),
    textTransform: "uppercase",
    marginBottom: 8,
  },
  clinicContainer: {
    marginVertical: 4,
  },
  rm: {
    fontSize: normalize(8),
  },
  statusText: {
    fontWeight: "bold",
    color: "rgba(0,0,0,0.8)",
    fontSize: normalize(18),
    textAlign: "center",
  },
};

JobCard.PropTypes = {
  data: PropTypes.object,
};

export default JobCard;
