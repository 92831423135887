import { BarCodeScanner } from "expo-barcode-scanner";
import * as Location from "expo-location";
import * as Notifications from "expo-notifications";
import React, { useState } from "react";
import { Dimensions, Image, Platform } from "react-native";
// import Onboarding from "react-native-onboarding-swiper";
import { useSelector } from "react-redux";
import { postNotificationToken } from "../../services/auth";
import { useMutation } from "react-query";
import * as Device from "expo-device";

async function registerForPushNotificationsAsync() {
  let token;
  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!");
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync({
      'projectId': 'fb1217de-321c-4a1d-befc-c8ebb96b032c',
    })).data;
  } else {
    alert("Must use physical device for Push Notifications");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return token;
}

const Onboard = ({ navigation }) => {
  const { slug } = useSelector((state) => state.user);
  const sendToken = useMutation(postNotificationToken, {
    onError: (error) => {
      alert("Oops! " + error.response.data.message);
    },
    onSuccess: (res) => {},
  });
  const [expoPushToken, setExpoPushToken] = useState("");

  const checkCurrentPage = (index) => {
    switch (index) {
      case 1:
        setTimeout(() => {
          Location.requestForegroundPermissionsAsync();
        }, 1000);
        break;
      case 2:
        setTimeout(() => {
          registerForPushNotificationsAsync().then((token) => {
            setExpoPushToken(token);
            sendToken.mutate({
              slug,
              token,
            });
          });
        }, 1000);
        break;
      case 3:
        setTimeout(() => {
          BarCodeScanner.requestPermissionsAsync();
        }, 1000);
        break;
    }
  };

  return (
    <>
      {/* <Onboarding
      onDone={() => navigation.navigate("HomeScreen")}
      pageIndexCallback={(index) => checkCurrentPage(index)}
      showSkip={false}
      imageContainerStyles={{ width: Dimensions.get("window").width - 100 }}
      titleStyles={{ fontFamily: "NotoSans-Bold" }}
      subTitleStyles={{ paddingHorizontal: 52 }}
      pages={[
        {
          backgroundColor: "#fff",
          image: (
            <Image
              style={{
                width: 300,
                height: 300,
              }}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/step0.png")}
            />
          ),
          title: "Welcome to Supermedic",
          subtitle:
            "We connect you with the nearest clinics with just 3 simple steps. Tap next to see how it works!",
        },
        {
          backgroundColor: "#fff",
          image: (
            <Image
              style={{
                width: 300,
                height: 300,
              }}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/step1.png")}
            />
          ),
          title: "1. Find a clinic near you",
          subtitle:
            "You can navigate around the map to find the job that suits you best, or filter based on your specialty.",
        },
        {
          backgroundColor: "#fff",
          image: (
            <Image
              style={{
                width: 300,
                height: 300,
              }}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/step2.png")}
            />
          ),
          title: "2. Get notified before the job",
          subtitle:
            "You will be notified on the day of the job, so you can be ready before the job starts.",
        },
        {
          backgroundColor: "#fff",
          image: (
            <Image
              style={{
                width: 300,
                height: 300,
              }}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/step3.png")}
            />
          ),
          title: "3. Scan the QR code",
          subtitle:
            "Once verified by the clinic/hospital, you can start work right away",
        },
        {
          backgroundColor: "#fff",
          image: (
            <Image
              style={{
                width: 300,
                height: 300,
              }}
              resizeMode="contain"
              source={require("../../assets/images/onboarding/step4.png")}
            />
          ),
          title: "Congratulations!",
          subtitle: "You are now ready to start working!",
        },
      ]}
      /> */}
    </>
  );
};

export default Onboard;
