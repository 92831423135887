import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { Dimensions, Platform, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Feather, MaterialIcons } from "@expo/vector-icons";

const GradientHeader = (props) => {
  return (
    <>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        // Button Linear Gradient
        colors={["#72C6EF", "rgb(86,161,209)", "rgb(54,124,180)"]}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: Dimensions.get("window").height,
        }}
        {...props}
      ></LinearGradient>
    </>
  );
};

export default GradientHeader;
