import PropTypes from "prop-types";
import * as React from "react";
import { Text, View, Pressable, Platform } from "react-native";
import Fonts from "../constants/fonts";
import statusFilter from "../utils/statusFilter";
import * as Haptics from "expo-haptics";
import { normalize } from "../utils/normalize";

let types = {
  PENDING: "Pending",
  CANCELLED: "Cancelled",
  APPROVED: "Approved",
  COMPLETED: "Completed",
  PAYMENT: "Payment",
};

const JobCard = (props) => {
  const data = props.data;
  const cardTitle = props.data?.title || "Total";
  const jobs = props?.jobs || [];
  const noJobs = jobs?.length === 0;

  const renderTitle = (label) => {
    let color = "grey";
    switch (label) {
      case types.PENDING:
        color = noJobs ? "#e8e8e8" : "#FFB454";
        break;

      case types.APPROVED:
        color = noJobs ? "#e8e8e8" : "#59C2FF";
        break;

      case types.COMPLETED:
        color = noJobs ? "#e8e8e8" : "#AAD94C";
        break;

      case types.CANCELLED:
        color = noJobs ? "#e8e8e8" : "#FC6767";
        break;

      case types.PAYMENT:
        color = noJobs ? "#e8e8e8" : "#FC6767";
        break;
    }
    return (
      <View style={{ ...styles.row, justifyContent: "center" }}>
        <View style={{ alignItems: "center" }}>
          <View style={{ ...styles.indicator, backgroundColor: color }} />
          <Text style={styles.title}>{label}</Text>
        </View>
      </View>
    );
  };

  return (
    <View
      style={{
        marginHorizontal: 0,
        paddingHorizontal: 14,
        shadowOffset: {
          width: 0,
          height: Platform.OS === "web" ? 0 : -20,
        },
        backgroundColor: "white",
        borderRadius: 20,
        marginBottom: 12,
      }}
      topShadow
    >
      <View style={styles.statusCountContainer}>
        {/* <View style={styles.leftContainer}>
          <Text style={styles.title}>{cardTitle}</Text>
          <Text style={styles.subText}>{jobs.length}</Text>
        </View> */}
        <View style={styles.rightContainer}>
          <View style={styles.row}>
            <Pressable
              onPress={() => {
                Platform.OS === "ios" && Haptics.selectionAsync();
                props.selectFilter(props.isDoctor ? 1 : 2);
              }}
            >
              <View style={styles.statusContainer}>
                {renderTitle(types.PENDING)}
                {/* <Text style={styles.subText}>
                  {
                    jobs.filter((val) => {
                      return statusFilter("pending", val, props.isDoctor);
                    }).length
                  }
                </Text> */}
              </View>
            </Pressable>
            <Pressable
              onPress={() => {
                Platform.OS === "ios" && Haptics.selectionAsync();
                props.selectFilter(props.isDoctor ? 3 : 3);
              }}
            >
              <View style={styles.statusContainer}>
                {renderTitle(props.isDoctor ? types.CANCELLED : types.PAYMENT)}
                {/* <Text style={styles.subText}>
                  {
                    jobs.filter((val) => {
                      return statusFilter("cancelled", val, props.isDoctor);
                    }).length
                  }
                </Text> */}
              </View>
            </Pressable>
            <Pressable
              onPress={() => {
                Platform.OS === "ios" && Haptics.selectionAsync();
                props.selectFilter(props.isDoctor ? 2 : 4);
              }}
            >
              <View style={styles.statusContainer}>
                {renderTitle(types.APPROVED)}
                {/* <Text style={styles.subText}>
                  {
                    jobs.filter((val) => {
                      return statusFilter("approved", val, props.isDoctor);
                    }).length
                  }
                </Text> */}
              </View>
            </Pressable>
            <Pressable
              onPress={() => {
                Platform.OS === "ios" && Haptics.selectionAsync();
              }}
            >
              <View style={styles.statusContainer}>
                {renderTitle(types.COMPLETED)}
                {/* <Text style={styles.subText}>
                  {
                    jobs.filter((val) => {
                      {
                        return statusFilter("completed", val);
                      }
                    }).length
                  }
                </Text> */}
              </View>
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = {
  statusCountContainer: {
    flexDirection: "row",
    paddingTop: 8,
    paddinBottom: 8,
  },
  title: {
    ...Fonts.bold,
    fontSize: normalize(13),
  },
  clinicContainer: {
    marginVertical: 4,
    alignItems: "center",
  },
  leftContainer: {
    marginHorizontal: 20,
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 4,
  },
  rightContainer: {
    justifyContent: "space-around",
    flex: 1,
    borderColor: "rgba(0,0,0,0.05)",
  },
  row: {
    flexDirection: "row",
    alignItems: "space-between",
    justifyContent: "space-around",
    marginHorizontal: 4,
  },
  statusContainer: {
    margin: 5,
    alignItems: "center",
  },
  indicator: {
    backgroundColor: "blue",
    height: 8,
    width: 8,
    borderRadius: 10,
    marginBottom: 5,
  },
  subText: {
    marginTop: 8,
    fontSize: normalize(13),
    color: "rgba(0,0,0,0.5)",
  },
};

JobCard.PropTypes = {
  data: PropTypes.object,
};

export default JobCard;
