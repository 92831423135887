// import "react-native-gesture-handler";

import "expo-dev-client";
import * as React from "react";
import { Alert, Linking, AppState, View, Dimensions, Platform } from "react-native";
import {
  NavigationContainer,
  DefaultTheme,
  createNavigationContainerRef,
  StackActions,
} from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import RootNavigation from "./src/navigation/navigation";
import { useFonts } from "expo-font";
import { connectToDevTools } from "react-devtools-core";
import store from "./src/store";
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./src/store";
import { LogBox } from "react-native";
import NetInfo from "@react-native-community/netinfo";
import api, { interceptor } from "./src/services/api";
import { QueryClientProvider, onlineManager } from "react-query";
import queryClient from "./src/utils/queryClient";
import { setLoggedOut, setIsDoctor } from "./src/reducers/auth/user";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Notifications from "expo-notifications";
import * as Updates from "expo-updates";
import injectWebCss from "./src/utils/injectWebCss";

export const buildVersion = "303";

export default function App() {
  global.XMLHttpRequest = global.originalXMLHttpRequest
    ? global.originalXMLHttpRequest
    : global.XMLHttpRequest;
  global.FormData = global.originalFormData
    ? global.originalFormData
    : global.FormData;

  fetch; // Ensure to get the lazy property
  const navigationRef = createNavigationContainerRef();
  LogBox.ignoreAllLogs(); // TODO: remove this line when you want to see the logs again
  // Text.defaultProps = Text.defaultProps || {};
  // Text.defaultProps.allowFontScaling = false;
  const appUpdate = async () => {
    try {
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        // ... notify user of update ...
        Updates.reloadAsync();
      }
    } catch (err) {
      // handle or log error
      console.log(err);
    }
  };

  if (!__DEV__ && Platform.OS !== 'web') appUpdate();

  if (__DEV__) {
    connectToDevTools({
      host: "localhost",
      port: 8097,
    });
  }

  const lastNotificationResponse = Notifications.useLastNotificationResponse();
  React.useEffect(() => {
    if (lastNotificationResponse?.notification?.request) {
      if (navigationRef.isReady()) {
        navigationRef.navigate("Inbox");
      }
    }
  }, [lastNotificationResponse]);
  React.useEffect(() => {
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
      }),
    });
  }, []);

  React.useEffect(() => {
    store.dispatch(setIsDoctor(false));
    // navigationRef.navigate("RatingScreen");

    let sub = AppState.addEventListener("change", _handleAppStateChange);
    return () => {
      sub.remove();
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    if (nextAppState === "active") {
      // checkUpdate();
    }
  };

  onlineManager.setEventListener((setOnline) => {
    return NetInfo.addEventListener((state) => {
      setOnline(state.isConnected);
    });
  });

  const [loaded] = useFonts({
    NotoSerif: require("./src/assets/fonts/NotoSerif-Bold.ttf"),
    "NotoSans-Bold": require("./src/assets/fonts/NotoSans-Bold.ttf"),
    "NotoSans-Regular": require("./src/assets/fonts/NotoSans-Regular.ttf"),
  });
  if (!loaded) {
    // TODO: add splashscreen
    return null;
  }
  const token = store.getState().user.token;
  const loggedIn = store.getState().user.loggedIn;

  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  interceptor(() => {
    store.dispatch(setLoggedOut());
    queryClient.clear();
    console.log("check logged in", loggedIn);
    navigationRef.current.dispatch(StackActions.replace("AuthScreen"));
    // loggedIn && Restart();
  }, loggedIn);
  injectWebCss();
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <NavigationContainer
            ref={navigationRef}
            linking={{
              config: {
                screens: {
                  ProfileScreen: "me",
                  PostJobScreen: "post",
                  DoctorProfileScreen: "profile",
                  JobDescriptionScreen: "job",
                  HomeScreen: {
                    screens: {
                      Home: "home",
                      Inbox: "inbox",
                      Account: "account",
                    },
                  },
                  AuthScreen: {
                    screens: {
                      LoginScreen: "login",
                      RegisterScreen: "register",
                      ForgotPasswordScreen: "forgot",
                    },
                  },
                },
              },
            }}
            documentTitle={{
              formatter: () => "Supermedic Facilities",
            }}
            theme={{
              ...DefaultTheme,
              colors: {
                ...DefaultTheme.colors,
                background: "white",
              },
            }}
          >
            <StatusBar style="dark" />
            <RootNavigation />
          </NavigationContainer>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}
