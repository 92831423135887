import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recentSearch: {
    location: [],
    jobs: [],
  },
};

export const recentSearch = createSlice({
  name: "recentSearch",
  initialState,
  reducers: {
    addRecentSearch: (state, action) => {
      let currentList = state.recentSearch[action.payload.type];
      if (currentList.includes(action.payload.data)) {
        currentList.splice(currentList.indexOf(action.payload.data), 1);
      }
      const newList = [action.payload.data, ...currentList];

      state.recentSearch[action.payload.type] = newList;
    },
    removeRecentSearch: (state, action) => {
      let currentList = state.recentSearch[action.payload.type];
      if (currentList.includes(action.payload.data)) {
        currentList.splice(currentList.indexOf(action.payload.data), 1);
      }
    },
  },
});

export const { addRecentSearch, removeRecentSearch } = recentSearch.actions;

export default recentSearch.reducer;
