import PropTypes from "prop-types";
import Styles from "../constants/styles";
import * as React from "react";
import { Dimensions, Platform, TouchableOpacity, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import withPreventDoubleClick from "../utils/preventDoubleClick";
const TouchableOpacityEx = withPreventDoubleClick(TouchableOpacity);

const FloatingButton = (props) => {
  const safeInsets = useSafeAreaInsets();

  let buttonStyle =
    Platform.OS === "web"
      ? {
          position: "absolute",
          right: 12,
          bottom: 12,
        }
      : {
          position: "absolute",
          right: 14,
        };

  let colors = [];
  switch (props.status) {
    case "approved":
      colors = ["#56ab2f", "#a8e063"];
      break;
    case "pending":
      colors = ["#005EEC", "#67B4FC"];
      break;
    case "completed":
      colors = ["#F7971E", "#FFD200"];
      break;
    case "rejected":
      colors = ["#EC008C", "#FC6767"];
      break;
    default:
      colors = ["#005EEC", "#67B4FC"];
      break;
  }
  const buttonWrapper = (child) => {
    if (Platform.OS === "web") {
      return (
        <View
          style={{
            flex: 1,
            ...Styles.webContainer,
          }}
        >
          {child}
        </View>
      );
    } else {
      return <>{child}</>;
    }
  };
  return (
    <>
      {buttonWrapper(
        <View
          style={{
            bottom: safeInsets.bottom,

            ...buttonStyle,
          }}
        >
          <TouchableOpacityEx onPress={props.onPress}>
            <View {...props} style={[styles.buttonStyle, props.style]}>
              {props.children}
            </View>
          </TouchableOpacityEx>
        </View>
      )}
    </>
  );
};

const styles = {
  buttonContainer: {
    position: "absolute",
    right: 14,
  },
  buttonStyle: {
    backgroundColor: "white",
    borderRadius: 100,
    margin: 10,
  },
};

FloatingButton.PropTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
};

export default FloatingButton;
