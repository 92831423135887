// only for doctor
import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { Alert, Dimensions, Text, TouchableOpacity, View } from "react-native";
import {
  StepHeader,
  Button,
  Row,
  Centered,
  BackButton,
} from "../../../components/all";
import colors from "../../../constants/colors";
import Styles from "../../../constants/styles";
import professions from "../../../constants/lov/professions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch, useSelector } from "react-redux";

const storeData = async (value) => {
  try {
    await AsyncStorage.setItem("profession", JSON.stringify(value));
  } catch (e) {
    // saving error
  }
};

export default function Step5({ navigation, setField }) {
  const { isDev } = useSelector((state) => state.user);

  const input = {
    emailCode: "emailCode",
    isGP: "isGP",
    isSpecialist: "isSpecialist",
  };

  const [selectedExpertise, setSelectedExpertise] = React.useState(null);

  return (
    <View
      style={{
        ...Styles.webSmallContainer,
        marginTop: 80,
      }}
    >
      <StepHeader
        label={`Medical Profession`}
        icon={<FontAwesome name="envelope-o" size={24} color="black" />}
        subtitle={`Select your profession`}
      />
      <View
        style={{
          width: "100%",
          marginTop: 32,
          alignItems: "center",
        }}
      >
        {professions.map((item, index) => (
          <TouchableOpacity
            onPress={() => {
              if (!isDev) {
                if(index > 0) {
                  Alert.alert("Coming Soon!");
                } else {
                  storeData(item);
                  setSelectedExpertise(index + 1);
                }
              } else {
                // setField(input.isSpecialist, index === 1);
                storeData(item);
                setSelectedExpertise(index + 1);
              }
            }}
          >
            <View
              style={{
                ...styles.category,
                width: Dimensions.get("window").width * 0.8,
                borderColor:
                  selectedExpertise === index + 1
                    ? colors.primary
                    : "transparent",
                backgroundColor:
                  selectedExpertise === index + 1
                    ? "white"
                    : "rgba(0,0,0,0.04)",
              }}
            >
              <Text style={{ textAlign: "center" }}>
                {item.name.replace("\n", " ")}
              </Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
      <View style={{ marginBottom: 20 }} />

      <Centered>
        <Row style={{ marginBottom: 24 }}>
          <Button
            label="Back"
            type={"secondary"}
            onPress={() => {
              navigation.goBack();
            }}
          />
          <Button
            label="Next"
            type={"primary"}
            onPress={() => {
              if (selectedExpertise) {
                navigation.navigate("RegisterScreen");
              } else {
                alert("Please select your expertise");
              }
            }}
          />
        </Row>
      </Centered>
    </View>
  );
}

const styles = {
  category: {
    padding: 18,
    margin: 6,
    marginTop: 10,
    paddingHorizontal: 24,
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 10,
    width: "80%",
  },
};
