import { Ionicons } from "@expo/vector-icons";
import React from "react";
import {
  TouchableOpacity,
  View,
  Text,
  Pressable,
  Platform,
  Dimensions,
} from "react-native";
import Row from "./Row";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";
import * as Haptics from "expo-haptics";

const BackButton = (props) => {
  const safeInsets = useSafeAreaInsets();
  const navigation = useNavigation();

  return (
    <Row
      style={{
        width:
          Dimensions.get("window").width > 780
            ? 780
            : Dimensions.get("window").width,
        paddingTop: props.noTopPadding ? 0 : safeInsets.top,
        justifyContent: props.isClose ? "flex-end" : "flex-start",
      }}
    >
      <Pressable
        onPressIn={() => {
          if (Platform.OS === "ios") {
            Haptics.selectionAsync();
          }
        }}
        onPress={() => {
          props?.onPressBack && props.onPressBack();
          !props?.ignoreBack && navigation.goBack();
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            justifyContent: "center",
            paddingHorizontal: 14,
            paddingRight: 18,
            borderRadius: 14,
            paddingTop: 20,
            paddingLeft: props.isClose ? 24 : 16,
            paddingBottom: props.compact ? 12 : 18,
          }}
        >
          <Row centered>
            <Ionicons
              name={props.isClose ? "close" : "chevron-back-outline"}
              size={28}
              color={props.color ? props.color : "black"}
            />
            {props.isClose ? null : (
              <Text
                style={{
                  color: props.color ? props.color : "black",
                  fontWeight: "bold",
                  marginLeft: 4,
                }}
              >
                Back
              </Text>
            )}
          </Row>
        </View>
      </Pressable>
    </Row>
  );
};

export default BackButton;
