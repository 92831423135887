import { Feather, Ionicons } from "@expo/vector-icons";
import { BlurView } from "expo-blur";
import React, { useState, useEffect } from "react";
import { Text, TouchableOpacity, View, Platform } from "react-native";
import MapView, { Marker, PROVIDER_GOOGLE } from "react-native-maps";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Centered,
  Row,
  Steps,
  Modal,
  ModalButtons,
} from "../../../components/all";
import { addFields } from "../../../reducers/auth/register";
import { normalize } from "../../../utils/normalize";

export default function Jobs(props) {
  const safeInsets = useSafeAreaInsets();
  const dispatch = useDispatch();
  const { fields, errorFields } = useSelector((state) => state.register);
  const coords = props.route.params?.coords;
  const address = props.route.params?.address;
  const [showGuideModal, setShowGuideModal] = useState(false);
  const mapRef = React.createRef();

  useEffect(() => {
    setTimeout(() => {
      setShowGuideModal(true);
    }, 200);
  }, []);

  const [selectedRegion, setSelectedRegion] = useState({ ...coords });
  let pinShadow =
    Platform.OS === "web"
      ? {}
      : {
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 30,
          },
          shadowOpacity: 0.45,
          shadowRadius: 7.84,
        };
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{ position: "absolute", top: safeInsets.top + 20, left: 20 }}
      >
        <TouchableOpacity
          onPress={() => {
            props.navigation.goBack();
          }}
        >
          <View style={styles.backBotton}>
            <Row>
              <Ionicons name="arrow-back" size={14} color="black" />
              <Text style={{ fontWeight: "bold", marginLeft: 4 }}>Back</Text>
            </Row>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.markerFixed}>
        <View style={pinShadow}>
          <Ionicons
            style={{
              color: "#d40b1b",
            }}
            size={55}
            name={"ios-location-sharp"}
          />
        </View>
      </View>
      <MapView
        provider={PROVIDER_GOOGLE}
        onRegionChangeComplete={(region) => {
          setSelectedRegion({
            longitude: region?.longitude,
            latitude: region?.latitude,
          });
        }}
        initialRegion={{
          ...coords,
          latitudeDelta: 0.00542,
          longitudeDelta: 0.00541,
        }}
        style={{
          zIndex: -2,
          width: "100%",
          height: "100%",
        }}
      ></MapView>
      <BlurView
        intensity={Platform.OS === "ios" ? 80 : 200}
        tint="light"
        style={{
          paddingBottom: safeInsets.bottom,
          paddingTop: 20,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <View style={{ marginHorizontal: 20 }}>
          <Text style={{ fontWeight: "bold", fontSize: normalize(20) }}>
            Find clinic location
          </Text>
          <Text
            style={{
              marginTop: 10,
              color: "#FF4B4B",
            }}
          >
            Drag the map & drop the pin on the exact location of the clinic.
          </Text>
        </View>
        <TouchableOpacity onPress={() => props.navigation.goBack()}>
          <Row
            centered
            style={{
              margin: 20,
              marginBottom: 14,
              marginHorizontal: 32,
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.1)",
              padding: 10,
              borderRadius: 12,
              justifyContent: "center",
            }}
          >
            <View style={{ marginRight: 10 }}>
              <Feather name="map-pin" size={14} />
            </View>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: normalize(16),
                color: "rgba(0,0,0,0.8)",
              }}
            >
              {address}
            </Text>
          </Row>
        </TouchableOpacity>

        <Centered>
          <Row>
            <Button
              label={"Confirm Location"}
              type={"primary"}
              style={{
                alignItems: "center",
              }}
              onPress={() => {
                dispatch(addFields({ ...fields, ...selectedRegion }));
                props.navigation.navigate("CategoryScreen");
              }}
            />
          </Row>
        </Centered>
        <View style={{ alignItems: "center", marginTop: 24, marginBottom: 0 }}>
          <Steps totalPages={4} currentPage={3} />
        </View>
      </BlurView>
      <Modal
        isVisible={showGuideModal}
        onBackdropPress={() => setShowGuideModal(false)}
      >
        <View style={{ alignItems: "center", marginBottom: 12 }}>
          <Ionicons name="alert-circle-outline" size={24} color="#FF4B4B" />
        </View>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: normalize(16),
            textAlign: "center",
          }}
        >
          Adjust Pin
        </Text>
        <Text
          style={{
            textAlign: "center",
            marginTop: 10,
            marginBottom: 24,
            marginHorizontal: 24,
          }}
        >
          Please make sure to place pin on the accurate location of the clinic.
        </Text>
        <ModalButtons
          onCancel={() => setShowGuideModal(false)}
          onConfirm={() => {
            setShowGuideModal(false);
          }}
        />
      </Modal>
    </View>
  );
}

const styles = {
  divider: {
    borderBottomWidth: 1,
    borderColor: "rgba(0,0,0,0.1)",
    marginTop: 20,
    marginBottom: 20,
  },
  detailTitle: {
    color: "grey",
    fontWeight: "bold",
    fontSize: normalize(10),
    marginBottom: 10,
  },
  markerFixed: {
    left: 0,
    right: 0,
    marginLeft: 0,
    marginTop: 0,
    position: "absolute",
    top: "40%",
    alignItems: "center",
  },
  backBotton: {
    backgroundColor: "white",
    justifyContent: "center",
    paddingHorizontal: 14,
    paddingRight: 18,
    borderRadius: 14,
    paddingVertical: 10,
  },
};
